import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {DataTypeProvider, IntegratedFiltering, IntegratedSorting, SortingState,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import {store} from "../../../store/ConfigureStore";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {setShowAddExceptionalHoliday} from "../../../actions/HolidaysActions";
import MGrid from '@material-ui/core/Grid';
import {CountryFormatter, FilterCell, IdEditor, ToJustifyTypeProvider} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {TableFilterRow} from "@devexpress/dx-react-grid-material-ui";
import {FilteringState} from "@devexpress/dx-react-grid";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";


const DocumentFormatter = ({value}) => {
    return <div>{value.map((document) => {
        return (<div>{document.label}</div>)
    })}</div>
};

const IdFormatter = ({value}) => (
    <MGrid container spacing={0}>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(setShowAddExceptionalHoliday({type: "EDIT", open: true, details: value}))
            }
            } color="default" aria-label="add">
                <EditIcon fontSize="small"/>
            </IconButton>
        </MGrid>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(setShowAddExceptionalHoliday({type: "DELETE", open: true, details: value}))
            }} aria-label="Delete">
                <DeleteIcon fontSize="small"/>
            </IconButton>
        </MGrid>
    </MGrid>
);


const CountryColumnsProvider = props => (
    <DataTypeProvider
        formatterComponent={CountryFormatter}
        {...props}
    />
);

const DocumentColumnsProvider = props => (
    <DataTypeProvider
        formatterComponent={DocumentFormatter}
        {...props}
    />
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableColumnExtensions: [
                {columnName: 'duration', width: 100},
                {columnName: 'forJustify', width: 80},
                {columnName: 'country', width: 250},
                {columnName: 'value', width: 150},
                {columnName: 'provedByDocuments', wordWrapEnabled: true},
                {columnName: 'label', wordWrapEnabled: true},
            ],
            countryColumns: ['country'],
            idColumns: ['value'],
            documentColumns: ['provedByDocuments'],
            justifyColumns: ['forJustify']
        };
    }


    render() {
        const {countryColumns, idColumns, documentColumns, tableColumnExtensions, justifyColumns} = this.state;
        const rows = this.props.data;
        const {loading, searchActive} = this.props;
        const {_label, _provedByDocuments, _country, _duration, _totalDuration, _toJustify} = strings;
        const columns = rows[0] && rows[0].totalDuration ? [
                {name: 'label', title: _label},
                {name: 'totalDuration', title: _totalDuration},
                {name: 'provedByDocuments', title: _provedByDocuments},
                {name: 'country', title: _country},
                {name: 'forJustify', title: _toJustify},
                {name: 'value', title: ' '},
            ] : 
            [
                {name: 'label', title: _label},
                {name: 'duration', title: _duration},
                {name: 'provedByDocuments', title: _provedByDocuments},
                {name: 'country', title: _country},
                {name: 'forJustify', title: _toJustify},
                {name: 'value', title: ' '},
            ]
        return (
            <Paper style={tableSize}>
                <Grid
                    rows={rows.map(row => {
                        return {value: row, ...row}
                    })}
                    columns={columns}
                    style={tableSize}
                >

                    <CountryColumnsProvider
                        for={countryColumns}
                    />
                    <DocumentColumnsProvider
                        for={documentColumns}
                    />
                    <IdProvider
                        for={idColumns}
                    />
                    <ToJustifyTypeProvider
                        for={justifyColumns}
                    />
                    <SortingState
                        onSortingChange={(sorting) => {
                            this.props.changeSorting(sorting)
                        }}
                    />
                    <FilteringState
                    onFiltersChange={(filters) => {this.props.changeFilters(filters)}}
                    />
                    <IntegratedSorting/>
                    <IntegratedFiltering/>
                    <Table
                        rowComponent={CustomTableRow}
                        containerComponent={CustomTableContainer}
                        noDataCellComponent={() => {
                            return <CustomEmptyTable loading={loading}/>
                        }}
                        columnExtensions={tableColumnExtensions}
                    />
                    <TableHeaderRow
                        contentComponent={TableHeaderContent}
                        showSortingControls/>
                    {searchActive && <TableFilterRow
                        messages={{filterPlaceholder: " "}}
                        cellComponent={FilterCell}
                    />}
                </Grid>
            </Paper>
        );
    }
}
