import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import {strings} from "../../../../i18n/Strings";

//TODO
const holidayTypes = ["Congé payé","Congé RTT","Congé Ancienneté", "Congé sans solde", "Congé exceptionnel"];
const errorTypes = ["Date de sortie"];
const {_registrationNumber, _returnDate, _startDate, _holidayType} = strings;
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        background: theme.palette.background.paper,
    },
    dialog: {
        width: '80%',
        maxHeight: 500
    },
});


class AdvancedSearchDialog extends React.Component {
    state = {
        selectedType: "",
        startDate: "",
        returnDate: "",
        userName: ""
    };


    handleChange = (event, name) => {
        this.setState({[name]: event.target.value})
    };

    handleSubmit = () => {
        this.setState({
            selectedType: "",
            startDate: "",
            returnDate: "",
            userName: "",
            errorType: ""
        })
    };

    render() {
        const {classes, open} = this.props;
        const {selectedType, startDate, returnDate, userName, errorType} = this.state;
        return (
            <div>
                <Dialog
                    classes={{
                        paper: classes.dialog,
                    }}
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Recherche avancée</DialogTitle>
                    <DialogContent>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Grid container direction={"row"}>
                                    <Grid item sm={4} style={{marginTop: 5}}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            {_registrationNumber}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="matricule"
                                            placeholder="Saisir le matricule"
                                            value={userName}
                                            onChange={(event) => {
                                                this.handleChange(event, "userName")
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction={"row"} style={{marginTop: 15}}>
                                    <Grid item sm={4} style={{marginTop: 5}}>
                                        <Typography variant="subtitle2" gutterBottom>{_startDate}</Typography>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <TextField
                                            type={"date"}
                                            fullWidth
                                            margin="dense"
                                            id="startDate"
                                            value={startDate}
                                            onChange={(event) => {
                                                this.handleChange(event, "startDate")
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction={"row"}>
                                    <Grid item sm={4} style={{marginTop: 5}}>
                                        <Typography variant="subtitle2" gutterBottom>{_returnDate}</Typography>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <TextField
                                            type={"date"}
                                            fullWidth
                                            margin="dense"
                                            id="returnDate"
                                            value={returnDate}
                                            onChange={(event) => {
                                                this.handleChange(event, "returnDate")
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction={"row"}>
                                    <Grid item sm={4} style={{marginTop: 5}}>
                                        <Typography variant="subtitle2" gutterBottom>{_holidayType}</Typography>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <TextField
                                            select
                                            fullWidth
                                            value={selectedType}
                                            onChange={(event) => {
                                                this.handleChange(event, "selectedType")
                                            }}
                                            id="holidayTypes"
                                        >
                                            {holidayTypes.map((type, index) => <MenuItem key={index}
                                                                                         value={type}> {type}</MenuItem>)}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction={"row"}>
                                    <Grid item sm={4} style={{marginTop: 5}}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Type de message d'erreur
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <TextField
                                            select
                                            fullWidth
                                            value={errorType}
                                            onChange={(event) => {
                                                this.handleChange(event, "errorType")
                                            }}
                                            id="errorTypes"
                                        >
                                            {errorTypes.map((type, index) => <MenuItem key={index} value={type}> {type}</MenuItem>)}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.toggleSearch} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.toggleSearch();
                            this.handleSubmit();
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AdvancedSearchDialog);
