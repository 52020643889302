
import React from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import ProfileCard from "../profile/Profile";
import ListItems from "./TitleData";
import MainToolBar from './AppBar';
import Routes from '../../components/routes/Index'
import {BrowserRouter} from "react-router-dom";
import {store} from "../../store/ConfigureStore";
import {toggleDrawer} from "../../actions/ReferencesActions";
import ToasterMessage from "../utilities/ToasterMessage";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getAxios} from "../../actions/AuthActions";
import {API, MY_REQUESTS_FOLLOW} from "../../api/Endpoints";

const drawerWidth = 220;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        boxShadow: '0 3px 8px -5px black',
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        overflowX: "hidden",
        boxShadow: '0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        zIndex: 99
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        borderBottom: '1px solid #cfd8dc'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
});

class PersistentDrawerLeft extends React.Component {
    state = {
        open: true,
    };




    handleDrawerToggle = () => {
        const {drawerOpen} = store.getState().references;
        store.dispatch(toggleDrawer(!drawerOpen));
        this.setState(state => ({open: !state.open}));
    };

    render() {
        const {classes, loading} = this.props;
        const {auth: {profile: {countryCode, userName}}} = this.props;
        const {open} = this.state;
        return (
            <BrowserRouter>
                <div className={classes.root}>
                    <CssBaseline/>
                    <AppBar
                        elevation={0}
                        position="fixed"
                        className={classNames(classes.appBar, {
                            [classes.appBarShift]: open,
                        })}
                    >
                        <MainToolBar handleDrawerToggle={() => {
                            this.handleDrawerToggle()
                        }}/>
                    </AppBar>
                    <Drawer
                        id="drawer"
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <ProfileCard/>
                        </div>
                        <ListItems countryCode={countryCode} count={this.props.count}/>
                    </Drawer>
                    <main
                        className={classNames(classes.content, {
                            [classes.contentShift]: open,
                        })}
                    >
                        <div className={classes.toolbar}/>
                        <div style={{width: `100vw - ${drawerWidth}`, position: "relative"}}>
                            {/*{loading && <LinearProgress/>}*/}
                            <Routes username={userName}/>
                        </div>
                    </main>
                    <ToasterMessage/>
                </div>
            </BrowserRouter>
        );
    }
}


export default withStyles(styles, {withTheme: true})(PersistentDrawerLeft);




