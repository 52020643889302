import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import DoctorSpecialitiesAutoComplete from "./DoctorSpecialitiesAutoComplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import {getAxios} from "../../../../actions/AuthActions";
import {API} from "../../../../api/Endpoints";
import {setShowExcepHolidayDetails} from "../../../../actions/ReferencesActions";

const styles = {
    'input': {
        '&::placeholder': {
            fontSize: 16
        }
    }
};

class ExceptionalDetailsDialog extends React.Component {
    state = {
        open: true,
        doctorsSpecialities: [],
        doctorsSpeciality: {id: null, label: ""},
        doctorName: "",
        certificateDepositDate: moment().format("YYYY-MM-DD")

    };

    componentWillMount() {
        const axios = getAxios();
        axios.get(`${API}/conges/exceptionalType/loadAllDoctorsSpecialities`).then(res => {

            this.setState({doctorsSpecialities: res.data})
        })

    }

    handleDoctorSpecialityChange = (doctorsSpeciality) => {

        this.setState({doctorsSpeciality})
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {doctorsSpeciality, doctorName, certificateDepositDate, doctorsSpecialities} = this.state;
        const {showExcepHolidayDetails} = this.props.references;
        return (

                <Dialog
                    open={showExcepHolidayDetails}
                    onClose={this.handleClose}
                >
                    <DialogTitle>{"Détails congé maladie"}</DialogTitle>
                    <DialogContent>
                        <Grid container direction={"column"} style={{height: 180}} alignItems={"stretch"}
                              justify={"space-around"}>
                            <Grid item>
                                <DoctorSpecialitiesAutoComplete
                                    handleDoctorSpecialityChange={this.handleDoctorSpecialityChange}
                                    doctorsSpecialities={doctorsSpecialities}/>
                            </Grid>
                            <Grid item>
                                <TextField
                                    placeholder="Nom du médecin"
                                    fullWidth
                                    margin="normal"
                                    InputProps={{classes: {input: this.props.classes['input']}}}
                                    value={doctorName}
                                    onChange={(e) => {
                                        this.setState({doctorName: e.target.value});
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container direction={"column"} alignItems={"stretch"} justify={"flex-start"}>
                                    <Grid item> <Typography variant="caption" style={{fontSize: 12, color: "grey"}}
                                                            gutterBottom>Date dépot du certificat</Typography></Grid>
                                    <Grid item> <TextField
                                        fullWidth
                                        type="date"
                                        // defaultValue={moment().format("YYYY-MM-DD")}
                                        onChange={(e) => {
                                            this.setState({certificateDepositDate: e.target.value});
                                        }}
                                        value={certificateDepositDate}
                                    />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.props.setShowExcepHolidayDetails(false);
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.setShowExcepHolidayDetails(false);
                            this.props.handleExcpeHolidayDetails({doctorsSpeciality, doctorName, certificateDepositDate});
                        }} color="primary" autoFocus>
                            Ajouter
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    }
}


const mapStateTopProps = state => {
    return {references: state.references}
};

const mapActionsToprops = {
    setShowExcepHolidayDetails
};

export default connect(mapStateTopProps, mapActionsToprops)(withStyles(styles, {withTheme: true})(ExceptionalDetailsDialog));
