import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getHRRequests} from "../../../actions/RequestsActions";
import RequestHisotryDialog from '../../utilities/RequestHistoryDialog'
import {Subject, timer} from "rxjs";
import {debounce, skip} from "rxjs/operators";
import ExportHRRequests from "./ExportHRRequests";

const styles = theme => ({});


class HRRequests extends Component {
    searchSubject = new Subject();
    state = {
        filters: [],
        sort: ""
    };

    changeFilters = (filters) => {
        this.setState({filters: filters.reduce(function(obj,item){
                obj[item.columnName] = item.value;
                return obj;
            }, {})}, () => {
            const {filters,sort} = this.state;
            const {
                addedForEmp,
                startDate,
                returnDate,
                status,
                typeConge
            } = filters;
            this.searchSubject.next({page: 0,size: 25,addedForEmp,returnDate,startDate,status, typeConge});

        })
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = (page, size, addedForEmp, returnDate, startDate, status, typeRequest, typeConge) => {
        this.setState({page, size});
        this.props.getHRRequests(page - 1, size, addedForEmp, returnDate, startDate, status, typeRequest, typeConge)
    };

    componentDidMount() {
        this.props.getHRRequests(0, 25);
        this.searchSubject.pipe(
            skip(1),
            debounce(() => timer(1000)),
        ).subscribe((searchEvent) => {

            const {page,size,addedForEmp,returnDate,startDate,status, typeRequest, typeConge} = searchEvent;
            this.props.getHRRequests(page,size,addedForEmp,returnDate,startDate,status, typeRequest, typeConge);
        })
    }


    render() {
        const {auth, requests} = this.props;
        const {hrRequests, myRequestsHistory} = requests;
        const {loading, data, totalElements} = hrRequests;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={(e) => {
                        const {page, size} = e;
                        const {addedForEmp, returnDate, startDate, status, typeRequest, typeConge} = this.state.filters;

                        this.changePaging(page, size, addedForEmp, returnDate, startDate, status, typeRequest, typeConge)
                    }}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                <RequestHisotryDialog
                    myRequestsHistory={myRequestsHistory}
                />
                <ExportHRRequests/>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests, auth: state.auth}
};

let mapActionsToProps = {getHRRequests};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(HRRequests));

