import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedSelection,
    IntegratedSorting,
    SelectionState,
    SortingState
} from '@devexpress/dx-react-grid';
import {Grid, Table, TableFilterRow, TableHeaderRow, TableSelection} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import IconButton from '@material-ui/core/IconButton'
import ConsultIcon from '@material-ui/icons/Search'
import {store} from "../../../store/ConfigureStore";
import {
    getMyRequestsFollow,
    getPendingRequestsCountDone, getPendingRequestsDone,
    setRequestValidationDetails
} from "../../../actions/RequestsActions";
import MGrid from '@material-ui/core/Grid'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import {API, MEDIUM_AVATAR_CORE_SERVER, MY_REQUESTS_FOLLOW} from "../../../api/Endpoints";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check'
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmationDialogInMass from "./ConfirmationDialogInMass";
import {
    endDatePredicate,
    FilterCell,
    HolidayTypeProvider,
    IdEditor, noPredicate,
    RequestTypeProvider, startDatePredicate,
    compareDates, compareDateTimes
} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";
import {getRejectionMotifsLabels} from "../../../actions/ReferencesActions";
import {getAxios, rolesToValidation} from "../../../actions/AuthActions";
import {inMassValidation} from "../../../actions/HolidaysActions";

const IdFormatter = ({value}) => (
    <IconButton onClick={() => {
        const {regestionNumberEmployee, id, employeeCountryCode} = value;
        store.dispatch(setRequestValidationDetails(value.id));
        store.dispatch(getMyRequestsFollow(value.id));
        store.dispatch(getRejectionMotifsLabels(regestionNumberEmployee, id, employeeCountryCode));
    }
    } color="default" aria-label="add">
        <ConsultIcon fontSize="small"/>
    </IconButton>
);


const EmployeeFormatter = ({value}) => {
    const addedByEmp = value;
    if (addedByEmp) {
        const {employeeName, employeeLastName, regestionNumberEmployee} = addedByEmp;
        return <MGrid container>
            <MGrid item sm={4}>
                <Avatar src={`${API}/${MEDIUM_AVATAR_CORE_SERVER}/${regestionNumberEmployee}`}/>
            </MGrid>
            <MGrid item sm={8} style={{paddingTop: 5}}>
                <MGrid container direction={"column"}>
                    <MGrid item>
                        <Typography gutterBottom noWrap>
                            {`${employeeName} ${employeeLastName}`}
                        </Typography>
                    </MGrid>
                    <MGrid item>
                        {regestionNumberEmployee}
                    </MGrid>
                </MGrid>
            </MGrid>
        </MGrid>
    } else {
        return <div>{value.toString()}</div>
    }

};


const EmployeeProvider = props => (
    <DataTypeProvider
        formatterComponent={EmployeeFormatter}
        {...props}
    />
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


function mapValues(rows) {
    return rows.map(row => {
        const {employeeLastName, employeeName, state, id, typeRequest, typeConge, typeCongeExcep, regestionNumberEmployee, startDate, duration, totalDuration, returnDate, updateDate} = row;
        const values = totalDuration ? {
            addedByEmp: {employeeLastName, employeeName, regestionNumberEmployee},
            typeConge,
            typeRequest,
            state,
            totalDuration,
            startDate,
            returnDate,
            updateDate,
            id: row
        } : {
            addedByEmp: {employeeLastName, employeeName, regestionNumberEmployee},
            typeConge,
            typeRequest,
            state,
            duration,
            startDate,
            returnDate,
            updateDate,
            id: row
        };
        return values;
    })

}

const getRowId = row => row.id;


export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            confirmationInMassDialogOpen: false,
            tableColumnExtensions: [
                {columnName: 'id', align: "right", width: 80},
                {columnName: 'duration', wordWrapEnabled: true},
                {columnName: 'typeRequest', wordWrapEnabled: true},
                {columnName: 'updateDate', wordWrapEnabled: true},
                {columnName: 'typeConge', wordWrapEnabled: true},
                {columnName: 'startDate', wordWrapEnabled: true},
                {columnName: 'returnDate', wordWrapEnabled: true},
                {columnName: 'addedByEmp', wordWrapEnabled: true, width: 200},
            ],
            integratedFilteringColumnExtensions: [
                { columnName: 'startDate', predicate: startDatePredicate },
                { columnName: 'returnDate', predicate: endDatePredicate },
                { columnName: 'addedByEmp', predicate: noPredicate }
            ],
            integratedSortingColumnExtensions: [
                { columnName: 'startDate', compare: compareDates },
                { columnName: 'returnDate', compare: compareDates },
                { columnName: 'updateDate', compare: compareDateTimes }
            ],
            employeeColumns: ['addedByEmp'],
            idColumns: ['id'],
            requestTypeColumns: ['typeRequest'],
            holidayTypeColumns: ['typeConge'],
            selection: [],
        };
        this.changeSelection = selection =>{
            this.setState({selection});
        } ;

        this.handleToggleConfirmationS = async () => {
            const rows = this.props.data;
            this.setState({confirmationInMassDialogOpen: !this.state.confirmationInMassDialogOpen})
            const {selection} = this.state;
            let roles = {};
            const ids = rows.filter((row, key) => selection.includes(key)).map(row => row.id);
            const axios = getAxios();
            let promises = [];
            ids.map( id => promises.push(axios.get(`${API}/${MY_REQUESTS_FOLLOW}/${id}`)));
            const results = await Promise.all([...promises]);
            console.log('results', results);
            results.map( e => {
                const follow = e.data.filter( step => step.state === "PENDING");
                const {role} = follow.shift();
                roles[parseFloat(e.request.responseURL.split("requests")[1].replace("/",""))] = role;
            })
            const requests =  ids.map( id => {
                return   {
                    "validatorEmpl": store.getState().auth.profile.userName,
                    "backup": null,
                    "validatorRole": roles[id],
                    "state": "APPROVED",
                    "rejectionReason": null,
                    "requestId": id,
                    "comment": null
                }
            })
            this.setState({selection: []});
            store.dispatch(inMassValidation(requests));
        }

        this.handleToggleConfirmationC = () => {
            this.setState({confirmationInMassDialogOpen: !this.state.confirmationInMassDialogOpen})
        }

    }


    render() {
        const {integratedSortingColumnExtensions, employeeColumns, idColumns, tableColumnExtensions,integratedFilteringColumnExtensions,  selection, confirmationInMassDialogOpen, holidayTypeColumns, requestTypeColumns} = this.state;
        const rows = this.props.data;
        const {loading, searchActive} = this.props;
        const {_partner, _holidayType, _requestType, _startDate, _returnDate, _updateDate, _absenceDuration, _VALIDATION_MASSE} = strings;
        const columns = [
            {name: 'addedByEmp', title: _partner},
            {name: 'typeConge', title: _holidayType},
            {name: 'typeRequest', title: _requestType},
            {name: 'startDate', title: _startDate},
            {name: 'returnDate', title: _returnDate},
            {name: 'updateDate', title: _updateDate},
            // {name: 'totalDuration', title: _absenceDuration},
            {name: 'duration', title: _absenceDuration},
            {name: 'id', title: ' '},
        ]
        return (
            <Fragment>
                <Paper style={tableSize}>
                    <Grid
                        rows={mapValues(rows)}
                        columns={columns}
                        style={tableSize}
                        // getRowId={getRowId}
                    >
                        <IdProvider
                            for={idColumns}
                        />

                        <EmployeeProvider
                            for={employeeColumns}
                        />

                        <RequestTypeProvider
                            for={requestTypeColumns}
                        />

                        <HolidayTypeProvider
                            for={holidayTypeColumns}
                        />

                        <SortingState
                            defaultSorting={[{columnName: 'requestDate', direction: 'asc'}]}
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        <SelectionState
                            selection={selection}
                            onSelectionChange={this.changeSelection}
                        />
                        <FilteringState
                            onFiltersChange={(filters) => {
                                this.props.changeFilters(filters)
                            }}
                        />
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />
                        <IntegratedSelection/>

                        <Table
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => {
                                return <CustomEmptyTable loading={loading}/>
                            }}
                            columnExtensions={tableColumnExtensions}/>
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}
                        <TableSelection showSelectAll/>

                    </Grid>
                </Paper>
                
                {rows.length > 0 && selection.length > 0 && <Tooltip title={_VALIDATION_MASSE}>
                    <Button
                        onClick={this.handleToggleConfirmationC}
                        id={"fab"} color={"primary"} variant={"fab"}>
                        <CheckIcon/>
                    </Button>
                </Tooltip>}

                <ConfirmationDialogInMass
                    handleToggleConfirmationC={this.handleToggleConfirmationC}
                    handleToggleConfirmationS={this.handleToggleConfirmationS}
                    open={confirmationInMassDialogOpen}/>

            </Fragment>
        );
    }
}
