import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {cancelRequest, deleteRequest, getMyRequests, showConfirmationDialog} from "../../../actions/RequestsActions";
import {store} from "../../../store/ConfigureStore";
import {getHolidayType} from "../../../utilities/MappingFunctions";
import { strings } from "../../../i18n/Strings";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function getSelectedRow(id, myRequests) {
    for (let request of myRequests.data) {
        if (request.id === id) {
            return request
        }
    }
    return {}
}

class ConfirmationDialog extends React.Component {

    render() {
        const { dialog, myRequests} = this.props;
        const {id, open, type} = dialog ;
        const {startDate, returnDate, typeConge} = getSelectedRow(id,myRequests);
        const request = getSelectedRow(id,myRequests);
        const {
            _holidayDeletionConfirmation,
            _holidayCancelationConfirmation,
            _cancel,
            _confirm,
            removeHoliday,
            cancelHoliday,
            _to$,
            _from,
            _holidayType
        } = strings;
        return (
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="#"
                    aria-describedby="#"
                >
                    <DialogTitle id="#">
                        { (type === "CANCEL")? cancelHoliday : removeHoliday}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="#">
                            {(type === "CANCEL")? _holidayCancelationConfirmation: _holidayDeletionConfirmation }  <br/> {_holidayType} : {getHolidayType(typeConge)} <br/> {_from} : {startDate} {_to$} {returnDate} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            store.dispatch(showConfirmationDialog({type: "CANCEL", id: null, open: false}))
                        }} color="primary">
                            {_cancel}
                        </Button>
                        <Button onClick={() => {
                            store.dispatch( (type === "CANCEL") ?   cancelRequest(request): deleteRequest(request.id))
                            store.dispatch(showConfirmationDialog({type: "CANCEL", id: null, open: false}))
                        }} color="primary">
                            {_confirm}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ConfirmationDialog;
