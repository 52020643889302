import React, {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {
    IntegratedSorting,
    SortingState,
    TreeDataState,
    CustomTreeData, IntegratedFiltering, FilteringState,
} from '@devexpress/dx-react-grid';
import {Grid, Table, TableFilterRow, TableHeaderRow, TableTreeColumn} from '@devexpress/dx-react-grid-material-ui';
import {tableSize, tableSizeTabs} from '../../../../styles/CommonStyles'
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ExportRequest from './dialogs/ExportRequest';
import FlagToExported from './dialogs/FlagToExported'
import ChangeReturnDate from './dialogs/ChangeReturnDate'
import AdvancedSearchDialog from './AdvancedSearchDialog'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../../utilities/CustomTableElements";
import {
    EmployeeProviderExport, endDatePredicate,
    FilterCell, getHolidayType,
    IdEditor, noPredicate,
    startDatePredicate,
    compareDates, compareDateTimes
} from "../../../../utilities/MappingFunctions";
import {CustomTableContainer} from "../../../../utilities/CustomTableContainer";
import IconButton from '@material-ui/core/IconButton'
import FlagIcon from '@material-ui/icons/Flag';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import MGrid from '@material-ui/core/Grid'
import {Subject} from "rxjs";

const IdFormatter = ({value}) => {
    const {type, requestId, exported} = value;
    return (
        <MGrid container spacing={0}>
            <MGrid item>
                <IconButton title={"Flaguer exported"} disabled={!(type === "subrequest")} onClick={() => {
                    editSubject.next({ openTab: "1", requestId})
                }
                } color="default" aria-label="add">
                    <FlagIcon fontSize="small"/>
                </IconButton>
            </MGrid>
            <MGrid item>
                <IconButton title={"Exporter la demande"} disabled={!((type === "request") || (type === "subrequest" && exported))} onClick={() => {
                    editSubject.next({ openTab: "0", requestId})
                }} aria-label="Cancel">
                    <ImportExportIcon fontSize="small"/>
                </IconButton>
            </MGrid>
            <MGrid item>
                <IconButton title={"Modifier date fin"} disabled={(type === "subrequest") || (type === "subrequest" && exported)} onClick={() => {
                    editSubject.next({ openTab: "2", requestId})
                }} aria-label="Delete">
                    <EditIcon fontSize="small"/>
                </IconButton>
            </MGrid>
        </MGrid>
    )
};

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


const getChildRows = (row, rows) => {
    const childRows = rows.filter(r => r.parentId === (row ? row.id : 0));
    return childRows.length ? childRows : null;
};

const ExportFormatter = ({value}) => {
    if (value === "") {
        return "";
    } else {
        return (value) ? <CheckIcon style={{color: "green"}}/> : <ErrorIcon style={{color: "red"}}/>
    }
};

const ExportTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={ExportFormatter}
        {...props}
    />
);


const mapValues = (rows) => {
    const $rows = [];
    rows.map(request => {
        const {id, subRequestVOS, addedForEmp, returnDate, typeConge} = request;
        const {registrationNumber, firstName, lastName} = addedForEmp;
        $rows.push({
            parentId: 0,
            id: id,
            uid: {type: "request", requestId: id, exported: false},
            addedForEmp: {userName: registrationNumber, firstName, lastName},
            endDate: returnDate,
            ...request,
            typeConge: {typeRequest: "request", type: typeConge}
        });
        subRequestVOS.map(subRequest => {
            const {startDate, endDate, exportTime, errorMessage, duration, totalDuration, requestId, exported} = subRequest;
            const $subrequest = {
                parentId: id,
                uid: {type: "subrequest", requestId, exported},
                startDate: startDate.replace(/[-]/g, "/"),
                endDate: endDate.replace(/[-]/g, "/"),
                typeConge: {typeRequest: "subrequest", type: request.typeConge},
                errorMsg: errorMessage,
                addedForEmp: {userName: "", firstName: "", lastName: ""},
                duration,
                totalDuration,
                exported
            };
            $rows.push($subrequest);
        })
    });
    return $rows
};

const editSubject = new Subject();



export const HolidayTypeFormatter = ({value}) => {
    const {type, typeRequest} = value;
    return (typeRequest  === "request")? getHolidayType(type): " ";
};

export const HolidayTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={HolidayTypeFormatter}
        {...props}
    />
);



export default class OnExportErrorsGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            openDialog: null,
            searchOpen: false,
            requestId: "",
            columns: [
                {name: 'addedForEmp', title: 'Collaborateur'},
                {name: 'typeConge', title: 'Type de congés'},
                {name: 'updateDate', title: 'Date de la demande'},
                {name: 'startDate', title: 'Date de départ'},
                {name: 'endDate', title: 'Date de retour'},
                {name: 'totalDuration', title: 'Durée globale'},
                {name: 'duration', title: 'Durée à exporter'},
                {name: 'exported', title: 'Export'},
                {name: 'errorMsg', title: `    Erreur`},
                {name: 'uid', title: `    `},
            ],
            exportColumns: ['exported'],
            employeeColumns: ['addedForEmp'],
            holidayTypeColumns: ['typeConge'],
            idColumns: ['uid'],
            tableColumnExtensions: [
                {columnName: 'addedForEmp', width: 260, wordWrapEnabled: true},
                {columnName: 'uid', width: 180},
                {columnName: 'updateDate', width: 150},
                {columnName: 'startDate', wordWrapEnabled: true},
                {columnName: 'returnDate', wordWrapEnabled: true},
                {columnName: 'typeConge', wordWrapEnabled: true},
                {columnName: 'duration', width: 70, wordWrapEnabled: true},
                {columnName: 'totalDuration', width: 70, wordWrapEnabled: true},
                {columnName: 'exported', width: 50, wordWrapEnabled: true},
                {columnName: 'errorMsg', width: 220, wordWrapEnabled: true}
            ],
            integratedSortingColumnExtensions: [
                { columnName: 'startDate', compare: compareDates },
                { columnName: 'returnDate', compare: compareDates },
                { columnName: 'updateDate', compare: compareDateTimes }
            ],
            integratedFilteringColumnExtensions: [
                {columnName: 'startDate', predicate: startDatePredicate},
                {columnName: 'returnDate', predicate: endDatePredicate},
                {columnName: 'addedForEmp', predicate: noPredicate}
            ],
        };
    }

    componentWillMount() {
        this.handleDialogOpen(null)
    }

    componentWillUnmount() {
        // editSubject.unsubscribe()
    }

    handleDialogOpen = (id) => {
        if (id === null) {
            editSubject.subscribe((value) => {
                const {requestId, openTab} = value;
                this.setState({openDialog: openTab, requestId})
            })
        }
        this.setState({openDialog: id});
    };
    toggleSearch = () => {
        const {searchOpen} = this.state;
        this.setState({searchOpen: !searchOpen});
    };

    render() {
        const {integratedSortingColumnExtensions, columns, idColumns, tableColumnExtensions, employeeColumns, exportColumns, openDialog, searchOpen, holidayTypeColumns, integratedFilteringColumnExtensions, requestId} = this.state;
        const {loading, searchActive, data} = this.props;
        return (
            <Fragment>
                {openDialog === "1" && <ExportRequest requestId={requestId} handleDialogOpen={this.handleDialogOpen}/>}
                {openDialog === "0" && <FlagToExported requestId={requestId} handleDialogOpen={this.handleDialogOpen}/>}
                {openDialog === "2" && <ChangeReturnDate requestId={requestId} handleDialogOpen={this.handleDialogOpen}/>}
                <Paper style={tableSizeTabs}>
                    {/*<SearchToolBar*/}
                    {/*toggleSearch={this.toggleSearch}*/}
                    {/*/>*/}
                    <Grid
                        style={tableSizeTabs}
                        rows={mapValues(data)}
                        columns={columns}
                    >
                        <ExportTypeProvider
                            for={exportColumns}
                        />
                        <HolidayTypeProvider
                            for={holidayTypeColumns}
                        />
                        <EmployeeProviderExport
                            for={employeeColumns}
                        />
                        <IdProvider
                            for={idColumns}
                        />
                        <TreeDataState/>
                        <SortingState
                            defaultSorting={[{columnName: 'requestDate', direction: 'asc'}]}
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        <FilteringState
                            onFiltersChange={(filters) => {
                                this.props.changeFilters(filters)
                            }}
                        />
                        <CustomTreeData
                            getChildRows={getChildRows}
                        />
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />
                        <Table
                            columnExtensions={tableColumnExtensions}
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => {
                                return <CustomEmptyTable loading={loading}/>
                            }}
                        />
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}
                        <TableTreeColumn
                            for="addedForEmp"
                        />
                    </Grid>
                </Paper>
                <AdvancedSearchDialog
                    toggleSearch={this.toggleSearch}
                    open={searchOpen}/>
            </Fragment>
        );
    }
}
