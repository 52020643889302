import React, {Component} from 'react';
import './style.css';
import {QuoatsBuild} from './quotas_plugin';
import {qoutas, qoutasConge, teams} from '../../../mock/quotas';
import Button from '@material-ui/core/Button';
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import SearchQuotaDialog from "./SearchQuotaDialog";
import SearchQuotaZoneProjectDialog from "./SearchQuotaProjectZoneDialog";
import SitesSearchQuotaDialog from "./SitesSearchQuotaDialog";
import CloseIcon from '@material-ui/icons/Close';
import ImportIcon from '@material-ui/icons/CloudUpload';
import HistoryIcon from '@material-ui/icons/History';
import BarChartIcon from '@material-ui/icons/BarChart';
import IconButton from '@material-ui/core/IconButton';
import {API, DEAFULT_AVATAR_CORE_SERVER} from "../../../api/Endpoints";
import {Avatar} from "@material-ui/core";
import {formatDate, getHolidayType} from "../../../utilities/MappingFunctions";
import {getAxios, hasAuthority} from "../../../actions/AuthActions";
import SearchIcon from '@material-ui/icons/Search';
import CachedIcon from '@material-ui/icons/Cached';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import {showTimedToaster} from "../../../actions/ReferencesActions";
import {store} from "../../../store/ConfigureStore";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import {strings} from "../../../i18n/Strings";
import {importQuota} from "../../../actions/QuotaActions";
import {withRouter} from "react-router-dom";
import QuotaHistory from "./QuotaHistory";

function getStateLabel(state) {
    const {_approved, _rejected, _hold} = strings;
    if (state === "APPROVED") {
        return <span style={{color: "green", fontWeight: "bold", marginLeft: 20}}>{_approved}</span>
    } else if (state === "REJECTED") {
        return <span style={{color: "red", fontWeight: "bold", marginLeft: 20}}>{_rejected}</span>
    } else if (state === "PENDING") {
        return <span style={{color: "grey", fontWeight: "bold", marginLeft: 20}}>{_hold}</span>
    }
    else {
        return state;
    }
}


class Quotas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            follow: [],
            searchQuota: false,
            searchQuotaSite: false,
            searchQuotaProjectZone: false,
            refreshDisabled: true,
            infoQuota: {
                user: {firstName: '', lastName: '', userName: ''},
                data: {label: null, type: null, start: null, end: null},
                isShow: false,
                addedByEmp: {},
            },
            loadingQuota: false,
            idTeam: "",
            shortName: "",
            idProject: "",
            idZone: "",
            $start: "",
            file: null,
            filters: [],
            page: 0,
            size: 10,
            sort: "",
            showHistoryQuotas: false,
            $end: ""
        };

    }

    quotaRef = React.createRef();
    defaultObj = {
        "teamLabel": "",
        "project": "",
        "site": "",
        "zone": "",
        "start": "",
        "end": "",
        "quotas": [],
        "teams": [],
        "conges": [],
        "legends": []
    };

    componentWillMount() {
        const {
            _HOLIDAY_APPROVED_ALL,_HOLIDAY_APPROVED_N1,_PENDING_REQUEST,_LEAD_TEAM_HOLIDAY,_HOLIDAY_REFUSED_ALL,_HOLIDAY_REFUSED_N1,_SOCIAL_APPROVED_ALL,_SOCIAL_PENDING,_SOCIAL_REFUSED_ALL,_UNPAID_SOCIAL_REFUSED_ALL_OK,_UNPAID_HOLIDAY_APPROVED_N1_HR,_UNPAID_SOCIAL_REFUSED_ALL_KO,_UNPAID_SOCIAL_REFUSED_N1_HR
        } = strings;
        this.setState({
            legends: [
                {label: _HOLIDAY_APPROVED_ALL.label, class: "CONGE_VALIDE_OK", title: _HOLIDAY_APPROVED_ALL.title},
                {label: _HOLIDAY_APPROVED_N1.label, class: "CONGE_VALIDE_KO", title: _HOLIDAY_APPROVED_N1.title},
                {label: _PENDING_REQUEST.label, class: "CONGE_PENDING_UNPAIDHOLIDAY", title: _PENDING_REQUEST.title},
                {label: _LEAD_TEAM_HOLIDAY.label, class: "CONGE_TL", title: _LEAD_TEAM_HOLIDAY.title},
                {label: _HOLIDAY_REFUSED_ALL.label, class: "CONGE_REFUS_KO", title: _HOLIDAY_REFUSED_ALL.title},
                {label: _HOLIDAY_REFUSED_N1.label, class: "CONGE_REFUS_OK", title: _HOLIDAY_REFUSED_N1.title},

                {label: _SOCIAL_APPROVED_ALL.label, class: "CONGE_VALIDE_OK_EXCEPTIONALHOLIDAY", title: _SOCIAL_APPROVED_ALL.title},
                {label: _SOCIAL_PENDING.label, class: "CONGE_PENDING_EXCEPTIONALHOLIDAY", title: _SOCIAL_PENDING.title},
                {label: _SOCIAL_REFUSED_ALL.label, class: "CONGE_REFUS_KO_EXCEPTIONALHOLIDAY", title: _SOCIAL_REFUSED_ALL.title},

                {label: _UNPAID_SOCIAL_REFUSED_ALL_OK.label, class: "CONGE_VALIDE_OK_UNPAIDHOLIDAY", title: _UNPAID_SOCIAL_REFUSED_ALL_OK.title},
                {label: _UNPAID_HOLIDAY_APPROVED_N1_HR.label, class: "CONGE_VALIDE_KO_UNPAIDHOLIDAY", title: _UNPAID_HOLIDAY_APPROVED_N1_HR.title},
                {label: _PENDING_REQUEST.label, class: "CONGE_PENDING_UNPAIDHOLIDAY", title: _PENDING_REQUEST.title},
                {label: _UNPAID_SOCIAL_REFUSED_ALL_KO.label, class: "CONGE_REFUS_KO_UNPAIDHOLIDAY", title: _UNPAID_SOCIAL_REFUSED_ALL_KO.title},
                {label: _UNPAID_SOCIAL_REFUSED_N1_HR.label, class: "CONGE_REFUS_OK_UNPAIDHOLIDAY", title: _UNPAID_SOCIAL_REFUSED_N1_HR.title},


            ]

        })
    }
    viewGD = () => {
        let _build = document.getElementById("data-build");
        _build.style.display = 'flex';
    };
    stringify = (e) => {
        return JSON.stringify(e, undefined, 4)
    };

    min_date(all_dates) {
        var min_dt = all_dates[0],
            min_dtObj = new Date(all_dates[0]);
        all_dates.forEach(function(dt, index)
        {
            if ( new Date( dt ) < min_dtObj)
            {
                min_dt = dt;
                min_dtObj = new Date(dt);
            }
        });
        return min_dt;
    }

    reverse(date) {
        return date.split("/").reverse().join("/");
    }

    recalculateQuota = async () => {
        const {_IMPORT_FILE_QUOTA, _ERREUR_ON_IMPORT} = strings;
        try{
            this.setState({loadingQuota: true});
            const axios = getAxios();
            axios.get(`${API}/conges/loadQuotaFile`);
            store.dispatch(showTimedToaster({open: true, message: _IMPORT_FILE_QUOTA, type: "info"}))
        } catch (e) {
            store.dispatch(showTimedToaster({open: true, message: _ERREUR_ON_IMPORT, type: "warning"}))
        }
        this.setState({loadingQuota:  false});
    }

    generateReportQuota = async () => {
        const {_ERREUR, _REPORT_SEND_MAIL} = strings;
        try{
            this.setState({loadingQuota: true});
            const axios = getAxios();
            await axios.get(`${API}/conges/sendQuotaReport?matricule=${this.props.auth.profile.userName}`);
            store.dispatch(showTimedToaster({open: true, message: _REPORT_SEND_MAIL, type: "info"}))
        } catch (e) {
            store.dispatch(showTimedToaster({open: true, message: _ERREUR, type: "warning"}))
        }
        this.setState({loadingQuota:  false});
    }

    generateData = async ($teams, shortName, idProject, idZone, $start, $end) => {
        try {
            const {_TEAM_LABEL, _QUOTA_NO_CONFIGURED} = strings;
            this.setState({loadingQuota: true, refreshDisabled: false});
            this.setState({teams: $teams, shortName, idProject, idZone, $start, $end});
            const axios = getAxios();
            const quotaHolidays = await axios.post(`${API}/conges/quota/holidays`, {
                "teams": $teams,
                "start": $start,
                "end": moment($end,'DD/MM/YYYY').add(2, "days").format('DD/MM/YYYY'),
            });
            const holidays = quotaHolidays.data;
            let minHolidaysStartDate = this.min_date(holidays.map(e => e.valeurs).flat().map( e => e.start)) || moment().format("DD/MM/YYYY")
            const _start = $start;
            /*
            *  Set start date as the min date for incoming holidays for quotas and teams lookup
            * */
            $start = minHolidaysStartDate;

            let quotaTeams = axios.post(`${API}/conges/quota/teams`, {
                "teams": $teams,
                "start": _start, //(moment($start).format('DD/MM/YYYY') === 'Invalid date') ? _start: moment($start).format('DD/MM/YYYY'),
                "end": moment($end,'DD/MM/YYYY').add(2, "days").format('DD/MM/YYYY'),
                "zoneId": idZone,
                "projectId": idProject,
                "label": shortName
            });

            let quotaValues = axios.post(`${API}/conges/quota/quotas`, {
                "teams": $teams,
                "start": _start,//(moment($start).format('DD/MM/YYYY') === 'Invalid date') ? _start: moment($start).format('DD/MM/YYYY'),
                "end": moment($end,'DD/MM/YYYY').add(2, "days").format('DD/MM/YYYY'),
                "zoneId": idZone,
                "projectId": idProject
            });
            /*
            * Lookup values for quotas and teams in parallel
            * */
            const teamsAndQuotas = await  Promise.all([quotaTeams, quotaValues])
            quotaTeams = teamsAndQuotas[0];
            quotaValues = teamsAndQuotas[1];
            const siteLabel = await axios.get(`${API}/conges/quota/findSiteName?idProject=${idProject}&idZone=${idZone}`);
            let quotas = quotaValues.data;
            const teams = quotaTeams.data;
            let tmpQuota = quotas;
            //
            if (tmpQuota.filter( e => e.code === "Quota")[0].values.length === 0 ) {
                store.dispatch(showTimedToaster({open: true, message: _QUOTA_NO_CONFIGURED, type: "warning"}))
            }
            const maping = {
                "Quota": "Quota color1",
                "Max": "Quota color2",
                "Total": "success total color3",
                "CP": "success byTotal",
                "CSS": "success byTotal",
                "CE": "success byTotal",
                "Reste": "worng color4"
            };
            quotas = quotas.map((e) => {
                e['class'] = maping[e.code];
                return e
            });
            let elementHtml = document.getElementById("content");
            // let _qoutas = document.getElementById("quota");
            // let _teams = document.getElementById("teams");
            // let _conges = document.getElementById("conges");
            // let _build = document.getElementById("data-build");
            // let start = document.getElementById("start");
            // let end = document.getElementById("end");
            try {
                // _qoutas = JSON.parse(_qoutas.value);
                // _teams = JSON.parse(_teams.value);
                // _conges = JSON.parse(_conges.value);
                // /*            QuoatsBuild.teams = _teams;
                //             QuoatsBuild.items = _qoutas;*/
                this.defaultObj.teams = teams;
                this.defaultObj.quotas = quotas;
                this.defaultObj.conges = holidays;
                const {siteName, totalEmployees} = siteLabel.data;
                this.defaultObj.project = `
                    ${siteName}
                            ${totalEmployees}
                `;
                this.defaultObj.teamLabel = _TEAM_LABEL;
                QuoatsBuild.data = this.defaultObj;

                setTimeout(() => {}, 100000);
                QuoatsBuild.buildAll(this, elementHtml,
                    //$start
                    moment(_start, "DD/MM/YYYY").format("YYYY/MM/DD")
                    , formatDate($end), holidays);
                this.setState({loadingQuota: false});
                setTimeout(()=>{window.dispatchEvent(new Event('resize'))}, 500);


            } catch (e) {
                this.setState({loadingQuota: false});
                console.log(e)
            }
            /*QuoatsBuild.teams = teams;
            QuoatsBuild.items = qoutas;
            QuoatsBuild.payload = qoutasConge;
            if ( el ) {
             QuoatsBuild.buildAll(el, "2019/02/05","2019/02/18")
            }*/
        } catch (e) {
            store.dispatch(showTimedToaster({open: true, message: e.response.data.message, type: "warning"}))
        }

    };

    generate = async (el) => {
        const {siteLabel} = this.props.quota;
        this.defaultObj.project = siteLabel;
        this.defaultObj.teams = teams;
        this.defaultObj.quotas = qoutas;
        this.defaultObj.conges = qoutasConge;
        QuoatsBuild.data = this.defaultObj;


        if (el) {
            QuoatsBuild.buildAll(this, el, "2019/02/05", "2019/02/18")
            // QuoatsBuild.buildAll(this, el, moment().format("DD/MM/YYYY"), moment().add(20, 'days').format('DD/MM/YYYY'))
        }
    };

    handleDialog = (name) => {
        const value = this.state[name];
        this.setState({[name]: !value})
    };
    hidePopup = () => {
        const {infoQuota} = this.state;
        infoQuota.isShow = false;
        this.setState({infoQuota});
    };

    render() {
        const {
            _OPINION_MANAGER, _OPINION_WFM, _REASON_FOR_REFUSAL, _OPINION_SYSTEM, _searchQuota, _changeQuota,
            _importQuota, _generateQuota, _END_DATE, _FILING_DATE, _motif, _OPINION_RH, _REFRESH_QUOTA
        } = strings;
        const {searchQuotaProjectZone, searchQuotaSite, searchQuota, infoQuota, loadingQuota} = this.state;
        const {drawerOpen} = this.props.references;
        const {searchActive, profile} = this.props.auth;
        const {showHistoryQuotas} = this.state;
        // const widthCalc = {width: (drawerOpen ? 'calc(100vw - 220px)' : '100vw')};
        const widthCalc = {width: '100%'};
        const {data, user, isShow} = infoQuota;
        const {type, label, start, end, labelExceptional, updatedDate} = data;
        const {userName, lastName, firstName} = user;

        const handleFileChange = (e) => {
            if (e.target.files) {
                store.dispatch(importQuota(e.target.files[0]))
            }
        };

        return (
            <>
                {loadingQuota && <LinearProgress/>}
                <div className={"backdrop " + (isShow ? 'show' : '')} onClick={this.hidePopup}></div>
                <div className={"info-quota " + (isShow ? 'show' : '')}>
                    <div className={"header " + type}>
                        <h3>{(label === "EXCEPTIONALHOLIDAY" && labelExceptional) ? labelExceptional : getHolidayType(label)}</h3>
                        <IconButton aria-label="Delete" className="close">
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </div>
                    {!showHistoryQuotas && (
                        <div className="body">
                            <table className="profile-card-quota">

                                <Grid style={{paddingTop: 10}} container direction={"column"} justify={"center"}
                                      spacing={16}>
                                    <Grid item>
                                        <Grid container direction={"column"} spacing={4} alignItems={"center"}
                                              justify={"space-around"}>
                                            <Grid item>
                                                <Avatar style={{height: 70, width: 70}} alt="Remy Sharp"
                                                        src={`${API}/${DEAFULT_AVATAR_CORE_SERVER}/${userName}`}/>
                                            </Grid>
                                            <Grid item>
                                                {firstName} {lastName}
                                            </Grid>
                                            <Grid item>
                                                [{userName}]
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" gutterBottom>
                                            <span style={{fontWeight: "bold"}}>{_END_DATE}</span>
                                            {start}
                                            <br/>
                                            <span style={{fontWeight: "bold"}}>{_END_DATE}</span>
                                            {moment(end).add(1, 'days').format("YYYY/MM/DD")}

                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" gutterBottom>
                                            <span style={{fontWeight: "bold"}}>{_FILING_DATE}</span>
                                            {updatedDate ? updatedDate.replace("T", " ") : updatedDate}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" gutterBottom>
                                            <span style={{fontWeight: "bold"}}>Jour(s) : </span>
                                            {(moment(end).diff(moment(start), 'days') != 0) ? moment(end).diff(moment(start), 'days') + 1 : 1}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} justify={"center"} spacing={16}>
                                    <Grid item>
                                        {
                                            this.state.follow.map(elem => {
                                                const {
                                                    state,
                                                    role,
                                                    addedByEmp,
                                                    updateDate,
                                                    reasonOfRejection
                                                } = elem;
                                                let comment;
                                                if (reasonOfRejection) {
                                                    comment = reasonOfRejection.label;
                                                } else {
                                                    comment = "";
                                                }
                                                if (role === "SYSTEME_PPV") {
                                                    return <Grid container style={{paddingBottom: 8}}
                                                                 direction={"column"}
                                                                 justify={"flex-start"} spacing={2}>
                                                        <Grid item>
                                                            <Typography variant="body2" gutterBottom>
                                                            <span
                                                                style={{fontWeight: "bold"}}>{_OPINION_WFM}</span>
                                                                {getStateLabel(state)}
                                                            </Typography>
                                                        </Grid>
                                                        {
                                                            (comment != "") && <Grid item>
                                                                <Typography variant="caption" gutterBottom>
                                                                        <span
                                                                            style={{fontWeight: "bold"}}>{_REASON_FOR_REFUSAL}</span> {comment}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                }
                                                if (role === "SYSTEME") {
                                                    return <Grid container style={{paddingBottom: 8}}
                                                                 direction={"column"} justify={"flex-start"}
                                                                 spacing={2}>
                                                        <Grid item>
                                                            <Typography variant="body2" gutterBottom>
                                                            <span
                                                                style={{fontWeight: "bold"}}>{_OPINION_SYSTEM}</span>
                                                                {getStateLabel(state)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction={"row"} spacing={8}>
                                                                <Grid item sm={3}>
                                                                    <Avatar style={{height: 30, width: 30}} alt=""
                                                                            src={`${API}/${DEAFULT_AVATAR_CORE_SERVER}/${addedByEmp.registrationNumber}`}/>
                                                                </Grid>
                                                                <Grid item sm={7}>
                                                                    <div style={{fontSize: 15, width: 176}}>
                                                                        {`${addedByEmp.firstName} ${addedByEmp.lastName}  [${addedByEmp.registrationNumber}] \n `}
                                                                        <br/>
                                                                        <span
                                                                            style={{fontSize: 13}}>{comment}</span>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                if (role === "PPV_VALIDATION") {
                                                    return <Grid container style={{paddingBottom: 8}}
                                                                 direction={"column"}
                                                                 justify={"flex-start"} spacing={2}>
                                                        <Grid item>
                                                            <Typography variant="body2" gutterBottom>
                                                                    <span
                                                                        style={{fontWeight: "bold"}}>{_OPINION_WFM}</span>
                                                                {getStateLabel(state)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction={"row"} spacing={8}>
                                                                <Grid item sm={3}>
                                                                    <Avatar style={{height: 30, width: 30}} alt=""
                                                                            src={`${API}/${DEAFULT_AVATAR_CORE_SERVER}/${addedByEmp.registrationNumber}`}/>
                                                                </Grid>
                                                                <Grid item sm={7}>
                                                                    <div style={{fontSize: 15, width: 176}}>
                                                                        {`${addedByEmp.firstName} ${addedByEmp.lastName}  [${addedByEmp.registrationNumber}] \n `}
                                                                        <br/>
                                                                        <span
                                                                            style={{fontSize: 13}}>{comment}</span>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                if (role === "MANAGER_VALIDATION") {
                                                    return <Grid container style={{paddingBottom: 8}}
                                                                 direction={"column"}
                                                                 justify={"flex-start"} spacing={2}>
                                                        <Grid item>
                                                            <Typography variant="body2" gutterBottom>
                                                                    <span
                                                                        style={{fontWeight: "bold"}}>{_OPINION_MANAGER}</span>
                                                                {getStateLabel(state)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction={"row"} spacing={8}>
                                                                <Grid item sm={3}>
                                                                    <Avatar style={{height: 40, width: 40}} alt=""
                                                                            src={`${API}/${DEAFULT_AVATAR_CORE_SERVER}/${addedByEmp.registrationNumber}`}/>
                                                                </Grid>
                                                                <Grid item sm={7}>
                                                                    <div style={{fontSize: 15, width: 176}}>
                                                                        {`${addedByEmp.firstName} ${addedByEmp.lastName}  [${addedByEmp.registrationNumber}] \n `}
                                                                        <br/>
                                                                        <span
                                                                            style={{fontSize: 13}}>{comment}</span>
                                                                        <span
                                                                            style={{fontSize: 13}}>{_motif} {updateDate}</span>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                if (role === "RH_VALIDATION") {
                                                    return <Grid container style={{paddingBottom: 8}}
                                                                 direction={"column"}
                                                                 justify={"flex-start"} spacing={2}>
                                                        <Grid item>
                                                            <Typography variant="body2" gutterBottom>
                                                                    <span
                                                                        style={{fontWeight: "bold"}}>{_OPINION_RH}</span>
                                                                {getStateLabel(state)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction={"row"} spacing={8}>
                                                                <Grid item sm={3}>
                                                                    <Avatar style={{height: 40, width: 40}} alt=""
                                                                            src={`${API}/${DEAFULT_AVATAR_CORE_SERVER}/${addedByEmp.registrationNumber}`}/>
                                                                </Grid>
                                                                <Grid item sm={7}>
                                                                    <div style={{fontSize: 15, width: 176}}>
                                                                        {`${addedByEmp.firstName} ${addedByEmp.lastName}  [${addedByEmp.registrationNumber}] \n `}
                                                                        <br/>
                                                                        <span
                                                                            style={{fontSize: 13}}>{comment}</span>
                                                                        <span
                                                                            style={{fontSize: 13}}>{_motif} {updateDate}</span>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                } else return <></>
                                            })
                                        }
                                    </Grid>
                                </Grid>


                                {/*<tr>*/}
                                {/*<th>*/}
                                {/*<CalendarTodayIcon/>*/}
                                {/*</th>*/}
                                {/*<td>*/}
                                {/*<p> {start} </p>*/}
                                {/*</td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*<th>*/}
                                {/*<CalendarTodayIcon/>*/}
                                {/*</th>*/}
                                {/*<td>*/}
                                {/*<p> {end} </p>*/}
                                {/*</td>*/}
                                {/*</tr>*/}
                            </table>
                        </div>
                    )}
                </div>

                {!showHistoryQuotas ? (
                    <div ref={this.generate} id="content" style={widthCalc}></div>
                ) : (
                    <QuotaHistory />
                )}

                <div style={{position: 'fixed', bottom: 20, right: 20, zIndex: 999}}>
                    <Grid container spacing={8}>
                        <Grid item>
                            <Button style={{zIndex: 1}} disabled={this.state.refreshDisabled} title={_REFRESH_QUOTA}
                                    variant="fab"
                                    color="primary" onClick={() => {
                                const {teams, shortName, idProject, idZone, $start, $end} = this.state;
                                this.generateData(teams, shortName, idProject, idZone, $start, $end);
                            }}>
                                <RefreshIcon/>
                            </Button>
                        </Grid>
                        {!showHistoryQuotas ? (
                            <Grid item>
                                <Button
                                    onClick={() => this.setState({
                                        showHistoryQuotas: true
                                    })}
                                    style={{zIndex: 1}}
                                    title={"Historique d'import"}
                                    variant="fab"
                                    color="primary"
                                >
                                    <HistoryIcon/>
                                </Button>
                            </Grid>
                        ) : (
                            <Grid item>
                                <Button
                                    onClick={() => this.setState({
                                        showHistoryQuotas: false
                                    })}
                                    style={{zIndex: 1}}
                                    title={"Quota"}
                                    variant="fab"
                                    color="primary"
                                >
                                    <BarChartIcon/>
                                </Button>
                            </Grid>
                        )}

                        <Grid item>
                            <input accept="text/csv" hidden id="icon-button-file" type="file"
                                   onChange={handleFileChange}/>
                            <label htmlFor="icon-button-file">
                                <Button
                                    component="span"
                                    style={{zIndex: 1}}
                                    title={"import quota"}
                                    variant="fab"
                                    color="primary"
                                >
                                    <ImportIcon/>
                                </Button>
                            </label>

                        </Grid>


                        {
                            (hasAuthority(["ROLE_QUOTA_READ"]) && !hasAuthority(['ROLE_QUOTA_READ_WFM'])) && <Grid item>
                            <Button style={{zIndex: 1}} title={_searchQuota} variant="fab" color="primary" onClick={() => {
                                this.handleDialog("searchQuota")
                            }}>
                                <SearchIcon/>
                            </Button>
                        </Grid>
                        }

                        {
                            hasAuthority(['ROLE_QUOTA_READ_WFM']) && <Grid item>
                                <Button style={{zIndex: 1}} title={_searchQuota} variant="fab" color="primary" onClick={() => {
                                    this.handleDialog("searchQuotaProjectZone")
                                }}>
                                    <SearchIcon/>
                                </Button>
                            </Grid>
                        }
                        {
                            hasAuthority(["ROLE_QUOTA_ADMIN"]) && <Grid item>
                                <Button style={{zIndex: 1}} title={_changeQuota} variant="fab" color="primary" onClick={() => {
                                    this.handleDialog("searchQuotaSite")
                                }}>
                                    <EditIcon/>
                                </Button>
                            </Grid>
                        }

                        {
                            (this.props.auth.profile.userName === "E20095" || this.props.auth.profile.userName === "047585") &&
                            <Grid item>
                                <Button style={{zIndex: 1}} title={_importQuota} variant="fab" color="primary"
                                        onClick={() => {
                                            this.recalculateQuota()
                                        }}>
                                    <CachedIcon/>
                                </Button>
                            </Grid>
                        }

                        {
                            (this.props.auth.profile.userName === "E20095" || this.props.auth.profile.userName === "047585" || this.props.auth.profile.userName === "066913") &&
                            <Grid item>
                                <Button style={{zIndex: 1}} title={_generateQuota} variant="fab" color="primary"
                                        onClick={() => {
                                            this.generateReportQuota()
                                        }}>
                                    <FileCopyIcon/>
                                </Button>
                            </Grid>
                        }
                    </Grid>

                </div>
                <SearchQuotaDialog generateData={this.generateData} open={searchQuota} handleDialog={() => {
                    this.handleDialog("searchQuota")
                }}/>
                <SitesSearchQuotaDialog open={searchQuotaSite} handleDialog={() => {
                    this.handleDialog("searchQuotaSite")
                }}/>

                <SearchQuotaZoneProjectDialog generateData={this.generateData} open={searchQuotaProjectZone} handleDialog={() => {
                    this.handleDialog("searchQuotaProjectZone")
                }}/>

            </>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        references: state.references,
        quota: state.quota,
        auth: state.auth
    }
};

let mapActionsToProps = {};


export default connect(mapStateToProps, mapActionsToProps)(withRouter(Quotas));
