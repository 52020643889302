import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import PeopleIcon from '@material-ui/icons/PeopleOutline'
import UsersList from './UsersList'
import {connect} from "react-redux";
import {strings} from "../../../i18n/Strings";
import {getEmployeeSolde} from "../../../actions/SoldeActions";
import {store} from '../../../store/ConfigureStore';

const styles = {
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
};

class UOMembersBalance extends React.Component {
    state = {
        right: false,
        firstLaunch: true

    };
    handleClick = () => {
        this.setState({right: true, firstLaunch: false})
    };
    toggleDrawer = (side, open) => () => {
        this.setState({
            right: open,
        });
    };

    render() {
        const {soldeEmployee, loadingEmployeeSolde}= this.props.solde;
        const {_employeesBalance} = strings;
        return (
            <div>
                    <Tooltip title={_employeesBalance}>
                        <Button
                            id={"fab"}
                            onClick={() => {
                                if(this.state.firstLaunch) {
                                    store.dispatch(getEmployeeSolde(0,50));
                                }
                                this.handleClick()
                            }} variant="fab" color="primary">
                            <PeopleIcon/>
                        </Button>
                    </Tooltip>
                <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer('right', false)}
                        onKeyDown={this.toggleDrawer('right', false)}
                    >

                    </div>
                    <UsersList
                        soldeEmployee={soldeEmployee}
                        loadingEmployeeSolde={loadingEmployeeSolde}
                    />
                </Drawer>
            </div>
        );
    }
}


let mapStateToProps = (state) => {
    return {solde: state.solde}
};

let mapActionsToProps = {};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(UOMembersBalance));
