import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {showRequestDetails} from "../../actions/RequestsActions";
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import History from "./History";
import Follow from "./Follow";
import {strings} from "../../i18n/Strings";


const styles = theme => {
    return {
        dialogPaper: {
            height: 'auto',
            maxWidth: 900,
            minWidth: 900,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class RequestDetails extends React.Component {
    state = {
        value: 0,
    };

    handleChange = (event, value) => {
        this.setState({value});
    };

    render() {
        const {classes, requests, myRequestsFollow, myRequestsHistory, details, references} = this.props;
        const {configUser} = references;
        const {showRequestDetails} = requests;
        const {value} = this.state;
        const {_history, _follow, _holidayDetails,_return} = strings;
        return (
            <div style={{position: "relative"}}>
                <Dialog
                    open={showRequestDetails.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    classes={{paper: classes.dialogPaper}}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                {_holidayDetails}
                            </Typography>
                        </Toolbar>
                        <div className={classes.root}>
                            <AppBar position="static" elevation={0}>
                                <Tabs value={value} onChange={this.handleChange}>
                                    <Tab label={_follow}/>
                                    <Tab label={_history}/>
                                </Tabs>
                            </AppBar>
                        </div>
                    </AppBar>
                    <div style={{paddingBottom: 50}}>
                        {value === 0 && <Follow myRequestsFollow={myRequestsFollow} details={details} configUser={configUser}/>}
                        {value === 1 && <History rows={myRequestsHistory}/>}
                    </div>
                    <div style={{position: "absolute", bottom: 5, right: 5}}>
                        <Button onClick={() => {
                            this.props.showRequestDetails({open: false})
                        }} color="primary">
                            {_return}
                        </Button>
                    </div>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests, references: state.references}
};

let mapActionsToProps = {
    showRequestDetails
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(RequestDetails));
