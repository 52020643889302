import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {withStyles} from '@material-ui/core/styles';
import {splitAbsence, setShowSpliterDialog} from "../../../../actions/AbsenceActions";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/es/TextField/TextField";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import {_labelStyles} from "../../../../styles/CommonStyles";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import {strings} from "../../../../i18n/Strings";

const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 550,
            maxHeight: 550,
            maxWidth: 800,
            minWidth: 800,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function getMenuItems(items) {
    return items.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
}


class SpliterDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.spliterDetails.id,
            idMyshift: this.props.spliterDetails.idMyshift,
            matricule: this.props.spliterDetails.matricule,
            start: this.props.spliterDetails.start,
            startUtc: this.props.spliterDetails.start_utc,
            end: this.props.spliterDetails.end,
            endUtc: this.props.spliterDetails.end_utc,
            selectedStart : this.props.spliterDetails.start.split(" ")[1],
            selectedEnd : this.props.spliterDetails.end.split(" ")[1],
            startTime: this.props.spliterDetails.start.split(" ")[1],
            endTime : this.props.spliterDetails.end.split(" ")[1],
            timeZone : this.props.spliterDetails.timeZone,
        };

    }


    checkLegalTime(e){
        console.log("checkLegalTime :" , e);
        const {_errorSplitAbsence} = strings;
        if (e <= this.state.startTime || e >= this.state.endTime){
            alert(_errorSplitAbsence);
            this.initState();
        }
    };

    initState () {
        this.setState({
            selectedEnd : this.state.endTime,
            selectedStart : this.state.startTime
        });
    };


 handleChange = (e) => {
     console.log("handleChange value :" + e.target.value);
     var newTime = "";
     var timeValue = e.target.value;
     var numbers = [];
     var splitTime = [];

     //1st condition: if the value entered is empty, we set the default value
     if(timeValue.trim() == ""){
         this.initState();
         return;
     }

     //2nd condition: only allow numbers, dot and double dot. If not match set the default value. Example => 23a55
     var regex = /^[0-9:]+$/;
     if( !regex.test(timeValue) ) {
         this.initState();
         return;
     }

     //3rd condition: replace the dot with double dot. Example => 23.55
     e.target.value = e.target.value.replace(".", ":").replace(/\./g,"");
     timeValue = e.target.value;

     //4th condition: auto add double dot if the input entered by user contains numbers only (no dot or double dot symbol found)
     //example => 2344 or 933
     if(timeValue.indexOf(".") == -1 && timeValue.indexOf(":") == -1){
         //check if the length is more than 4 we strip it up to 4
         if(timeValue.trim().length > 4){
             timeValue = timeValue.substring(0,4);
         }
         var inputTimeLength = timeValue.trim().length;
         numbers = timeValue.split('');
         switch(inputTimeLength){
             //Example => 23
             case 2:
                 if(parseInt(timeValue) <= 0){
                     e.target.value = this.state.endTime;
                 }else if(parseInt(timeValue) >= 24){
                     e.target.value = this.state.endTime;
                 }else{
                     e.target.value = timeValue + ":00";
                 }
                 break;
             //Example => 234
             case 3:
                 newTime = "0" + numbers[0] + ":";
                 if(parseInt(numbers[1] + numbers[2]) > 59){
                     newTime += "00";
                 }else{
                     newTime += numbers[1] + numbers[2];
                 }
                 e.target.value = newTime;
                 break;
             //Example 2345
             case 4:
                 if(parseInt(numbers[0] + numbers[1]) >= 24){
                     newTime = "00:";
                 }else{
                     newTime = numbers[0] + numbers[1] + ":";
                 }
                 if(parseInt(numbers[2] + numbers[3]) > 59){
                     newTime += "00";
                 }else{
                     newTime += numbers[2] + numbers[3];
                 }
                 e.target.value = newTime;
                 break;
         }
         this.checkLegalTime(e.target.value);
         return;
     }

     //5th condition: if double dot found
     var doubleDotIndex = timeValue.indexOf(":");
     //if user doesnt enter the first part of hours example => :35
     if(doubleDotIndex == 0){
         newTime = "00:";
         splitTime = timeValue.split(':');
         numbers = splitTime[1].split('');
         if(parseInt(numbers[0] + numbers[1]) > 59){
             newTime += "00";
         }else{
             newTime += numbers[0] + numbers[1];
         }
         e.target.value = newTime;
         this.checkLegalTime(e.target.value);

     }else{
         //if user enter not full time example=> 9:3
         splitTime = timeValue.split(':');
         var partTime1 = splitTime[0].split('');
         if(partTime1.length == 1){
             newTime = "0" + partTime1[0] + ":";
         }else{
             if(parseInt(partTime1[0] + partTime1[1]) > 23){
                 newTime = "00:";
             }else{
                 newTime = partTime1[0] + partTime1[1] + ":";
             }
         }

         var partTime2 = splitTime[1].split('');
         if(partTime2.length == 1){
             newTime += "0" + partTime2[0];
         }else{
             if(parseInt(partTime2[0] + partTime2[1]) > 59){
                 newTime += "00";
             }else{
                 newTime += partTime2[0] + partTime2[1];
             }
         }
         e.target.value = newTime;
         this.checkLegalTime(e.target.value);

     }
 };

    render() {
        const {classes, show} = this.props;
        const {_absenceStartHour, _absenceEndHour} = strings
        const {matricule, start, end,idMyshift, id, selectedStart, selectedEnd, startTime, endTime, timeZone, startUtc, endUtc} = this.state;
        console.log("selectedStart : "+ selectedStart);
        console.log("selectedEnd : "+ selectedEnd);
        console.log("startTime : "+ startTime);
        console.log("endTime : "+ endTime);
        console.log("start : "+ start);
        console.log("end : "+ end);
        console.log("timeZone : "+ timeZone);
        return (
            <div style={{position: "relative"}}>
                <Dialog
                    open={show}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                    }}
                    classes={{paper: classes.dialogPaper}}
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                Découper une absence
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{marginTop: 30}}>
                        <Grid container direction={"row"} spacing={32}>
                            <Grid item sm={12}>
                                <InputLabel style={_labelStyles} htmlFor="matricule">Matricule</InputLabel>
                                <TextField
                                    id={"matricule"}
                                    value={matricule}
                                    margin="dense"
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                            <Grid container className={classes.grid} justify="space-around">
                                <td>
                                    <label>{_absenceStartHour}</label>
                                </td>
                                <td>
                                    <input type='time' value={startTime} disabled={true}></input>
                                </td>
                                <td>
                                    <label>{_absenceEndHour}</label>
                                </td>
                                <td>
                                    <input
                                    type='time' value={selectedEnd}
                                    onChange={(e)=>{
                                        this.setState({
                                        selectedStart: e.target.value,
                                        selectedEnd : e.target.value
                                        });
                                    }}
                                    onBlur={(e) => {
                                        this.handleChange(e)
                                    }}>
                                    </input>
                                </td>
                            </Grid>
                            <Grid container className={classes.grid} justify="space-around">
                                <td>
                                    <label>{_absenceStartHour}</label>
                                </td>
                                <td>
                                    <input type='time' value={selectedStart} disabled={true}></input>
                                </td>
                                <td>
                                    <label>{_absenceEndHour}</label>
                                </td>
                                <td>
                                    <input type='time' value={endTime} disabled={true}></input>
                                </td>
                            </Grid>
                            <Grid item sm={6}>
                                <InputLabel style={_labelStyles} htmlFor="start">Départ</InputLabel>
                                <TextField
                                    disabled
                                    id={"start"}
                                    fullWidth
                                    value={start}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <InputLabel style={_labelStyles} htmlFor="end">Retour</InputLabel>
                                <TextField
                                    disabled
                                    id={"end"}
                                    fullWidth
                                    value={end}
                                />

                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => {
                            this.props.setShowSpliterDialog({show: false, spliterDetails: {}})
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.splitAbsence(id, idMyshift, matricule, startUtc, selectedEnd, selectedStart, endUtc, timeZone);
                            this.props.setShowSpliterDialog({show: false, spliterDetails: {}});
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {absence: state.absence}
};

let mapActionsToProps = {
    setShowSpliterDialog,
    splitAbsence
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(SpliterDialog));
