import {getAxios} from "./AuthActions";
import {API} from "../api/Endpoints";
import {store} from "../store/ConfigureStore";
import {showTimedToaster} from "./ReferencesActions";
import { strings } from "../i18n/Strings"; 

export function getInErrorExportsDone(payload) {
    return {
        type: "GET_IN_ERROR_EXPORTS",
        payload
    }
}

export function getUnExportedsDone(payload) {
    return {
        type: "GET_UNEXPORTED",
        payload
    }
}

export function flagExported(requestId, comment)  {
    return async dispatch => {
        const axios = getAxios();
        const {_respFlagExportedOK, _respFlagExportedKO} = strings; 

        axios.post(`${API}/conges/request/flagExported`, {
            "id": requestId,
            "comment": comment
        })
            .then( res => {
                dispatch(showTimedToaster({open: true, message: _respFlagExportedOK, type: "success"}));
                dispatch(getInErrorExports(0, 25));
            })
            .catch(e => {
                dispatch(showTimedToaster({open: true, message: _respFlagExportedKO, type: "warning"}))
            })

    }
}

export function exportToHr(requestIds)  {
    return async dispatch => {
        const axios = getAxios();
        const {_respExportToHrOk,  _respExportToHrKO} = strings; 
        axios.post(`${API}/conges/request/exportToHr`, requestIds)
            .then( res => {
                dispatch(showTimedToaster({open: true, message: _respExportToHrOk, type: "success"}));
                dispatch(getInErrorExports(0, 25));
            })
            .catch(e => {
                dispatch(showTimedToaster({open: true, message: _respExportToHrKO, type: "warning"}))
            })

    }
}



export function getInErrorExports(page, size, startDate, endDate, status, registrationNumber, typeConge)  {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().hrExports.inErrorExports;
        dispatch(getInErrorExportsDone({loading: true, data, totalElements}));
        const inErrorExports = await axios.post(`${API}/conges/request/inError`, {
            "status" : null,
            "startDate" : startDate,
            "endDate" : endDate,
            "registrationNumber": registrationNumber,
            "typeConge" : typeConge,
            "order": false,
            "sort": null,
            "page": page || 0,
            "size": size || 25
        });
        dispatch(getInErrorExportsDone({
            loading: false,
            data: inErrorExports.data.payload,
            totalElements: inErrorExports.data.totalElements
        }))
    }
}


export function getUnExported(page, size, startDate, endDate, status, registrationNumber, typeConge)  {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().hrExports.unExported;
        dispatch(getUnExportedsDone({loading: true, data, totalElements}));
        const inErrorExports = await axios.post(`${API}/conges/request/notExported`, {
            "status" : null,
            "startDate" : startDate,
            "endDate" : endDate,
            "registrationNumber": registrationNumber,
            "typeConge" : typeConge,
            "order": false,
            "sort": null,
            "page": page || 0,
            "size": size || 25
        });
        dispatch(getUnExportedsDone({
            loading: false,
            data: inErrorExports.data.payload,
            totalElements: inErrorExports.data.totalElements
        }))
    }
}

