import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PerDayAbsence from "./perDay/PerDayAbsence";
import PerHourAbsence from "./perHour/PerHourAbsence";
import {strings} from "../../../i18n/Strings";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

class CurrentAbsenceMyshift extends React.Component {
    state = {
        value: 0,
    };

    componentWillMount() {
        window.localStorage.setItem("CURRENT_TAB", "ABSENCE_DAY")
    }

    handleChange = (event, value) => {
        this.setState({value});
        window.localStorage.setItem("CURRENT_TAB", value ? "ABSENCE_HOUR" : "ABSENCE_DAY")
    };

    render() {
        const {classes} = this.props;
        const {value} = this.state;
        const {_dayAbsence, _hourAbsence} = strings;
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange}>
                        <Tab label={_dayAbsence}/>
                        <Tab label={_hourAbsence}/>
                    </Tabs>
                </AppBar>
                {value === 0 && <PerDayAbsence/>}
                {value === 1 && <PerHourAbsence/>}
            </div>
        );
    }
}

export default withStyles(styles)(CurrentAbsenceMyshift);


