import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getProcessedRequests} from "../../../actions/RequestsActions";
import RequestDetails from "../../utilities/RequestDetails";

const styles = theme => ({});


class Requests extends Component {

    state = {
        filters: [],
        sort: ""
    };

    changeFilters = (filters) => {

        this.setState({filters: filters.reduce(function(obj,item){
                obj[item.columnName] = item.value;
                return obj;
            }, {})}, () => {
            const {filters,sort} = this.state;
            const {
                addedForEmp,
                typeConge,
                typeRequest,
                returnDate,
                startDate,
                status
            } = filters;
            this.props.getProcessedRequests(0,25,addedForEmp,typeConge,typeRequest,returnDate,startDate,status)
        })
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getProcessedRequests(page - 1, size)
    };
    componentDidMount() {
        this.props.getProcessedRequests(0,25)
    }

    render() {
        const {auth, requests} = this.props;
        const {processedRequests,  myRequestsHistory, myRequestsFollow, dialog, requestDetails} = requests;
        const {loading, data, totalElements} = processedRequests;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={(page, size) => {
                        this.changePaging(page, size)
                    }}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                <RequestDetails
                myRequestsHistory={myRequestsHistory}
                myRequestsFollow={myRequestsFollow}
                details={requestDetails}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests, auth: state.auth}
};

let mapActionsToProps = {getProcessedRequests};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(Requests));

