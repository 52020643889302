import React, {Component} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import {connect} from "react-redux";
import 'react-dates/initialize';
import '../../../../styles/DatePicker.css';
import {DateRangePicker, SingleDatePicker} from 'react-dates';
import TextField from "@material-ui/core/TextField";
import 'moment/locale/fr';
import {
    getDaysOff,
    getDaysOffExcep,
    setMonthIndicator,
    setShowExcepHolidayDetails
} from "../../../../actions/ReferencesActions";
import BackupAutoComplete from './BackupAutoComplete';
import AddForEmployeeAutoComplete from './AddForEmployeeAutoComplete';
import moment from 'moment-timezone';
import {$labelStyles, labelStyles} from "../../../../styles/CommonStyles";
import { getIsProd, hasAuthority } from "../../../../actions/AuthActions";
import '../../holidays/addHolidayRequest/addRequest.css'
import ExceptionalDetailsDialog from "../../holidays/addHolidayRequest/ExceptionalDetailsDialog";
import {strings} from "../../../../i18n/Strings";
import {LAST_DATE } from '../../../../utilities/GlobalConstants';
import {formatString} from "../../../../utilities/MappingFunctions";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'visible'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 500,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});



function getVO(type) {
    if (type === "PAIDHOLIDAY" || type === "RTTHOLIDAY" || type === "SENIORITYHOLIDAY") {
        return "MyCongeVO"
    }
    if (type === "EXCEPTIONALHOLIDAY") {
        return "ExceptionalCongeVO"
    }
    if (type === "UNPAIDHOLIDAY") {
        return "MyCongeVO"
    } else {
        return type
    }
}


function getMenuItems(items) {
    return items.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
}

function getExcepLabel(e) {
    const {duration, provedByDocuments} = e;
    const {_DURRATION_OF_HOLIDAY, _REQUIRED_DOCS} = strings

    return formatString(_DURRATION_OF_HOLIDAY + _REQUIRED_DOCS, [
        duration,
        provedByDocuments.map(doc =>  " * " + doc.label).join("\n")
    ]);
}

function getMenuItemsWithToolTip(items) {
    return items.map((item, index) => {
            const {label, value} = item;
            return <MenuItem title={getExcepLabel(item.value)} key={index} value={item.value}>{label}</MenuItem>
        }
    )
}

class Selects extends Component {

    state = {
        "@type": "",
        duration: 0,
        totalDuration: 0,
        typeRequest: "REQUEST",
        typeConge: "",
        exceptionalType: "",
        startDate: null,
        endDate: null,
        focusedInputRange: null,
        focused: false,
        hasBackup: false,
        addedForEmp: null,
        backup: null,
        comment: '',
        requiresEndDate: false,
        doctorSpecialityId: null,
        doctorSpecialityLabel: null,
        doctorName: null,
        certificateDepositDate: null,
        selectedExcepHoliday: false,
        exceptionalTypeDetails: "",
        hasRoleMan: false,
        country: null
    };

    async componentWillMount() {
        const value = this.props.requests.showAddRequest.value;
        const start = moment(value.start.substring(0,10) , "DD/MM/YYYY");
        const {configUser} = this.props.references;
        const lastDay = configUser && configUser.lastDay;
        const end = moment(value.start.substring(0,10) , "DD/MM/YYYY").add(lastDay && lastDay == LAST_DATE ? 0 : 1, 'days');

        this.setState({
            startDate: end,
            endDate: start,
            addedForEmp: {
                registrationNumber: value.matricule
            }
        });
        this.onDatesChange(start,end);
        if (!this.props.addedClient) {
            const {auth, references} = this.props;
            const {profile} = auth;
            const {backup} = references;
            const {userName} = profile;
            const res = await getIsProd(userName);
            const isProd = false;
            this.setState({
                    addedForEmp: {
                        registrationNumber: userName,
                        prod: isProd,
                        role: []
                    },
                    hasBackup: (backup.length > 0)
                },
            )
        }
        const demandesAjoutees = (window.location.pathname === 'DemandesAjoutees');
        const hasRoleMan = (hasAuthority(["ROLE_MAN"]) && !demandesAjoutees) && !hasAuthority(["ROLE_RH_CONGE"]);
        if(hasRoleMan && this.props.addedClient) {
            this.setState({"@type": "ExceptionalCongeVO",hasRoleMan, requiresEndDate: true,
                typeConge: "EXCEPTIONALHOLIDAY", comment: "Cette demande est de type maladie non rémunérée"})
        }
    }

    handleExcpeHolidayDetails = details => {

        const {doctorsSpeciality, doctorName, certificateDepositDate} = details;
        const {id, label} = doctorsSpeciality;

        this.setState({doctorSpecialityId: id, doctorSpecialityLabel: label, doctorName, certificateDepositDate});
    };

    handleChange = (event, name) => {

        if (name === "backup") {
            this.setState({backup: JSON.parse(event)}, () => {
                const {backup} = this.state;
                this.props.handleRequestChange({...this.state, backup});
            })
        }
        if (name === "typeConge") {
            this.setState({
                typeConge: event.target.value,
                "@type": getVO(event.target.value),
                exceptionalType: "",
                // startDate: null,
                // endDate: null,
                focusedInputRange: null,
                focused: false,
                comment: '',
                backup: null,
                selectedExcepHoliday: false,
                exceptionalTypeDetails: ""
            })
        } else {
            if (name !== "backup") {
                this.setState({[name]: event.target.value});
            }
        }
    };
    onDatesChange = (startDate, endDate) => {
        const {configUser} = this.props.references;
        const lastDay = configUser && configUser.lastDay;
        const {exceptionalType} = this.state;
        if (endDate == null) {
            this.setState({startDate: startDate, endDate: endDate});
            this.props.setMonthIndicator(startDate);
            return null;
        } else {
            this.setState({startDate: startDate, endDate: endDate});
            this.props.setMonthIndicator(startDate);
            this.props.getDaysOff(moment(startDate).format("DD/MM/YYYY"),(lastDay && lastDay == LAST_DATE) ?
                moment(endDate,"DD/MM/YYYY").add(1,'days').format("DD/MM/YYYY"):moment(endDate).format("DD/MM/YYYY"));
        }
    };

    onDateChange(startDate) {
        const {exceptionalType} = this.state;
        this.props.setMonthIndicator(startDate);
        this.props.getDaysOffExcep(moment(startDate).format("DD/MM/YYYY"), exceptionalType);
        this.setState({startDate});

    }

    render() {
        const {_typeRequest, _holidayPeriod, _holidayType, _startDate,_ancienneteHoliday,_rttHoliday,
            _payedHoliday, _unpayedHoliday,_exceptionalHoliday, _exceptionalHolidayFr,_returnDate, _comment} = strings;
        const {references} = this.props;
        const {configUser} = references;
        let congeMappings =
         [
            {
                value: "PAIDHOLIDAY",
                label: _payedHoliday
            },
            {
                value: "RTTHOLIDAY",
                label: _rttHoliday
            },
            {
                value: "SENIORITYHOLIDAY",
                label: _ancienneteHoliday
            },
            {
                value: "EXCEPTIONALHOLIDAY",
                label: _exceptionalHolidayFr
            },
            {
                value: "UNPAIDHOLIDAY",
                label: _unpayedHoliday
            }
        ];
        const {classes, addedClient} = this.props;
        const {typeConge, exceptionalType, startDate, endDate, focusedInputRange, comment, requiresEndDate, selectedExcepHoliday, exceptionalTypeDetails, hasRoleMan} = this.state;
        const {exceptTypeHr, exceptTypeHrUser} = this.props.references;
        const demandesAjoutees = !(window.location.pathname === 'DemandesAjoutees');
        this.props.handleRequestChange(this.state);
        return (
            <Grid container direction={"column"} spacing={16}>
                {
                    addedClient && <Grid item>
                        <AddForEmployeeAutoComplete handleChange={(employee) => {
                            const {registrationNumber, role, prod, country} = employee;
                            this.setState({
                                addedForEmp: {
                                    registrationNumber,
                                    prod,
                                    role,
                                }
                            })

                            if(demandesAjoutees || !hasAuthority(["ROLE_MAN"])) {
                                this.setState({
                                    exceptionalType: {
                                        "provedByDocuments": [],
                                        "id": 139,
                                        "label": "Maladie Non Payée",
                                        "duration": 1,
                                        "roleEnum": "RH_VALIDATION",
                                        "requiresEndDate": true,
                                        "country": country,
                                        "mappingHR": {
                                            "id": 108,
                                            "label": "Maladie Non Payée",
                                            "abbreviation": "MAN",
                                            "source": "HR",
                                            "type": "UNPAIDHOLIDAY"
                                        },
                                        "forJustify": false
                                    }
                                })
                            }
                        }}/>
                    </Grid>
                }
                {
                    (!hasRoleMan) && <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                              style={labelStyles}
                            >
                                {_typeRequest}
                            </InputLabel>
                            <Select
                              disabled={hasRoleMan}
                              value={typeConge}
                              onChange={(e) => {
                                  this.handleChange(e, "typeConge")
                              }}
                            >
                                {getMenuItems(congeMappings)}
                            </Select>
                        </FormControl>
                    </Grid>
                }

                <Grid item>
                    {
                        !addedClient && <BackupAutoComplete handleChange={this.handleChange}/>
                    }
                </Grid>

                {
                    ["EXCEPTIONALHOLIDAY"].includes(typeConge) && (!hasRoleMan) &&
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                style={labelStyles}
                            >
                                {_holidayType}
                            </InputLabel>
                            <Select
                                disabled={hasRoleMan}
                                value={exceptionalType}
                                onChange={(e) => {
                                    var typeField = e.target.value;
                                    const {startDate} = this.state;

                                    this.setState({
                                        requiresEndDate: false,
                                        selectedExcepHoliday: true,
                                        exceptionalTypeDetails: getExcepLabel(typeField)
                                    });

                                    if (typeField.requiresEndDate) {
                                        this.setState({requiresEndDate: true})
                                    }

                                    if (startDate != null) {
                                        this.props.getDaysOffExcep(moment(startDate).format("DD/MM/YYYY"), e.target.value)
                                    }
                                    // test if the selected holiday type is sickness to show doctor details & certificate deposit date
                                    // if (typeField.id === 11) {
                                    //     this.props.setShowExcepHolidayDetails(true);
                                    // }
                                    this.handleChange(e, "exceptionalType")
                                }}
                            >
                                {getMenuItemsWithToolTip((!addedClient ? exceptTypeHrUser : exceptTypeHr).filter(type => (type.forJustify===true)).map(type => {
                                    return {value: type, label: type.label}
                                }))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {
                    (["EXCEPTIONALHOLIDAY"].includes(typeConge) && !requiresEndDate) &&
                    <Grid item>
                        <div>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <InputLabel
                                        style={{marginLeft: 10, fontSize: 11}}
                                        htmlFor="single-select">{_startDate}</InputLabel>
                                </Grid>
                                <Grid item>
                                    <SingleDatePicker
                                        disabled={true}
                                        numberOfMonths={2}
                                        noBorder={true}
                                        block={true}
                                        withPortal={true}
                                        hideKeyboardShortcutsPanel={true}
                                        onDateChange={startDate => this.onDateChange(startDate)}
                                        onFocusChange={({focused}) => this.setState({focused})}
                                        isOutsideRange={(startDate) => {
                                            return false
                                        }}
                                        focused={this.state.focused}
                                        date={startDate}
                                    />
                                </Grid>
                            </Grid>
                            <Divider/>
                            <Divider/>
                        </div>
                    </Grid>
                }

                {
                    (["PAIDHOLIDAY", "UNPAIDHOLIDAY"].includes(typeConge) || (["EXCEPTIONALHOLIDAY"].includes(typeConge) && requiresEndDate)) &&
                    <Grid item>
                        <div>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <InputLabel
                                        style={{marginLeft: 10, fontSize: 11}}
                                        htmlFor="range-select">{_holidayPeriod}</InputLabel>
                                </Grid>
                                <Grid item>
                                    <DateRangePicker
                                        disabled={true}
                                        startDateId="start"
                                        endDateId="end"
                                        block={true}
                                        startDate={startDate}
                                        noBorder={true}
                                        withPortal={true}
                                        endDate={endDate}
                                        startDatePlaceholderText={_startDate}
                                        endDatePlaceholderText={_returnDate}
                                        hideKeyboardShortcutsPanel={true}
                                        onDatesChange={({startDate, endDate}) => {
                                            this.onDatesChange(startDate, endDate)
                                        }}
                                        isOutsideRange={(startDate) => {
                                            return false
                                        }}
                                        focusedInput={focusedInputRange}
                                        onFocusChange={(focusedInputRange) => {
                                            this.setState({focusedInputRange})
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <Divider/>
                        <Divider/>
                    </Grid>
                }

                <Grid item>
                    <InputLabel
                        style={$labelStyles}
                    >
                        {_comment}
                    </InputLabel>
                    <br/>
                    <TextField
                        disabled={hasRoleMan}
                        value={comment}
                        onChange={e => {
                            this.handleChange(e, "comment")
                        }}
                        multiline
                        rows="4"
                        className={classes.formControl}
                        margin="normal"
                    />
                </Grid>
                {
                    selectedExcepHoliday && <Grid item>
                        <div className={"exceptional-holiday"}>
                            {exceptionalTypeDetails}
                        </div>
                    </Grid>
                }
                <ExceptionalDetailsDialog handleExcpeHolidayDetails={this.handleExcpeHolidayDetails}/>
            </Grid>
        );
    }
}


const mapStateTopProps = state => {
    return {references: state.references, auth: state.auth, requests: state.requests}
};

const mapActionsToprops = {
    getDaysOff, getDaysOffExcep, setMonthIndicator, setShowExcepHolidayDetails
};

export default connect(mapStateTopProps, mapActionsToprops)(withStyles(styles)(Selects));


