import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getTeleoptiMapping} from "../../../actions/ReferencesActions";
import AddMappingTeleoptiDialog from "./AddMappingTeleoptiDialog";
import DeleteMappingConfimationDialog from './DeleteMappingConfimationDialog'
import RefData from "../../../utilities/RefData";

const styles = theme => ({});


class MappingTeleopti extends Component {

    state = {
        filters: [],
        sort: ""
    };

    changeFilters = (filters) => {
        this.setState({filters: filters.reduce(function(obj,item){
                obj[item.columnName] = item.value;
                return obj;
            }, {})}, () => {
            const {filters,sort} = this.state;
            const {
                abbreviation,
                country
            } = filters;
            this.props.getTeleoptiMapping(0,25,abbreviation, country? RefData.COUNTRY_CODES[country.label]: null)
        })
    };
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getTeleoptiMapping(page - 1, size)
    };
    componentDidMount() {
        this.props.getTeleoptiMapping(0,25)
    }

    render() {
        const {references, auth} = this.props;
        const {teleoptiMapping} = references;
        const {totalElements, data, loading} = teleoptiMapping;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={this.changePaging}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                <AddMappingTeleoptiDialog/>
                <DeleteMappingConfimationDialog/>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {references: state.references,  auth: state.auth}
};

let mapActionsToProps = {getTeleoptiMapping};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(MappingTeleopti));

