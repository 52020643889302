import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {store} from "../../../store/ConfigureStore";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core/styles";
import {setShowAddRecup} from "../../../actions/HolidaysActions";
import {deleteRequestRecup} from "../../../actions/RequestsActions";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class DeleteRecupConfirmationDialog extends React.Component {



    render() {
        const {showAddRecup} = this.props.holidays;
        const {startDate, returnDate, id} = showAddRecup.details;
        const type = showAddRecup.type;
        return (
            <div>
                <Dialog
                    open={true}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="#"
                    aria-describedby="#"
                >
                    <DialogTitle id="#">
                        Suppression du type de récupération
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="#">
                            Confirmez-vous la suppression de la récupération ? <br/> de {startDate} à {returnDate}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            store.dispatch(setShowAddRecup({type: "ADD", open: false, details: {}}))
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            store.dispatch(deleteRequestRecup(id))
                            store.dispatch(setShowAddRecup({type: "ADD", open: false, details: {}}))
                        }} color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {holidays: state.holidays}
};

let mapActionsToProps = {};


export default withStyles(() => {
})(connect(mapStateToProps, mapActionsToProps)(DeleteRecupConfirmationDialog));
