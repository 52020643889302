import React, {Fragment} from 'react';
import Select from 'react-select';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import {API, DEAFULT_AVATAR_CORE_SERVER} from "../../../api/Endpoints";
import {connect} from "react-redux";
import {$labelStyles} from "../../../styles/CommonStyles";
import InputLabel from '@material-ui/core/InputLabel';
import {strings} from "../../../i18n/Strings";


function getUserItem(user) {
    const {registrationNumber, firstName, lastName} = user;
    return <Grid container direction={"row"} spacing={8}>
        <Grid item>
            <Avatar alt="" src={`${API}/${DEAFULT_AVATAR_CORE_SERVER}/${registrationNumber}`}
                    style={{height: 35, width: 35}}/>
        </Grid>
        <Grid item>
            <Typography variant="body1" gutterBottom>
                {registrationNumber} {firstName} {lastName}
            </Typography>
        </Grid>
    </Grid>
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 9999,
        maxWidth: 500
    },
    input: {
        display: 'flex',
        padding: 0,
        width: 500,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 99999,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});


function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            style={{minWidth: 500}}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}


function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
};

class IntegrationReactSelect extends React.Component {
    state = {
        value: null
    };

    handleChange = name => value => {
        this.props.handleChange(value.value,"backup")
        this.setState({
            [name]: value,
        });
    };

    render() {
        const {classes} = this.props;
        const {backup} = this.props.references;
        const {_backupValidator} = strings;
        const selectStyles = {
            input: base => ({
                ...base,
                color: "lightGrey",
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        return (
            <Fragment>
                { backup.length > 0 && <div className={classes.root}>
                    <NoSsr>
                        <InputLabel
                            style={$labelStyles}
                        >
                            {_backupValidator}
                        </InputLabel>
                        <Select
                            classes={classes}
                            styles={selectStyles}
                            options={backup.map(suggestion => ({
                                value: JSON.stringify(suggestion),
                                label: getUserItem(suggestion),
                            }))}
                            components={components}
                            value={this.state.value}
                            onChange={this.handleChange('value')}
                            placeholder=" "
                        />
                    </NoSsr>
                </div>}
            </Fragment>
        );
    }
}

const mapStateTopProps = state => {
    return {references: state.references}
};

const mapActionsToprops = {};

export default connect(mapStateTopProps, mapActionsToprops)(withStyles(styles, {withTheme: true})(IntegrationReactSelect));
