import * as React from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

const styles = {
    root: {
        overflow: 'auto',
        minHeight: "60vh",
        width: '100%',
        color: 'grey'
    },
};

const CustomTableContainerBase = ({
                                      children, classes, className, ...restProps
                                  }) => (
    <div
        className={classNames(classes.root, className)}
        {...restProps}
    >
        {children}
    </div>
);


export const CustomTableContainer = withStyles(styles, {name: 'CustomTableContainer'})(CustomTableContainerBase);