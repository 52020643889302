import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {withStyles} from '@material-ui/core/styles';
import {setShowAddHoliday} from "../../../actions/HolidaysActions";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ActiveCountries from "../rejectionMotifs/ActiveCountries";
import TextField from "@material-ui/core/es/TextField/TextField";
import {DateRangePicker} from "react-dates";
import '../../../styles/DatePicker.css';
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import {_labelStyles} from "../../../styles/CommonStyles";
import moment from 'moment';
import {addPublicHoliday, editPublicHoliday} from "../../../actions/ReferencesActions";

const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 450,
            maxHeight: 450,
            maxWidth: 800,
            minWidth: 800,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}



class AddMappingTeleoptiDialog extends React.Component {
    state = {
        value:  0,
        label: "",
        startDate: null,
        endDate: null,
        comment: null,
        focusedInputRange: null,
        id: null
    };

    handleChange = (event, value) => {
        this.setState({[value]: event});
    };

    onDatesChange = (startDate, endDate) => {
        if (endDate === null) {
            endDate=startDate;
        }
        this.setState({startDate: startDate, endDate: endDate});
    };

    formatDate = date => {
        date = date.split('/')
        let temp = date[0];
        date[0] = date[1];
        date[1] = temp;
        return date.join('/')
    }

    componentWillMount() {
        const {label, startDate, endDate, country, id } = this.props.holidays.showAddHoliday.details;
        this.setState({label, country, id })
        if(startDate) {
            this.setState({
            startDate : moment(this.formatDate(startDate)),
            endDate : moment(this.formatDate(endDate))
            })
        }
    }
    componentWillUnmount() {
        this.setState({id: null, label: null, startDate: null, endDate: null, country: null })
    }

    render() {
        const {classes, references, holidays, type} = this.props;
        const {activeCountries} = references;
        // const {showAddHoliday} = holidays;
        const {label, startDate, endDate, country, focusedInputRange } = this.state;
        return (
            <div style={{position: "relative"}}>
                <Dialog
                    open={true}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    classes={{paper: classes.dialogPaper}}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                {`${(type === "EDIT")? 'Editer' : 'Ajouter'} un jour férié`}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{marginTop: 10}}>
                        <Grid container direction={"column"} spacing={32}>
                            <Grid item>
                                <InputLabel style={_labelStyles} htmlFor="publicHoliday">Jour Férié</InputLabel>
                                <TextField
                                    onChange={(e) => {this.handleChange(e.target.value,"label")}}
                                    autoFocus
                                    value={label}
                                    margin="dense"
                                    id="publicHoliday"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item > <ActiveCountries multi={false}
                                                          country={country}
                                                          activeCountries={activeCountries}
                                                         handleChangeLable={(country) => {

                                                             const {value, label} = country;
                                                             this.setState({country: {id: value, label}});
                                                         }}/></Grid>
                            <Grid item>
                                <DateRangePicker
                                    startDateId="start"
                                    endDateId="end"
                                    startDate={startDate}
                                    noBorder={true}
                                    endDate={endDate}
                                    startDatePlaceholderText="Date de début"
                                    endDatePlaceholderText="Date de fin"
                                    hideKeyboardShortcutsPanel={true}
                                    onDatesChange={({startDate, endDate}) => {
                                        this.onDatesChange(startDate, endDate)
                                    }}
                                    withPortal={true}
                                    focusedInput={focusedInputRange}
                                    isOutsideRange={(day) => {return false}}
                                    onFocusChange={(focusedInputRange) => {
                                        this.setState({focusedInputRange})
                                    }}
                                />
                                <Divider/>
                                <Divider/>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => {
                            this.props.setShowAddHoliday({type: "ADD", open: false, details: {}})
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            const {startDate, endDate, country, label, id} = this.state;
                            if(type === "EDIT") {
                                this.props.editPublicHoliday({ id: id, duration: null, country, label, startDate: moment(startDate).format("DD/MM/YYYY"), endDate: moment(endDate).format("DD/MM/YYYY")})
                            }
                            else {
                                this.props.addPublicHoliday({ id: null, duration: null, country, label, startDate: moment(startDate).format("DD/MM/YYYY"), endDate: moment(endDate).format("DD/MM/YYYY")})
                            }

                            this.props.setShowAddHoliday({type: "ADD", open: false, details: {}})
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {references: state.references, holidays: state.holidays}
};

let mapActionsToProps = {
    setShowAddHoliday,
    addPublicHoliday,
    editPublicHoliday
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(AddMappingTeleoptiDialog));
