import * as React from "react";
import { Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState
} from "@devexpress/dx-react-grid";
import { Grid, Table, TableFilterRow, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { tableSizeTabs } from "../../../../styles/CommonStyles";
import IconButton from "@material-ui/core/IconButton";
import MGrid from "@material-ui/core/Grid";
import { CustomEmptyTable, CustomTableRow, TableHeaderContent } from "../../../../utilities/CustomTableElements";
import { API, MEDIUM_AVATAR_CORE_SERVER } from "../../../../api/Endpoints";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {
  FilterCell,
  IdEditor,
  noPredicate,
  ToJustifyTypeProvider,
  UserStatusTypeProvider,
  compareDates, getTimeEntryType
} from "../../../../utilities/MappingFunctions";
import { strings } from "../../../../i18n/Strings";
import { CustomTableContainer } from "../../../../utilities/CustomTableContainer";
import { store } from "../../../../store/ConfigureStore";
import {
  showDeletionDialog,
  showTimeEntryDetails
} from "../../../../actions/AbsenceActions";
import moment from "moment";
import ConsultIcon from "@material-ui/icons/Visibility";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";

const IdFormatter = ({value}) => {
  const {id} = value;
  return (
      <MGrid container style={{ display: "flex", flexWrap: "nowrap" }} spacing={0}>
        <MGrid item>
          <IconButton onClick={() => {
            store.dispatch(showTimeEntryDetails({open:true, status:"VALID", data : value}));
          }

          } color="default" aria-label="add">
            <ConsultIcon fontSize="small"/>
          </IconButton>
        </MGrid>
        <div hidden={value.canceled}>
          <MGrid item>
            <IconButton  onClick={() => {
              store.dispatch(showDeletionDialog({open: true, type: "CANCEL", id: id, data : value}))
            }} aria-label="Cancel">
              <CancelIcon fontSize="small"/>
            </IconButton>
          </MGrid>
        </div>
      </MGrid>
  )
};

const StatusProvider = props => (
    <DataTypeProvider
        formatterComponent={statusFormatter}
        {...props}
    />
);

const statusFormatter = ({value}) => {
  if (value === "CANCELED") {
    return <div style={{fontWeight: "bold", color: "Red"}}>Annulée</div>
  }
  if (value === "PUSHED") {
    return <div style={{fontWeight: "bold", color: "Green"}}>Saisie</div>
  }
}

const HourFormatter = ({ value }) => {
  if (value) {
    return <>{value.split(" ").length > 0 ? value.split(" ")[1] : ""}</>;
  } else {
    return <></>;
  }
};

const EmployeeFormatter = ({ value }) => {
  const addedByEmp = value;
  if (addedByEmp) {
    const {
      employeeName,
      employeeLastName,
      regestionNumberEmployee
    } = addedByEmp;
    return (
      <MGrid container>
        <MGrid item sm={4}>
          <Avatar
            src={`${API}/${MEDIUM_AVATAR_CORE_SERVER}/${regestionNumberEmployee}`}
          />
        </MGrid>
        <MGrid item sm={8} style={{ paddingTop: 5 }}>
          <MGrid container direction={"column"}>
            <MGrid item>
              <Typography gutterBottom noWrap>
                {`${employeeName} ${employeeLastName}`}
              </Typography>
            </MGrid>
            <MGrid item>{regestionNumberEmployee}</MGrid>
          </MGrid>
        </MGrid>
      </MGrid>
    );
  } else {
    return <div>{value.toString()}</div>;
  }
};

const IdProvider = props => (
  <DataTypeProvider
    formatterComponent={IdFormatter}
    editorComponent={IdEditor}
    {...props}
  />
);

const HourProvider = props => (
  <DataTypeProvider formatterComponent={HourFormatter} {...props} />
);

export default class GlobalGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmationInMassDialogOpen: false,
      tableColumnExtensions: [
        { columnName: "justified", width: 100 },
        { columnName: "id", align: "right", width: 100 }
      ],
      integratedFilteringColumnExtensions: [
        { columnName: "date", predicate: noPredicate },
        { columnName: "canceled", predicate: noPredicate }
      ],
      integratedSortingColumnExtensions: [
        { columnName: 'date', compare: compareDates },
      ],
      idColumns: ["id"],
      activeColumns: [ "active"],
      typeColumns: [ "type"],
      hourColumns: [],
      selection: [],
      canceledColumns : ["canceled"]
    };
    this.handleToggleConfirmation = () => {
      this.setState({
        confirmationInMassDialogOpen: !this.state.confirmationInMassDialogOpen
      });
    };
  }

  GMTToLocale(value, action) {
    const d = new Date();
    const diff = (d.getTimezoneOffset() / 60) * -1;
    const date = moment(value)
      .add(action * diff, "hours")
      .format("YYYY-MM-DD HH:mm:ss");
    return date;
  }

  render() {
    const {
      idColumns,
      activeColumns,
      tableColumnExtensions,
      integratedFilteringColumnExtensions,
      integratedSortingColumnExtensions,
      hourColumns,
      canceledColumns
    } = this.state;
    const rows = this.props.data.map(row => {
      const { firstName, lastName, registrationNumber , start , end  , date , type,canceled} = row;

      return {
        registrationNumber,
        firstName,
        lastName,
        start,
        end,
        date,
        id: row,
        type : getTimeEntryType(type),
        canceled : canceled ? "CANCELED" : "PUSHED" ,
      };
    });
    const { loading, searchActive } = this.props;
    const {
      _justified,
      _registrationNumber,
      _firstName,
      _lastName,
      _absenceDay,
      _absenceStartHour,
      _absenceEndHour,
      _type,
      _requestStatus,
      _actif
    } = strings;
    const { userName } = store.getState().auth.profile;
    const columns = [
      ...[
        { name: "registrationNumber", title: _registrationNumber },
        { name: "firstName", title: _firstName },
        { name: "lastName", title: _lastName },
        { name: "date", title: "Date" },
        { name: "start", title: _absenceStartHour },
        { name: "end", title: _absenceEndHour },
        { name: "type", title: _type },
        { name: "canceled", title: _requestStatus },
        { name: "", title: "" },
      ],
      { name: "id", title: " " }
    ];
    return (
      <Fragment>
        <Paper style={tableSizeTabs}>
          <Grid rows={rows} columns={columns} style={tableSizeTabs}>
            <IdProvider for={idColumns}/>

            <HourProvider for={hourColumns}/>
            <UserStatusTypeProvider for={activeColumns}/>
            <StatusProvider
                for={canceledColumns}
            />
            <SortingState
              defaultSorting={[{ columnName: "requestDate", direction: "asc" }]}
              onSortingChange={sorting => {
                this.props.changeSorting(sorting);
              }}
            />
            <FilteringState
              onFiltersChange={filters => {
                this.props.changeFilters(filters);
              }}
            />
            <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}/>
            <IntegratedFiltering
              columnExtensions={integratedFilteringColumnExtensions}
            />

            <Table
              rowComponent={CustomTableRow}
              containerComponent={CustomTableContainer}
              noDataCellComponent={() => {
                return <CustomEmptyTable loading={loading}/>;
              }}
              columnExtensions={tableColumnExtensions}
            />
            <TableHeaderRow
              contentComponent={TableHeaderContent}
              showSortingControls
            />
            {searchActive && (
              <TableFilterRow
                messages={{ filterPlaceholder: " " }}
                cellComponent={FilterCell}
              />
            )}
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}
