import React from "react";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import AcceptIcon from "@material-ui/icons/CheckOutlined";
import RejectIcon from "@material-ui/icons/CancelOutlined";
import PendingIcon from "@material-ui/icons/AccessTime";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import pink from "@material-ui/core/colors/pink";
import { getHolidayType, states } from "../../utilities/MappingFunctions";

import { API, MEDIUM_AVATAR_CORE_SERVER } from "../../api/Endpoints";
import { System } from "../../assets/system";
import { strings } from "../../i18n/Strings";



const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginLeft: 20,
    marginTop: 20,
    maxWidth: 700
  },
  card: {
    minWidth: 270
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});
const $styles = {
  icon_green: {
    color: green[500]
  },
  icon_red: {
    color: red[500]
  },
  icon_black: {
    color: grey[500]
  }
};

const getIcon = state => {
  if (state === "REJECTED") {
    return <RejectIcon style={$styles.icon_red} />;
  }
  if (state === "PENDING") {
    return <PendingIcon style={$styles.icon_black} />;
  }
  if (state === "APPROVED") {
    return <AcceptIcon style={$styles.icon_green} />;
  } else {
    return state;
  }
};

class Follow extends React.Component {
  render() {
    const { _holidayType, _to$, _holidayPeriod, _days,_holidayDetailsMsg, _holidayDetailsMsgDR, _requestDate, _hr, _avis, _WFM_validation, _automatiquement_le, _system } = strings;
    const { classes, myRequestsFollow, details, configUser } = this.props;
    const { startDate, returnDate, updateDate, duration, typeConge, exceptionalType, role } =
      details || {};
    console.log(configUser)
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={7}>
            <Grid
              container
              style={{ paddingTop: 20, paddingLeft: 40 }}
              direction={"column"}
              justify={"center"}
            >
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  <span style={{ fontWeight: "bold", color: pink[600] }}>
                    {configUser.countryCode == '78' ? _holidayDetailsMsgDR : _holidayDetailsMsg}
                  </span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  <span style={{ fontWeight: "bold" }}>
                    {_requestDate} :{" "}
                  </span>
                  {updateDate}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  <span style={{ fontWeight: "bold" }}>{_holidayType} : </span>
                  { (typeConge == "EXCEPTIONALHOLIDAY" && exceptionalType && exceptionalType.label) ? exceptionalType.label : getHolidayType(typeConge)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  <span style={{ fontWeight: "bold" }}>
                    {_holidayPeriod} :{" "}
                  </span>
                  {startDate}
                  <span style={{ fontWeight: "bold" }}> {_to$} : </span>
                  {returnDate}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  <span style={{ fontWeight: "bold" }}>{_days} : </span>
                  {duration}
                </Typography>
              </Grid>
              {/*{*/}
              {/*(typeConge === "EXCEPTIONALHOLIDAY") && <>*/}
              {/*<Grid item>*/}
              {/*<Typography variant="body2" gutterBottom>*/}
              {/*<span style={{fontWeight: "bold"}}>Nom du médecin : </span>*/}
              {/*/!*to do*!/*/}
              {/*</Typography>*/}
              {/*</Grid>*/}
              {/*<Grid item>*/}
              {/*<Typography variant="body2" gutterBottom>*/}
              {/*<span style={{fontWeight: "bold"}}>Spécialité du médecin : </span>*/}
              {/*/!*to do*!/*/}
              {/*</Typography>*/}
              {/*</Grid>*/}
              {/*<Grid item>*/}
              {/*<Typography variant="body2" gutterBottom>*/}
              {/*<span style={{fontWeight: "bold"}}>Date dépot du certificat : </span>*/}
              {/*/!*to do*!/*/}
              {/*</Typography>*/}
              {/*</Grid>*/}
              {/*</>*/}
              {/*}*/}
            </Grid>
          </Grid>
          <Grid item sm={5}>
            {
              <List dense className={classes.card}>
                {myRequestsFollow.map((value, index) => {
                  const { addedByEmp, state, updateDate, role } = value;
                  if (addedByEmp) {
                    const {
                      registrationNumber,
                      firstName,
                      lastName
                    } = addedByEmp[0];
                    const roleMappings = {
                      MANAGER_VALIDATION: "Manager",
                      RH_VALIDATION: _hr,
                      PPV_VALIDATION: "PPV"
                    };
                    if (role === "SYSTEME") {
                      return (
                        <ListItem key={index}>
                          <Avatar alt="#">
                            <span style={{ marginTop: 8 }}>
                              <System />
                            </span>
                          </Avatar>
                          <ListItemText
                            primary={<span> {_system} </span>}
                            secondary={`${states[state]} ${_automatiquement_le} ${updateDate}`}
                          />
                          <ListItemSecondaryAction>
                            <IconButton>{getIcon(state)}</IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    }

                    if (role === "SYSTEME_PPV") {
                      return (
                        <ListItem key={index}>
                          <Avatar alt="#">
                            <span style={{ marginTop: 8 }}>
                              <System />
                            </span>
                          </Avatar>
                          <ListItemText
                            primary={
                              <span>
                                {" "}
                                {_WFM_validation}{" "}
                              </span>
                            }
                            secondary={`${states[state]} ${_automatiquement_le} ${updateDate}`}
                          />
                          <ListItemSecondaryAction>
                            <IconButton>{getIcon(state)}</IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    } else {
                      return (
                        <ListItem key={index}>
                          <Avatar
                            alt="#"
                            src={`${API}/${MEDIUM_AVATAR_CORE_SERVER}/${registrationNumber}`}
                          />
                          <ListItemText
                            primary={
                              <span>
                                {" "}
                                {firstName} {lastName}
                                <br /> {registrationNumber}
                              </span>
                            }
                            secondary={
                              state === "PENDING"
                                ? `${_avis} ${roleMappings[role]}`
                                : `
                                                ${_avis} ${roleMappings[role]} :
                                                ${states[state]} ${updateDate}`
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton>{getIcon(state)}</IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    }
                  }
                })}
              </List>
            }
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Follow);
