import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import { withStyles } from '@material-ui/core/styles';
import {getJustificationTypes } from "../../../../actions/AbsenceActions";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/es/TextField/TextField";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import { _labelStyles } from "../../../../styles/CommonStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { API, EXCEPTIONAL_TYPES } from "../../../../api/Endpoints";
import { getAxios } from "../../../../actions/AuthActions";
import { DateRangePicker, SingleDatePicker } from "react-dates";

import moment from 'moment';
import { strings } from "../../../../i18n/Strings";
import Indicator from "../../holidays/addHolidayRequest/Indicator";
import AddForEmployeeAutoComplete from '../../../routes/addedRequests/AddForEmployeeAutoComplete2';
import { addHolidayRequest } from "../../../../actions/HolidaysActions";
import { addErrorinfo } from "../../../../actions/HolidaysActions";
import { setShowAddRequest } from "../../../../actions/RequestsActions";
import {getDaysOffSuccess, showTimedToaster} from "../../../../actions/ReferencesActions";
import { saveTime, getTime } from "../../../../actions/AbsenceActions";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl';

const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 600,
            maxHeight: 600,
            maxWidth: 800,
            minWidth: 800,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        select: {
            width: "500px"
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function getMenuItems(items) {
    return items.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
}

function checkCancelationDate (date) {
    const dateNow = new Date();
    const dateFiche = new Date(date);
    const dateToCheck = dateNow.getDate() < 18 ?
        new Date(dateNow.getFullYear()+"-"+dateNow.getMonth()+"-18"):
        new Date(dateNow.getFullYear()+"-"+(dateNow.getMonth()+1) +"-18");
    //const dateToCheck = dateNow.setDate(18);
    console.log(dateFiche);
    console.log(dateToCheck);
    return dateFiche < dateToCheck;
}

function checkTime(value){
    for (let time of value){
        if (time.start > time.end){
            return true
        }
    }
}
class InputDialog extends React.Component {
    state = {
        focused: false,
        start: null,
        end: null,
        addedEmployee: null,
        showMessagePaie : false,
        showMessageErrorTime : false,
        item: {
            registrationNumber: "",
            items: [
                { start: "", end: "" }
            ],
            type: "",
            label: "",
        }
    };


    async componentWillMount() {
        const { rows, dialogIsOpen } = this.props;
    }

    componentWillUnmount() {
    }

    handleRequestChange = request => {

        this.setState({ request })
    };

    render() {
        const { classes, so, rows, dialogIsOpen, references,absence } = this.props;
        const { item, addedEmployee, showMessagePaie, showMessageErrorTime } = this.state;
        let showMessageError = showMessageErrorTime;
        const {justificationTypes} = absence;
        const { _timeTrajetIn } = strings;
        const { configUser } = references;
        return (
            <div style={{ position: "relative" }}>
                <Dialog
                    open={dialogIsOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => { }}
                    classes={{ paper: classes.dialogPaper }}
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                {_timeTrajetIn}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{ marginTop: 30 }}>

                        <div style={{ marginTop: 20 }}>
                            <Grid container>
                                <Grid sm={4}>
                                    <Grid item direction={"row"}>
                                        <InputLabel>
                                            Type
                                        </InputLabel>
                                        <FormControl >
                                            <Select
                                                style={{width:"500px"}}
                                                value={
                                                    {type: item.type,
                                                    label: item.label}
                                                }
                                                renderValue={(v) => v.label}
                                                onChange={(e) => {
                                                    this.setState({
                                                        item: {
                                                            registrationNumber: this.state.item.registrationNumber,
                                                            date: this.state.item.date,
                                                            items: [...this.state.item.items],
                                                            type: e.target.value.type,
                                                            label: e.target.value.label,
                                                        }
                                                    })
                                                }}>

                                                {!justificationTypes.loading && justificationTypes.data.sort((a, b) => a.label.localeCompare(b.label)).map((item, index) => {
                                                    return <MenuItem key={index} value={{type: item.type, label: item.label}}>{item.label}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                        <br></br>
                                        <br></br>
                                    </Grid>
                                    <Grid item direction={"row"} >
                                        <AddForEmployeeAutoComplete handleChange={(employee) => {
                                            const { registrationNumber, role, prod, country } = employee;
                                            this.setState({
                                                addedEmployee: {
                                                    registrationNumber,
                                                    prod,
                                                    role,
                                                },
                                                item: {
                                                    registrationNumber: registrationNumber,
                                                    date: this.state.item.date,
                                                    type: this.state.item.type,
                                                    label: this.state.item.label,
                                                    items: [

                                                    ]
                                                }
                                            })
                                        }} />
                                    </Grid>
                                    <br></br>
                                    <br></br>
                                    <Grid item direction={"column"}>
                                        <Grid item sm={4}>
                                            <InputLabel
                                                style={{ marginLeft: 10, fontSize: 11 }}
                                                htmlFor="single-select">Date</InputLabel>
                                        </Grid>
                                        <Grid item sm={12} >
                                            <SingleDatePicker
                                                disabled={false}
                                                numberOfMonths={1}
                                                noBorder={true}
                                                block={true}
                                                withPortal={true}
                                                hideKeyboardShortcutsPanel={false}
                                                onDateChange={startDate => {
                                                    this.setState({
                                                        start: startDate,
                                                        showMessagePaie : checkCancelationDate(startDate.format("YYYY-MM-DD").toString()),
                                                        item: {
                                                            registrationNumber: this.state.item.registrationNumber,
                                                            date: startDate.format("YYYY-MM-DD").toString(),
                                                            type: this.state.item.type,
                                                            label: this.state.item.label,
                                                            items: [...this.state.item.items],
                                                        }
                                                    })
                                                }}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                                isOutsideRange={(startDate) => {
                                                    return moment(new Date(), "YYYY-MM-DD").subtract('1', 'day').isBefore(startDate.startOf('day'));
                                                }}
                                                focused={this.state.focused}
                                                date={this.state.start}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" gutterBottom>
                                                  <span style={{ fontWeight: "bold", color: "Red" }} hidden={!showMessagePaie}>
                                                    {"Attention ! votre demande a été saisie pour une période de paie qui a déjà été clôturée"}
                                                  </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <br></br><br></br>
                                    <Grid >
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                            {item.items.map(function (x, i) {
                                                return <tr key={i} >
                                                    <td><label>Heure debut</label></td>
                                                    <td><input
                                                        type='time' onChange={(e) => {
                                                            x.start = e.target.value
                                                        }}
                                                    ></input></td>
                                                    <td><label>Heure fin</label></td>
                                                    <td><input
                                                        type='time' onChange={(e) => {
                                                            x.end = e.target.value
                                                        }}
                                                    ></input></td>
                                                </tr>;
                                                })}
                                            </tbody>
                                        </table>

                                        <br></br>
                                        <IconButton
                                            onClick={() => {
                                                this.setState({
                                                    item: {
                                                        registrationNumber: this.state.item.registrationNumber,
                                                        date: this.state.item.date,
                                                        items: [...this.state.item.items, { start: "", end: "" }],
                                                        type: this.state.item.type,
                                                        label: this.state.item.label,
                                                    }
                                                })
                                            }}
                                            color="default"
                                            aria-label="add"
                                        >
                                            <Add fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" gutterBottom>
                                                  <span style={{ fontWeight: "bold", color: "Red" }} hidden={!showMessageError}>
                                                    {"Attention ! l'heure de fin doit être strictement inférieure à l'heure de début"}
                                                  </span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item sm={8}>
                                    <Indicator addedClient={addedEmployee} />
                                </Grid>
                            </Grid>
                        </div>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => {
                            this.props.toggleShow();
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button
                            disabled={!(item.registrationNumber != null && item.date != null && item.items != null && item.items.length > 0)}
                            onClick={() => {
                                if (item.registrationNumber != null && item.date != null && item.items != null && item.items.length > 0) {
                                    let request = { ...item };
                                    request.timeEntrys = request.items;
                                    showMessageError = checkTime(request.timeEntrys);
                                    this.setState({
                                        showMessageErrorTime : checkTime(request.timeEntrys)
                                    });
                                    if(!showMessageError){
                                        this.props.saveTime(request, request.type)
                                        this.props.toggleShow();
                                    }
                                }
                            }} color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateTopProps = (state) => {
    return { auth: state.auth, references: state.references, absence: state.absence}
};


const mapActionsToprops = {
    addHolidayRequest,
    setShowAddRequest,
    getDaysOffSuccess,
    addErrorinfo,
    saveTime,
    getTime,
    getJustificationTypes
};


export default connect(mapStateTopProps, mapActionsToprops)(withStyles(styles)(InputDialog));
