import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedSorting,
    SortingState
} from '@devexpress/dx-react-grid';
import {Grid, Table, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import IconButton from '@material-ui/core/IconButton'
import HistoryIcon from '@material-ui/icons/History'
import MGrid from '@material-ui/core/Grid'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import {getMyRequestsHistory, showRequestHistory} from "../../../actions/RequestsActions";
import {store} from "../../../store/ConfigureStore";
import {
    EmployeeFormatter, endDatePredicate,
    FilterCell,
    HolidayTypeProvider, IdEditor, noPredicate,
    RequestStatusFormatter,
    RequestTypeProvider, startDatePredicate,
    compareDates, compareDateTimes
} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";

const IdFormatter = ({value}) => (
    <MGrid container spacing={0}>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(getMyRequestsHistory(value));
                store.dispatch(showRequestHistory(true));
            }
            } color="default" aria-label="add">
                <HistoryIcon fontSize="small"/>
            </IconButton>
        </MGrid>
    </MGrid>
);


const RequestStatusProvider = props => (
    <DataTypeProvider
        formatterComponent={RequestStatusFormatter}
        {...props}
    />
);


const EmployeeProvider = props => (
    <DataTypeProvider
        formatterComponent={EmployeeFormatter}
        {...props}
    />
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


function mapValues(rows) {
    return rows.map(row => {
        const {
            _exceptionalAbsence
        } = strings;
        if(row.typeConge === "EXCEPTIONALHOLIDAY" && row.exceptionalType) {
            row.typeConge = { label: _exceptionalAbsence + " " + row.exceptionalType.label }
        }
        return row;
    });
}


export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableColumnExtensions: [
                {columnName: 'id', align: "right", width: 80},
                {columnName: 'totalDuration', wordWrapEnabled: true},
                {columnName: 'typeRequest', wordWrapEnabled: true},
                {columnName: 'updateDate', wordWrapEnabled: true},
                {columnName: 'typeConge', wordWrapEnabled: true},
                {columnName: 'startDate', wordWrapEnabled: true},
                {columnName: 'returnDate', wordWrapEnabled: true},
                {columnName: 'addedForEmp', wordWrapEnabled: true, width: 200},
            ],
            integratedFilteringColumnExtensions: [
                { columnName: 'startDate', predicate: startDatePredicate },
                { columnName: 'returnDate', predicate: endDatePredicate },
                { columnName: 'addedForEmp', predicate: noPredicate }
            ],
            integratedSortingColumnExtensions: [
                { columnName: 'startDate', compare: compareDates },
                { columnName: 'returnDate', compare: compareDates },
                { columnName: 'updateDate', compare: compareDateTimes }
            ],
            employeeColumns: ['addedForEmp'],
            idColumns: ['id'],
            requestColumns: ['status'],
            requestTypeColumns: ['typeRequest'],
            holidayTypeColumns: ['typeConge'],
        };
        this.changeSelection = selection => this.setState({selection});
        this.handleToggleConfirmation = () => {

            this.setState({confirmationInMassDialogOpen: !this.state.confirmationInMassDialogOpen})
        }

    }


    render() {
        const {integratedSortingColumnExtensions, employeeColumns, idColumns, tableColumnExtensions, integratedFilteringColumnExtensions, requestColumns, holidayTypeColumns, requestTypeColumns} = this.state;
        const rows = this.props.data;
        const {loading, searchActive} = this.props;
        console.log('hrRequests', rows[0]);
        
        const {_partner, _updateDate, _absenceDuration, _startDate, _returnDate, _status, _requestType, _holidayType} = strings;
        
        const columns = rows[0] && rows[0].totalDuration ? [
            {name: 'addedForEmp', title: _partner},
            {name: 'typeRequest', title: _requestType},
            {name: 'typeConge', title: _holidayType},
            {name: 'startDate', title: _startDate},
            {name: 'returnDate', title: _returnDate},
            {name: 'updateDate', title: _updateDate},
            {name: 'totalDuration', title: _absenceDuration},
            {name: 'status', title: _status},
            {name: 'id', title: ' '},
        ] : [
            {name: 'addedForEmp', title: _partner},
            {name: 'typeRequest', title: _requestType},
            {name: 'typeConge', title: _holidayType},
            {name: 'startDate', title: _startDate},
            {name: 'returnDate', title: _returnDate},
            {name: 'updateDate', title: _updateDate},
            {name: 'duration', title: _absenceDuration},
            {name: 'status', title: _status},
            {name: 'id', title: ' '},
        ]
        return (
            <Fragment>
                <Paper style={tableSize}>
                    <Grid
                        rows={mapValues(rows)}
                        columns={columns}
                        style={tableSize}
                    >
                        <IdProvider
                            for={idColumns}
                        />
                        <RequestStatusProvider
                            for={requestColumns}
                        />

                        <EmployeeProvider
                            for={employeeColumns}
                        />
                        <RequestTypeProvider
                            for={requestTypeColumns}
                        />
                        <HolidayTypeProvider
                            for={holidayTypeColumns}
                        />
                        <SortingState
                            defaultSorting={[{columnName: 'requestDate', direction: 'asc'}]}
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        <FilteringState
                            onFiltersChange={(filters) => {
                                this.props.changeFilters(filters)
                            }}
                        />
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />

                        <Table
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => {
                                return <CustomEmptyTable loading={loading}/>
                            }}
                            columnExtensions={tableColumnExtensions}/>
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}

                    </Grid>
                </Paper>

            </Fragment>
        );
    }
}
