import * as React from 'react';
import {DataTypeProvider,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import Avatar from "@material-ui/core/Avatar/Avatar";
import {API, MEDIUM_AVATAR_CORE_SERVER} from "../../api/Endpoints";
import MGrid from '@material-ui/core/Grid'
import {CustomTableRow, TableHeaderContent} from "../../utilities/CustomTableElements";
import {RequestStatusProvider} from "../../utilities/MappingFunctions";

import {System} from "../../assets/system";

import {getRole} from "../../utilities/MappingFunctions";
import { strings } from "../../i18n/Strings";

const AssignedToCongeFormatter = ({value}) => {
    const addedByEmp = value;
    if (addedByEmp) {
        const {firstName, lastName, registrationNumber} = addedByEmp;
        const photoURL = `${API}/${MEDIUM_AVATAR_CORE_SERVER}/${registrationNumber}`
        return <MGrid container>
            <MGrid item sm={4} style={{paddingTop: 10}}>
                { (registrationNumber === "")? <div style={{paddingLeft: 5}}><System /></div>: <Avatar src={photoURL} /> }
            </MGrid>
            <MGrid item sm={8} style={{paddingTop: 5}}>
                <MGrid container direction={"row"}>
                    <MGrid item>
                        {`${firstName} ${lastName}`}
                    </MGrid>
                    <MGrid item>
                        {registrationNumber}
                    </MGrid>
                </MGrid>
            </MGrid>
        </MGrid>
    } else {
        return <div>{value.toString()}</div>
    }
};



const AssignedToCongeProvider = props => (
    <DataTypeProvider
        formatterComponent={AssignedToCongeFormatter}
        {...props}
    />
);

export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        const {_state, _updatedBy, _rejectionMotif, _updateDate, _comment} = strings;
        this.state = {
            columns: [
                {name: 'state', title: _state},
                {name: 'addedByEmp', title: _updatedBy},
                {name: 'role', title: 'Role'},
                {name: 'reasonOfRejection', title: _rejectionMotif},
                {name: 'updateDate', title: _updateDate},
                {name: 'comment', title: _comment},
            ],
            tableColumnExtensions: [
                {columnName: 'state', width: 100, wordWrapEnabled: true },
                {columnName: 'role',  wordWrapEnabled: true },
                {columnName: 'comment', width: 200, wordWrapEnabled: true },
                {columnName: 'reasonOfRejection', width: 120, wordWrapEnabled: true },
                {columnName: 'addedByEmp', width: 150, wordWrapEnabled: true },
                {columnName: 'updateDate', wordWrapEnabled: true },

            ],
            requestStatusColumns: ['state'],
            addedByEmpColumns: ['addedByEmp'],
        };
    };

    render() {
        const {columns, requestStatusColumns, addedByEmpColumns, tableColumnExtensions} = this.state;
        const {rows} = this.props;
        return (
            <Grid
                style={{overflow: "hidden"}}
                rows={rows.map( row => {
                    const {addedByEmp, role, reasonOfRejection} = row;
                    if (role === "SYSTEME") {
                        row['addedByEmp'] = {firstName: "", lastName: "", registrationNumber: ""};
                    }

                    if (role === "SYSTEME_PPV") {
                        row['addedByEmp'] = {firstName: "", lastName: "", registrationNumber: ""};
                    }

                    row['role'] = getRole(row.role);
                    row['reasonOfRejection'] = reasonOfRejection ? reasonOfRejection.comment : "  "
                    return row;
                })}
                columns={columns}
            >
                <RequestStatusProvider
                    for={requestStatusColumns}
                />
                <AssignedToCongeProvider
                    for={addedByEmpColumns}
                />
                <Table
                    rowComponent={CustomTableRow}
                    columnExtensions={tableColumnExtensions}/>
                <TableHeaderRow
                    contentComponent={TableHeaderContent}/>
            </Grid>
        );
    }
}
