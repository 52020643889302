import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Home from "./home/Home";
import ExportManagement from "./exportsManagement/Tabs";
import ReferencesManagement from "./exportsManagement/Tabs";
import Requests from "./requests/Requests";
import PublicHolidays from "./publicHolidays/PublicHolidays";
import ProcessedRequests from "./processedRequests/ProcessedRequests";
import PendingRequests from "./pendingRequests/PendingRequests";
import AddedRequests from "./addedRequests/AddedRequests";
import AddedRequestsFr from "./addedRequestsFr/AddedRequests";
import ToCancelRequests from "./toCancelRequests/ToCancelRequests";
import Recups from "./recups/Recups";
import HRRequests from "./hrRequests/HRRequests";
import RpRequestsError from "./rpRequestsError/RpRequestsError";
import AllPendingRequests from "./allPendingRequests/AllPendingRequests";
import ChildrenRequests from "./childrenRequests/ChildrenRequests";
import MappingTeleopti from "./mappingTeleopti/MappingTeleopti";
import ExceptionalHolidays from "./exceptionalHolidays/ExceptionalHolidays";
import RejectionMotifs from "./rejectionMotifs/RejectionMotifs";
import AbsenceMyshift from "./absenceMyshift/index";
import AbsenceMyshiftFR from "./absenceMyshiftFr/index";
import Quotas from "./quotas";
import CustomError from './errors/CustomError';
import CurrentAbsenceMyshift from "./currentAbsenceMyshift";
import SubRequest from './subRequests/SubRequest';
import AppContainer from "./AppContainer";

export default (props) => {

    const {username} = props;
    const routes =  [
        {path: '/' , component: Home, page: 'Home'},
        {path: '/Accueil' , component: Home, page: 'Home'},
        {path: '/MesDemandes' , component: Requests, page: 'Requests'},
        {path: '/DemandesTraitees' , component: ProcessedRequests, page: 'ProcessedRequests'},
        {path: '/GestionDesReferentielExport' , component: ReferencesManagement, page: 'ReferencesManagement'},
        {path: '/GestionDesExports' , component: ExportManagement, page: 'ExportManagement'},
        {path: '/JoursFeries' , component: PublicHolidays, page: 'PublicHolidays'},
        {path: '/DemandesEnAttente' , component: PendingRequests, page: 'PendingRequests'},
        {path: '/DemandesAjoutees' , component: AddedRequests, page: 'AddedRequests'},
        {path: '/DemandesAjouteesFr' , component: AddedRequestsFr, page: 'AddedRequestsFr'},
        {path: '/AnnnulationConges' , component: ToCancelRequests, page: 'ToCancelRequests'},
        {path: '/Recuperation' , component: Recups, page: 'Recups'},
        {path: '/CongesPayesDeMesCollaborateurs' , component: HRRequests, page: 'HRRequests'},
        {path: '/RapportExportError' , component: RpRequestsError, page: 'RpRequestsError'},
        {path: '/ToutesDemandesEnAttente' , component: AllPendingRequests, page: 'AllPendingRequests'},
        {path: '/CongesDeMesCollaborateurs' , component: ChildrenRequests, page: 'ChildrenRequests'},
        {path: '/MappingTeleopti' , component: MappingTeleopti, page: 'MappingTeleopti'},
        {path: '/MotifsDeRefus' , component: RejectionMotifs, page: 'RejectionMotifs'},
        {path: '/CongeExceptionnel' , component: ExceptionalHolidays, page: 'ExceptionalHolidays'},
        {path: '/JustificationAbsences' , component: AbsenceMyshift, page: 'AbsenceMyshift'},
        {path: '/JustificationAbsencesFR' , component: AbsenceMyshiftFR, page: 'AbsenceMyshiftFR'},
        {path: '/JustificationCurrentAbsences' , component: CurrentAbsenceMyshift, page: 'CurrentAbsenceMyshift'},
        {path: '/quotas' , component: Quotas, page: 'Quotas'},
        {path: '/error' , component: CustomError, page: 'CustomError'},
        {path: '/FlagExported' , component: SubRequest, page: 'SubRequest'}
    ];

    const currentRouteComponent = (pathName) => {
        const {page} = routes.find(route => route.path === pathName);
        return page;
    }


    return <Switch>
        <AppContainer currentRouteComponent={currentRouteComponent} username={username}>
            {routes && routes.map(({path, component}) => (
                <Route exact path={path} component={component}/>
            ))}
        </AppContainer>

    </Switch>
}
