import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {store} from "../../../store/ConfigureStore";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core/styles";
import {showRequestCancel} from "../../../actions/RequestsActions";
import {cancelRequestRH} from "../../../actions/HolidaysActions";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class CancelRequestConfirmationDialog extends React.Component {

    render() {
        const {details} = this.props;
        const {addedForEmp, startDate, returnDate} = details;
        const {firstName, lastName} = addedForEmp;
        return (
            <div>
                <Dialog
                    open={true}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="#"
                    aria-describedby="#"
                >
                    <DialogTitle id="#">
                        Annulation
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="#">
                            Confirmez-vous l'annulation de la demande ? <br/>
                            {`Collaborateur: ${firstName} ${lastName}`} <br/>
                            {`De ${startDate} à ${returnDate}`} <br/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            store.dispatch(showRequestCancel({type: "ADD", details: {}, open: false}))
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            store.dispatch(cancelRequestRH(details))
                            store.dispatch(showRequestCancel({type: "ADD", details: {}, open: false}))
                        }} color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests}
};

let mapActionsToProps = {};


export default withStyles(() => {
})(connect(mapStateToProps, mapActionsToProps)(CancelRequestConfirmationDialog));
