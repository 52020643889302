import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {showRequestCancel, showRequestHistory} from "../../actions/RequestsActions";
import {withStyles} from '@material-ui/core/styles';
import History from "./History";
import {store} from "../../store/ConfigureStore";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";


const styles = theme => {
    return {
        dialogPaper: {
            maxWidth: 900,
            minWidth: 900,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        content : {
            margin: 0,
            padding: 0
        }
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class RequestDetails extends React.Component {
    state = {
        value: 0,
    };

    handleChange = (event, value) => {
        this.setState({value});
    };

    render() {
        const {classes, requests, myRequestsHistory, details} = this.props;
        const {value} = this.state;
        return (
                <Dialog
                    open={requests.showRequestHistory}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    classes={{paper: classes.dialogPaper}}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                        <AppBar position="static" color="primary" elevation={0}>
                            <Toolbar>
                                <Typography variant="h6" color="inherit">
                                    {"Historique de la demande"}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <History rows={myRequestsHistory}/>
                    <DialogActions>
                        <Button onClick={() => {
                            this.props.showRequestHistory(false)
                        }} color="primary">
                            RETOUR
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests}
};

let mapActionsToProps = {
    showRequestHistory
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(RequestDetails));
