import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getAddedRequests} from "../../../actions/RequestsActions";
import RequestDetails from "../../utilities/RequestDetails";
import ConfirmationDialog from "./ConfirmationDialog";
import _ from 'lodash'

const styles = theme => ({});


class AddedRequests extends Component {

    state = {
        filters: [],
        sort: ""
    };

    onChangeDebounceFilter = _.debounce(filters => this.changeFilters(filters), 500);


    changeFilters = (filters) => {
        this.setState({filters: filters.reduce(function(obj,item){
                obj[item.columnName] = item.value;
                return obj;
            }, {})}, () => {
            const {filters,sort} = this.state;
            const {
                addedForEmp,
                typeConge,
                typeRequest,
                returnDate,
                startDate,
                status
            } = filters;

            this.props.getAddedRequests(0,25,addedForEmp,typeConge,typeRequest,returnDate,startDate,status)
        })
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getAddedRequests(page - 1, size)
    };

    async componentDidMount() {
        this.props.getAddedRequests(0,25);
    }

    render() {
        const {auth, requests, references} = this.props;
        const {addedRequests,  myRequestsHistory, myRequestsFollow, dialog, requestDetails} = requests;
        const {loading, data, totalElements} = addedRequests;
        const {configUser} = references;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={(page, size) => {
                        this.changePaging(page, size)
                    }}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={e => this.onChangeDebounceFilter(e)}/>
                <RequestDetails
                    myRequestsHistory={myRequestsHistory}
                    myRequestsFollow={myRequestsFollow}
                    details={requestDetails}
                />
                <ConfirmationDialog
                    dialog={dialog}
                    addedRequests={addedRequests}
                />

            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests, auth: state.auth, references: state.references}
};

let mapActionsToProps = {getAddedRequests};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(AddedRequests));

