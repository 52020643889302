import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {showConfirmationDialog} from "../../../actions/RequestsActions";
import {store} from "../../../store/ConfigureStore";
import { strings } from "../../../i18n/Strings";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}



class ConfirmationDialogInMass extends React.Component {

    render() {
        const { open } = this.props;
        const {_APPROUVE_VALIDATION, _VALIDATION_MASSE, _cancel, _confirm} = strings;
        // const {open} = dialog ;
        // alert before validating the requests
        return (
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.props.handleToggleConfirmation()}
                    aria-labelledby="#"
                    aria-describedby="#"
                >
                    <DialogTitle id="#">
                        {_VALIDATION_MASSE}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="#">
                            {_APPROUVE_VALIDATION}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.handleToggleConfirmationC()} color="primary">
                            {_cancel}
                        </Button>
                        <Button onClick={() => this.props.handleToggleConfirmationS()} color="primary">
                            {_confirm}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ConfirmationDialogInMass;
