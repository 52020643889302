import {
  getAxios,
  getIsProd,
  rolesToValidation,
  setLoading
} from "./AuthActions";
import { store } from "../store/ConfigureStore";
import {
  ADD_EXCEP_CONGE_REQUEST,
  ADD_PAYED_CONGE_REQUEST,
  API,
  CANCEL_HOLIDAY_RH,
  EXCEPT_HOLIDAY,
  PUBLIC_HOIDAYS,
  RECUPS,
  TIMELINE,
  VALIDATED,
  CONGE_ERROR_DATE
} from "../api/Endpoints";
import * as moment from "moment-timezone";
import {
  getAddedRequests,
  getMyRequests,
  getPendingRequests,
  getPendingRequestsCount,
  getPendingRequestsDone,
  getToCancelRequests,
  setShowAddRequest,
  showRequestCancel
} from "./RequestsActions";
import { showTimedToaster, getDaysOffSuccess } from "./ReferencesActions";
import { getSolde } from "./SoldeActions";
import { getToasterMessage } from "../utilities/MappingFunctions";
import { strings } from "../i18n/Strings";
import { LAST_DATE } from "../utilities/GlobalConstants";
import { config } from "rxjs";
import { countDaysOffAfterFinishDate, leaveRequestNotAllowed } from "../components/utilities/DateUtils";

export function getTimeLineSuccess(payload) {
  return {
    type: "GET_TIMELINE",
    payload
  };
}

export function setTimeLineStartDate(payload) {
  return {
    type: "SET_TIMELINE_DATE",
    payload
  };
}

export function getPublicHolidaysSuccess(payload) {
  return {
    type: "GET_PUBLIC_HOLIDAYS",
    payload
  };
}

export function getExceptionalHolidaysSuccess(payload) {
  return {
    type: "GET_EXCEPTIONAL_HOLIDAYS",
    payload
  };
}

export function getRecupsSuccess(payload) {
  return {
    type: "GET_RECUPS",
    payload
  };
}

export function setShowAddRecup(payload) {
  return {
    type: "SET_SHOW_ADD_RECUP",
    payload
  };
}

export function setShowAddHoliday(payload) {
  return {
    type: "SET_SHOW_ADD_HOLIDAY",
    payload
  };
}

export function setShowAddExceptionalHoliday(payload) {
  return {
    type: "SET_SHOW_EXCEPTIONAL_HOLIDAYS",
    payload
  };
}

export function getPublicHolidays(page, size, country, countryId, label, year) {
  return async dispatch => {
    const axios = getAxios();
    const { authoritiesConge } = store.getState().auth;
    const query = {
      country: country,
      countryId: countryId,
      label: label,
      startDate: null,
      duration: null,
      sort: null,
      order: null,
      year: year,
      page: page,
      size: size,
      authorities:authoritiesConge
    };
    const { publicHolidays } = store.getState().holidays;
    dispatch(
      getPublicHolidaysSuccess({
        totalElements: publicHolidays.totalElements,
        data: publicHolidays.data,
        loading: true
      })
    );
    const _publicHolidays = await axios.post(`${API}/${PUBLIC_HOIDAYS}`, query);
    let $publicHolidays = [];
    const { totalElements, payload } = _publicHolidays.data;
    payload.map(holiday => {
      const { label, startDate, duration, endDate, country } = holiday;
      return $publicHolidays.push({
        label: label,
        startDate: startDate,
        duration: duration,
        endDate: endDate,
        country: country.label
      });
    });
    dispatch(
      getPublicHolidaysSuccess({ totalElements, data: payload, loading: false })
    );
  };
}

export function getExceptionalHolidays(page, size, label, country) {
  return async dispatch => {
    const axios = getAxios();
    const query = {
      label: label,
      duration: 0,
      country: country,
      sort: null,
      order: null,
      roleEnum: rolesToValidation(),
      page: page,
      size: size
    };
    const { exceptionalHolidays } = store.getState().holidays;
    dispatch(
      getExceptionalHolidaysSuccess({
        totalElements: exceptionalHolidays.totalElements,
        data: exceptionalHolidays.data,
        loading: true
      })
    );
    const _exceptionalHolidays = await axios.post(
      `${API}/${EXCEPT_HOLIDAY}`,
      query
    );
    const { totalElements, payload } = _exceptionalHolidays.data;
    dispatch(
      getExceptionalHolidaysSuccess({
        totalElements,
        data: payload,
        loading: false
      })
    );
  };
}

export const getTimeLine = (startDate, uoId, endDate) => {
  return async dispatch => {
    const enCodeDate = date => date.split("/").join("%2F");
    const axios = getAxios();
    const { profile } = store.getState().auth;
    let path = `${API}/${TIMELINE}/${
      profile.userName
    }?startDate=${enCodeDate(startDate)}`;
    if (uoId) {
      path = path + `&uoId=${uoId}`;
    }
    // const $startDate = moment(startDate, "YYYY/MM/DD").add(15,'days').calendar();
    const timeLine = await axios.get(path);
    dispatch(getTimeLineSuccess(timeLine.data));
  };
};

export const getRecups = (
  page,
  size,
  startDate,
  endDate,
  recuperationStatus,
  status
) => {
  return async dispatch => {
    const axios = getAxios();
    const { data, totalElements } = store.getState().holidays.recups;
    dispatch(getRecupsSuccess({ loading: true, data, totalElements }));
    let recups = await axios.post(`${API}/${RECUPS}`, {
      startDate: startDate,
      endDate: endDate,
      status: status,
      recuperationStatus: recuperationStatus,
      sort: null,
      order: null,
      page: page,
      size: size
    });
    dispatch(
      getRecupsSuccess({
        loading: false,
        data: recups.data.payload,
        totalElements: recups.data.totalElements
      })
    );
  };
};

export const addErrorinfo = (request)  => {
    console.log("REQUEST ==> " , request)
  return async dispatch => {
    const axios = getAxios();
    await axios.get(`${API}/${CONGE_ERROR_DATE}/${request.addedForEmp.registrationNumber}/${moment(request.startDate).format("YYYY") }`);

  };
};

export const addHolidayRequest = (request, addedClient) => {
  return async dispatch => {
    try {
      const {
        duration,
        totalDuration,
        typeRequest,
        typeConge,
        exceptionalType,
        startDate,
        endDate,
        hasBackup,
        addedForEmp,
        backup,
        comment,
        doctorSpecialityId,
        doctorSpecialityLabel,
        doctorName,
        certificateDepositDate,
        multipleDates,
        lastDate
      } = request;
      const {configUser, daysOff} = store.getState().references;
      const morning = request.checkedAM;
      const afternoon = request.checkedPM;
      const {_respAddHolidayRequest, _EMPTY_LEAVE, _EMP_REQUIRED, _CASUAL_LEAVE_TIME} = strings;
      const returnDateConvertCount =  Number.isInteger(countDaysOffAfterFinishDate(daysOff, endDate)) && countDaysOffAfterFinishDate(daysOff, endDate) + 1;
      // const noLeaveReaquestAllowed = leaveRequestNotAllowed(daysOff);
      dispatch(setShowAddRequest({ open: true, canSubmit: false }));
      dispatch(setLoading(true));
      console.log("lastDate :" , lastDate);
      let configLastDate = lastDate == LAST_DATE ? LAST_DATE  : configUser.lastDay;
      console.log("configLastDate :" , configLastDate);
      // if(noLeaveReaquestAllowed){
      //   dispatch(
      //     showTimedToaster({
      //       open: true,
      //       message: _EMPTY_LEAVE,
      //       type: "warning"
      //     })
      //   );
      //   dispatch(setShowAddRequest({ open: true, canSubmit: true }));
      //   dispatch(setLoading(false));
      // } else {
        if (typeConge === "EXCEPTIONALHOLIDAY") {

          let requestObj = {
            "@type": request["@type"],
            exceptionalType: exceptionalType,
            comment: comment,
            addedForEmp: addedForEmp,
            morning: morning,
            afternoon: afternoon,
            startDate: moment(startDate).format("DD/MM/YYYY"),
            returnDate:
              moment(endDate).format("DD/MM/YYYY") != "Invalid date"
                ? (configLastDate == LAST_DATE ? moment(endDate).add(returnDateConvertCount, 'days').format("DD/MM/YYYY") : moment(endDate).format("DD/MM/YYYY") )
                : null,
            duration: duration,
            totalDuration: totalDuration,
            typeRequest: typeRequest,
            backup: backup,
            hasBackup: hasBackup,
            doctorSpecialityId: doctorSpecialityId,
            doctorName: doctorName,
            doctorSpecialityLabel: doctorSpecialityLabel,
            multipleDates:multipleDates,
            certificateDepositDate:
              moment(certificateDepositDate).format("DD/MM/YYYY") ==
              "Invalid date"
                ? null
                : moment(certificateDepositDate).format("DD/MM/YYYY")
          };
          if( !addedForEmp && addedClient) {
            dispatch(
              showTimedToaster({
                open: true,
                message: _EMP_REQUIRED ,
                type: "warning"
              })
            );
            dispatch(setShowAddRequest({ open: true, canSubmit: true }));
            dispatch(setLoading(false));
         }else {
          const axios = getAxios();
          axios
            .post(`${API}/${ADD_EXCEP_CONGE_REQUEST}`, requestObj)
            .then(res => {
              if (!addedClient) {
                dispatch(getMyRequests(0, 25));
                dispatch(setShowAddRequest({ open: false, canSubmit: true }));
                dispatch(
                  showTimedToaster({
                    open: true,
                    message: _respAddHolidayRequest ,
                    type: "success"
                  })
                );
              } else if(addedForEmp) {
                dispatch(getAddedRequests(0, 25));
                dispatch(setShowAddRequest({ open: false, canSubmit: true }));
                dispatch(
                  showTimedToaster({
                    open: true,
                    message: _respAddHolidayRequest ,
                    type: "success"
                  })
                );
              }else {
                dispatch(
                  showTimedToaster({
                    open: true,
                    message: _EMP_REQUIRED ,
                    type: "warning"
                  })
                );
              }
              dispatch(setShowAddRequest({ open: false, canSubmit: true }));
              dispatch(setLoading(false));
            })
            .catch(e => {
              dispatch(
                showTimedToaster({
                  open: true,
                  message: getToasterMessage(e.response.data),
                  type: "warning"
                })
              );
              dispatch(setShowAddRequest({ open: true, canSubmit: true }));
              dispatch(setLoading(false));
            });
         }
        }
        if (typeConge === "UNPAIDHOLIDAY") {
          let requestObj = {
            "@type": request["@type"],
            addedForEmp: addedForEmp,
            comment: comment,
            duration: duration,
            morning: morning,
            afternoon: afternoon,
            backup: backup,
            startDate: moment(startDate).format("DD/MM/YYYY"),
            returnDate: configLastDate == LAST_DATE ? moment(endDate).add(returnDateConvertCount, 'days').format("DD/MM/YYYY") : moment(endDate).format("DD/MM/YYYY"),
            totalDuration: totalDuration,
            hasBackup: hasBackup,
            typeConge: typeConge,
            typeRequest: typeRequest
          };
          if( !addedForEmp && addedClient) {
            dispatch(
              showTimedToaster({
                open: true,
                message: _EMP_REQUIRED ,
                type: "warning"
              })
            );
            dispatch(setShowAddRequest({ open: true, canSubmit: true }));
            dispatch(setLoading(false));
         }else {
          const axios = getAxios();
          axios
            .post(`${API}/${ADD_PAYED_CONGE_REQUEST}`, requestObj)
            .then(res => {
              if (!addedClient) {
                dispatch(getMyRequests(0, 25));
                dispatch(setShowAddRequest({ open: false, canSubmit: true }));
                dispatch(
                  showTimedToaster({
                    open: true,
                    message: _respAddHolidayRequest ,
                    type: "success"
                  })
                );
              } else if(addedForEmp) {
                dispatch(getAddedRequests(0, 25));
                dispatch(setShowAddRequest({ open: false, canSubmit: true }));
                dispatch(
                  showTimedToaster({
                    open: true,
                    message: _respAddHolidayRequest ,
                    type: "success"
                  })
                );
              }else {
                dispatch(
                  showTimedToaster({
                    open: true,
                    message: _EMP_REQUIRED ,
                    type: "warning"
                  })
                );
              }
              dispatch(setLoading(false));
            })
            .catch(e => {
              dispatch(
                showTimedToaster({
                  open: true,
                  message: getToasterMessage(e.response.data),
                  type: "warning"
                })
              );
              dispatch(setShowAddRequest({ open: true, canSubmit: true }));
              dispatch(setLoading(false));
            });
         }
        }
        if (typeConge === "PAIDHOLIDAY" || typeConge === "RTTHOLIDAY" || typeConge === "SENIORITYHOLIDAY" || typeConge === "CASUALLEAVE") {
            let requestObj = {
            "@type": request["@type"],
            addedForEmp: addedForEmp,
            comment: comment,
            duration: duration,
            morning: morning,
            afternoon: afternoon,
            backup: backup,
            returnDate: endDate != null ? (configLastDate == LAST_DATE ? moment(endDate).add(returnDateConvertCount, 'days').format("DD/MM/YYYY") : moment(endDate).format("DD/MM/YYYY")) : null,
            startDate: moment(startDate).format("DD/MM/YYYY"),
            totalDuration: totalDuration,
            hasBackup: hasBackup,
            typeConge: typeConge,
            typeRequest: typeRequest
          };
          const axios = getAxios();
          if( !addedForEmp && addedClient) {
                dispatch(
                  showTimedToaster({
                    open: true,
                    message: _EMP_REQUIRED ,
                    type: "warning"
                  })
                );
                dispatch(setShowAddRequest({ open: true, canSubmit: true }));
                dispatch(setLoading(false));
          }else {
            axios
            .post(`${API}/${ADD_PAYED_CONGE_REQUEST}`, requestObj)
            .then(res => {
              if (!addedClient) {
                dispatch(getMyRequests(0, 25));
              } else {
                dispatch(getAddedRequests(0, 25));
               }
              dispatch(setShowAddRequest({ open: false, canSubmit: true }));
              dispatch(
                showTimedToaster({
                  open: true,
                  message: _respAddHolidayRequest ,
                  type: "success"
                })
              );
              dispatch(setLoading(false));
            })
            .catch(e => {
              dispatch(
                showTimedToaster({
                  open: true,
                  message: getToasterMessage(e.response.data),
                  type: "warning"
                })
              );
              dispatch(setShowAddRequest({ open: true, canSubmit: true }));
              dispatch(setLoading(false));
            });
          }

        }
        getDaysOffSuccess([]);
      // }
    } catch (e) {
      dispatch(
        showTimedToaster({ open: true, message: e.response, type: "warning" })
      );
      dispatch(setShowAddRequest({ open: true, canSubmit: true }));
      dispatch(setLoading(false));
      throw e;
    }
  };
};

export const inMassValidation = requests => {
  return async dispatch => {
    const axios = getAxios();
    const { pendingRequests } = store.getState().requests;
    const {_respInMassValidation} = strings;
    await axios.post(`${API}/${VALIDATED}`, requests);
    dispatch(
      showTimedToaster({
        open: true,
        message: _respInMassValidation,
        type: "success"
      })
    );

    const leftRequests = pendingRequests.data.filter(
      r => ![...requests.map(req => req.requestId)].includes(r.id)
    );
    dispatch(
      getPendingRequestsDone({
        loading: false,
        data: leftRequests,
        totalElements: leftRequests.length
      })
    );

    dispatch(getPendingRequestsCount());
  };
};

export const cancelRequestRH = request => {
  return async dispatch => {
    const axios = getAxios();
    const { status, $request } = request;
    // {status: "CANCELED", ...$request}
    const {_respCancelRequestRH} = strings;
    await axios.post(`${API}/${CANCEL_HOLIDAY_RH}`, request);
    dispatch(
      showTimedToaster({
        open: true,
        message: _respCancelRequestRH,
        type: "success"
      })
    );
    dispatch(getToCancelRequests(0, 25));
    dispatch(showRequestCancel({ type: "ADD", details: {}, open: false }));
  };
};

export const addHolidayRecup = (startDate, returnDate) => {
  return async dispatch => {
    const axios = getAxios();
    const { userName } = store.getState().auth.profile;
    const isProd = await getIsProd(userName);
    const {_respAddHolidayRecup} = strings;
    axios
      .post(`${API}/conges/recups`, {
        "@type": "RecuperationVO",
        addedForEmp: {
          registrationNumber: userName,
          prod: isProd.data,
          role: []
        },
        startDate: startDate,
        returnDate: returnDate,
        duration: 0,
        totalDuration: 0,
        typeRequest: "REQUEST",
        hasBackup: false
      })
      .then(res => {
        dispatch(
          showTimedToaster({
            open: true,
            message: _respAddHolidayRecup,
            type: "success"
          })
        );
        dispatch(getRecups(0, 25));
        dispatch(getSolde());
        dispatch(setShowAddRecup({ open: false, details: {}, type: "ADD" }));
      })
      .catch(err => {
        dispatch(
          showTimedToaster({
            open: true,
            message: getToasterMessage(err.response.data),
            type: "warning"
          })
        );
      });
  };
};

export const addHolidayRecupRequest = (
  startDate,
  returnDate,
  assignedToRecup
) => {
  return async dispatch => {
    const axios = getAxios();
    const { userName } = store.getState().auth.profile;
    const isProd = await getIsProd(userName);
    const {_respAddHolidayRecup} = strings;
    axios
      .post(`${API}/conges/recups/request`, {
        "@type": "RecuperationRequestVO",
        assignedToRecup: assignedToRecup,
        addedForEmp: {
          registrationNumber: userName,
          prod: isProd.data,
          role: []
        },
        startDate: startDate,
        returnDate: returnDate,
        duration: 0,
        totalDuration: 0,
        typeRequest: "REQUEST",
        hasBackup: false
      })
      .then(res => {
        dispatch(
          showTimedToaster({
            open: true,
            message: _respAddHolidayRecup,
            type: "success"
          })
        );
        dispatch(getRecups(0, 25));
        dispatch(getSolde());
        dispatch(setShowAddRecup({ open: false, details: {}, type: "ADD" }));
      })
      .catch(err => {
        dispatch(
          showTimedToaster({
            open: true,
            message: getToasterMessage(err.response.data),
            type: "warning"
          })
        );
      });
  };
};
