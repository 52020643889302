import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {$labelStyles, labelStyles} from "../../../../styles/CommonStyles";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import AddForEmployeeAutoComplete from "../../addedRequests/AddForEmployeeAutoComplete2";
import {DateRangePicker} from "react-dates";
import {getAxios} from "../../../../actions/AuthActions";
import {API} from "../../../../api/Endpoints";
import {store} from "../../../../store/ConfigureStore";
import {getAbsenceLessThanHour} from "../../../../actions/AbsenceActions";

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    dialogPaper: {
        height: "auto",
        // maxHeight: '70vh',
        minWidth: 300,
    },
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class LessThanHourSearchAbsence extends React.Component {
    state = {
        startHour: "",
        endHour: "",
        matricule: "",
        addedForEmp: {},
        focusedInputRange: null,
        startDate: null,
        endDate: null,
    };


    handleRequestChange = request => {
        this.setState({request})
    };


    onDatesChange = (startDate, endDate) => {

        this.setState({startDate: startDate, endDate: endDate});

    }

    handleChange = (event, name) => {
        this.setState({[name]: event.target.value});
    }

    searchForLessThanHour() {
        const {startDate, endDate, matricule} = this.state;
        store.dispatch(getAbsenceLessThanHour(startDate, endDate, matricule));
    }

    render() {
        const {classes, open} = this.props;
        const {focusedInputRange, startDate, endDate, matricule} = this.state;
        return (
            <Dialog
                classes={{paper: classes.dialogPaper}}
                open={true}
                onClose={() => {
                }}
                TransitionComponent={Transition}
            >
                <AppBar
                    elevation={0}
                    className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Recherche des absences
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>

                    <div style={{minHeight: 160, minWidth: 500}}>
                        <Grid container direction={"column"}  alignItems={"stretch"} spacing={16}>

                            <Grid item>
                                {/*<AddForEmployeeAutoComplete handleChange={(employee) => {*/}
                                    {/*const {registrationNumber, role, prod} = employee;*/}
                                    {/*this.setState({matricule: registrationNumber});*/}

                                {/*}}/>*/}

                                <TextField
                                    fullWidth={true}
                                    value={matricule}
                                    placeholder={"Saisir un matricule (*)"}
                                    onChange={e => {
                                        this.handleChange(e, "matricule")
                                    }}
                                    className={classes.formControl}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item>
                                <DateRangePicker
                                    startDateId="start"
                                    endDateId="end"
                                    startDate={startDate}
                                    noBorder={true}
                                    endDate={endDate}
                                    startDatePlaceholderText="Date de début *"
                                    endDatePlaceholderText="Date de fin *"
                                    hideKeyboardShortcutsPanel={true}
                                    onDatesChange={({startDate, endDate}) => {
                                        this.onDatesChange(startDate, endDate)
                                    }}
                                    withPortal={true}
                                    focusedInput={focusedInputRange}
                                    isOutsideRange={date => date.isBefore(moment().subtract('90','days')) || date.isAfter(moment())}
                                    onFocusChange={(focusedInputRange) => {
                                        this.setState({focusedInputRange})
                                    }}
                                />

                            </Grid>
                        </Grid>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.toggleAbsence();
                    }} color="primary">
                        Annuler
                    </Button>
                    <Button disabled={!( startDate && endDate && matricule.length == 6 )} onClick={() => {
                        this.searchForLessThanHour();
                        this.props.toggleAbsence();
                    }} color="primary">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


const mapStateTopProps = (state) => {
    return {}
};

const mapActionsToprops = {};

export default connect(mapStateTopProps, mapActionsToprops)(withStyles(styles)(LessThanHourSearchAbsence));
