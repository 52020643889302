import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { BrowserRouter, Link, Redirect, Route } from 'react-router-dom';
import Home from '../home/Home';
import { Switch } from '@material-ui/core';

  const Footer = () => (
    <div style={{ height: "30px" }}><Link to="/Accueil"> Retourner à la page d'accueil</Link>
    </div>
  );
  
class CustomError extends Component {
    state = {
    }
    async componentWillMount() {
    }
    render() {
        return (
            <div style={{height: "100%", marginTop: 10}} style={{height: "100vh", marginTop: 10}}>
               <Paper  elevation={1} style={{ padding:"50px", textAlign: "center"}}>
                    <Typography style={{color: "red", fontSize: "50px"}}>
                        500
                    </Typography>
                    <Typography style={{color: "red", fontSize: "20px"}}>
                        Oups, erreur interne au serveur !
                    </Typography>
                    <Typography style={{color: "red", fontSize: "16px"}}>
                    Une erreur interne au serveur a été lancé, veuillez contacter l'administrateur du système pour lui transmettre l'information.
                    </Typography>
                    <BrowserRouter>
                            <Footer />
                    </BrowserRouter>                
                </Paper>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        solde: state.solde,
        references: state.references
    };
}

export default CustomError;
