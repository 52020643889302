import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {withStyles} from '@material-ui/core/styles';
import {splitAbsence, setShowSpliterDialog} from "../../../../actions/AbsenceActions";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/es/TextField/TextField";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import {_labelStyles} from "../../../../styles/CommonStyles";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import {strings} from "../../../../i18n/Strings";

const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 550,
            maxHeight: 550,
            maxWidth: 800,
            minWidth: 800,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function getMenuItems(items) {
    return items.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
}


class SpliterDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.spliterDetails.id,
            idMyshift: this.props.spliterDetails.idMyshift,
            matricule: this.props.spliterDetails.matricule,
            start: this.props.spliterDetails.start,
            startUtc: this.props.spliterDetails.start_utc,
            end: this.props.spliterDetails.end,
            endUtc: this.props.spliterDetails.end_utc,
            selectedStart : this.props.spliterDetails.start.split(" ")[1],
            selectedEnd : this.props.spliterDetails.end.split(" ")[1],
            startTime: this.props.spliterDetails.start.split(" ")[1],
            endTime : this.props.spliterDetails.end.split(" ")[1],
            timeZone : this.props.spliterDetails.timeZone,
        };

    }


    handleChange = (e) => {
        const {_errorSplitAbsence} = strings;
        if (e <= this.state.startTime || e >= this.state.endTime)
            alert(_errorSplitAbsence)
        else {
            this.setState({
                selectedStart: e,
                selectedEnd : e
            });
        }
    };

    render() {
        const {classes, show} = this.props;
        const {_absenceStartHour, _absenceEndHour} = strings
        const {matricule, start, end,idMyshift, id, selectedStart, selectedEnd, startTime, endTime, timeZone, startUtc, endUtc} = this.state;
        console.log("selectedStart : "+ selectedStart);
        console.log("selectedEnd : "+ selectedEnd);
        console.log("startTime : "+ startTime);
        console.log("endTime : "+ endTime);
        console.log("start : "+ start);
        console.log("end : "+ end);
        console.log("timeZone : "+ timeZone);
        return (
            <div style={{position: "relative"}}>
                <Dialog
                    open={show}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                    }}
                    classes={{paper: classes.dialogPaper}}
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                Découper une absence
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{marginTop: 30}}>
                        <Grid container direction={"row"} spacing={32}>
                            <Grid item sm={12}>
                                <InputLabel style={_labelStyles} htmlFor="matricule">Matricule</InputLabel>
                                <TextField
                                    id={"matricule"}
                                    value={matricule}
                                    margin="dense"
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                            <Grid container className={classes.grid} justify="space-around">
                                <td>
                                    <label>{_absenceStartHour}</label>
                                </td>
                                <td>
                                    <input type='time' value={startTime} disabled={true}></input>
                                </td>
                                <td>
                                    <label>{_absenceEndHour}</label>
                                </td>
                                <td>
                                    <input
                                    type='time' value={selectedEnd} max={endTime} min={startTime}
                                    onChange={(e) => {
                                        this.handleChange(e.target.value)
                                    }}>
                                    </input>
                                </td>
                            </Grid>
                            <Grid container className={classes.grid} justify="space-around">
                                <td>
                                    <label>{_absenceStartHour}</label>
                                </td>
                                <td>
                                    <input type='time' value={selectedStart} disabled={true}></input>
                                </td>
                                <td>
                                    <label>{_absenceEndHour}</label>
                                </td>
                                <td>
                                    <input type='time' value={endTime} disabled={true}></input>
                                </td>
                            </Grid>
                            <Grid item sm={6}>
                                <InputLabel style={_labelStyles} htmlFor="start">Départ</InputLabel>
                                <TextField
                                    disabled
                                    id={"start"}
                                    fullWidth
                                    value={start}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <InputLabel style={_labelStyles} htmlFor="end">Retour</InputLabel>
                                <TextField
                                    disabled
                                    id={"end"}
                                    fullWidth
                                    value={end}
                                />

                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => {
                            this.props.setShowSpliterDialog({show: false, spliterDetails: {}})
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.splitAbsence(id, idMyshift, matricule, startUtc, selectedEnd, selectedStart, endUtc, timeZone);
                            this.props.setShowSpliterDialog({show: false, spliterDetails: {}});
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {absence: state.absence}
};

let mapActionsToProps = {
    setShowSpliterDialog,
    splitAbsence
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(SpliterDialog));
