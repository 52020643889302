const initialRequestState = {loading: false, data: [], details: {}, totalElements: 0};

export default function requestsReducer(state = {
    myRequests: initialRequestState,
    processedRequests: initialRequestState,
    toCancelRequests: {
        loading: false,
        data: [],
        totalElements: 0
    },
    hrRequests: initialRequestState,
    pendingRequests: initialRequestState,
    allPendingRequests: {
        loading: false,
        data: [{
            "task": {
                "id": "1006068",
                "businessKey": "11399",
                "candidates": [{
                    "registrationNumber": "E20423",
                    "firstName": "Amina",
                    "lastName": "Fadil",
                    "email": "Amina.FADIL@intelcia.com",
                    "regulation": "MIT",
                    "solde": 36.44,
                    "increment": 2.0,
                    "country": {"id": 1, "label": "Maroc"},
                    "active": true,
                    "prod": false,
                    "role": [],
                    "uo": "DEP RH RABAT"
                }],
                "processVariables": {
                    "owner": "037198",
                    "manager": "000371",
                    "endDate": "2017-08-05",
                    "isPublished": false,
                    "ppvBack": [],
                    "type": "UNPAIDHOLIDAY",
                    "timeout": "2017-07-30T23:30:00",
                    "ppvList": [],
                    "rhList": ["E20423"],
                    "currentUser": "000371",
                    "ppvValidate": false,
                    "rh": "035610",
                    "managerList": [],
                    "businessKey": 11399,
                    "uo": null,
                    "rejectionReason": "",
                    "timeReminder": "2017-07-30T08:00:00",
                    "startDate": "2017-07-31",
                    "validate": true
                },
                "role": "RH_VALIDATION",
                "assignee": {
                    "active": true,
                    "country": {id: 1, label: "Maroc"},
                    "email": "imane.boulahfa@intelcia.com",
                    "firstName": "Imane",
                    "increment": 2,
                    "lastName": "Boulahfa",
                    "prod": false,
                    "registrationNumber": "035610",
                    "regulation": "MIT",
                    "role": [],
                    "solde": 21,
                    "uo": "DEP RH CASA 3",
                    "businessKey": "12153",
                }
            },
            "role": "RH_VALIDATION",
            "request": {
                "@type": "RequestVO",
                "id": 11399,
                "status": "REJECTED",
                "addedForEmp": {
                    "registrationNumber": "037198",
                    "firstName": "Mariam",
                    "lastName": "Adnane El Alaoui",
                    "email": "mariam.adnaneelalaoui@intelcia.com",
                    "regulation": "MIT",
                    "solde": 7.5,
                    "increment": 1.5,
                    "country": {"id": 1, "label": "Maroc"},
                    "active": true,
                    "prod": true,
                    "role": [],
                    "uo": "MA-Cns-Daxon AS"
                },
                "startDate": "31/07/2017",
                "returnDate": "06/08/2017",
                "duration": 6,
                "totalDuration": 6,
                "typeConge": "UNPAIDHOLIDAY",
                "updateDate": "21/07/2017 16:42",
                "typeRequest": "REQUEST",
                "hasBackup": false,
                "exportState": "NOTEXPORTED"
            }
        }],
        "totalElements": 1
    },
    addedRequests: initialRequestState,
    childrenRequests: initialRequestState,
    myRequestsHistory: [],
    myRequestsFollow: [],
    showRequestDetails: {open: false},
    showRequestHistory: false,
    showRequestCancel: {open: false},
    showAddRequest: {open: false, canSubmit: true},
    dialog: {open: false},
    requestDetails: {},
    validationDetails: {open: false, data: {}},
    count: 0,
}, {type, payload}) {
    switch (type) {
        case "GET_MY_REQUESTS":
            return {...state, myRequests: payload};
        case "GET_PROCESSED_REQUESTS":
            return {...state, processedRequests: payload};
        case "GET_CHILDREN_REQUESTS":
            return {...state, childrenRequests: payload};
        case "GET_HR_REQUESTS":
            return {...state, hrRequests: payload};
         case "GET_ERROR_REQUESTS":
                return {...state, hrRequests: payload};
        case "GET_TO_CANCEL_REQUESTS":
            return {...state, toCancelRequests: payload};
        case "GET_ADDED_REQUESTS":
            return {...state, addedRequests: payload};
        case "GET_PENDING_REQUESTS":
            return {...state, pendingRequests: payload};
        case "GET_ALL_PENDING_REQUESTS":
            return {...state, allPendingRequests: payload};
        case "GET_MY_REQUESTS_HISTORY":
            return {...state, myRequestsHistory: payload};
        case "GET_MY_REQUESTS_FOLLOW":
            return {...state, myRequestsFollow: payload};
        case "SHOW_REQUEST_DETAILS":
            return {...state, showRequestDetails: payload};
        case "SHOW_REQUEST_HISTORY":
            return {...state, showRequestHistory: payload};
        case "SHOW_CONFIRMATION_DIALOG":
            return {...state, dialog: payload};
        case "SET_REQUEST_DETAILS":
            return {...state, requestDetails: payload};
        case "SHOW_REQUEST_CANCEL":
            return {...state, showRequestCancel: payload};
        case "SET_REQUEST_VALIDATION":
            return {...state, validationDetails: payload};
        case "SET_SHOW_ADD_REQUEST":
            return {...state, showAddRequest: payload};
        case "GET_PENDING_REQUESTS_COUNT":
            return {...state, count: payload};
        default:
            return state
    }
}
