import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {withStyles} from '@material-ui/core/styles';
import {addHolidayRecup, addHolidayRecupRequest, setShowAddRecup} from "../../../actions/HolidaysActions";
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid'
import TextField from "@material-ui/core/TextField";
import {DateRangePicker} from 'react-dates';
import Divider from '@material-ui/core/Divider'
import * as moment from 'moment-timezone';
import {store} from "../../../store/ConfigureStore";
import {strings} from "../../../i18n/Strings";
import { LAST_DATE } from '../../../utilities/GlobalConstants';


const styles = theme => {
    return {

        dialogPaper: {
            minHeight: 400,
            maxHeight: 400,
            maxWidth: 800,
            minWidth: 800,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        underline: {
            color: "black"
        }
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AddRecup extends React.Component {
    state = {
        value: 0,
        startDate: null,
        endDate: null,
        focusedInputRange: null,
        configUser: null
    };

    handleChange = (event, value) => {
        this.setState({value});
    };

    onDatesChange = (startDate, endDate) => {
        this.setState({startDate: startDate, endDate: endDate});
    };
    formatDate = date => {
        date = date.split('/');
        let temp = date[0];
        date[0] = date[1];
        date[1] = temp;
        return date.join('/')
    };

    async componentWillUnmount() {
        this.setState({label: null, startDate: null, endDate: null, country: null})
    }

    render() {
        const {classes, type, assignedToRecup, references} = this.props;
        const {startDate, endDate, focusedInputRange, comment} = this.state;
        const {configUser} = references;
        const {_writeRecuperation,_writeHoliday,_confirm,_cancel,_returnDate,_finishDate, _startDate,_comment,_durationRecovery} = strings;
        return (
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    this.props.setShowAddRecup({open: false, details: {}, type: "ADD"})
                }}
                classes={{paper: classes.dialogPaper}}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <AppBar position="static" color="primary" elevation={0}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit">
                            {type === "ADD" ? _writeRecuperation : _writeHoliday}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{padding: 15}}>
                    <Grid container direction={"column"} spacing={16}>
                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <InputLabel
                                        style={{fontSize: 14, color: "grey"}}
                                        htmlFor="range-select">{_durationRecovery}</InputLabel>
                                </Grid>
                                <Grid item>
                                    <DateRangePicker
                                        startDateId="start"
                                        endDateId="end"
                                        block={true}
                                        withPortal={true}
                                        startDate={startDate}
                                        noBorder={true}
                                        endDate={endDate}
                                        startDatePlaceholderText={_startDate}
                                        endDatePlaceholderText={configUser && configUser.lastDay == LAST_DATE ? _finishDate : _returnDate }
                                        hideKeyboardShortcutsPanel={true}
                                        minimumNights={configUser && configUser.lastDay == LAST_DATE ? 0 : 1}
                                        hideKeyboardShortcutsPanel={true}
                                        onDatesChange={({startDate, endDate}) => {
                                            this.onDatesChange(startDate, endDate)
                                        }}
                                        isOutsideRange={(day) => {
                                            return false
                                        }}
                                        focusedInput={focusedInputRange}
                                        onFocusChange={(focusedInputRange) => {
                                            this.setState({focusedInputRange})
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Divider/>
                            <Divider/>
                        </Grid>
                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <InputLabel
                                        style={{fontSize: 14, color: "grey"}}
                                        htmlFor="range-select">{_comment}</InputLabel>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        value={comment}
                                        fullWidth
                                        onChange={this.handleChange}
                                        multiline
                                        rows="4"
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {

                        store.dispatch(setShowAddRecup({type: "ADD", open: false, details: {}}))
                    }} color="primary">
                        {_cancel}
                    </Button>
                    <Button onClick={() => {
                        if (type === "ADD") {
                            this.props.addHolidayRecup(moment(startDate).format("DD/MM/YYYY"),configUser && configUser.lastDay == LAST_DATE ?  moment(endDate).format("DD/MM/YYYY") :  moment(endDate).subtract(1,'days').format("DD/MM/YYYY"))
                        } else {
                            this.props.addHolidayRecupRequest(moment(startDate).format("DD/MM/YYYY"), configUser && configUser.lastDay == LAST_DATE ? moment(endDate).add(1,'days').format("DD/MM/YYYY") : moment(endDate).format("DD/MM/YYYY"), assignedToRecup)
                        }
                    }} color="primary">
                        {_confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

let mapStateToProps = (state) => {
    return {auth: state.auth, holidays: state.holidays, references: state.references}
};

let mapActionsToProps = {setShowAddRecup, addHolidayRecup, addHolidayRecupRequest};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(AddRecup));
