import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {setShowAddRejectionMotif, getRejectionMotifs} from "../../../actions/ReferencesActions";
import AddRejectionMotifDialog from './AddRejectionMotifDialog';
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button';
import DeleteRejectionMotifConfirmationDialog from './DeleteRejectionMotifConfirmationDialog';
import RefData from "../../../utilities/RefData";

const styles = theme => ({
});


class RejectionMotifs extends Component {

    state = {
        filters: [],
        sort: "",
        id: null
    };

    changeFilters = (filters) => {
        this.setState({
            filters: filters.reduce(function (obj, item) {
                obj[item.columnName] = item.value;
                return obj;
            }, {})
        }, () => {
            const {filters, sort} = this.state;
            const {
                label,
                country,
                criticality,
                state
            } = filters;
            this.props.getRejectionMotifs(0, 25, country? RefData.COUNTRY_CODES[country.label]: null, label, criticality, state);
        })
    };

    changeSorting = (sorting) => {
        const {columnName,direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page,size}) => {
        this.setState({page, size});
        this.props.getRejectionMotifs(page-1,size)
    };

    componentDidMount() {
        this.props.getRejectionMotifs(0,25)
    }

    render() {
        const {auth, references} = this.props;
        const {id} = this.state;
        const {rejectionMotifs, activeCountries, showAddRejectionMotif} = references;
        const {totalElements, data, loading} = rejectionMotifs;
        const {open, type, details} = showAddRejectionMotif;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={this.changePaging}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                <Tooltip title={"Ajouter motif de refus"}>
                    <Button id={"fab"} onClick={() => {this.props.setShowAddRejectionMotif({open: true, details: {}, type: "ADD"})}} variant="fab" color="primary">
                        <AddIcon/>
                    </Button>
                </Tooltip>
                { open && (type === "ADD" || type === "EDIT")  &&  <AddRejectionMotifDialog type={type} activeCountries={activeCountries}/>}
                { open && (type === "DELETE") &&  <DeleteRejectionMotifConfirmationDialog  />}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {references: state.references, auth: state.auth}
};

let mapActionsToProps = {setShowAddRejectionMotif, getRejectionMotifs};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(RejectionMotifs));

