import * as React from "react";
import { Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow
} from "@devexpress/dx-react-grid-material-ui";
import { tableSize } from "../../../styles/CommonStyles";
import IconButton from "@material-ui/core/IconButton";
import ConsultIcon from "@material-ui/icons/Visibility";
import { store } from "../../../store/ConfigureStore";
import {
  getAddedRequestsFollow,
  getAddedRequestsHistory,
  showRequestDetails
} from "../../../actions/RequestsActions";
import MGrid from "@material-ui/core/Grid";
import {
  CustomEmptyTable,
  CustomTableRow,
  TableHeaderContent
} from "../../../utilities/CustomTableElements";
import AddRequestDialog from "./AddRequestDialog";
import {
  EmployeeProvider,
  endDatePredicate,
  FilterCell,
  HolidayTypeProvider,
  IdEditor,
  noPredicate,
  RequestStatusProvider,
  RequestTypeProvider,
  startDatePredicate,
  MANHolidayTypeProvider,
  compareDates,
  compareDateTimes
} from "../../../utilities/MappingFunctions";
import { strings } from "../../../i18n/Strings";
import { FilteringState } from "@devexpress/dx-react-grid";
import { TableFilterRow } from "@devexpress/dx-react-grid-material-ui";
import { Root } from "../../../utilities/MappingFunctions";
import { VirtualTable } from "@devexpress/dx-react-grid-material-ui";
import { CustomTableContainer } from "../../../utilities/CustomTableContainer";
import { hasAuthority } from "../../../actions/AuthActions";

const IdFormatter = ({ value }) => {
  return (
    <MGrid container spacing={0}>
      <MGrid item>
        <IconButton
          onClick={() => {
            store.dispatch(getAddedRequestsHistory(value));
            store.dispatch(getAddedRequestsFollow(value));
            store.dispatch(showRequestDetails({ open: true }));
          }}
          color="default"
          aria-label="add"
        >
          <ConsultIcon fontSize="small" />
        </IconButton>
      </MGrid>
    </MGrid>
  );
};

export const IdProvider = props => (
  <DataTypeProvider
    formatterComponent={IdFormatter}
    editorComponent={IdEditor}
    {...props}
  />
);

function mapValues(rows) {
  return rows.map(row => {
    const {
      _exceptionalAbsence
    } = strings;
    const { assignedToConge, state,typeRequest } = row;
    if(row.assignedToConge.typeConge === "EXCEPTIONALHOLIDAY" && row.assignedToConge.typeRequest === "REQUEST" &&
        row.assignedToConge.addedForEmp.country.label ==="France"
    ) {
      assignedToConge.typeConge = { label: _exceptionalAbsence + " " + assignedToConge.exceptionalType.label }
    }
    if (typeRequest === 'CANCELLATION'){
      assignedToConge.typeRequest = 'CANCELLATION'
    }
    return { ...assignedToConge, state };
  });
}

export default class GlobalGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tableColumnExtensions: [
        { columnName: "id", align: "right", width: 80 },
        { columnName: "duration", width: 80, wordWrapEnabled: true },
        { columnName: "status", wordWrapEnabled: true },
        { columnName: "typeRequest", wordWrapEnabled: true },
        { columnName: "updateDate", wordWrapEnabled: true },
        { columnName: "typeConge", wordWrapEnabled: true },
        { columnName: "startDate", wordWrapEnabled: true },
        { columnName: "returnDate", wordWrapEnabled: true },
        { columnName: "returnDate", wordWrapEnabled: true },
        { columnName: "reasonOfRejection", wordWrapEnabled: true },
        { columnName: "addedForEmp", width: 200, wordWrapEnabled: true }
      ],
      integratedFilteringColumnExtensions: [
        { columnName: "startDate", predicate: startDatePredicate },
        { columnName: "returnDate", predicate: endDatePredicate },
        { columnName: "addedForEmp", predicate: noPredicate }
      ],
      integratedSortingColumnExtensions: [
        { columnName: 'startDate', compare: compareDates },
        { columnName: 'returnDate', compare: compareDates },
        { columnName: 'updateDate', compare: compareDateTimes }
      ],
      employeeColumns: ["addedForEmp"],
      requestColumns: ["status"],
      idColumns: ["id"],
      holidayTypeColumns: ["typeConge"],
      requestTypeColumns: ["typeRequest"]
    };
    this.changeSelection = selection => this.setState({ selection });
  }

  render() {
    const {
      requestColumns,
      employeeColumns,
      idColumns,
      integratedFilteringColumnExtensions,
      tableColumnExtensions,
      holidayTypeColumns,
      requestTypeColumns,
      integratedSortingColumnExtensions
    } = this.state;
    const {
      _seeker,
      _requestDate,
      _holidayType,
      _typeRequest,
      _startDate,
      _returnDate,
      _requestStatus,
      _duration,
      _totalDuration
    } = strings;
    const rows = this.props.data;
    const { loading, searchActive } = this.props;
    const columns = [
      { name: "addedForEmp", title: _seeker },
      { name: "updateDate", title: _requestDate },
      { name: "typeConge", title: _holidayType },
      { name: "typeRequest", title: _typeRequest },
      { name: "startDate", title: _startDate },
      { name: "returnDate", title: _returnDate },
      { name: "totalDuration", title: _totalDuration },
      { name: "status", title: _requestStatus },
      { name: "id", title: " " }
    ];
    return (
      <Fragment>
        <Paper style={tableSize}>
          <Grid
            rows={mapValues(rows)}
            columns={columns}
            style={tableSize}
            rootComponent={Root}
          >
            <VirtualTable height="auto" />
            <IdProvider for={idColumns} />

            <RequestStatusProvider for={requestColumns} />

            <EmployeeProvider for={employeeColumns} />
            {!hasAuthority(["ROLE_MAN"]) ? (
              <HolidayTypeProvider for={holidayTypeColumns} />
            ) : (
              <MANHolidayTypeProvider for={holidayTypeColumns} />
            )}

            <RequestTypeProvider for={requestTypeColumns} />

            <SortingState
              defaultSorting={[{ columnName: "requestDate", direction: "asc" }]}
              onSortingChange={sorting => {
                this.props.changeSorting(sorting);
              }}
            />
            {/*<SelectionState*/}
            {/*selection={selection}*/}
            {/*onSelectionChange={(selection) => {this.changeSelection([selection[selection.length-1]])}}*/}
            {/*/>*/}
            <FilteringState
              onFiltersChange={filters => {
                this.props.changeFilters(filters);
              }}
            />
            <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}/>
            <IntegratedFiltering
              columnExtensions={integratedFilteringColumnExtensions}
            />
            <Table
              containerComponent={CustomTableContainer}
              rowComponent={CustomTableRow}
              noDataCellComponent={() => {
                return <CustomEmptyTable loading={loading} />;
              }}
              columnExtensions={tableColumnExtensions}
            />
            <TableHeaderRow
              contentComponent={TableHeaderContent}
              showSortingControls
            />
            {searchActive && (
              <TableFilterRow
                messages={{ filterPlaceholder: " " }}
                cellComponent={FilterCell}
              />
            )}
            {/*<TableSelection />*/}
          </Grid>
        </Paper>
        <AddRequestDialog addedClient={true} />
      </Fragment>
    );
  }
}
