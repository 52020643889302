export const STOP_QUOTA_IMPORT = 'STOP_QUOTA_IMPORT';
export const STOP_QUOTA_IMPORT_SUCCESS = 'STOP_QUOTA_IMPORT_SUCCESS';
export const STOP_QUOTA_IMPORT_FAIL = 'STOP_QUOTA_IMPORT_FAIL';
export const DOWNLOAD_QUOTA_FILE = 'DOWNLOAD_QUOTA_FILE';
export const DOWNLOAD_QUOTA_FILE_SUCCESS = 'DOWNLOAD_QUOTA_FILE_SUCCESS';
export const DOWNLOAD_QUOTA_FILE_FAIL = 'DOWNLOAD_QUOTA_FILE_FAIL';
export const GET_QUOTA_HISTORY = 'GET_QUOTA_HISTORY';
export const GET_QUOTA_HISTORY_SUCCESS = 'GET_QUOTA_HISTORY_SUCCESS';
export const GET_QUOTA_HISTORY_FAIL = 'GET_QUOTA_HISTORY_FAIL';


