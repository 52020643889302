import React, {Component, Fragment} from 'react';
import {Collapse, withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {downloadQuotasFile, getHistoryQuotas, stopQuotasImport} from "../../../actions/QuotaActions";
import {Subject} from "rxjs";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {strings} from "../../../i18n/Strings";
import Tooltip from "@material-ui/core/Tooltip";
import HourglassEmptyIcon from '@material-ui/icons/AccessAlarm';
import CheckCircleOutlineIcon from '@material-ui/icons/RadioButtonChecked';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/IconButton";
import {store} from "../../../store/ConfigureStore";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ConfirmDialog from "../../layout/ConfirmDialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import {NotFound} from "../../../assets/notFound";

const styles = theme => ({
    root: {
        width: '100%'
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    formControl: {
        minWidth: 250,
    },
    title: {
        paddingTop: 10,
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    header: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.8125rem',
        fontFamily: "Varela Round",
        fontWeight: 'bold',
        lineHeight: 1.57,
    },
    table: {
        minWidth: 700,
    },
});

class QuotaHistory extends Component {
    searchSubject = new Subject();
    state = {
        filters: [],
        sort: "",
        page: 1,
        size: 25,
        fileName: "",
        status: "",
        startDate: null,
        endDate: null,
        uploadBy: "",
        openDialog: false,
        search: false,
        idRow: null
    };

    changePaging = (page, size, fileName, startDateFilter, endDateFilter, uploadedBy, status) => {
        this.setState({page, size});
        this.props.getHistoryQuotas({
            "fileName": fileName,
            "startDateFilter": startDateFilter,
            "endDateFilter": endDateFilter,
            "uploadedBy": uploadedBy,
            "status": status,
            "sort": null,
            "order": false,
            "page": page - 1,
            "size": size
        });
    };

    componentDidMount() {
        this.props.getHistoryQuotas({
            "fileName": "",
            "startDateFilter": "",
            "endDateFilter": "",
            "uploadedBy": "",
            "status": "PENDING",
            "sort": null,
            "order": false,
            "page": 0,
            "size": 25
        });
    }

    incrementPage = () => {
        let {size, page} = this.state;
        const $page = page + 1;
        const $size = size;
        this.setState({page: $page, size: $size});
        const {fileName, creationDate, updateDate, uploadedBy, finish} = this.state.filters;
        this.changePaging($page, $size, fileName, creationDate, updateDate, uploadedBy, finish);
    };
    decrementPage = () => {
        const {size, page} = this.state;
        this.setState({page: page - 1});
        const {fileName, creationDate, updateDate, uploadedBy, finish} = this.state.filters;
        this.changePaging(this.state.page, size, fileName, creationDate, updateDate, uploadedBy, finish);
    };
    handlePageSizeChange = event => {
        const $page = 1;
        const $size = event.target.value;
        this.setState({size: $size, page: $page});
        const {fileName, creationDate, updateDate, uploadedBy, finish} = this.state.filters;
        this.changePaging($page, $size, fileName, creationDate, updateDate, uploadedBy, finish)
    };

    onSubmit = async () => {
        const {fileName, startDate, endDate, uploadedBy, page, status} = this.state;
        await this.props.getHistoryQuotas({
            "fileName": fileName,
            "startDateFilter": startDate && moment(startDate).format("YYYY-MM-DD"),
            "endDateFilter": endDate && moment(endDate).format("YYYY-MM-DD"),
            "uploadedBy": uploadedBy,
            "status": status,
            "sort": null,
            "order": false,
            "page": 0,
            "size": 25
        });

        this.setState({
            search: false
        });
    };

    onInit = () => {
        this.setState({
            fileName: "",
            status: "",
            uploadedBy: "",
            startDate: "",
            endDate: "",
        });
        this.props.getHistoryQuotas({
            "fileName": "",
            "startDateFilter": "",
            "endDateFilter": "",
            "uploadedBy": "",
            "status": "PENDING",
            "sort": null,
            "order": false,
            "page": 0,
            "size": 25
        });
    };

    render() {
        const {
            openDialog, idRow, page, size, search, fileName,
            uploadBy, status, startDate, endDate, uploadedBy
        } = this.state;
        const {classes, quota} = this.props;
        const {quotas} = quota;
        const {loading, data, totalElements} = quotas;
        const {
            _status, _uploadedBy, _fileName, _creationDate, _updateDate, _SUCCESS_DOWNLOAD, _INTERNAL_SERVER_ERROR,
            _stop, _download, _IMPORT_STOPPED, _resulstsFound, _of, _noResultsFound
        } = strings;

        const columns = [
            {name: 'fileName', title: _fileName},
            {name: 'creationDate', title: _creationDate},
            {name: 'updateDate', title: _updateDate},
            {name: 'uploadedBy', title: _uploadedBy},
            {name: 'finish', title: _status},
            {name: '', title: 'ACTIONS'},
        ];

        const onSearch = () => {
            this.setState({search: !search})
        }
        const onCloseDialog = () => {
            this.setState({
                openDialog: false
            });
        };

        const onOpenDialog = id => {
            this.setState({
                openDialog: true,
                idRow: id
            });
        };

        const shutdown = async () => {
            await store.dispatch(stopQuotasImport(idRow, _IMPORT_STOPPED, _INTERNAL_SERVER_ERROR));
            this.props.getHistoryQuotas({
                "fileName": "",
                "startDateFilter": "",
                "endDateFilter": "",
                "uploadedBy": "",
                "status": "PENDING",
                "sort": null,
                "order": false,
                "page": 0,
                "size": 25
            });
            onCloseDialog();
        }
        const handleStatus = (status) => {
            switch (status) {
                case 'PENDING' :
                    return (
                        <Tooltip title="EN COURS">
                            <HourglassEmptyIcon style={{
                                position: 'relative',
                                left: 7,
                                cursor: "pointer",
                                color: "#ff5e20"
                            }}/>
                        </Tooltip>
                    )
                case 'CANCELED' :
                    return (
                        <Tooltip title="ANNULÉ">
                            <CancelIcon style={{
                                position: 'relative',
                                left: 7,
                                cursor: "pointer",
                                color: "#c31d2c"
                            }}/>
                        </Tooltip>
                    )
                case 'ADDED' :
                    return (
                        <Tooltip title="TERMINÉ">
                            <CheckCircleOutlineIcon style={{
                                position: 'relative',
                                left: 7,
                                cursor: "pointer",
                                color: "#108548"
                            }}/>
                        </Tooltip>
                    )
                default :
                    return null;
            }
        }

        return (
            <div>
                <div className={classes.root}>
                    <AppBar elevation={0} position="static" color={"default"}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit" noWrap>
                                {_resulstsFound} {totalElements}
                            </Typography>
                            <Tooltip title="Recherche avancée">
                                <IconButton
                                    style={{marginLeft: 50}}
                                    onClick={onSearch}
                                    color="default"
                                    aria-label="search">
                                    <SearchIcon style={{color: "black"}} fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                            <div className={classes.grow}/>
                            <div>
                                <Grid container direction={"row"} spacing={32}>
                                    <Grid item>
                                        <TextField
                                            select
                                            style={{width: 70, paddingTop: 5}}
                                            value={size}
                                            onChange={(event) => {
                                                this.handlePageSizeChange(event)
                                            }}
                                            id="paging"
                                        >
                                            {[25, 50, 100].map((p, index) => <MenuItem key={index}
                                                                                       value={p}> {p}</MenuItem>)}
                                        </TextField>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                                            {page} {_of} {parseInt(totalElements / size) + 1}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={16}>
                                            <Grid item>
                                                <Button disabled={page <= 1} onClick={() => this.decrementPage()}
                                                        color="default" aria-label="add">
                                                    <ChevronLeftIcon/>
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button disabled={page >= parseInt(totalElements / size) + 1}
                                                        onClick={() => this.incrementPage()}
                                                        color="default" aria-label="add">
                                                    <ChevronRightIcon/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Collapse in={search} timeout={800}>
                        <Paper elevation={0} style={{padding: 25}}>
                            <Grid container direction={"row"} spacing={32}>
                                <Grid item sm={3}>
                                    <TextField
                                        onChange={e => this.setState({
                                            fileName: e.target.value
                                        })}
                                        value={fileName}
                                        label="Nom fichier"
                                        fullWidth
                                    />
                                </Grid>
                                {/*<Grid item sm={2}>*/}
                                {/*    <TextField*/}
                                {/*        label="Date début"*/}
                                {/*        onChange={e => this.setState({*/}
                                {/*            startDate: e.target.value*/}
                                {/*        })}*/}
                                {/*        type="date"*/}
                                {/*        value={startDate}*/}
                                {/*        InputLabelProps={{*/}
                                {/*            shrink: true,*/}
                                {/*        }}*/}
                                {/*        fullWidth*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item sm={2}>*/}
                                {/*    <TextField*/}
                                {/*        label="Date fin"*/}
                                {/*        type="date"*/}
                                {/*        onChange={e => this.setState({*/}
                                {/*            endDate: e.target.value*/}
                                {/*        })}*/}
                                {/*        value={endDate}*/}
                                {/*        InputLabelProps={{*/}
                                {/*            shrink: true,*/}
                                {/*        }}*/}
                                {/*        fullWidth*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item sm={3}>
                                    <TextField
                                        label="Matricule"
                                        onChange={e => this.setState({
                                            uploadedBy: e.target.value
                                        })}
                                        value={uploadedBy}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="status">Statut</InputLabel>
                                        <Select
                                            value={status}
                                            onChange={e => this.setState({
                                                status: e.target.value
                                            })}
                                            inputProps={{
                                                name: 'status',
                                                id: 'status',
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>-- SÉLÉCTIONNER --</em>
                                            </MenuItem>
                                            <MenuItem value="PENDING">EN COURS</MenuItem>
                                            <MenuItem value="ADDED">TERMINÉ</MenuItem>
                                            <MenuItem value="CANCELED">ANNULÉ</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={2}>
                                    <Tooltip title="Rechercher">
                                        <IconButton
                                            style={{marginLeft: 50, marginTop: 15}}
                                            color="default"
                                            onClick={this.onSubmit}
                                            aria-label="search">
                                            <SearchIcon style={{color: "#f80061"}}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Réinitialiser">
                                        <IconButton
                                            style={{marginTop: 15}}
                                            color="default"
                                            onClick={() => this.onInit()}
                                            aria-label="init">
                                            <CloseIcon style={{color: "#f80061"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Collapse>
                </div>
                {loading && <LinearProgress/>}
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {columns && columns.map(column => (
                                <TableCell className={classes.header}>{column.title}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.fileName}
                                </TableCell>
                                <TableCell align="left">
                                    {row.creationDate && row.creationDate.replace('T', '  ')}
                                </TableCell>
                                <TableCell align="left">
                                    {row.updateDate && row.updateDate.replace('T', '  ')}
                                </TableCell>
                                <TableCell align="left">
                                    {row.uploadedBy}
                                </TableCell>
                                <TableCell align="left">
                                    {handleStatus(row.status)}
                                </TableCell>
                                <TableCell align="left">
                                    <main>
                                        <Tooltip title={_download}>
                                            <IconButton
                                                color="default"
                                                aria-label="add"
                                                onClick={() => {
                                                    store.dispatch(downloadQuotasFile(row.id, row.fileName,
                                                        _SUCCESS_DOWNLOAD, _INTERNAL_SERVER_ERROR));
                                                }}>
                                                <GetAppRoundedIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                        {row.status === 'PENDING' && (
                                            <Tooltip title={_stop}>
                                                <IconButton
                                                    color="default"
                                                    aria-label="add"
                                                    onClick={() => onOpenDialog(row.id)}>
                                                    <HighlightOffIcon fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </main>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {!loading && data && data.length === 0 && (
                    <Grid
                        container
                        direction={"column"}
                        justify={"center"}
                        alignItems={"center"}
                        style={{width: "calc( 100vw - 240px)", height: "60vh"}}
                    >
                        <Fragment>
                            <Grid item>
                                <Typography variant="h4" component="h5">
                                    {_noResultsFound}...
                                </Typography>
                            </Grid>
                            <Grid item>
                                <NotFound/>
                            </Grid>
                        </Fragment>


                    </Grid>
                )}

                <ConfirmDialog
                    open={openDialog}
                    handleAction={shutdown}
                    handleClose={onCloseDialog}
                />

            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {quota: state.quota, auth: state.auth}
};

let mapActionsToProps = {getHistoryQuotas};

export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(QuotaHistory));
