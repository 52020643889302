import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Selects from "./Selects";
import Indicator from "./Indicator";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from "react-redux";
import {addHolidayRequest} from "../../../actions/HolidaysActions";
import {addErrorinfo} from "../../../actions/HolidaysActions";
import {setShowAddRequest} from "../../../actions/RequestsActions";
import {getDaysOffSuccess, getReferencesFrance} from "../../../actions/ReferencesActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {strings} from "../../../i18n/Strings";

import './addRequest.css';
import {store} from "../../../store/ConfigureStore";
import {LAST_DATE} from "../../../utilities/GlobalConstants";


const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    dialogPaper: {
        height: "auto",
        // maxHeight: '70vh',
        maxWidth: '95vw',
    },
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AddRequestDialog extends React.Component {
    state = {
        request: {},
    };


    handleRequestChange = request => {

        this.setState({request})
    };

    submit = (request, addedClient) => {
        console.log("alert")
        confirmAlert({
          title: 'Confirm to submit',
          message: 'Conformément à la note interne Congés j\'ai pris connaissance que sur les 25 jours de CP annuels, 20 doivent impérativement être pris entre le 1er mai et le 31 octobre. Toute dérogation à la demande du salarié entraîne renonciation du salarié au congé supplémentaire de fractionnement.',
          buttons: [
            {
              label: 'Oui',
              onClick: () => {
                this.props.addHolidayRequest(request, addedClient)
                this.props.addErrorinfo(request)
              }
            },
            {
              label: 'Non',

            }
          ]
        });
      };

      submit2 = (request, addedClient) => {
        console.log("alert")
        confirmAlert({
          title: 'Attention, veuillez noter que :',
          message: '- Ce congé ne peut être pris qu’en cas de décès d’un enfant de moins de 25 ans durant l’année suivant le décès  - Le congé peut être fractionné en 2 périodes maximum (fractions d’une journée minimum)»',
          buttons: [
            {
              label: 'Oui',
              onClick: () => {
                this.props.addHolidayRequest(request, addedClient)
                this.props.addErrorinfo(request)
              }
            },
            {
              label: 'Non',

            }
          ]
        });
      };

    render() {
        const {classes, addedClient, addHolidayRequest, addErrorinfo , requests, auth, references} = this.props;
        const {showAddRequest} = requests;
        const {open, canSubmit} = showAddRequest;
        const {request} = this.state;
        const {loading} = auth;
        const {configUser} = references;
        const {_newHolidayRequest, _confirm, _cancel} = strings;
        request.lastDate = LAST_DATE;


        return (
            <>
            {request.exceptionalType &&
                   console.log("request ..." , request.exceptionalType.label)
            }
                <Button id={"fab"}
                        onClick={() => {
                            store.dispatch(getReferencesFrance());
                            this.props.setShowAddRequest({open: true, canSubmit: true})
                        }}
                        color={"primary"}
                        variant={"fab"}>
                    <AddIcon/>
                </Button>
                <Dialog
                    classes={{paper: classes.dialogPaper}}
                    open={open}
                    onClose={() => {
                    }}
                    TransitionComponent={Transition}
                >
                    <AppBar
                        elevation={0}
                        className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {_newHolidayRequest}
                            </Typography>
                            <IconButton color="inherit" onClick={() => {
                                this.props.setShowAddRequest({open: false});
                                this.props.getDaysOffSuccess([]);
                            }}>
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {loading && <LinearProgress/>}
                    <DialogContent>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <div style={{marginTop: 20}}>
                                <Grid container>
                                    <Grid item sm={5}>
                                        <Selects configUser={configUser} handleRequestChange={this.handleRequestChange}
                                                 addedClient={addedClient}/>
                                    </Grid>
                                    <Grid item sm={7}>
                                        <Indicator addedClient={addedClient}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                            this.props.setShowAddRequest({open: false, canSubmit: false});
                            this.props.getDaysOffSuccess([]);

                        }} color="primary">
                            {_cancel}
                        </Button>
                       {((request.exceptionalType && request.exceptionalType.label !=="Congé deuil pour perte d’enfant") || !request.exceptionalType) &&
                        references.daysOff.error &&
                        <Button disabled={!canSubmit} onClick={() => {
                          this.submit(request, addedClient);
                        }} color="primary">
                            {_confirm}
                        </Button>
                       }

                {request.exceptionalType && request.exceptionalType.label ==="Congé deuil pour perte d’enfant" &&
                        <Button disabled={!canSubmit} onClick={() => {
                          this.submit2(request, addedClient);
                        }} color="primary">
                            {_confirm}
                        </Button>
                       }
                      {((request.exceptionalType && request.exceptionalType.label !=="Congé deuil pour perte d’enfant") || !request.exceptionalType) &&
                           references.daysOff.info  &&
                           <Button disabled={!canSubmit} onClick={() => {
                            addHolidayRequest(request, addedClient);
                        }} color="primary">
                            {_confirm}
                        </Button>
                      }

                        { ((request.exceptionalType && request.exceptionalType.label !=="Congé deuil pour perte d’enfant") || !request.exceptionalType) &&
                           !references.daysOff.info  &&  !references.daysOff.error  &&
                           <Button disabled={!canSubmit} onClick={() => {
                            addHolidayRequest(request, addedClient);
                        }} color="primary">
                            {_confirm}
                        </Button>
                      }

                    </DialogActions>
                </Dialog>
            </>
        );
    }
}


const mapStateTopProps = (state) => {
    return {requests: state.requests, auth: state.auth, references: state.references}
};

const mapActionsToprops = {
    addHolidayRequest,
    setShowAddRequest,
    getDaysOffSuccess,
    addErrorinfo,
};

export default connect(mapStateTopProps, mapActionsToprops)(withStyles(styles)(AddRequestDialog));
