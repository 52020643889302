import React from "react";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { getAxios, hasAuthority } from "../../../actions/AuthActions";
import { store } from "../../../store/ConfigureStore";
import {
  API,
  DEAFULT_AVATAR_CORE_SERVER,
  EMPLOYEE_CHILDREN,
  EMPLOYEE_CHILDREN_COUNTRY,
  EMPLOYEE_QUERY
} from "../../../api/Endpoints";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import InputLabel from "@material-ui/core/InputLabel";
import { strings } from "../../../i18n/Strings";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
      onChange={e => {
        e.preventDefault();
        other.onChange(e);
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

function getUserItem(user) {
  const { registrationNumber, firstName, lastName } = user;
  return (
    <Grid container direction={"row"} spacing={8}>
      <Grid item>
        <Avatar
          alt="#"
          src={`${API}/${DEAFULT_AVATAR_CORE_SERVER}/${registrationNumber}`}
          style={{ height: 35, width: 35 }}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1" gutterBottom>
          {registrationNumber} {firstName} {lastName}
        </Typography>
      </Grid>
    </Grid>
  );
}
async function getSuggestions(inputValue) {
  const currentUser = store.getState().auth.profile.userName;
  const inputLength = inputValue ? inputValue.length : 0;
  if (inputLength >= 4) {
    const axios = getAxios();
    const { profile } = store.getState().auth;
    const hasRoleMan = hasAuthority(["ROLE_MAN"]);
    const hasRolePaieConge = hasAuthority(["ROLE_PAIE_CONGE"]);
    let employees;
    if (hasRolePaieConge) {
      employees = await axios.get(
        `${API}/${EMPLOYEE_QUERY}/?q=${inputValue}`
      );
    } else if (hasRoleMan) {
      employees = await axios.get(
        `${API}/${EMPLOYEE_CHILDREN_COUNTRY}/${profile.userName}?q=${inputValue}`
      );
    } else {
      employees = await axios.get(
        `${API}/${EMPLOYEE_CHILDREN}/${profile.userName}?q=${inputValue}`
      );
    }
    const labels = employees.data;
    return labels.filter( user =>  user.registrationNumber !== currentUser).map(suggestion => {
      return {
        value: suggestion,
        label: getUserItem(suggestion)
      };
    });
  }
}

function getSuggestionValue(suggestion) {
  return suggestion.registrationNumber;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: 500,
    marginLeft: 10
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    maxHeight: 500
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    width: 500,
    margin: 0,
    padding: 0,
    listStyleType: "none",
    maxHeight: 180,
    overflowY: "auto",
    overflowX: "hidden"
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

class IntegrationAutosuggest extends React.Component {
  state = {
    single: "",
    popper: "",
    suggestions: [],
    suggestionsOpen: false
  };

  handleSuggestionsFetchRequested = async ({ value }) => {
    const suggestions = (await getSuggestions(value)) || [];
    this.setState({
      suggestions,
      suggestionsOpen: true
    });

  };

  handleSuggestionsClearRequested = () => {
    // this.setState({
    //     suggestions: [],
    // });
  };

  handleChange = name => (event, value) => {
    const { newValue } = value;
    this.setState({
      suggestionsOpen: false,
      [name]: newValue
    });
  };

  render() {
    const { classes } = this.props;
    const {suggestionsOpen} = this.state;
    const { _employee } = strings;
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      // onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion
    };

    return (
      <div className={classes.root}>
        <InputLabel style={{ fontSize: 12 }}>{_employee} *</InputLabel>
        <Autosuggest
          alwaysRenderSuggestions={suggestionsOpen}
          onSuggestionSelected={(
            event,
            {
              suggestion,
              suggestionValue,
              suggestionIndex,
              sectionIndex,
              method
            }
          ) => {
            const { value } = suggestion;
            const { registrationNumber } = value;
            this.setState({ single: registrationNumber });
            this.props.handleChange(value);
          }}
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: " ",
            value: this.state.single,
            onChange: this.handleChange("single")
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(IntegrationAutosuggest);
