import React, {Component} from 'react';
import './App.css';
import AppShell from './components/layout/AppShell2';
import connect from "react-redux/es/connect/connect";
import CircularProgress from '@material-ui/core/CircularProgress';
import {setShowAddJustification} from "./actions/AbsenceActions";
import AddExceptionalHolidayDialog from "./components/routes/exceptionalHolidays/AddExceptionalHolidayDialog";
import ExceptionalDetailsDialog from "./components/routes/holidays/addHolidayRequest/ExceptionalDetailsDialog";
import LessThanHourSearchAbsence from "./components/routes/absenceMyshift/perHour/LessThanHourSearchDialog";
import ExportChildrenRequests from "./components/routes/childrenRequests/ExportChildrenRequests";
import ExportChildrenSubRequests from "./components/routes/childrenRequests/ExportChildrenSubRequests";
import AddForEmployeeAutoComplete from "./components/routes/addedRequests/AddForEmployeeAutoComplete2";
import CustomError from './components/routes/errors/CustomError';
import SubRequest from './components/routes/subRequests/SubRequest'


class App extends Component {

    state = {
    }

    render() {
        const {isLoggedIn, loading, floading,  errorInRequest} = this.props.auth;
        const {count} = this.props.requests;
        if(floading){
           return <CircularProgress color="secondary"  style = {{position: "absolute", top:"50%", left: "50%"}} />
        }
        if(errorInRequest){
            return <CustomError />
        }
        return (
            <div style={{position: "relative"}}>

                {/*<LessThanHourSearchAbsence/>*/}
                {isLoggedIn && <AppShell loading={loading} count={count} auth={this.props.auth}/>}
                {/*{isLoggedIn && <ExceptionalDetailsDialog/>}*/}
                {/*{isLoggedIn && <ExportChildrenRequests/>}*/}
                {/*{isLoggedIn && <AddForEmployeeAutoComplete/>}*/}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {auth: state.auth, absence: state.absence, requests: state.requests};
}

const mapActionsToProps = {
    setShowAddJustification: setShowAddJustification
};
export default connect(
    mapStateToProps,
    mapActionsToProps
)(App);
