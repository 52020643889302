import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {theme} from "./theme/MaterialTheme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {Provider} from 'react-redux';
import {store} from "./store/ConfigureStore";
import App from './App'
import {getAuthorizationUrl, randomInt} from "./actions/AuthActions";
// import './interceptor/Interceptor';

getAuthorizationUrl(`secret${randomInt(0, 999999)}`);

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <App/>
        </Provider>
    </MuiThemeProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

