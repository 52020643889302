import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import { withStyles } from "@material-ui/core/styles";
import {
  processRequest,
  setRequestValidation,
  saveNewRequestProposal
} from "../../../actions/RequestsActions";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar/index";
import { API, MEDIUM_AVATAR_CORE_SERVER } from "../../../api/Endpoints";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getHolidayType } from "../../../utilities/MappingFunctions";
import {
  getDaysOffExcep,
  getRejectionMotifsLabels,
  showTimedToaster
} from "../../../actions/ReferencesActions";
import { strings } from "../../../i18n/Strings";
import { SingleDatePicker } from "react-dates";
import { InputLabel, LinearProgress } from "@material-ui/core";
import moment from "moment";

const styles = theme => {
  return {
    dialogPaper: {
      height: "auto",
      maxWidth: 1000,
      minWidth: 1000
    },
    dialogContent: {
      marginTop: 100
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    bigAvatar: {
      width: 90,
      height: 90,
      borderStyle: "solid",
      borderColor: "#ff1a75",
    },
    text: {
      marginTop: 12
    },
    textGrey: {
      marginTop: 12,
      color: "grey"
    }
  };
};


function getStateLabel(state) {
  const {
    _approved,
    _rejected
  } = strings;
  if (state === "APPROVED") {
    return (
      <span style={{ color: "green", fontWeight: "bold", marginLeft: 20 }}>
        {_approved}
      </span>
    );
  } else if (state === "REJECTED") {
    return (
      <span style={{ color: "red", fontWeight: "bold", marginLeft: 20 }}>
        {_rejected}
      </span>
    );
  } else {
    return state;
  }
}

const getRequestType = type => {
  if (type === "CANCELLATION") {
    return "Demande d'annulation";
  }
  if (type === "REQUEST") {
    return "Demande d'absence";
  }
  if (type === "ENTRYRECUP") {
    return "Récupération";
  } else {
    return type;
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ConfirmationDialogDomRep extends React.Component {
  state = {
    open: true,
    avis: "",
    rejectionReason: null,
    rejectionComment: "",
    disabled: false,
    focused: '',
    startDate: '',
    endDate: ''
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentWillUnmount() {
    this.setState({ avis: "", rejectionReason: null, rejectionComment: "", disabled: false });
  }

  render() {
    const {
      _holidayDetails,
      _approved,
      _rejected,
      _comment,
      _project,
      _holidayType,
      _days,
      _holidayPeriod,
      _to$,
      _avis,
      _hr,
      _reasonOfRejection,
      _newProposal,
      _confirm,
      _cancel,
      _startDate,
      _finishDate
    } = strings;
    const { myRequestsFollow, validationDetails } = this.props.requests;
    const { open, data } = validationDetails;
    const {
      regestionNumberEmployee,
      employeeName,
      employeeLastName,
      typeConge,
      typeCongeExcep,
      startDate,
      returnDate,
      status,
      duration,
      id,
      comment,
      projectName,
      isEmployeeProd
    } = data;
    const { rejectionMotifsLabels } = this.props.references;
    const { classes } = this.props;
    const { avis, rejectionReason, rejectionComment, disabled } = this.state;
    const {auth: {profile: {countryCode}}, isLoadingDayOff} = this.props;
    const lang =sessionStorage.getItem('language') != null ? sessionStorage.getItem('language') : 'fr';

    const startDateHandler = (startDate) => {
      this.setState({startDate});
      if(isEmployeeProd) {
        this.setState({disabled: true});
        this.props.getDaysOffExcep(
          moment(startDate).format("DD/MM/YYYY"), null, "PAIDHOLIDAY", 
          { "registrationNumber": regestionNumberEmployee },
          (endDate) => {
            this.setState({endDate});
            this.setState({disabled: false});
          }
        );
      }
    }

    const endDateHandler = (endDate) => {
      this.setState({endDate});
    }

    const rejectReasonBlock = (
          <>
            <Grid item  style={{ marginTop: 10 }}>
              <Grid container>
                <Grid item xs={5} container direction="column" justify="center">
                  <Typography variant="body2">
                    <span style={{ fontWeight: "bold" }}>
                      {_reasonOfRejection} :
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Select
                    style={{ width: '85%' }}
                    value={rejectionReason}
                    default={"rejectionReason"}
                    name={"rejectionReason"}
                    onChange={event => {
                      this.setState({
                        rejectionReason: event.target.value
                      });
                    }}
                    id="rejectionReason"
                  >
                    {rejectionMotifsLabels.map((p, index) => (
                      <MenuItem key={index} value={p}>
                        {" "}
                        {lang === 'en' ? p.labelEn : p.labelEn}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            {(typeConge === "PAIDHOLIDAY" && status !== 'PENDING_RH') && <Grid item  style={{ marginTop: 10 }}>
              <Grid container>
                <Grid item xs={5} container direction="column" justify="center">
                  <Typography variant="body2">
                    <span style={{ fontWeight: "bold" }}>
                      {_newProposal} :
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Grid container direction={"column"} style={{ width: "85%" }}>
                    <Grid item>
                      <InputLabel style={{marginLeft: 10, fontSize: 11}} htmlFor="single-select">
                        {_startDate}
                      </InputLabel>
                    </Grid>
                    <Grid item>
                      <SingleDatePicker
                        id="startDate"
                        numberOfMonths={2}
                        noBorder={true}
                        block={true}
                        withPortal={true}
                        hideKeyboardShortcutsPanel={true}
                        onDateChange={startDate => startDateHandler(startDate)}
                        onFocusChange={({focused}) => this.setState({focused: focused ? 'startDate' : ''})}
                        isOutsideRange={(startDate) => { return false }}
                        focused={this.state.focused === 'startDate'}
                        date={this.state.startDate}
                      />
                    </Grid>
                </Grid>
                </Grid>
              </Grid>
            </Grid>}
            {(typeConge === "PAIDHOLIDAY" && ((!isEmployeeProd && status === 'PENDING') || (isEmployeeProd && this.state.endDate))) && <Grid item  style={{ marginTop: 10 }}>
              <Grid container>
                <Grid item xs={5} container direction="column" justify="center">
                </Grid>
                <Grid item xs={7}>
                  <Grid container direction={"column"} style={{ width: "85%" }}>
                    <Grid item>
                      <InputLabel style={{marginLeft: 10, fontSize: 11}} htmlFor="single-select">
                        {_finishDate}
                      </InputLabel>
                    </Grid>
                    <Grid item>
                      <SingleDatePicker
                        id="endDate"
                        numberOfMonths={2}
                        noBorder={true}
                        block={true}
                        withPortal={true}
                        hideKeyboardShortcutsPanel={true}
                        onDateChange={endDate => endDateHandler(endDate)}
                        onFocusChange={({focused}) => this.setState({focused: focused ? 'endDate' : ''})}
                        isOutsideRange={(endDate) => { return false }}
                        focused={this.state.focused === 'endDate'}
                        date={this.state.endDate}
                        disabled={isEmployeeProd}
                      />
                    </Grid>
                </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>);

    const validateAndSave = () => {
      let message = null;

      // validation
      if (!this.state.avis) {
        message = "validation status is empty !";
      } else if(this.state.avis === "REJECTED") {
        if(!rejectionReason) {
          message = "rejection reason is empty !";
        } else if(typeConge === "PAIDHOLIDAY" && status !== 'PENDING_RH') {
          if(!this.state.startDate) {
            message = "start date is empty !";
          } else if(!isEmployeeProd && !this.state.endDate) {
            message = "end date is empty !";
          }
        }
      }

      // save
      if(message) {
        this.props.showTimedToaster({ open: true, message, type: "warning" });
      } else {
        this.setState({disabled: true});
        if(typeConge === "PAIDHOLIDAY" && this.state.avis === "REJECTED" && status !== 'PENDING_RH') {
          this.props.saveNewRequestProposal(
            moment(this.state.startDate, "DD/MM/YYYY").format("DD/MM/YYYY"), 
            moment(this.state.endDate, "DD/MM/YYYY").add(1, 'days').format("DD/MM/YYYY"), 
            id, 
            () => {
              this.setState({open: false});
              this.props.processRequest(regestionNumberEmployee, null, id, rejectionComment, avis, rejectionReason);
            }
          );
        }else {
          this.props.processRequest(regestionNumberEmployee, null, id, rejectionComment, avis, rejectionReason);
        }
      } 
    }

    return (
      <div style={{ position: "relative" }}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          classes={{ paper: classes.dialogPaper }}
        >
          <AppBar position="static" color="primary" elevation={0}>
            <Toolbar>
              <Typography variant="h6" color="inherit">
                {_holidayDetails}
              </Typography>
            </Toolbar>
          </AppBar>
          
          {isLoadingDayOff && <LinearProgress />}

          <DialogContent>
            <Grid container direction={"column"} spacing={8}>
              <Grid item>
                <Grid container direction={"row"} style={{ marginTop: 25 }}>
                  <Grid item sm={6}>
                    <Grid container direction={"column"}>
                      <Grid item container direction="column" alignContent="center">
                        <Avatar 
                          src={`${API}/${MEDIUM_AVATAR_CORE_SERVER}/${regestionNumberEmployee}`}
                          className={classes.bigAvatar}
                        />
                      </Grid>
                      <Grid item container direction="column" alignContent="center">
                        <Typography variant={"body2"}>
                          {regestionNumberEmployee}
                        </Typography>
                      </Grid>
                      <Grid item container direction="column" alignContent="center">
                        <Typography variant={"body2"}>
                          {`${employeeName} ${employeeLastName}`}
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginTop: 10 }}>
                        <Grid container>
                          <Grid item xs={5} container direction="column" justify="center">
                            <Typography variant="body2">
                              <span style={{ fontWeight: "bold" }}>
                                {_avis} :
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Select
                              style={{width: '85%'}}
                              value={avis}
                              default={"avis"}
                              name={"avis"}
                              onChange={event => {
                                this.setState({ avis: event.target.value });
                              }}
                              id="avis"
                            >
                              {[
                                { value: "REJECTED", label: _rejected },
                                { value: "APPROVED", label: _approved }
                              ].map((p, index) => (
                                <MenuItem key={index} value={p.value}>
                                  {" "}
                                  {p.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                      { avis === "REJECTED" ? rejectReasonBlock : ''}
                    </Grid>
                  </Grid>
                  <Grid item sm={6}>
                    <Grid
                      container
                      direction={"column"}
                      justify={"center"}
                      style={{ marginTop: 25 }}
                    >
                      <Grid item>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ fontWeight: "bold" }}>
                            {_project} :{" "}
                          </span>
                          {projectName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ fontWeight: "bold" }}>
                            {_holidayType} :{" "}
                          </span>
                          {typeConge === 'EXCEPTIONALHOLIDAY' ? typeCongeExcep : getHolidayType(typeConge)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ fontWeight: "bold" }}>
                            {_holidayPeriod} :{" "}
                          </span>
                          {startDate}
                          <span style={{ fontWeight: "bold" }}> {_to$} : </span>
                          {returnDate}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ fontWeight: "bold" }}>{_days} : </span>
                          {duration}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ fontWeight: "bold" }}>
                            {_comment} :{" "}
                          </span>
                          {comment}
                        </Typography>
                      </Grid>
                      {myRequestsFollow
                        .filter(elem => elem.state != "PENDING")
                        .map(elem => {
                          const { state, role } = elem;
                          if (role === "SYSTEME_PPV") {
                            return (
                              <Typography variant="body2" gutterBottom>
                                <span style={{ fontWeight: "bold" }}>
                                  {_avis} WFM :{" "}
                                </span>
                                {getStateLabel(state)}
                              </Typography>
                            );
                          }
                          if (role === "PPV_VALIDATION") {
                            return (
                              <Typography variant="body2" gutterBottom>
                                <span style={{ fontWeight: "bold" }}>
                                  {_avis} WFM :{" "}
                                </span>
                                {getStateLabel(state)}
                              </Typography>
                            );
                          }
                          if (role === "MANAGER_VALIDATION") {
                            return (
                              <Typography variant="body2" gutterBottom>
                                <span style={{ fontWeight: "bold" }}>
                                  {_avis} Manager :
                                </span>
                                {getStateLabel(state)}
                              </Typography>
                            );
                          }
                          if (role === "RH_VALIDATION") {
                            return (
                              <Typography variant="body2" gutterBottom>
                                <span style={{ fontWeight: "bold" }}>
                                  {_avis} {_hr} :
                                </span>
                                {getStateLabel(state)}
                              </Typography>
                            );
                          } else return <></>;
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label={_comment}
                  fullWidth
                  multiline
                  rows="4"
                  onChange={event => {
                    this.setState({ rejectionComment: event.target.value });
                  }}
                />
              </Grid>
              <Grid item>
                <div style={{ height: 25 }}></div>
              </Grid>
            </Grid>
          </DialogContent>
          <div style={{ position: "absolute", bottom: 5, right: 5 }}>
            <Button
              disabled={disabled}
              onClick={() => {
                this.props.setRequestValidation({ open: false, data: {} });
                this.setState({
                  avis: "",
                  rejectionReason: null,
                  rejectionComment: "",
                  disabled: false
                });
              }}
              color="primary"
            >
                {_cancel}
            </Button>
            <Button
              disabled={disabled}
              onClick={validateAndSave}
              color="primary"
            >
                {_confirm}
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    requests: state.requests,
    references: state.references,
    auth: state.auth,
    isLoadingDayOff: state.references.isLoadingDayOff
  };
};

let mapActionsToProps = {
  setRequestValidation,
  getRejectionMotifsLabels,
  processRequest,
  showTimedToaster,
  saveNewRequestProposal,
  getDaysOffExcep
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(ConfirmationDialogDomRep)
);
