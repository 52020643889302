import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getExceptionalHolidays, setShowAddExceptionalHoliday} from "../../../actions/HolidaysActions";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddExceptionalHolidayDialog from './AddExceptionalHolidayDialog';
import DeletePublicHolidayConfirmationDialog from './DeleteExceptionalHolidayConfirmationDialog'
import RefData from "../../../utilities/RefData";
const styles = theme => ({});

class ExceptionalHolidays extends Component {

    state = {
        filters: [],
        sort: "",
        edit: false
    };

    changeFilters = (filters) => {
        this.setState({filters: filters.reduce(function(obj,item){
                obj[item.columnName] = item.value;
                return obj;
            }, {})}, () => {
            const {filters,sort} = this.state;
            const {
                label,
                country
            } = filters;
            this.props.getExceptionalHolidays(0,25,label, country? RefData.COUNTRY_CODES[country.label]: null)
        })
    };
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getExceptionalHolidays(page - 1, size)
    };

    componentDidMount() {
        this.props.getExceptionalHolidays(0,25)
    }


    render() {
        const {auth, holidays} = this.props;
        const {exceptionalHolidays, showAddExceptionalHoliday} = holidays;
        const {open, type} = showAddExceptionalHoliday;
        const {totalElements, data, loading} = exceptionalHolidays;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={this.changePaging}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                <Button id={"fab"}
                        onClick={() => {
                            this.props.setShowAddExceptionalHoliday({type: "ADD", open: true, details: {}})
                        }}
                        color={"primary"}
                        variant={"fab"}>
                    <AddIcon/>
                </Button>
                { open && (type === "EDIT" || type == "ADD") && <AddExceptionalHolidayDialog type={type}/>}
                { open && (type === "DELETE") && <DeletePublicHolidayConfirmationDialog />}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {holidays: state.holidays, auth: state.auth}
};

let mapActionsToProps = {getExceptionalHolidays, setShowAddExceptionalHoliday};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(ExceptionalHolidays));

