import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import AddRecup from './AddRecup';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getRecups, setShowAddRecup} from "../../../actions/HolidaysActions";
import Button from "@material-ui/core/Button";
import DeleteRecupConfirmationDialog from "./DeleteRecupConfirmationDialog";
import AddIcon from '@material-ui/icons/Add'
import RequestDetails from "../requests/Requests";
import RequestHisotryDialog from '../../utilities/RequestHistoryDialog'

const styles = theme => ({});

class Recups extends Component {

    state = {
        filters: [],
        sort: ""
    };

    changeFilters = (filters) => {
        this.setState({filters: filters.reduce(function(obj,item){
                obj[item.columnName] = item.value;
                return obj;
            }, {})}, () => {
            const {filters,sort} = this.state;
            const {
                startDate,
                endDate,
                status,
                recuperationStatus
            } = filters;
            this.props.getRecups(0,25,startDate,endDate,recuperationStatus,status)
        })
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getRecups(page - 1, size)
    };

    componentDidMount() {
        this.props.getRecups(0,25)
    }


    render() {
        const {auth, holidays, requests} = this.props;
        const {recups, showAddRecup} = holidays;
        const {type, open, details} = showAddRecup;
        const {loading, data, totalElements} = recups;
        const {myRequestsHistory, myRequestsFollow, requestDetails} = requests;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    changePaging={(page, size) => {
                        this.changePaging(page, size)
                    }}
                    data={data}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                <RequestHisotryDialog
                    myRequestsHistory={myRequestsHistory}
                />
                <Button variant={"fab"} id={"fab"} onClick={() => {
                    this.props.setShowAddRecup({open: true, details: {}, type: "ADD"})
                }
                } color="primary" aria-label="add">
                    <AddIcon fontSize="small"/>
                </Button>
                {open && (type === "EDIT" || type == "ADD") && <AddRecup type={type} assignedToRecup={details}/>}
                { open && (type === "DELETE") && <DeleteRecupConfirmationDialog/> }
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        holidays: state.holidays,
        auth: state.auth,
        references: state.references,
        requests: state.requests
    }
};

let mapActionsToProps = {getRecups, setShowAddRecup};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(Recups));

