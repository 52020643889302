import {getAxios} from "./AuthActions";
import {API, DOWNLOAD_IMPORTED_QUOTAS_FILE, MY_QUOTAS, STOP_QUOTAS_IMPORT} from "../api/Endpoints";
import {showTimedToaster} from "./ReferencesActions";
import {strings} from "../i18n/Strings";
import {
    GET_QUOTA_HISTORY,
    STOP_QUOTA_IMPORT,
    STOP_QUOTA_IMPORT_FAIL,
    STOP_QUOTA_IMPORT_SUCCESS
} from "../types/QuotaTypes";
import {store} from "../store/ConfigureStore";


export function getHistoryQuotasDone(payload) {
    return {
        type: GET_QUOTA_HISTORY,
        payload
    }
}

export const getHistoryQuotas = (filters) => {
    return async dispatch => {

        const { page, size, fileName, status, uploadedBy, endDateFilter, startDateFilter } = filters;

        const axios = getAxios();
        const {quota} = store.getState();
        const {data, totalElements} = quota.quotas;
        dispatch(getHistoryQuotasDone({loading: true, data, totalElements}));
        let quotas = await axios.post(`${API}/${MY_QUOTAS}?page=${page}&size=${size}`, {
                "fileName": fileName,
                "startDateFilter": startDateFilter,
                "endDateFilter": endDateFilter,
                "uploadedBy": uploadedBy,
                "status": status,
                "sort": null,
                "order": false,
                "page": page,
                "size": size
            }
        );
        dispatch(getHistoryQuotasDone({
            loading: false,
            data: quotas.data.results,
            totalElements: quotas.data.totalElements
        }));
    }
};

export const stopQuotasImport = (id, _IMPORT_STOPPED, _INTERNAL_SERVER_ERROR) => async dispatch => {
    const axios = getAxios();

    dispatch({
        type: STOP_QUOTA_IMPORT
    });

    await axios.post(`${API}/${STOP_QUOTAS_IMPORT}?id=${id}`, {})
        .then(res => {
            dispatch(showTimedToaster({open: true, message: _IMPORT_STOPPED, type: "success"}))
            dispatch({
                type: STOP_QUOTA_IMPORT_SUCCESS,
                payload: res.data
            });

        }).catch(() => {
            dispatch(showTimedToaster({open: true, message: _INTERNAL_SERVER_ERROR, type: "error"}))
            dispatch({
                type: STOP_QUOTA_IMPORT_FAIL,
                payload: false
            });
        })
};

export const downloadQuotasFile = (id, fileName, _SUCCESS_DOWNLOAD, _INTERNAL_SERVER_ERROR) => async dispatch => {
    const axios = getAxios();

    await axios.get(`${API}/${DOWNLOAD_IMPORTED_QUOTAS_FILE}/${id}`)
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            dispatch(showTimedToaster({open: true, message: _SUCCESS_DOWNLOAD, type: "success"}))
        }).catch(() => {
            dispatch(showTimedToaster({open: true, message: _INTERNAL_SERVER_ERROR, type: "error"}))
        })
};

export function getQutotaHolidaysSuccess(payload) {
    return {
        type: "GET_QUOTA_HOLIDAYS",
        payload
    }
}

export function getSitesSuccess(payload) {
    return {
        type: "GET_SITES",
        payload
    }
}

export function setSiteLablSuccess(payload) {
    return {
        type: "SET_SITE_LABEL",
        payload
    }
}


export function setQuotaLoading(payload) {
    return {
        type: "QUOTA_LOADING",
        payload
    }
}


export const getQuotaHolidays = ({idTeam, start, end}) => {
    return async dispatch => {
        const axios = getAxios();
        dispatch(setQuotaLoading(true))
        const quotaHolidays = await axios.post(`${API}/conges/quota/holidays`,{idTeam, start, end});
        dispatch(getQutotaHolidaysSuccess(quotaHolidays.data));
        dispatch(setQuotaLoading(false))

    }
};


export const setQuota = (sites) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respSetQuota} = strings; 
        await axios.post(`${API}/conges/quota/submit`,sites);
        dispatch(showTimedToaster({open: true, message: _respSetQuota, type: "success"}))
        dispatch(getSitesSuccess([]));

    }
};

export const importQuota = (file) => {
    return async dispatch => {
        const axios = getAxios();
        let formData = new FormData();
        formData.append("file", file);

        await axios.post(`${API}/conges/quota/uploadFile`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(res => {
                dispatch(showTimedToaster({open: true, message: "operation succeeded", type: "success"}))
            })
            .catch(e => {
                dispatch(showTimedToaster({open: true, message: "operation failed", type: "warning"}))
            })

    }
};



export const getSiteLabel = idTeam => {
    return async dispatch => {
        const axios = getAxios();
        const siteLabel = await axios.get(`${API}/conges/quota/findSiteName?idTeam=${idTeam}`);
        dispatch(setSiteLablSuccess(siteLabel.data.siteName));
    }
};
