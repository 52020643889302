import moment from 'moment-timezone';
import {activeCountries, countries} from "../utilities/RefData";

function getInitialMonth() {
    const date = moment().format("DD/MM/YYYY").split("/");
    return new Date(Number(date[2]), Number(date[1]) -1)
}

export default function referencesReducer(state = {
    toasterMessage: {open: false, message: "", type: "success"},
    exceptTypeHr: [],
    exceptTypeHrFr: [],
    exceptTypeHrUser: [],
    teams: [],
    backup: [],
    daysOff: [],
    isLoadingDayOff: false,
    uo: [],
    showAddMapping: false,
    showExcepHolidayDetails: false,
    monthIndicator: getInitialMonth(),
    showAddRejectionMotif: {open: false, details: {}, type: "ADD"},
    employeeChildren: [],
    activeCountries,
    rejectionMotifs: {
        data: [], loading: false, totalElements: 9
    },
    countries,
    mappingTeleoptiLabels: [{"id": 1, "label": "Allaitement", "abbreviation": "AL", "source": "TELEOPTI"}, {
        "id": 2,
        "label": "Congé décès",
        "abbreviation": "CD",
        "source": "TELEOPTI",
        "type": "EXCEPTIONALHOLIDAY"
    }, {
        "id": 3,
        "label": "Congé décès frêre et soeur",
        "abbreviation": "CF",
        "source": "TELEOPTI",
        "type": "EXCEPTIONALHOLIDAY"
    }, {"id": 4, "label": "Congé déménagement", "abbreviation": "DE", "source": "TELEOPTI"}, {
        "id": 5,
        "label": "Congé Individuel de Formation",
        "abbreviation": "CI",
        "source": "TELEOPTI"
    }, {
        "id": 6,
        "label": "Congé mariage",
        "abbreviation": "CM",
        "source": "TELEOPTI",
        "type": "EXCEPTIONALHOLIDAY"
    }, {
        "id": 7,
        "label": "Congé mariage enfants",
        "abbreviation": "CE",
        "source": "TELEOPTI",
        "type": "EXCEPTIONALHOLIDAY"
    }, {
        "id": 8,
        "label": "Congé maternité",
        "abbreviation": "MT",
        "source": "TELEOPTI",
        "type": "EXCEPTIONALHOLIDAY"
    }, {
        "id": 9,
        "label": "Congé naissance",
        "abbreviation": "CN",
        "source": "TELEOPTI",
        "type": "EXCEPTIONALHOLIDAY"
    }, {"id": 10, "label": "Congé parental", "abbreviation": "CA", "source": "TELEOPTI"}, {
        "id": 11,
        "label": "Congé paternité",
        "abbreviation": "PT",
        "source": "TELEOPTI"
    }, {"id": 12, "label": "Congé payé", "abbreviation": "CP", "source": "TELEOPTI", "type": "PAIDHOLIDAY"}, {
        "id": 13,
        "label": "Congé sabbatique",
        "abbreviation": "SA",
        "source": "TELEOPTI"
    }, {
        "id": 14,
        "label": "Congé sans solde",
        "abbreviation": "CS",
        "source": "TELEOPTI",
        "type": "UNPAIDHOLIDAY"
    }, {
        "id": 15,
        "label": "Maladie",
        "abbreviation": "AJ",
        "source": "TELEOPTI",
        "type": "EXCEPTIONALHOLIDAY"
    }, {"id": 16, "label": "Mise à pied", "abbreviation": "MP", "source": "TELEOPTI"}, {
        "id": 17,
        "label": "Modulation basse",
        "abbreviation": "MB",
        "source": "TELEOPTI"
    }, {
        "id": 18,
        "label": "Opération Chirurgicale - Circoncision",
        "abbreviation": "OP",
        "source": "TELEOPTI",
        "type": "EXCEPTIONALHOLIDAY"
    }, {"id": 19, "label": "Préavis", "abbreviation": "PR", "source": "TELEOPTI"}, {
        "id": 20,
        "label": "Préavis non effectué - employeur",
        "abbreviation": "PE",
        "source": "TELEOPTI"
    }, {"id": 21, "label": "Récup", "abbreviation": "RE", "source": "TELEOPTI", "type": "ENTRYRECUP"}, {
        "id": 89,
        "label": "Baptême",
        "abbreviation": "BP",
        "source": "TELEOPTI"
    }, {"id": 90, "label": "Exhumation", "abbreviation": "EX", "source": "TELEOPTI"}, {
        "id": 91,
        "label": "Hospitalisation",
        "abbreviation": "HO",
        "source": "TELEOPTI"
    }, {"id": 92, "label": "Premiére Communion", "abbreviation": "PC", "source": "TELEOPTI"}],
    teleoptiMapping: {data: [], totalElements: 69, loading: false},
    drawerOpen: true,
    deleteMappingDialog: {open: false, mapping: "{}"},
    rejectionMotifsLabels: []
}, {type, payload, isLoading}) {
    switch (type) {
        case "GET_EXCEPT_TYPE_RH":
            return {...state, exceptTypeHr: payload};
        case "GET_EXCEPT_TYPE_RH_FR":
            return {...state, exceptTypeHrFr: payload};
        case "GET_CONFIG_USER":
            return {...state, configUser: payload};
        case "GET_EXCEPT_TYPE_RH_USER":
            return {...state, exceptTypeHrUser: payload};
        case "SET_SHOW_EXCEPTIONAL_HOLIDAY_DETAILS":
            return {...state, showExcepHolidayDetails: payload};
        case "SET_SHOW_ADD_REJECTION_MOTIF":
            return {...state, showAddRejectionMotif: payload};
        case "SET_SHOW_ADD_MAPPING":
            return {...state, showAddMapping: payload};
        case "SET_DELETE_MAPPING":
            return {...state, deleteMappingDialog: payload};
        case "GET_EMPLOYEE_CHILDREN":
            return {...state, employeeChildren: payload};
        case "GET_REJECTION_MOTIFS":
            return {...state, rejectionMotifs: payload};
        case "GET_REJECTION_LABELS_MOTIFS":
            return {...state, rejectionMotifsLabels: payload};
        case "GET_MAPPING_TELEOPTI":
            return {...state, teleoptiMapping: payload};
        case "GET_BACKUP":
            return {...state, backup: payload};
        case "GET_DAYS_OFF":
            return {...state, daysOff: payload, isLoadingDayOff: isLoading};
        case "GET_UO":
            return {...state, uo: payload};
        case "TOGGLE_DRAWER":
            return {...state, drawerOpen: payload};
        case "SHOW_TOASTER":
            return {...state, toasterMessage: payload};
        case "SET_TEAMS":
            return {...state, teams: payload};
        case "SET_AM_PM_DATES":
            return {...state, ampm: payload};
        case "SET_MONTH_INDICATOR":
            const date = moment(payload).format("DD/MM/YYYY").split("/");
            return {...state, monthIndicator: new Date(Number(date[2]), Number(date[1]) -1)};
        default:
            return state
    }
}
