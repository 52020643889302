import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import {withStyles} from "@material-ui/core";
import SubRequestToolBar from './SubRequestToolBar'
import Grid from './Grid'

import App from './Grid'

import {AppBar, Toolbar, IconButton, Typography, Button} from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';



export default class SubRequest extends Component {
    

    
    render() {
        return(
        
          <div>
            <SubRequestToolBar/>
            <Grid/>
           
          </div>
       
   
        )
      }

}