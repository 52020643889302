import React, { Component } from "react";
import ReactExport from "react-data-export";
import Button from "@material-ui/core/Button";
import { getHolidayType, states } from "../../../utilities/MappingFunctions";
import { getAxios } from "../../../actions/AuthActions";
import { API, CHILDREN_REQUESTS, ROLE_ADMIN_GROUP, ROLE_ADMIN_COUNTRY } from "../../../api/Endpoints";
import AddIcon from "@material-ui/icons/Assignment";
import moment from "moment";
import {hasAuthority, hasPermission} from "../../../actions/AuthActions";
import { strings } from "../../../i18n/Strings";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default class ExportChildrenRequests extends Component {
  state = {
    dataToExport: [{ columns: [""], data: [{ value: "" }] }]
  };

  apiToCall(){
    let apiUri;
    if (hasAuthority("ROLE_ADMIN_GROUP")){
      apiUri = `${ROLE_ADMIN_GROUP}`;
    } else if (hasAuthority("ROLE_ADMIN_COUNTRY")){
      apiUri = `${ROLE_ADMIN_COUNTRY}`;
    } else {
      apiUri = `${CHILDREN_REQUESTS}`;
    }
    return apiUri;
  }

  componentDidMount() {
    this.handleClick();
  }

  handleClick = () => {
    const {
        addedForEmp,
        startDate,
        returnDate,
        status,
        typeConge
    } = this.props.filters;
    getAxios().post(`${API}/`+this.apiToCall(), {
        "registrationNumber": addedForEmp,
        "startDate": startDate,
        "endDate": returnDate,
        "status": status,
        "typeConge": typeConge,
        "sort": null,
        "order": false,
        "page": 0,
        "size": 100000
      })
      .then(res => {
        const {_registrationNumber, _firstName, _lastName, _holidayType, _startDate, _returnDate, _status,
          _duration, _totalDuration, _FILING_DATE, _exportHRA, _enteredBy, _validatedBy} = strings;
        let dataToExport = res.data.payload
          .filter(h => h.typeRequest === "REQUEST")
          .map(h => {
            const {
              duration,
              startDate,
              returnDate,
              totalDuration,
              updateDate,
              typeConge,
              exportState,
              enteredBy,
              validatedBy
            } = h;
            return {
              [_registrationNumber] : h.addedForEmp.registrationNumber,
              [_firstName]: h.addedForEmp.firstName,
              [_lastName]: h.addedForEmp.lastName,
              [_holidayType]: getHolidayType(typeConge),
              [_startDate] : startDate,
              [_returnDate]: returnDate,
              [_status]: states[h.status],
              [_duration]: duration,
              [_totalDuration]: totalDuration,
              [_FILING_DATE]: updateDate,
              [_exportHRA]: exportState,
              [_enteredBy]: enteredBy,
              [_validatedBy]: validatedBy
            };
          });
        this.setState({ dataToExport });
      }); 
  }

  render() {
    const { dataToExport } = this.state;
    const {_extractHolidays} = strings;
    return (
      <div>
        <ExcelFile
          filename={`Extraction ${moment().format("DD/MM/YYYY hh:mm:ss")}`}
          element={
            <Button
              title={_extractHolidays}
              id={"fab"}
              color={"primary"}
              variant={"fab"}
              onClick={() => this.handleClick()}

            >
              <AddIcon />
            </Button>
          }
        >
          <ExcelSheet
            dataSet={[
              {
                columns: Object.keys(dataToExport[0] || {}),
                data: (dataToExport ? dataToExport : []).map(o => {
                  return Object.values(o).map(e => {
                    return { value: e };
                  });
                })
              }
            ]}
            name={`username~`}
          />
        </ExcelFile>
      </div>


    );
  }
}
