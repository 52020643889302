import LocalizedStrings from "react-localization";

export const language =
  window.navigator.userLanguage || window.navigator.language;

export function hasLanguage() {
  return language == "fr-FR" ? "DD/MM/YYYY" : "YYYY/MM/DD";
}

export const stringsLangs = {
fr: {
    _fr_name: "FRANÇAIS",
    _en_name: 'ANGLAIS',
    _pt_name: 'PORTUGAIS',
    _es_name: "ESPAGNOL",
    _lang: "fr",
    _download: "TÉLÉCHARGER",
    _stop: "ARRÊTER",
    _toJustify: "A justifer",
    _firstName: "Prénom",
    _lastName: "Nom",
    _registrationNumber: "Matricule",
    _absenceDay: "Date de l'absence",
    _absenceStartHour: "Heure début",
    _absenceEndHour: "Heure fin",
    _type: "Type",
    _justified: "Justifiée",
    _added: "Ajoutée",
    _deleted: "Supprimée",
    _canceled: "Annulée",
    _minor: "Mineur",
    _normal: "Normale",
    _major: "Majeur",
    _enabled: "Activé",
    _disabled: "Désactivé",
    _payedHoliday: "Congé payé",
    _unpayedHoliday: "Congé sans solde",
    _approved: "Acceptée",
    _approve: "accepter",
    _pending: "En cours",
    _rejected: "Refusée",
    _reject: "refuser",
    _acceptMsg: "vous voulez accepter cette proposition ?",
    _cancelationRequest: "Demande d'annulation",
    _absenceRequest: "Demande de congé",
    _recupStatusConsumed: "Etat de consommation de la récupération",
    _loading: "Chargement",
    _rejectionMotif: "Motif de refus",
    _exceptionalHolidayType: "Type de congé exceptionnel",
    _home: "Accueil",
    _updateDate: "Derniėre modification",
    _recuperation: "Récupération",
    _requestStatus: "Statut de la demande",
    _teleoptiMapping: "Mapping teleopti",
    _exceptionalHoliday: "Congé Social",
    _exceptionalAbsence: "Absence Exceptionnelle",
    _exceptionalHolidayFr: "Absence pour évènement exceptionnel",
    _exceptionalHolidays: "Congé Sociaux",
    _publicHoliday: "Jours férié",
    _rejectionMotifs: "Motifs de refus",
    _addedRequests: "Demandes Ajoutée",
    _addedRequestsFr: "Demandes Ajoutée France",
    _processedRequests: "Demandes traitées",
    _pendingRequests: "Demandes en attente",
    _childrenRequests: "Congés de mes collaborateurs",
    _childrenRequestsCountry: "Congés des collaborateurs du pays",
    _allPendingRequests: "Toutes demandes en attente",
    _payedCollaboratorsHolidays: "Congés de mes collaborateurs",
    _payedErrorHolidays: "Congés en Erreur",
    _holidayCancelation: "Annulation Congès",
    _effectifsHolidays: "Congès des effectifs",
    _lastUpdate: "Dernière mise à jour du solde",
    _exceptionalLeave: "",
    _resulstsFound: "Résultats trouvés:",
    _csvExport: "Export CSV",
    _myUOHolidays: "Les congés de mon UO",
    _of: "sur",
    _publicHolidays: "Jours fériés",
    _myUO: "Mon UO",
    _annualBalance: "Solde annuel",
    _pendingBalance: "Nombre de jours en attente d'export 'Solde Annuel'",
    _cumulativeBalance: "Solde Cumulé",
    _from: "du",
    _to$: "au",
    _employeesBalance: "Soldes de mes collaborateurs",
    _noResultsFound: "Aucun resultat",
    _2faActivation: "Activation 2FA",
    _seeker: "Demandeur",
    _requestDate: "Date de la demande",
    _holidayType: "Type de congé",
    _startDate: "Date de début",
    _returnDate: "Date de retour",
    _finishDate: "Date de fin",
    _endDate: "Date de fin",
    _duration: "Durée",
    _totalDuration: "Durée totale",
    _consumedDuration: "Durée consommée",
    _partner: "Collaborateur",
    _validator: "Validateur",
    _backup: "Backup",
    _requestType: "Type de demande",
    _status: "Statut",
    _uploadedBy: "Chargé par",
    _fileName: "Nom de fichier",
    _creationDate: "Date de création",
    _addedForEmp: "Créé pour",
    _label: "Libéllé",
    _provedByDocuments: "Pièces Justificative",
    _country: "Pays",
    _newHolidayRequest: "Nouvelle demande de congé",
    _cancel: "annuler",
    _confirm: "confirmer",
    _holidayDuration: "Durée du congé",
    _comment: "Commentaire",
    _follow: "Suivi",
    _history: "Historique",
    _absenceDuration: "Durée absence",
    _abbreviation: "Abréviation",
    _allCountries: "Tous les pays",
    _criticality: "Criticité",
    _holidayDeletion: "Suppression du congé",
    _assignedRole: "Role à assigner",
    _writeRecuperation: "Saisir une récupération",
    _writeSetting: "Saisir un paramétrage",
    _writeHoliday: "Saisir Congé récupération",
    _teleoptiHolidayType: "Type de congé Teleopti",
    _exceptionalHOlidayMapping: "Mapping des types de congés exceptionnels",
    _deletetion: "Suppression",
    _recupDeletetion: "Suppression du type de récupération",
    _mappingTeleoptiDeletionConfirmation:
      "Confirmez-vous la suppression du mapping Téléopti ?",
    _publicHolidayDeletionConfirmation:
      "Confirmez-vous la suppression du jour férié ?",
    _recupDeletionConfirmation:
      "Confirmez-vous la suppression de la récupération ?",
    _holidayDeletionConfirmation:
      "Confirmez-vous  la suppression du congé suivant :",
    _holidayCancelationConfirmation:
      "Confirmez-vous  l'annulation du congé suivant :",
    _commentDetails: "Saisir plus de details sur la raison de votre demande",
    _selectHolidayDuration: "Séléctionner durée de congé",
    _reasonOfRequest: "Raison de congé",
    _justificationOfAbsences: "Justification absences",
    _justificationOfAbsencesFr: "Justification absences France",
    _justificationOfCurrentAbsences: "Justification absences courantes",
    _exportsManagement: "Gestion des exports",
    _disconnect: "Se déconnecter",
    _selectCountries: "Sélectionner les pays",
    _myRequests: "Mes demandes",
    _employee: "Employé",
    _dayAbsence: "Absence en jour",
    _hourAbsence: "Absence horaires",
    _timeTrajet: "Temps de trajet",
    _timeDelegation: "Saisie des temps",
    _holidayDetails: "Details de la demande",
    _typeRequest: "Type de la demande",
    _holidayPeriod: "Période du congé",
    _backupValidator: "Validateur backup",
    _INTERNAL_SERVER_ERROR:
      "Erreur interne du serveur, veuillez réessayer ultérieurement",
    _ADDED_FOR_EMPL_NULL: "Vous devez saisir un employé",
    _EMPTY_LEAVE: "Vous avez choisi un jour de congé qui coïncide avec un dayoff ou un jour férié.",
    _EMP_REQUIRED: `Merci de sélectionner l'employé correspondant `,
    _CASUAL_LEAVE_TIME: `congé occasionnel d'une durée de 2jours `,
    _BACKUP_IS_NULL: "Le validateur backup n'a pas été choisi",
    _CANCEL_HOLIDAY_AFTER_START: "Vous ne pouvez pas annuler un congé après",
    _CANCEL_HOLIDAY_BY_OTHERS:
      "Vous ne pouvez annuler que les congés que vous avez créé",
    _CANCEL_HOLIDAY_CANCEL: "Voulez-vous annuler une demande d'annulation ?",
    _CANCEL_HOLIDAY_EXISTS:
      "Une demande d'annulation existe déjà pour cette demande",
    _CANCEL_HOLIDAY_IN_PROCESS: "Vous ne pouvez annuler que les congés validés",
    _COUNTRY_EXCEPTIONAL_CONGE_TYPE_NULL: "Vous devez choisir un Pays",
    _COUNTRY_MUST_BE_SET: "Vous devez choisir un pays avant de continuer.",
    _COUNTRY_REJECTION_REASON: "Vous devez Choisir un pays",
    _CRITICALITY_REJECTION_REASON: "Vous devez choisir la criticité",
    _DATES_AFTER:
      "Les dates saisies doivent être antérieures à la date courante",
    _DATES_AFTER_DAY:
      "Règle RH : La date début du congès doit être supérieure à la date de demain",
    _DATES_AFTER_NOW:
      "Règle RH : La date début du congès doit être supérieure à la date de demain à la date d'aujourd'hui",
    _DATES_ARE_NULL: "Les dates n'ont pas été saisies",
    _DATES_IN_PERIOD: "Chevauchement de dates",
    _DATES_PAST:
      "Les dates saisies doivent être supérieures à la date courante",
    _DELETE_HOLIDAY_IN_PROCESS:
      "Vous ne pouvez supprimer que les congés non validés",
    _DELETE_REQUEST:
      "Vous ne pouvez pas supprimer un congé que vous n'avez pas créé",
    _DIFFERENT_QUOTA_TEAM:
      "Le site a des valeurs différentes pour les quotas équipe",
    _DURATION_EXCEPTIONAL_CONGE_TYPE_NULL:
      "Vous devez saisir une durée supérieure à 0",
    _EMPLOYEE_MUST_BE_SET: "Vous devez saisir un employé avant de continuer.",
    _EXCEPTIONAL_CONGE_TYPE_EXIST:
      "Le type de congé exceptionnel existe déja avec le même nom :",
    _EXCEPTIONAL_TYPE_NULL: "Vous devez choisir un type de congé exceptionnel",
    _INSUFFICIENT_SOLDE: "jours, Votre solde est insuffisant",
    _LABEL_EXCEPTIONAL_CONGE_TYPE_NULL: "Vous devez saisir un label",
    _LABEL_REJECTION_REASON: "Vous devez saisir un label",
    _MAPPING_EXCEPTIONAL_CONGE_TYPE_NULL:
      "Vous devez effectuer le mapping avec HR-Access",
    _MAPPING_EXCEPTIONAL_TYPE_TELEOPTI:
      "Vous devez effectuer le mapping avec Teleopti",
    _MAPPING_EXIST_TYPE_TELEOPTI: "Ce mapping existe déjà avec Teleopti",
    _NO_BACKUP_VALIDATOR: "Vous devez spécifier un validateur backup pour:",
    _PUBLIC_HOLIDAY_EXISTS:
      "Il existe déjà un jour férié avec les mêmes dates pour le même pays",
    _PUBLIC_HOLIDAY_OVERLAP: "Il existe un chauvechement des jours fériés",
    _RECUP_DURATION_INSUFFICIENT:
      "Le congé de récupération que vous avez saisi dépasse la durée de la récupération qui est de :",
    _RECUP_EXISTS:
      "Il existe déjà une récupération avec les mêmes dates pour cet employé",
    _RECUP_MONTH:
      "Le congés de récupération ne peux pas être consomée après un mois",
    _RECUP_NOT_EXIST: "La récupération est inexistante",
    _RECUP_NOT_VALID: "La date saisie n'est ni un dayoff ni un jour férié",
    _RECUP_REQUEST_MONTH:
      "La récupération ne peux pas être ajoutée après un mois",
    _RECUP_REQUEST_NOT_VALID:
      "Les dates choisies ne sont pas des jours ouvrables",
    _REJECTION_REASON_EXIST: "Le motif de refus existe déja avec le même nom :",
    _REJECT_REQUEST_REASON: "Vous devez saisir un motif de refus",
    _REQUEST_DELETED: "Ce congé est déjà supprimé.",
    _REQUIRED_FIELDS:
      "Tous les champs doivent être remplis avant d'enregistrer la demande",
    _SAME_DATES_EXCEPTION: "Il existe déjà un congé avec les mêmes dates",
    _SCENARIO_MUST_BE_SET: "Vous devez saisir un scénario avant de continuer.",
    _SITE_NOT_FOUND: "Le site n'est pas configuré sur le référentiel",
    _SOLDE_ESTIMATED_INSUFFICIENT:
      "Votre solde estimé dépasse votre solde HR, vous ne pouvez pas soumettre des demandes de congés",
    _START_DATE_EXCEED_YEAR: "La date de début du congé dépasse une année",
    _STATE_MUST_BE_SET:
      "Vous devez choisir un avis avant de valider la demande.",
    _STATE_REJECTION_REASON: "Vous devez choisir un etat",
    _SYSTEM_REJECT_REQUEST: "Demande rejetée automatiquement par le système",
    _TEAM_NOT_FOUND: "L'équipe n'est pas configurée sur le référentiel",
    _TEAM_NOT_SET: "Equipe non paramétrée pour l'utilisateur",
    _TYPE_MUST_BE_SET: "Vous devez saisir un type de congé avant de continuer.",
    _UO_NOT_SET: "Site non paramétré pour l'utilisateur",
    _USER_NOT_EXIST: "L'employé est introuvable",
    _VALIDATOR_NOT_AVAILABLE:
      "Nous ne pouvons prendre en compte votre demande vu que celle-ci ne respecte pas le délai défini de J+2 ouvrés hors Jours Fériés ( Jours ouvrés = Du Lundi au Vendredi ). Nous vous remercions de changer les dates afin de respecter ce délai. Merci",

    _numbersOfDaysOff: "Nombre de DaysOff",
    _leaveDay: "Jour travaillé",
    _all: "Tous",
    _initializer: "Initiateur",
    _hr: "RH",
    _system: "Système",
    _ppv_system: "Système ( validation WFM automatique  )",
    _project: "Projet",
    _days: "Jour(s)",
    _avis: "Avis",
    _WFM_validation: "Système : validation WFM automatique",
    _automatiquement_le: "automatiquement le",
    _reasonOfRejection: "Motif de refus",
    _newProposal: "Nouvelle proposition",
    _newProposalProcessed: "La nouvelle proposition a été traitée avec succès",
    _updatedBy: "Ajoutée par",
    _actif: "Collaborateur active",
    _unpaidSicknessHoliday: "Maladie Non Payée",
    _durationRecovery: "Durée récuperation",
    _consumed: "Consommée",
    _expired: "Expirée",
    _active: "Active",
    _yes: "Oui",
    _no: "Non",
    _holidayDetailsMsg: "Cette demande n’est définitivement validée qu’après accord de votre hiérarchie",
    _holidayDetailsMsgDR: "Cette demande n’est définitivement validée qu’après accord de votre hiérarchie & RH",
    _return: "RETOUR",
    _cancelHoliday: "Annulation du congé",
    _removeHoliday: "Suppression du congé",
    _area: "Zone",
    _starting: "Départ",
    _end: "Fin",
    _team: "Equipe",
    _marageQuota: "Gestion des Quotas équipe",
    _geographicalArea: "Zone géographique",
    _searchQuota: "Recherche Quota",

    _respProcessRequest: "La demande a été traitée ",
    _respCancelRequest: "La demande a été annulée ",
    _respDeleteRequestOK: "La demande a été supprimée ",
    _respDeleteRequestKO: "Vous ne pouvez pas supprimer un congé en cours de validation ",
    _respDeleteRequestRecupOK: "La demande a été supprimée ",
    _respDeleteRequestRecupKO: "Vous ne pouvez pas supprimer cette Récupération ",
    _withoutPayHoliday: "Congé sans solde ",
    _edit: "modifier",
    _create: "créer",
    _withSuccess: "avec success ",
    _respDeleteRejectionReason: "Motif de refus supprimer avec success ",
    _respEditExceptionalHoliday:"Le congé social a été modifié avec success ",
    _respAddExceptionalHoliday: "Le congé social a été ajouté avec success ",
    _respDeleteExceptionalHoliday: "Le congé social a été supprimé avec success ",
    _respAddPublicHoliday: "Le jours feries a été ajouté avec success ",
    _respEditPublicHoliday: "Le jours feries a été modifié avec success ",
    _respDeletePublicHoliday: "Le jours feries a été supprimé avec success ",
    _respSetQuota: "Le quota a été modifié avec success ",
    _SUCCESS_DOWNLOAD: "Le fichier a été téléchargé avec success ",
    _IMPORT_STOPPED: "L'import a bien été arrêté",
    _respAddHolidayRequest: "votre demande a été ajoutée ",
    _respInMassValidation: "validation en masse effectuée ",
    _respCancelRequestRH: "Annulation du congé effectuée ",
    _respAddHolidayRecup: "votre demande a été envoyée ",
    _respFlagExportedOK: "La demande a été exportée ",
    _respFlagExportedKO: "Erreur lors de l'export de la demande ",
    _respExportToHrOk: "La demande a été exportée ",
    _respExportToHrKO: "Erreur lors de l'export de la demande ",
    _respJustifyAbsence: "Absence justifée avec success ",
    _respSaveTrajet: "Trajet enregistre avec success ",
    _lastDay: "Last Day",
    _unitDay: "Unit Day",
    _hraServer: "Instance HRACCESS",
    _validateur: "Validateur",
    _validationHorsCompEquipe: "Validation hors composition d'équipe",
    _rttBalance : "Solde RTT",
    _ancienneteBalance: "Solde d'ancienneté",
    _pendingRtt: "Nombre de jours en attente d'export 'RTT'",
    _pendingAnciennete: "Nombre de jours en attente d'export 'Ancienneté'",
    _rttHoliday: "Congé RTT",
    _ancienneteHoliday: "Congé Ancienneté",
    _am_label: "Après-midi",
    _pm_label: "Matinée",
    _timeTrajetIn: "Saisie des temps",
    _DURRATION_OF_HOLIDAY: "La durée du congé est de {} Jour(s).",
    _REQUIRED_DOCS: " Les documents à fournir lors du retour sont: {}\n\n",
    _OPINION_MANAGER: "Avis Manager :",
    _OPINION_WFM: "Avis système WFM : ",
    _OPINION_SYSTEM: "Avis système : ",
    _OPINION_RH: "Avis RH :",
    _REASON_FOR_REFUSAL: "Motif de refus : ",
    _hold: "En attente",
    _motif: "Modifié :",
    _changeQuota: "Changement quota",
    _importQuota: "Importer Quota",
    _generateQuota: "Génerer le Rapport Quota",
      _QUOTA_NO_CONFIGURED: "Le quota est non paramétré pour la période sélectionnée",
      _TEAM_LABEL: "Equipes",
      _LABEL_MORNING_AFTERNON: "(matinée: {} / aprés midi: {} )",
      _REPORT_SEND_MAIL: "Le rapport est envoyé par mail.",
      _ERREUR: "une erreur est survenue",
      _IMPORT_FILE_QUOTA: "L'import du fichier quota encours.",
      _ERREUR_ON_IMPORT: "une erreur d'import est survenue",
      _QUOTA: "Quota en %",
      _MAX: "Quota en nombre",
      _TOTAL: "Total validé & en attente de validation Management (Hors Maternité)",
      _PL: "CP",
      _UL: "CSS",
      _EL: "CE",
      _REST: "Reste",
      _START_DATE: "Date début :",
      _END_DATE: "Date retour :",
      _FILING_DATE: "Date dépot",
      _REFRESH_QUOTA: "Rafraîchir le quota",
      _HOLIDAY_APPROVED_ALL: {label: "Congé validé all", title: "Avis WFM : OK\n Décision N+1/RH : OK"},
      _HOLIDAY_APPROVED_N1: {label: "Congé validé N+1/RH", title: "Avis WFM : KO\n Décision N+1/RH : OK"},
      _LEAD_TEAM_HOLIDAY: {label: "Congé TL", title: ""},
      _HOLIDAY_REFUSED_ALL: {label: "Congé refusé all", title: "Avis WFM : KO\n Décision N+1/RH : KO"},
      _HOLIDAY_REFUSED_N1: {label: "Congé refusé N+1/RH", title: "Avis WFM : OK\n Décision N+1/RH : KO"},
      _SOCIAL_APPROVED_ALL: {label: "Congé social validé all", title: "Avis WFM : OK\n Décision N+1/RH : OK"},
      _SOCIAL_PENDING: {label: "Demande social en attente", title: ""},
      _SOCIAL_REFUSED_ALL: {label: "Congé social refusé all", title: "Avis WFM : KO\n Décision N+1/RH : KO"},
      _UNPAID_SOCIAL_REFUSED_ALL_OK: {label: "Congé sans solde validé all", title: "Avis WFM : OK\n Décision N+1/RH : OK"},
      _UNPAID_HOLIDAY_APPROVED_N1_HR: {label: "Congé sans solde validé N+1/RH", title: "Avis WFM : KO\n Décision N+1/RH : OK"},
      _PENDING_REQUEST: {label: "Demande en attente", title: ""},
      _UNPAID_SOCIAL_REFUSED_ALL_KO: {label: "Congé sans solde refusé all", title: "Avis WFM : KO\n Décision N+1/RH : KO"},
      _UNPAID_SOCIAL_REFUSED_N1_HR: {label: "Congé sans solde refusé N+1/RH", title: "Avis WFM : OK\n Décision N+1/RH : KO"},
      _LABEL_WEEK: "Semaine",
      _casualBalance : "Solde de congé occasionnel",
      _casualHoliday : "Congé occasionnel",
      _Quota_available : "Quota disponible",
      _Quota_reached : "Quota atteint",
      _Quota_not_set : "Quota non paramétré",
      _Quota_almost_reached: "Quota presque atteint",
      _MAX_DURATION_FOR_CASUAL_LEAVE : "Vous avez depassé la durée (2 J) autorisé pour une demande de ce congé",
      _INSUFFICIENT_SOLDE__CASUAL_LEAVE :"Votre solde est insuffisant",
      _APPROUVE_VALIDATION : "Confirmez vous la validation de toutes les demandes sélectionnées ?",
      _VALIDATION_MASSE : "Validation en masse",
      _errorSplitAbsence: "Le découpage de l'absence est erroné",
      _respSplitAbsence: "Absence a été bien découpée ",
    _errorSaveSplitAbsence : "Erreur lors de l'enregistrement de découpage",
    _Date_concernee : "Date concernée",
    _MotifEntryTime : "Motif",
    _extractHolidays : "Extraire les congés",
    _exportHRA : "Export HRA",
    _enteredBy : "Saisi par",
    _validatedBy : "Validé par",
    _deposit_date : "date de dépôt",
    _typeOfsocialLeave : "type de congé social"
},
en: {
    _fr_name: "FRENCH",
    _en_name: 'ENGLISH',
    _pt_name: 'PORTUGUESE',
    _es_name: "SPANISH",
    _download: "DOWNLOAD",
    _stop: "STOP",
    _respAddHolidayRequest: "your request has been added ",
    _respInMassValidation: "Bulk approval performed ",
    _respCancelRequestRH: "Leave cancellation performed ",
    _respAddHolidayRecup: "your request has been sent ",
    _respFlagExportedOK: "The request has been exported ",
    _respFlagExportedKO: "Error while exporting the request",
    _respExportToHrOk: "The request has been exported ",
    _respExportToHrKO: "Error while exporting the request ",
    _respJustifyAbsence: "Leave substantiated successfully ",
    _respSetQuota: "The quota has been modified successfully ",
    _SUCCESS_DOWNLOAD: "The file has been downloaded successfully ",
    _IMPORT_STOPPED: "The import has been stopped",
    _edit: "Modify ",
    _create: "Create ",
    _withSuccess: "Successful ",
    _respDeleteRejectionReason: "Reason for denial, delete successfully ",
    _respEditExceptionalHoliday:"The social leave has been modified successfully ",
    _respAddExceptionalHoliday: "The social leave has been added successfully ",
    _respDeleteExceptionalHoliday: "The social leave has been deleted successfully ",
    _respAddPublicHoliday: "The public holiday has been added successfully ",
    _respEditPublicHoliday: "The public holiday has been modified successfully ",
    _respDeletePublicHoliday: "The public holiday has been deleted successfully ",
    _respProcessRequest: "The request has been processed ",
    _respCancelRequest: "The request has been cancelled ",
    _respDeleteRequestOK: "The request has been deleted ",
    _respDeleteRequestKO: "You cannot delete a leave that is pending acceptance ",
    _respDeleteRequestRecupOK: "The request has been deleted ",
    _respDeleteRequestRecupKO: "You cannot delete this comp. leave ",
    _withoutPayHoliday: "Unpaid leave ",

    _searchQuota: "Search Quota",
    _geographicalArea: "Geographical area",
    _marageQuota: "Management of Team Quotas",
    _end: "End",
    _team: "Team",
    _area: "Area",
    _starting: "Start",
    _project: "Project",
    _removeHoliday:"Leave deletion",
    _cancelHoliday: "Leave cancellation",
    _return: "BACK",
    _requestDate: "Date of request",
    _holidayDetailsMsg: "This request is definitely approved only after your line manager accepts it.",
    _holidayDetailsMsgDR: "This request is definitely approved only after your line manager & HR accepts it.",
    _yes: "Yes",
    _no: "No",
    _consumed: "Used up",
    _expired: "Expired",
    _active: "Active",
    _comment: "Comment",
    _lang: "en",
    _toJustify: "Provide evidence",
    _firstName: "First name",
    _lastName: "Last name",
    _registrationNumber: "Employee ID number",
    _absenceDay: "Date of leave",
    _absenceStartHour: "Start time",
    _absenceEndHour: "End time",
    _type: "Type",
    _justified: "Evidence provided",
    _added: "Added",
    _deleted: "Deleted",
    _canceled: "Cancelled",
    _minor: "Minor",
    _normal: "Normal",
    _major: "Major",
    _enabled: "Enabled",
    _disabled: "Disabled",
    _payedHoliday: "Paid leave",
    _unpayedHoliday: "Unpaid leave",
    _approved: "Approved",
    _approve: "accept",
    _pending: "Pending",
    _rejected: "Denied",
    _reject: "reject",
    _acceptMsg: "you want to accept this proposal ?",
    _cancelationRequest: "Cancellation request",
    _absenceRequest: "Leave request",
    _recupStatusConsumed: "Comp. leave use status",
    _loading: "Loading",
    _rejectionMotif: "Reason for denial",
    _exceptionalHolidayType: "Type of exceptional leave",
    _annualBalance: "Annual balance",
    _myUOHolidays: "Leave of my OU",
    _pendingBalance: "Number of days pending the 'Annual Balance' export ",
    _updateDate: "Modification date",
    _home: "Home",
    _recuperation: "Comp. leave",
    _requestStatus: "Status of the request",
    _teleoptiMapping: "teleopti mapping",
    _exceptionalHoliday: "Social leave",
    _exceptionalAbsence: "Exceptional leave",
    _exceptionalHolidayFr: "Leave due to an exceptional event",
    _exceptionalHolidays: "Social leave",
    _publicHolidays: "Public holidays",
    _publicHoliday: "Public holidays",
    _rejectionMotifs: "Reason for denial",
    _addedRequests: "Added requests ",
    _addedRequestsFr: "Added requests France",
    _processedRequests: "Processed requests ",
    _pendingRequests: "Pending requests",
    _cumulativeBalance: "Cumulative Balance",
    _childrenRequests: "My employee's leave",
    _childrenRequestsCountry: "Leave of colleagues by country",
    _allPendingRequests: "All my pending requests",
    _payedCollaboratorsHolidays: "My employee's leave ",
    _holidayCancelation: "Leave cancellation",
    _effectifsHolidays: "Staff leave",
    _lastUpdate: "Last balance update",
    _exceptionalLeave: "",
    _resulstsFound: "Results obtained:",
    _csvExport: "CSV export",
    _of: "on",
    _myUO: "My OU",
    _from: "from",
    _to$: "to",
    _employeesBalance: "My employee's balances",
    _noResultsFound: "No results",
    _2faActivation: " Enabling 2FA ",
    _seeker: "Applicant",
    _holidayType: "Type of leave",
    _startDate: "Start Date",
    _returnDate: "Return date",
    _finishDate: "End Date",
    _endDate: "End date",
    _duration: "Duration",
    _totalDuration: "Total duration",
    _consumedDuration: "Duration used up",
    _partner: "Employee",
    _validator: "Approving officer",
    _backup: "Backup",
    _requestType: "Request type",
    _status: "status",
    _uploadedBy: "Uploaded By",
    _fileName: "File name",
    _creationDate: "Creation date",
    _addedForEmp: "Created for",
    _label: "Description",
    _provedByDocuments: "Supporting documents",
    _country: "Country",
    _newHolidayRequest: "New leave request",
    _cancel: "cancel",
    _confirm: "confirm",
    _holidayDuration: "Duration of leave",
    _follow: "Follow-up",
    _history: "History",
    _absenceDuration: "Leave duration",
    _abbreviation: "Abbreviation",
    _allCountries: "All countries",
    _criticality: "Criticality",
    _holidayDeletion: "Deletion of leave",
    _assignedRole: "Role to assign",
    _writeRecuperation: "Enter comp. leave",
    _writeHoliday: "Enter comp. leave",
    _teleoptiHolidayType: "Type of Teleopti leave",
    _exceptionalHOlidayMapping: "Mapping of types of exceptional leave ",
    _deletetion: "Deletion",
    _recupDeletetion: "Removal of the type of recovery leave",
    _mappingTeleoptiDeletionConfirmation:
      "Do you confirm the removal of the Teleopti mapping?",
    _publicHolidayDeletionConfirmation:
      "Do you confirm the removal of the public holiday?",
    _recupDeletionConfirmation: "Do you confirm the removal of the recovery?",
    _holidayDeletionConfirmation:
      "Do you confirm the removal of the following leave?",
    _holidayCancelationConfirmation:
      "Do you confirm the cancellation of the following leave:",
    _commentDetails: "Enter further information about the reason of your request",
    _dayAbsence: "Leave in days",
    _hourAbsence: "Leave in hours",
    _myRequests: "My requests",
    _justificationOfAbsences: "Provide supporting documents for leave",
    _justificationOfAbsencesFr: "Provide supporting documents for leave - France",
    _justificationOfCurrentAbsences: "Provide supporting documents for recurring leave ",
    _employee: "Employee",
    _typeRequest: "Request type",
    _backupValidator: "Backup approving officer",
    _holidayPeriod: "Leave period",
    _addedRequest: "Added requests ",
    _INTERNAL_SERVER_ERROR: "Internal server error, please try again later",
    _holidayDetails: "Details of the request",
    _RECUP_NOT_EXIST: "The recovery leave does not exist.",
    _RECUP_NOT_VALID:
      "The date you entered is neither a day off nor a public holiday",
    _RECUP_REQUEST_NOT_VALID: "The dates you selected are not working days",
    _RECUP_REQUEST_MONTH: "The comp.leave cannot be added after one month has elapsed",
    _RECUP_MONTH: "The recovery leave cannot be taken after one month has elapsed",
    _PUBLIC_HOLIDAY_EXISTS:
      "There is already a public holiday on the same dates for the same country",
    _PUBLIC_HOLIDAY_OVERLAP: "There is an overlapping of public holidays",
    _numbersOfDaysOff: "Number of days off",
    _leaveDay: "Day worked",
    _all: "All",
    _initializer: "Initiator",
    _hr: "HR",
    _system: "System",
    _ppv_system: "System (automatic WFM approval)",
    _days: "Days",
    _avis: "Feedback",
    _WFM_validation: "System: automatic WFM validation",
    _automatiquement_le: "automatically the",
    _reasonOfRejection: "Reason for denial",
    _newProposal: "New Proposal",
    _newProposalProcessed: "The new Proposal has been is processed succesfuly",
    _ADDED_FOR_EMPL_NULL: "You must enter an employee",
    _EMPTY_LEAVE: "You have selected a leave date that matches a day off or public holiday",
    _EMP_REQUIRED: "Please select the corresponding employee",
    _BACKUP_IS_NULL: "The backup approving officer has not been selected",
    _CANCEL_HOLIDAY_AFTER_START: "You cannot cancel a leave once it has been created",
    _CANCEL_HOLIDAY_BY_OTHERS: "You can only cancel leave that you have created",
    _CANCEL_HOLIDAY_CANCEL: " Do you want to cancel a cancellation request?",
    _CANCEL_HOLIDAY_EXISTS:
      "There is already a cancellation request for this request",
    _CANCEL_HOLIDAY_IN_PROCESS: "You can only cancel approved leave",
    _COUNTRY_EXCEPTIONAL_CONGE_TYPE_NULL: "You must select a country",
    _COUNTRY_MUST_BE_SET: "You must select a country before you can proceed.",
    _COUNTRY_REJECTION_REASON: "You must select a country",
    _CRITICALITY_REJECTION_REASON: "You must select the criticality",
    _DATES_AFTER: "The dates entered must be earlier than the current date",
    _DATES_AFTER_DAY:
      "HR Rule: Leave start date must be later than tomorrow's date",
    _DATES_AFTER_NOW:
      "HR Rule: Leave start date must be later than tomorrow's date",
    _DATES_ARE_NULL: "Dates have not been entered",
    _DATES_IN_PERIOD: "Overlapping dates",
    _DATES_PAST: "Dates entered must be later than the current date",
    _DELETE_HOLIDAY_IN_PROCESS:
      "You can only delete leave that has not been approved",
    _DELETE_REQUEST: "You cannot delete leave that you did not create",
    _DIFFERENT_QUOTA_TEAM: " The site has different values for team quotas",
    _DURATION_EXCEPTIONAL_CONGE_TYPE_NULL:
      "The duration you enter must be greater than 0",
    _EMPLOYEE_MUST_BE_SET: "You must enter an employee before you can proceed.",
    _EXCEPTIONAL_CONGE_TYPE_EXIST:
      "The type of special leave already exists under the same name:",
    _EXCEPTIONAL_TYPE_NULL: "You must select a type of exceptional leave",
    _INSUFFICIENT_SOLDE: "days, your balance is not sufficient.",
    _LABEL_EXCEPTIONAL_CONGE_TYPE_NULL: "You must enter a description",
    _LABEL_REJECTION_REASON: "You must enter a description",
    _MAPPING_EXCEPTIONAL_CONGE_TYPE_NULL:
      "You must map using HR-Access",
    _MAPPING_EXCEPTIONAL_TYPE_TELEOPTI:
      "You must map using Teleopti",
    _MAPPING_EXIST_TYPE_TELEOPTI: "This mapping already exists in Teleopti",
    _NO_BACKUP_VALIDATOR: "You must specify a backup approving officer for:",
    _RECUP_DURATION_INSUFFICIENT:
      "The recovery leave you have entered exceeds the recovery leave duration which is :",
    _RECUP_EXISTS:
      "There is already a recovery leave with the same dates for this employee",
    _REJECTION_REASON_EXIST:
      "The reason for denial has already been entered for the same name:",
    _REJECT_REQUEST_REASON: "You must enter a reason for denial",
    _REQUEST_DELETED: "This leave has already been deleted.",
    _REQUIRED_FIELDS:
      "All fields must be completed before saving the request",
    _SAME_DATES_EXCEPTION: "There is already a leave for the same dates",
    _SCENARIO_MUST_BE_SET: "You must enter a scenario before you can proceed.",
    _SITE_NOT_FOUND: "The site is not configured on the repository",
    _SOLDE_ESTIMATED_INSUFFICIENT:
      "Your estimated balance exceeds your HR balance, you are not permitted to submit leave requests",
    _START_DATE_EXCEED_YEAR:
      "The start date of the leave exceeds one year",
    _STATE_MUST_BE_SET:
      "You must select a statement before approving the request.",
    _STATE_REJECTION_REASON: "You must select a status",
    _SYSTEM_REJECT_REQUEST: "The request is denied automatically by the system",
    _TEAM_NOT_FOUND: "The team is not configured on the repository",
    _TEAM_NOT_SET: "The team is not configured for the user",
    _TYPE_MUST_BE_SET: "You must enter a leave type before you can proceed.",
    _UO_NOT_SET: "The site is not configured for the user",
    _USER_NOT_EXIST: "The employee cannot be found",
    _VALIDATOR_NOT_AVAILABLE:
      "We cannot take your request into account as it does not meet the defined timeframe of D+2 working days excluding public holidays (Working days = Monday to Friday). Please change the dates in order to keep to this timeframe. Thank you",
    _updatedBy: "Added by",
    _actif: "Active employee",
    _unpaidSicknessHoliday: "Unpaid sick leave",
    _disconnect: "Logout",
    _durationRecovery: "Recovery leave duration",
    _rttBalance : "RTT Balance",
    _ancienneteBalance: "Seniority Balance",
    _pendingRtt: "Number of days pending 'RTT' export",
    _pendingAnciennete: "Number of days pending 'Seniority' export",
    _rttHoliday: "RTT leave",
    _ancienneteHoliday: "Seniority leave",
    _lastDay: "Last Day",
    _unitDay: "Unit Day",
    _hraServer: "HRACCESS session",
    _validateur: "Approving officer",
    _validationHorsCompEquipe: "Approval except team composition",
    _am_label: "Afternoon",
    _pm_label: "Morning",
    _timeTrajetIn: "enter route time",
    _DURRATION_OF_HOLIDAY: "The duration of the leave is {} day(s).",
    _REQUIRED_DOCS: " The documents to be provided upon return are: {}\n\n",
    _OPINION_MANAGER: "Manager's Opinion:",
    _OPINION_WFM: "WFM System Opinion:",
    _OPINION_SYSTEM: "System Opinion:",
    _OPINION_RH: "HR Opinion:",
    _REASON_FOR_REFUSAL: "Reason for rejection:",
    _hold: "Pending",
    _motif: "Modified:",
    _changeQuota: "Change quota",
    _importQuota: "Import Quota",
    _generateQuota: "Generate Quota Report",
      _QUOTA_NO_CONFIGURED: "The quota is not configured for the selected period",
      _TEAM_LABEL: "Teams",
      _LABEL_MORNING_AFTERNON: "morning: {} / afternoon: {})",
      _REPORT_SEND_MAIL: "The report is sent by email.",
      _ERREUR: "An error has occurred",
      _IMPORT_FILE_QUOTA: "The quota file import is ongoing.",
      _ERREUR_ON_IMPORT: "An import error has occurred",
      _QUOTA: "Quota",
      _MAX: "Max",
      _TOTAL: "Total",
      _PL: "PL",
      _UL: "UL",
      _EL: "EL",
      _REST: "Remaining",
      _START_DATE: "Start date:",
      _END_DATE: "Return date:",
      _FILING_DATE: "Submission date",
      _REFRESH_QUOTA: "Refresh the quota",
      _HOLIDAY_APPROVED_ALL: {label :"Leave approved all", title:"WFM Opinion: OK Decision Manager/HR: OK"},
      _HOLIDAY_APPROVED_N1: {label :"Leave approved N+1/HR", title:"WFM Opinion: KO Decision Manager/HR: OK"},
      _PENDING_REQUEST: {label :"Request pending", title:"."},
      _LEAD_TEAM_HOLIDAY: {label :"Leave TL", title:"."},
      _HOLIDAY_REFUSED_ALL: {label :"Leave denied all", title:"WFM Opinion: KO Decision Manager/HR: KO"},
      _HOLIDAY_REFUSED_N1: {label :"Leave denied N+1/HR", title:"WFM Opinion: OK Decision Manager/HR: KO"},
      _SOCIAL_APPROVED_ALL: {label :"Social leave approved all", title:"WFM Opinion: OK Decision Manager/HR: OK"},
      _SOCIAL_PENDING: {label :"Social leave request pending", title:"."},
      _SOCIAL_REFUSED_ALL: {label :"Social leave denied all", title:"WFM Opinion: KO Decision Manager/HR: KO"},
      _UNPAID_SOCIAL_REFUSED_ALL_OK: {label :"Unpaid leave approved all", title:"WFM Opinion: OK Decision Manager/HR: OK"},
      _UNPAID_HOLIDAY_APPROVED_N1_HR: {label :"Unpaid leave approved N+1/HR", title:"WFM Opinion: KO Decision Manager/HR: OK"},
      _UNPAID_SOCIAL_REFUSED_ALL_KO: {label :"Unpaid leave denied all", title:"WFM Opinion: KO Decision Manager/HR: KO"},
      _UNPAID_SOCIAL_REFUSED_N1_HR: {label :"Unpaid leave denied N+1/HR", title:"WFM Opinion: OK Decision Manager/HR: KO"},
      _LABEL_WEEK: "Week",
      _casualBalance : "Casual leave balance",
      _casualHoliday : "Casual Leave",
      _Quota_available : "Quota available",
      _Quota_reached : "Quota reached",
      _Quota_not_set : "Quota not set",
      _Quota_almost_reached: "Quota almost reached",
      _MAX_DURATION_FOR_CASUAL_LEAVE : "You have exceeded the duration (2 days) authorized for a request for this leave",
      _INSUFFICIENT_SOLDE__CASUAL_LEAVE :"Your balance is not sufficient",
      _APPROUVE_VALIDATION : "Do you confirm the validation of all the selected requests ?",
      _VALIDATION_MASSE : "Bulk validation",
      _errorSplitAbsence: "Le découpage de l'absence est erroné",
      _respSplitAbsence: "Absence a été bien découpée ",
    _errorSaveSplitAbsence : "Erreur lors de l'enregistrement de découpage",
    _Date_concernee : "Date concernée",
    _MotifEntryTime : "Motif",
    _extractHolidays: "Extract leaves",
    _exportHRA : "Export HRA",
    _enteredBy : "Entered by",
    _validatedBy : "Validated by",
    _deposit_date : "deposit date",
    _typeOfsocialLeave : "type of social leave"
},
pt: {
    _fr_name: "FRANCÊS",
    _en_name: 'INGLÊS',
    _pt_name: 'PORTUGUÊS',
    _es_name: "ESPANHOL",
    _lang: "pt",
    _download: "BAIXAR",
    _stop: "PARAR",
    _toJustify: "A justificar",
    _firstName: "Nome",
    _lastName: "Apelido",
    _registrationNumber: "Matrícula",
    _absenceDay: "Data de ausência",
    _absenceStartHour: "Hora início",
    _absenceEndHour: "Hora fim",
    _type: "Type",
    _justified: "Justificada",
    _added: "Acrescentada",
    _deleted: "Eliminada",
    _canceled: "Anulada",
    _minor: "Menor",
    _normal: "Normal",
    _major: "Maior",
    _enabled: "Ativado",
    _disabled: "Desativado",
    _payedHoliday: "Dia de férias pago",
    _unpayedHoliday: "Dia de licença sem vencimento",
    _approved: "Aceite",
    _pending: "Em curso",
    _rejected: "Recusada",
    _cancelationRequest: "Pedido de anulação",
    _absenceRequest: "Pedido de férias",
    _recupStatusConsumed: "Estado de recuperação do consumo",
    _loading: "Carregamento",
    _rejectionMotif: "Motivo da recusa",
    _exceptionalHolidayType: "Tipo de ausência excepcional",
    _home: "Página de início",
    _updateDate: "Data de modificação",
    _recuperation: "Recuperação",
    _requestStatus: "Estatuto do pedido",
    _teleoptiMapping: "Mapeamento teleopti",
    _exceptionalHoliday: "Licença social",
    _exceptionalAbsence: "Ausência excepcional",
    _exceptionalHolidayFr: "Ausência por evento excecional",
    _exceptionalHolidays: "Licença social",
    _publicHoliday: "Dias feriados",
    _rejectionMotifs: "Motivo da recusa",
    _addedRequests: "Pedidos Adicionados",
    _addedRequestsFr: "Pedidos Adicionados France",
    _processedRequests: "Pedidos tratados",
    _pendingRequests: "Pedidos pendentes",
    _childrenRequests: "Férias dos meus colaboradores",
    _allPendingRequests: "Todos os pedidos pendentes",
    _payedCollaboratorsHolidays: "Férias dos meus colaboradores",
    _payedErrorHolidays: "Férias com erro",
    _holidayCancelation: "Anulação de férias",
    _effectifsHolidays: "Férias dos colaboradores",
    _lastUpdate: "Ultima atualização do saldo",
    _exceptionalLeave: "",
    _resulstsFound: "Resultados encontrados: ",
    _csvExport: "Exportação CSV",
    _myUOHolidays: "As férias da minha UO",
    _of: "sobre",
    _publicHolidays: "Dias feriados",
    _myUO: "A minha UO",
    _annualBalance: "Saldo anual",
    _pendingBalance: "Número de dias em espera de exportação 'Saldo Anual'",
    _cumulativeBalance: "Saldo Acumulado",
    _from: "de",
    _to$: "a",
    _employeesBalance: "Saldos dos meus colaboradores",
    _noResultsFound: "Nenhum resultado",
    _2faActivation: "Ativação 2FA",
    _seeker: "Requerente",
    _requestDate: "Data do pedido",
    _holidayType: "Tipo de licença",
    _startDate: "Data de início",
    _returnDate: "Data de regresso",
    _finishDate: "Data de fim",
    _endDate: "Data de fim",
    _duration: "Duração",
    _totalDuration: "Duração total",
    _consumedDuration: "Duração utilizada",
    _partner: "Colaborador",
    _validator: "Validador",
    _backup: "Backup",
    _requestType: "Tipo de pedido",
    _status: "Estatuto",
    _uploadedBy: "Enviado por",
    _fileName: "Nome do arquivo",
    _creationDate: "data de criação",
    _addedForEmp: "Criado por",
    _label: "Redigido",
    _provedByDocuments: "Documento justificativo",
    _country: "País",
    _newHolidayRequest: "Novo pedido de férias",
    _cancel: "anular",
    _confirm: "confirmar",
    _holidayDuration: "Duração das férias",
    _comment: "Comentário",
    _follow: "Acompanhamento",
    _history: "Histórico",
    _absenceDuration: "Duração da ausência",
    _abbreviation: "Abreviatura",
    _allCountries: "Todos os países",
    _criticality: "Critérios",
    _holidayDeletion: "Eliminação das férias",
    _assignedRole: "Função a designar",
    _writeRecuperation: "Introduzir uma recuperação",
    _writeSetting: "Introduzir uma parametragem",
    _writeHoliday: "Introduzir Folga recuperação",
    _teleoptiHolidayType: "Tipo de férias Teleopti",
    _exceptionalHOlidayMapping: "Mapeamento do tipo de ausências excepcionais",
    _deletetion: "Eliminação",
    _recupDeletetion: "Eliminação do tipo de recuperação",
    _mappingTeleoptiDeletionConfirmation:
        "Confirma a eliminação do mapeamento Teleopti ?",
    _publicHolidayDeletionConfirmation:
        "Confirma eliminação do dia feriado ?",
    _recupDeletionConfirmation:
        "Confirma a eliminação da recuperação ?",
    _holidayDeletionConfirmation:
        "Confirma a eliminação do dia de férias seguinte:",
    _holidayCancelationConfirmation:
        "Confirma a anulação do dia de férias seguinte:",
    _commentDetails: "Introduzir mais detalhes sobre a razão do seu pedido",
    _selectHolidayDuration: "Selecionar a duração das férias",
    _reasonOfRequest: "Motivo do pedido",
    _justificationOfAbsences: "Justificações ausências",
    _justificationOfAbsencesFr: "Justificação ausências França",
    _justificationOfCurrentAbsences: "Justificação ausências correntes",
    _exportsManagement: "Gestão das exportações",
    _disconnect: "Desconetar-se",
    _selectCountries: "Selecionar os países",
    _myRequests: "Os meus pedidos",
    _employee: "Colaborador",
    _dayAbsence: "Ausência em dias",
    _hourAbsence: "Ausências horárias",
    _holidayDetails: "Detalhes do pedido",
    _typeRequest: "Tipo de pedido",
    _holidayPeriod: "Periodo das férias",
    _backupValidator: "Validador backup",
    _INTERNAL_SERVER_ERROR:
        "Erro interno do servidor, queira tentar posteriormente",
    _ADDED_FOR_EMPL_NULL: "Deve introduzir um colaborador",
    _EMPTY_LEAVE: "Selecionou um dia de férias que coincide com folga ou feriado",
    _EMP_REQUIRED: `Selecionar por favor o colabrador correspondente`,
    _BACKUP_IS_NULL: "Não foi escolhido o validador backup",
    _CANCEL_HOLIDAY_AFTER_START: "Não é possível anular férias após",
    _CANCEL_HOLIDAY_BY_OTHERS:
        "Só é possível anular férias criadas por si",
    _CANCEL_HOLIDAY_CANCEL: "Pretende anular o pedido de anulação ?",
    _CANCEL_HOLIDAY_EXISTS:
        "Já Existe um pedido de anulação para esse pedido",
    _CANCEL_HOLIDAY_IN_PROCESS: "Só pode anular férias validadas",
    _COUNTRY_EXCEPTIONAL_CONGE_TYPE_NULL: "Deve selecionar um país",
    _COUNTRY_MUST_BE_SET: "Deve selecionar um país antes de continuar.",
    _COUNTRY_REJECTION_REASON: "Deve selecionar um país",
    _CRITICALITY_REJECTION_REASON: "Deve selecionar os critérios",
    _DATES_AFTER:
        "As datas introduzidas devem ser anteriores à data presente",
    _DATES_AFTER_DAY:
        "Regra RH: A data do início das férias deve ser superior à data de amanhã",
    _DATES_AFTER_NOW:
        "Regra RH: A data do início das férias deve ser superior à data de amanhã, na data de hoje",
    _DATES_ARE_NULL: "As datas não foram introduzidas",
    _DATES_IN_PERIOD: "Superposição de datas",
    _DATES_PAST:
        "As datas introduzidas devem ser superiores à data de hoje",
    _DELETE_HOLIDAY_IN_PROCESS:
        "Só pode eliminar as férias não validadas",
    _DELETE_REQUEST:
        "Não é possivel eliminar férias que não foram criadas por si",
    _DIFFERENT_QUOTA_TEAM:
        "O site contém valores diferentes para as cotas de équipa",
    _DURATION_EXCEPTIONAL_CONGE_TYPE_NULL:
        "Deve introduzir uma duração superior a 0",
    _EMPLOYEE_MUST_BE_SET: "Deve selecionar um colaborador antes de continuar.",
    _EXCEPTIONAL_CONGE_TYPE_EXIST:
        "O tipo de ausência excepcional já existe com o mesmo nome:",
    _EXCEPTIONAL_TYPE_NULL: "Deve selcionar um tipo de ausência excepcional",
    _INSUFFICIENT_SOLDE: "dias. O seu saldo é insuficiente",
    _LABEL_EXCEPTIONAL_CONGE_TYPE_NULL: "Deve selecionar uma denominação",
    _LABEL_REJECTION_REASON: "Deve selecionar uma denominação",
    _MAPPING_EXCEPTIONAL_CONGE_TYPE_NULL:
        "Deve efetuar um mapeamento com o HR Access",
    _MAPPING_EXCEPTIONAL_TYPE_TELEOPTI:
        "Deve efetuar um mapeamento com Teleopti",
    _MAPPING_EXIST_TYPE_TELEOPTI: "Este mapeamento já existe com Teleopti",
    _NO_BACKUP_VALIDATOR: "Deve especificar um validador backup para:",
    _PUBLIC_HOLIDAY_EXISTS:
        "Já existe um dia feriado com as mesmas datas par o mesmo país",
    _PUBLIC_HOLIDAY_OVERLAP: "Existe uma superposição dos dias feriados",
    _RECUP_DURATION_INSUFFICIENT:
        "Os dias de recuperação que selecionou ultrapassam a duração da recuperação: ",
    _RECUP_EXISTS:
        "Já existe uma recuperação com as mesmas datas para este colaborador",
    _RECUP_MONTH:
        "Os dias de recuperação não podem ser utilizados após um mês",
    _RECUP_NOT_EXIST: "Não existe recuperação",
    _RECUP_NOT_VALID: "A data introduzida não é nem uma folga nem um feriado",
    _RECUP_REQUEST_MONTH:
        "A recupração não pode ser acrescentada após um mês",
    _RECUP_REQUEST_NOT_VALID:
        "As datas escolhidas não são dias úteis",
    _REJECTION_REASON_EXIST: "O motivo da recusa já existe com o mesmo nome:",
    _REJECT_REQUEST_REASON: "Deve introduzir o motivo da recusa",
    _REQUEST_DELETED: "Estas férias já foram eliminadas.",
    _REQUIRED_FIELDS:
        "Todos os campos devem estar preenchidos antes de registar o seu pedido",
    _SAME_DATES_EXCEPTION: "Já existe um pedido de férias com as mesmas datas",
    _SCENARIO_MUST_BE_SET: "É necessário definir um contexto antes de continuar.",
    _SITE_NOT_FOUND: "O site não está configurado na base dados",
    _SOLDE_ESTIMATED_INSUFFICIENT:
        "o seu saldo estimado ultrapassa o seu saldo HR, não pode submeter pedidos de folgas",
    _START_DATE_EXCEED_YEAR: "A data de início do pedido ultrapassa um ano",
    _STATE_MUST_BE_SET:
        "Deve escolher uma mensagem antes de validar o pedido.",
    _STATE_REJECTION_REASON: "Deve escolher um estado",
    _SYSTEM_REJECT_REQUEST: "Pedido rejeitado automaticamente pelo sistema",
    _TEAM_NOT_FOUND: "O site não está configurado na base dados",
    _TEAM_NOT_SET: "Equipa não está configurada para o utilizador",
    _TYPE_MUST_BE_SET: "Deve selecionar um tipo de férias antes de continuar.",
    _UO_NOT_SET: "Site não está configurado para o utilizador",
    _USER_NOT_EXIST: "Não é possível encontrar o colaborador",
    _VALIDATOR_NOT_AVAILABLE:
        "Não é possível aceitar o seu pedido porque não respeita o prazo de D+2 úteis, sem contar com feriados (úteis=Segunda a Sexta). Agradecemos que altere as datas de forma a respeitar o prazo. Obrigada",
    _numbersOfDaysOff: "Número de folgas",
    _leaveDay: "Dia trabalhado",
    _all: "Todos",
    _initializer: "Iniciador",
    _hr: "RH",
    _system: "Sistema",
    _ppv_system: "Sistema (validação WRM automático)",
    _project: "Projeto",
    _days: "Dia(s)",
    _avis: "Perceber",
    _WFM_validation: "Sistema: validação automática de WFM",
    _automatiquement_le: "automaticamente o",
    _reasonOfRejection: "Motivo da recusa",
    _newProposal: "Nova proposta",
    _newProposalProcessed: "A nova Proposta foi processada com sucesso",
    _updatedBy: "Acrescentada por",
    _actif: "Colaborador ativo",
    _unpaidSicknessHoliday: "Ausência por doença não pago",
    _durationRecovery: "Duração recuperação",
    _consumed: "Utilizado",
    _expired: "Expirada",
    _active: "Ativa",
    _yes: "Sim",
    _no: "Não",
    _holidayDetailsMsg: "Este pedido só  fica definitvamente validado após acordo com sua a hierarquia",
    _holidayDetailsMsgDR: "Este pedido só  fica definitvamente validado após acordo com sua a hierarquia & RH",
    _return: "RETORNO",
    _cancelHoliday: "Anulação das férias",
    _removeHoliday: "Eliminação das férias",
    _area: "Zona",
    _starting: "Iinício",
    _end: "Fim",
    _team: "Équipa",
    _marageQuota: "Gestão das Cotas da équipa",
    _geographicalArea: "Zona gerográfica",
    _searchQuota: "Procura Cota",

    _respProcessRequest: "O pedido foi tratado ",
    _respCancelRequest: "O pedido foi anulado ",
    _respDeleteRequestOK: "O pedido foi eliminado ",
    _respDeleteRequestKO: "Não é possível eliminar um pedido pendente de validação ",
    _respDeleteRequestRecupOK: "O pedido foi eliminado ",
    _respDeleteRequestRecupKO: "Não pode eliminar esta Recuperação ",
    _withoutPayHoliday: "Licença sem vencimento ",
    _edit: "modificar",
    _create: "criar",
    _withSuccess: "com sucesso ",
    _respDeleteRejectionReason: "Motivo da recusa eliminado com sucesso ",
    _respEditExceptionalHoliday:"A licença social foi alterada com sucesso ",
    _respAddExceptionalHoliday: "A licença social foi adicionada com sucesso ",
    _respDeleteExceptionalHoliday: "A licença social foi eliminada com sucesso ",
    _respAddPublicHoliday: "O feriado foi adicionado com sucesso ",
    _respEditPublicHoliday: "O feriado foi alterado com sucesso ",
    _respDeletePublicHoliday: "O feriado foi eliminado com sucesso ",
    _respSetQuota: "A cota foi modificada com sucesso ",
    _SUCCESS_DOWNLOAD: "O arquivo foi baixado com sucesso ",
    _respAddHolidayRequest: "o seu pedido foi acrescentado ",
    _respInMassValidation: "validação em massa efetuada ",
    _respCancelRequestRH: "Anulação do pedido efetuado ",
    _respAddHolidayRecup: "o seu pedido foi enviado ",
    _respFlagExportedOK: "O pedido foi exportado ",
    _respFlagExportedKO: "Erro aquando da exportação do pedido ",
    _respExportToHrOk: "O pedido foi exportado ",
    _respExportToHrKO: "Erro aquando da exportação do pedido ",
    _respJustifyAbsence: "Ausência justificada com sucesso ",
    _lastDay: "ULTÍMO DIA",
    _unitDay: "UNIDADE DIA ",
    _hraServer: "Instância HRACCESS",
    _validateur: "Validador",
    _validationHorsCompEquipe: "Validação fora composição da équipa",
    _rttBalance : "Saldo RTT",
    _ancienneteBalance: "Saldo de antiguidade",
    _pendingRtt: "Número de dias a espera de exportação 'RTT'",
    _pendingAnciennete: "Número de dias a espera de exprtação 'Antiguidade'",
    _rttHoliday: "Férias RTT",
    _ancienneteHoliday: "Férias por antiguidade",
    _am_label: "Tarde",
    _pm_label: "Manhã",
    _childrenRequestsCountry: "Férias dos colaboradores do país",
    _timeTrajet: "Tempo de trajeto",
    _timeDelegation: "Registro de tempos",
    _CASUAL_LEAVE_TIME: "licença ocasional de 2 dias",
    _respSaveTrajet: "Trajeto salvo com sucesso",
    _timeTrajetIn: "Registro de tempo de trajeto",
    _DURRATION_OF_HOLIDAY: "A duração das férias é de {} dia(s).",
    _REQUIRED_DOCS: "Os documentos necessários ao retornar são: {}\n\n",
    _OPINION_MANAGER: "Opinião do gerente:",
    _OPINION_WFM: "Opinião do sistema WFM: ",
    _OPINION_SYSTEM: "Opinião do sistema: ",
    _OPINION_RH: "Opinião do RH:",
    _REASON_FOR_REFUSAL: "Motivo de recusa: ",
    _hold: "Em espera",
    _motif: "Modificado:",
    _changeQuota: "Alterar quota",
    _importQuota: "Importar quota",
    _generateQuota: "Gerar relatório de quota",
    _QUOTA_NO_CONFIGURED: "A quota não está configurada para o período selecionado",
    _TEAM_LABEL: "Equipes",
    _LABEL_MORNING_AFTERNON: "(manhã: {} / tarde: {})",
    _REPORT_SEND_MAIL: "O relatório foi enviado por e-mail.",
    _ERREUR: "Ocorreu um erro",
    _IMPORT_FILE_QUOTA: "Importando arquivo de quota",
    _ERREUR_ON_IMPORT: "Erro ao importar arquivo",
    _QUOTA: "Quota em %",
    _MAX: "Quota em número",
    _TOTAL: "Total validado e aguardando validação da gerência (exceto licença-maternidade)",
    _PL: "PL",
    _UL: "CSS",
    _EL: "CE",
    _REST: "Restante",
    _START_DATE: "Data de início:",
    _END_DATE: "Data de retorno:",
    _FILING_DATE: "Data de envio",
    _REFRESH_QUOTA: "Atualizar quota",
    _HOLIDAY_APPROVED_ALL: {label: "Férias aprovadas (todos)", title: "Opinião WFM: OK\nDecisão N+1/RH: OK"},
    _HOLIDAY_APPROVED_N1: {label: "Férias aprovadas (N+1/RH)", title: "Opinião WFM: KO\nDecisão N+1/RH: OK"},
    _LEAD_TEAM_HOLIDAY: {label: "Férias do líder de equipe", title: ""},
    _HOLIDAY_REFUSED_ALL: {label: "Férias recusadas (todos)", title: "Opinião WFM: KO\nDecisão N+1/RH: KO"},
    _HOLIDAY_REFUSED_N1: {label: "Férias recusadas (N+1/RH)", title: "Opinião WFM: OK\nDecisão N+1/RH: KO"},
    _SOCIAL_APPROVED_ALL: {label: "Férias sociais aprovadas (todos)", title: "Opinião WFM: OK\nDecisão N+1/RH: OK"},
    _SOCIAL_PENDING: {label: "Solicitação social pendente", title: ""},
    _SOCIAL_REFUSED_ALL: {label: "Férias sociais recusadas (todos)", title: "Opinião WFM: KO\nDecisão N+1/RH: KO"},
    _UNPAID_SOCIAL_REFUSED_ALL_OK: {label: "Férias não remuneradas aprovadas (todos)", title: "Opinião WFM: OK\nDecisão N+1/RH: OK"},
    _UNPAID_HOLIDAY_APPROVED_N1_HR: {label: "Férias não remuneradas aprovadas (N+1/RH)", title: "Opinião WFM: KO\nDecisão N+1/RH: OK"},
    _PENDING_REQUEST: {label: "Solicitação pendente", title: ""},
    _UNPAID_SOCIAL_REFUSED_ALL_KO: {label: "Férias não remuneradas recusadas (todos)", title: "Opinião WFM: KO\nDecisão N+1/RH: KO"},
    _UNPAID_SOCIAL_REFUSED_N1_HR: {label: "Férias não remuneradas recusadas (N+1/RH)", title: "Opinião WFM: OK\nDecisão N+1/RH: KO"},
    _LABEL_WEEK: "Semana",
    _casualBalance : "Saldo de licença ocasional",
    _casualHoliday : "Licença ocasional",
    _Quota_available : "Quota disponível",
    _Quota_reached : "Quota atingida",
    _Quota_not_set : "Quota não configurada",
    _Quota_almost_reached: "Quota quase alcançada",
    _MAX_DURATION_FOR_CASUAL_LEAVE: "Você excedeu a duração (2 dias) permitida para uma solicitação desse tipo de licença",
    _INSUFFICIENT_SOLDE__CASUAL_LEAVE: "Seu saldo é insuficiente",
    _APPROUVE_VALIDATION: "Você confirma a validação de todas as solicitações selecionadas?",
    _VALIDATION_MASSE: "Validação em massa",
    _errorSplitAbsence: "Erro ao dividir a ausência",
    _respSplitAbsence: "A ausência foi dividida com sucesso",
    _errorSaveSplitAbsence: "Erro ao salvar a divisão da ausência",
    _Date_concernee : "Date concernée",
    _MotifEntryTime : "Motif",
    _extractHolidays: "Extraire les congés",
    _exportHRA : "Export HRA",
    _enteredBy : "Saisi par",
    _validatedBy : "Validé par",
    _approve: "aceitar",
    _reject: "rejeitar",
    _acceptMsg: "você quer aceitar esta proposta ?",
    _deposit_date : "deposit date",
    _typeOfsocialLeave : "type of social leave"
},
es: {
  _fr_name: "FRANCÉS",
  _en_name: 'INGLÉS',
  _pt_name: 'PORTUGUÉS',
  _es_name: "PORTUGUÉS",
  _lang: "ES",
  _toJustify: "para justificar",
  _firstName: "Nombre",
  _lastName: "Apellido",
  _registrationNumber: "Matrícula",
  _absenceDay: "Fecha de la ausencia",
  _absenceStartHour: "Hora Inicio",
  _absenceEndHour: "Hora fin",
  _type: "Type",
  _justified: "Justificada",
  _added: "Agregada",
  _deleted: "Eliminada",
  _canceled: "Cancelada",
  _minor: "Menor",
  _normal: "Normal",
  _major: "Mayor",
  _enabled: "Activado",
  _disabled: "Desactivado",
  _payedHoliday: "Vacaciones pagadas",
  _unpayedHoliday: "Permiso sin sueldo",
  _approved: "Aceptada",
  _pending: "En curso",
  _rejected: "Denegada",
  _cancelationRequest: "Solicitud de cancelación",
  _absenceRequest: "Solicitud de vacaciones",
  _recupStatusConsumed: "Estado de consumo de la recuperación",
  _loading: "Cargando",
  _rejectionMotif: "Motivo de denegación",
  _exceptionalHolidayType: "Tipo de licencia excepcional",
  _home: "Recepción",
  _updateDate: "Fecha de modificación",
  _recuperation: "Recuperación",
  _requestStatus: "Estado de la solicitud",
  _teleoptiMapping: "Cartografía Teleopti",
  _exceptionalHoliday: "Permiso social",
  _exceptionalAbsence: "Ausencia excepcional",
  _exceptionalHolidayFr: "Ausencia por evento excepcional",
  _exceptionalHolidays: "Permisos sociales",
  _publicHoliday: "Días festivos",
  _rejectionMotifs: "Motivo de denegación",
  _addedRequests: "Solicitudes añadidas",
  _addedRequestsFr: "Solicitudes añadidas France",
  _processedRequests: "Solicitudes procesadas",
  _pendingRequests: "Solicitudes pendientes",
  _childrenRequests: "Vacaciones de mis colaboradores",
  _allPendingRequests: "Todas las solicitudes pendientes",
  _payedCollaboratorsHolidays: "Vacaciones de mis colaboradores",
  _payedErrorHolidays: "Vacaciones en error",
  _holidayCancelation: "Cancelaciones Permisos",
  _effectifsHolidays: "Vacaciones de personal",
  _lastUpdate: "Última actualización del saldo",
  _exceptionalLeave: "",
  _resulstsFound: "Resultados encontrados:",
  _csvExport: "Exportación CSV",
  _myUOHolidays: "Los permisos de mi UO",
  _of: "sobre",
  _publicHolidays: "Días festivos",
  _myUO: "Mi UO",
  _annualBalance: "Saldo anual",
  _pendingBalance: "Número de diás pendiente de exportación 'Saldo Anual'",
  _cumulativeBalance: "Saldo acumulado",
  _from: "Del",
  _to$: "Al",
  _employeesBalance: "Saldos de mis colaboradores",
  _noResultsFound: "Ningún resultado",
  _2faActivation: "Activación 2FA",
  _seeker: "Solicitante",
  _requestDate: "Fecha de la solicitud",
  _holidayType: "Tipo de permiso",
  _startDate: "Fecha de inicio",
  _returnDate: "Fecha de regreso",
  _finishDate: "Fecha de fin",
  _endDate: "Fecha de fin",
  _duration: "Duración",
  _totalDuration: "Duración total",
  _consumedDuration: "Tiempo consumido",
  _partner: "Colaborador",
  _validator: "Validador",
  _backup: "Copia de seguridad",
  _requestType: "Tipo de solicitud",
  _status: "Estado",
  _addedForEmp: "Creado por",
  _label: "Título",
  _provedByDocuments: "Documento acreditativo",
  _country: "País",
  _newHolidayRequest: "Nueva solicitud de permiso",
  _cancel: "cancelar",
  _confirm: "confirmar",
  _holidayDuration: "Duración del permiso",
  _comment: "Comentario",
  _follow: "Seguimiento",
  _history: "Historial",
  _absenceDuration: "Duración de ausencia",
  _abbreviation: "Abreviatura",
  _allCountries: "Todos los países",
  _criticality: "Criticidad",
  _holidayDeletion: "Supresión de las vacaciones",
  _assignedRole: "Función a asignar",
  _writeRecuperation: "Introducir una recuperación",
  _writeSetting: "Introducir un ajuste",
  _writeHoliday: "Introducir Permiso recuperación",
  _teleoptiHolidayType: "Tipo de permiso Teleopti",
  _exceptionalHOlidayMapping: "Mapeo de tipos de excedencias excepcionales",
  _deletetion: "Supresión",
  _recupDeletetion: "Supresión del tipo de recuperación",
  _mappingTeleoptiDeletionConfirmation:
      "Confirma la eliminación de la cartografía Teleopti ?",
  _publicHolidayDeletionConfirmation:
      "Confirma la supresión del día festivo ?",
  _recupDeletionConfirmation:
      "Confirma la eliminación de la recuperación ?",
  _holidayDeletionConfirmation:
      "Confirma la eliminación del permiso siguiente:",
  _holidayCancelationConfirmation:
      "Confirma la cancelación del permiso siguiente: ",
  _commentDetails: "Introduzca más informaciones sobre el motivo de su solicitud",
  _selectHolidayDuration: "Seleccione la duración del permiso",
  _reasonOfRequest: "Motivo del permiso",
  _justificationOfAbsences: "Justificación de ausencias",
  _justificationOfAbsencesFr: "Justificación de ausencias Francia",
  _justificationOfCurrentAbsences: "Justificación de las ausencias corrientes",
  _exportsManagement: "Gestión de las exportaciones",
  _disconnect: "Desconectarse",
  _selectCountries: "Seleccionar los países",
  _myRequests: "Mis solicitudes",
  _employee: "Empleado",
  _dayAbsence: "Ausencia en días",
  _hourAbsence: "Ausencia en horas",
  _holidayDetails: "Detalles de la solicitud",
  _typeRequest: "Tipo de solicitud",
  _holidayPeriod: "Período de vacaciones",
  _backupValidator: "Validador Back-Up",
  _INTERNAL_SERVER_ERROR:
      "Error interno del servidor, inténtelo de nuevo más tarde",
  _ADDED_FOR_EMPL_NULL: "Debe introducir un empleado",
  _EMPTY_LEAVE: "Ha elegido un día de permiso que coincide con un día libre o un día festivo.",
  _EMP_REQUIRED: "Seleccione el empleado correspondiente",
  _BACKUP_IS_NULL: "No se ha escogido el validador Back-Up",
  _CANCEL_HOLIDAY_AFTER_START: "No se puede cancelar un permiso después de",
  _CANCEL_HOLIDAY_BY_OTHERS:
      "Sólo puede cancelar los permisos que haya creado",
  _CANCEL_HOLIDAY_CANCEL: "Desea cancelar una solicitud de cancelación?",
  _CANCEL_HOLIDAY_EXISTS:
      "Ya existe una solicitud de cancelación para esta solicitud",
  _CANCEL_HOLIDAY_IN_PROCESS: "Sólo puede cancelar los permisos validados",
  _COUNTRY_EXCEPTIONAL_CONGE_TYPE_NULL: "Debe elegir un país",
  _COUNTRY_MUST_BE_SET: "Debe elegir un país antes de seguir.",
  _COUNTRY_REJECTION_REASON: "Debe elegir un país",
  _CRITICALITY_REJECTION_REASON: "Debe elegir la criticidad",
  _DATES_AFTER:
      "Las fechas introducidas deben ser anteriores a la fecha actual",
  _DATES_AFTER_DAY:
      "Regla de RRHH: La fecha de inicio de las vacaciones debe ser superior a la fecha de mañana.",
  _DATES_AFTER_NOW:
      "Regla de RRHH: La fecha de inicio de las vacaciones debe ser superior a la de hoy.",
  _DATES_ARE_NULL: "No se han introducido las fechas",
  _DATES_IN_PERIOD: "Superposición de fechas",
  _DATES_PAST:
      "Sólo se pueden eliminar los permisos que no estén validados",
  _DELETE_HOLIDAY_IN_PROCESS:
      "No puede eliminar permisos que no haya creado",
  _DELETE_REQUEST:
      "No puede eliminar permisos que no haya creado",
  _DIFFERENT_QUOTA_TEAM:
      "El sitio tiene diferentes valores para las cuotas de equipo",
  _DURATION_EXCEPTIONAL_CONGE_TYPE_NULL:
      "Debe introducir una duración superior a 0",
  _EMPLOYEE_MUST_BE_SET: "Debe introducir un empleado antes de seguir.",
  _EXCEPTIONAL_CONGE_TYPE_EXIST:
      "El tipo de permiso excepcional ya existe con el mismo nombre:",
  _EXCEPTIONAL_TYPE_NULL: "Debe elegir un tipo de permiso excepcional",
  _INSUFFICIENT_SOLDE: "días, su saldo es insuficiente",
  _LABEL_EXCEPTIONAL_CONGE_TYPE_NULL: "Debe introducir una etiqueta",
  _LABEL_REJECTION_REASON: "Debe introducir una etiqueta",
  _MAPPING_EXCEPTIONAL_CONGE_TYPE_NULL:
      "Debe mapear con HR-Access",
  _MAPPING_EXCEPTIONAL_TYPE_TELEOPTI:
      "Debe mapear con Teleopti",
  _MAPPING_EXIST_TYPE_TELEOPTI: "Esta mapeo ya existe con Teleopti",
  _NO_BACKUP_VALIDATOR: "Debe especificar un validador Back-Up para:",
  _PUBLIC_HOLIDAY_EXISTS:
      "Ya existe un día festivo con las mismas fechas para el mismo país",
  _PUBLIC_HOLIDAY_OVERLAP: "Hay superposición de días festivos",
  _RECUP_DURATION_INSUFFICIENT:
      "El permiso de recuperación que ha introducido supera la duración de la recuperación que es de :",
    _RECUP_EXISTS:
"Ya existe una recuperación con las mismas fechas para este empleado",
    _RECUP_MONTH:
"La licencia de recuperación no puede consumirse después de un mes",
    _RECUP_NOT_EXIST: "La recuperación es inexistente",
    _RECUP_NOT_VALID: "La fecha introducida no es ni día libre ni día festivo ",
    _RECUP_REQUEST_MONTH:
"La recuperación no puede ser añadida después de un mes",
    _RECUP_REQUEST_NOT_VALID:
"Las fechas elegidas no son días laborables",
    _REJECTION_REASON_EXIST: "El motivo de denegación ya existe con el mismo nombre:",
    _REJECT_REQUEST_REASON: "Debe introducir un motivo de denegación",
    _REQUEST_DELETED: "Este permiso ya está eliminado.",
    _REQUIRED_FIELDS:
"Deben rellenarse todos los campos antes de registrar la solicitud",
    _SAME_DATES_EXCEPTION: "Ya existe un permiso con las mismas fechas",
    _SCENARIO_MUST_BE_SET: "Debe introducir un escenario antes de seguir.",
    _SITE_NOT_FOUND: "El sitio no está configurado en el referencial",
    _SOLDE_ESTIMATED_INSUFFICIENT:
"Su saldo estimado supera su saldo de RRHH, no puede presentar solicitudes de permiso.",
    _START_DATE_EXCEED_YEAR: "La fecha de inicio del permiso supera un año",
    _STATE_MUST_BE_SET:
"Debe escoger un dictamen antes de validar la solicitud.",
    _STATE_REJECTION_REASON: "Debe elegir un estado",
    _SYSTEM_REJECT_REQUEST: "Solicitud denegada automáticamente por el sistema",
    _TEAM_NOT_FOUND: "El equipo no está configurado en el referencial",
    _TEAM_NOT_SET: "Equipo no configurado para el usuario",
    _TYPE_MUST_BE_SET: "Debe introducir un tipo de permiso antes de seguir.",
    _UO_NOT_SET: "Sitio no configurado para el usuario",
    _USER_NOT_EXIST: "No se encuentra al empleado",
    _VALIDATOR_NOT_AVAILABLE:
"No podemos tener en cuenta su solicitud ya que ésta no respeta el plazo definido de D+2 trabajados fuera de Días Festivos ( Días laborables = De Lunes a Viernes ). Gracias por cambiar las fechas para respetar este plazo. Gracias",
    _numbersOfDaysOff: "Número de días libres",
    _leaveDay: "Día trabajado",
    _all: "Todos",
    _initializer: "Iniciador",
    _hr: "RH",
    _system: "Sistema",
    _ppv_system: "Sistema (validación automática WFM)",
    _project: "Proyecto",
    _days: "Día(s)",
    _avis: "Aviso",
    _WFM_validation: "Sistema: validación automática WFM",
    _automatiquement_le: "automáticamente el",
    _reasonOfRejection: "Motivo de denegación",
    _newProposal: "Nueva propuesta",
    _newProposalProcessed: "La nueva Propuesta ha sido procesada exitosamente",
    _updatedBy: "Añadido por",
    _actif: "Colaborador activo",
    _unpaidSicknessHoliday: "Enfermedad no retribuida",
    _durationRecovery: "Fecha recuperación",
    _consumed: "Disfrutada",
    _expired: "Vencida",
    _active: "Activa",
    _yes: "Sí",
    _no: "No",
    _holidayDetailsMsg: "Esta solicitud no se valida definitivamente hasta que su superior haya dado su consentimiento",
    _holidayDetailsMsgDR: "Esta solicitud no se valida definitivamente hasta que su superior haya dado su consentimiento & RH",
    _return: "RETORNO",
    _cancelHoliday: "Cancelación de permiso ",
    _removeHoliday: "Eliminación del permiso",
    _area: "Zona",
    _starting: "Salida",
    _end: "Fin",
    _team: "Equipo",
    _marageQuota: "Gestión de Cuotas equipo",
    _geographicalArea: "Zona geográfica",
    _searchQuota: "Búsqueda Cuota",

    _respProcessRequest: "La solicitud ha sido procesada ",
    _respCancelRequest: "La solicitud ha sido cancelada ",
    _respDeleteRequestOK: "La solicitud ha sido eliminada ",
    _respDeleteRequestKO: "No puede suprimir un permiso pendiente de validación ",
    _respDeleteRequestRecupOK: "La solicitud ha sido eliminada ",
    _respDeleteRequestRecupKO: "No puede eliminar esta Recuperación ",
    _withoutPayHoliday: "Permiso sin sueldo ",
    _edit: "modificar",
    _create: "Crear",
    _withSuccess: "con éxito ",
    _respDeleteRejectionReason: "Motivo de denegación eliminado con éxito ",
    _respEditExceptionalHoliday:"El permiso social fue modificado con éxito ",
    _respAddExceptionalHoliday: "El permiso social fue añadido con éxito ",
    _respDeleteExceptionalHoliday: "El permiso social fue eliminado con éxito ",
    _respAddPublicHoliday: "El día festivo fue añadido con éxito ",
    _respEditPublicHoliday: "El días festivo fue modificado con éxito ",
    _respDeletePublicHoliday: "El días festivo fue eliminado con éxito ",
    _respSetQuota: "La cuota ha sido modificada con éxito ",
    _respAddHolidayRequest: "Su solicitud ha sido añadida ",
    _respInMassValidation: "Validación masiva realizada ",
    _respCancelRequestRH: "Cancelación de permiso realizada ",
    _respAddHolidayRecup: "Su solicitud ha sido enviada ",
    _respFlagExportedOK: "La solicitud ha sido exportada ",
    _respFlagExportedKO: "Error al exportar la solicitud ",
    _respExportToHrOk: "La solicitud ha sido exportada ",
    _respExportToHrKO: "Error al exportar la solicitud ",
    _respJustifyAbsence: "Ausencia justificada con éxito ",
    _lastDay: "Último día",
    _unitDay: "Día de la Unidad",
    _hraServer: "Instancia HRACCESS",
    _validateur: "Validador",
    _validationHorsCompEquipe: "Validación ajena a la composición del equipo",
    _rttBalance : "Saldo compensatorios",
    _ancienneteBalance: "Saldo antigüedad ",
    _pendingRtt: "Número de días pendiente de exportación 'Compensatorios'",
    _pendingAnciennete: "Número de días pendiente de exportación 'Antigüedad'",
    _rttHoliday: "Días compensados por horas extras",
    _ancienneteHoliday: "Vacaciones antigüedad",
    _am_label: "Tarde",
    _pm_label: "Mañana",
    _download: "DESCARGAR",
    _stop: "DETENER",
    _childrenRequestsCountry: "Vacaciones de los colaboradores del país",
    _uploadedBy: "Cargado por",
    _fileName: "Nombre de archivo",
    _creationDate: "Fecha de creación",
    _timeTrajet: "Tiempo de trayecto",
    _timeDelegation: "Registro de tiempos",
    _CASUAL_LEAVE_TIME: "permiso ocasional de 2 días",
    _SUCCESS_DOWNLOAD: "El archivo se ha descargado correctamente",
    _respSaveTrajet: "Trayecto guardado correctamente",
    _timeTrajetIn: "Registro de tiempo de trayecto",
    _DURRATION_OF_HOLIDAY: "La duración de las vacaciones es de {} día(s).",
    _REQUIRED_DOCS: "Los documentos requeridos al regresar son: {}\n\n",
    _OPINION_MANAGER: "Opinión del gerente:",
    _OPINION_WFM: "Opinión del sistema WFM: ",
    _OPINION_SYSTEM: "Opinión del sistema: ",
    _OPINION_RH: "Opinión del departamento de recursos humanos:",
    _REASON_FOR_REFUSAL: "Motivo de rechazo: ",
    _hold: "En espera",
    _motif: "Modificado:",
    _changeQuota: "Cambiar cuota",
    _importQuota: "Importar cuota",
    _generateQuota: "Generar informe de cuota",
    _QUOTA_NO_CONFIGURED: "La cuota no está configurada para el período seleccionado",
    _TEAM_LABEL: "Equipos",
    _LABEL_MORNING_AFTERNON: "(mañana: {} / tarde: {})",
    _REPORT_SEND_MAIL: "El informe se ha enviado por correo electrónico.",
    _ERREUR: "Se ha producido un error",
    _IMPORT_FILE_QUOTA: "Importando archivo de cuota",
    _ERREUR_ON_IMPORT: "Se ha producido un error al importar",
    _QUOTA: "Cuota en %",
    _MAX: "Cuota en número",
    _TOTAL: "Total validado y pendiente de validación por la gerencia (excluyendo maternidad)",
    _PL: "PL",
    _UL: "CSS",
    _EL: "CE",
    _REST: "Restante",
    _START_DATE: "Fecha de inicio:",
    _END_DATE: "Fecha de regreso:",
    _FILING_DATE: "Fecha de presentación",
    _REFRESH_QUOTA: "Actualizar cuota",
    _HOLIDAY_APPROVED_ALL: {label: "Vacaciones aprobadas (todos)", title: "Opinión WFM: OK\nDecisión N+1/RH: OK"},
    _HOLIDAY_APPROVED_N1: {label: "Vacaciones aprobadas (N+1/RH)", title: "Opinión WFM: KO\nDecisión N+1/RH: OK"},
    _LEAD_TEAM_HOLIDAY: {label: "Vacaciones del líder de equipo", title: ""},
    _HOLIDAY_REFUSED_ALL: {label: "Vacaciones rechazadas (todos)", title: "Opinión WFM: KO\nDecisión N+1/RH: KO"},
    _HOLIDAY_REFUSED_N1: {label: "Vacaciones rechazadas (N+1/RH)", title: "Opinión WFM: OK\nDecisión N+1/RH: KO"},
    _SOCIAL_APPROVED_ALL: {label: "Vacaciones sociales aprobadas (todos)", title: "Opinión WFM: OK\nDecisión N+1/RH: OK"},
    _SOCIAL_PENDING: {label: "Solicitud social pendiente", title: ""},
    _SOCIAL_REFUSED_ALL: {label: "Vacaciones sociales rechazadas (todos)", title: "Opinión WFM: KO\nDecisión N+1/RH: KO"},
    _UNPAID_SOCIAL_REFUSED_ALL_OK: {label: "Vacaciones sin sueldo aprobadas (todos)", title: "Opinión WFM: OK\nDecisión N+1/RH: OK"},
    _UNPAID_HOLIDAY_APPROVED_N1_HR: {label: "Vacaciones sin sueldo aprobadas (N+1/RH)", title: "Opinión WFM: KO\nDecisión N+1/RH: OK"},
    _PENDING_REQUEST: {label: "Solicitud pendiente", title: ""},
    _UNPAID_SOCIAL_REFUSED_ALL_KO: {label: "Vacaciones sin sueldo rechazadas (todos)", title: "Opinión WFM: KO\nDecisión N+1/RH: KO"},
    _UNPAID_SOCIAL_REFUSED_N1_HR: {label: "Vacaciones sin sueldo rechazadas (N+1/RH)", title: "Opinión WFM: OK\nDecisión N+1/RH: KO"},
    _LABEL_WEEK: "Semana",
    _casualBalance : "Saldo de permiso ocasional",
    _casualHoliday : "Permiso ocasional",
    _Quota_available : "Cuota disponible",
    _Quota_reached : "Cuota alcanzada",
    _Quota_not_set : "Cuota no establecida",
    _Quota_almost_reached: "Cuota casi alcanzada",
    _MAX_DURATION_FOR_CASUAL_LEAVE : "Ha superado la duración permitida (2 días) para una solicitud de este permiso",
    _INSUFFICIENT_SOLDE__CASUAL_LEAVE :"Saldo insuficiente",
    _APPROUVE_VALIDATION : "¿Confirma la validación de todas las solicitudes seleccionadas?",
    _VALIDATION_MASSE : "Validación masiva",
    _errorSplitAbsence: "Error al dividir la ausencia",
    _respSplitAbsence: "La ausencia se ha dividido correctamente",
    _errorSaveSplitAbsence : "Error al guardar la división de la ausencia",
    _Date_concernee : "Date concernée",
    _MotifEntryTime : "Motif",
    _extractHolidays: "Extraire les congés",
    _exportHRA : "Export HRA",
    _enteredBy : "Saisi par",
    _validatedBy : "Validé par",
    _approve: "aceptar",
    _reject: "rechazar",
    _acceptMsg: "quieres aceptar esta propuesta ?",
    _deposit_date : "deposit date",
    _typeOfsocialLeave : "type of social leave"
}
};
export let lang = ['fr', 'en', 'pt'].includes(sessionStorage.getItem("language")) ? sessionStorage.getItem("language") : 'fr';
export let strings=stringsLangs[lang];
export function setLanguage(codeLang) {
    if(stringsLangs[codeLang] != null){
        strings =stringsLangs[codeLang];
        return stringsLangs[codeLang];
    }else{
        strings =stringsLangs.fr;
        return stringsLangs.fr;
    }
}
