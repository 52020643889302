import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {DataTypeProvider, IntegratedFiltering, IntegratedSorting, SortingState,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CancelIcon from '@material-ui/icons/Cancel'
import ConsultIcon from '@material-ui/icons/Visibility'
import {store} from "../../../store/ConfigureStore";
import {
    getMyRequestsFollow,
    getMyRequestsHistory,
    processNewRequestProposal,
    showConfirmationDialog,
    showRequestDetails
} from "../../../actions/RequestsActions";
import MGrid from '@material-ui/core/Grid'
import {CustomEmptyTable, CustomEmptyTableLoading, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import {
    getHolidayType,
    RequestStatusFormatter,
    getRequestType,
    FilterCell,
    compareDates,
    compareDateTimes,
    IdEditor, RequestTypeProvider, HolidayTypeProvider, endDatePredicate, startDatePredicate, noPredicate
} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {FilteringState} from "@devexpress/dx-react-grid";
import {TableFilterRow} from "@devexpress/dx-react-grid-material-ui";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";
import moment from 'moment';
import { AppBar, Button, Dialog, DialogActions, DialogContent, Toolbar, Typography } from '@material-ui/core';
import { connect } from 'react-redux';




const IdFormatter = ({value}) => {
    const {id} = value;
    return (
        <MGrid container spacing={0}>
            <MGrid item>
                <IconButton onClick={() => {
                    store.dispatch(getMyRequestsHistory(id));
                    store.dispatch(getMyRequestsFollow(id));
                    store.dispatch(showRequestDetails({open: true}));
                }
                } color="default" aria-label="add">
                    <ConsultIcon fontSize="small"/>
                </IconButton>
            </MGrid>
            <MGrid item>
                <IconButton  onClick={() => {
                    store.dispatch(showConfirmationDialog({type: "CANCEL", id: id, open: true}))
                }} aria-label="Cancel">
                    <CancelIcon fontSize="small"/>
                </IconButton>
            </MGrid>
            <MGrid item>
                <IconButton onClick={() => {

                    store.dispatch(showConfirmationDialog({type: "DELETE", id: id, open: true}))
                }} aria-label="Delete">
                    <DeleteIcon fontSize="small"/>
                </IconButton>
            </MGrid>
            {/*<MGrid item>*/}
                {/*<IconButton disabled={value.status != "ACCEPTED"} onClick={() => {*/}

                {/*}} aria-label="Delete">*/}
                    {/*<PictureAsPdfIcon/>*/}
                {/*</IconButton>*/}
            {/*</MGrid>*/}
        </MGrid>
    )
};

const RequestStatusProvider = props => (
    <DataTypeProvider
        formatterComponent={RequestStatusFormatter}
        {...props}
    />
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


export class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableColumnExtensions: [
                {columnName: 'id', width: 190 },
                {columnName: 'duration',  width: 80, wordWrapEnabled: true },
                {columnName: 'startDate',  wordWrapEnabled: true },
                {columnName: 'returnDate',  wordWrapEnabled: true },
                {columnName: 'totalDuration', width: 80,  wordWrapEnabled: true },
                {columnName: 'status',  wordWrapEnabled: true },
                {columnName: 'typeRequest',  wordWrapEnabled: true  },
                {columnName: 'typeConge', width: 110,  wordWrapEnabled: true  },
                {columnName: 'updateDate',  wordWrapEnabled: true },
            ],
            integratedFilteringColumnExtensions: [
                { columnName: 'startDate', predicate: startDatePredicate },
                { columnName: 'returnDate', predicate: endDatePredicate },
                { columnName: 'typeConge', predicate: noPredicate }
            ],
            integratedSortingColumnExtensions: [
                { columnName: 'startDate', compare: compareDates },
                { columnName: 'returnDate', compare: compareDates },
                { columnName: 'updateDate', compare: compareDateTimes }
            ],
            exportColumns: ['status'],
            idColumns: ['id'],
            requestTypeColumns: ['typeRequest'],
            holidayTypeColumns: ['typeConge'],
            isProposalDialogOpen: false,
            newRequestProposal: null,
        };
    }

    render() {
        const {integratedSortingColumnExtensions, exportColumns, idColumns, tableColumnExtensions,integratedFilteringColumnExtensions, holidayTypeColumns, requestTypeColumns} = this.state;
        const {references: {configUser}} = store.getState();
        const {_status, _holidayType, _requestType, _startDate, _returnDate, _updateDate, _duration, _totalDuration,_consumedDuration, _newProposal, _approve, _reject, _cancel, _acceptMsg} = strings;

        const rows = this.props.data.map( row => {
            const {duration, totalDuration, startDate, returnDate, exceptionalType, typeConge, newRequestProposalVO} = row;
            var a = moment(startDate, "DD/MM/YYYY");
            var b = moment(returnDate, "DD/MM/YYYY");
            if(typeConge === "EXCEPTIONALHOLIDAY") {
                return {...row, duration: totalDuration, totalDuration: b.diff(a, 'days'), typeConge: {type: row.typeConge, label: exceptionalType ? exceptionalType.label: getHolidayType(row.typeConge)}}
            } else {
                return {
                    ...row,
                    duration: totalDuration,
                    status: (this.props.isMyRequests === true && newRequestProposalVO != null) ? (
                            <Button color="secondary" size="small"
                              onClick={() => {
                                this.setState({newRequestProposal: newRequestProposalVO});
                                this.setState({isProposalDialogOpen: true});
                              }}
                            >
                                {_newProposal}
                            </Button>
                        ) : row.status,
                    totalDuration:  b.diff(a, 'days')
                }
            }
        });
        const {loading, searchActive} = this.props;
        const columns = [
            {name: 'updateDate', title: _updateDate},
            {name: 'typeConge', title: _holidayType},
            {name: 'typeRequest', title: _requestType},
            {name: 'startDate', title: _startDate},
            {name: 'returnDate', title: _returnDate},
            {name: 'duration', title: _consumedDuration},
            // {name: 'totalDuration', title: _totalDuration},
            {name: 'status', title: _status},
            {name: 'id', title: ' '}
        ];
        return (
            <Fragment>
                <Paper style={tableSize}>
                    <Grid
                        rows={rows.map(row => {
                            return {...row, id : row};
                        })}
                        columns={columns}
                        style={tableSize}
                    >

                        <RequestStatusProvider
                            for={exportColumns}
                        />
                        <IdProvider
                            for={idColumns}
                        />
                        <RequestTypeProvider
                            for={requestTypeColumns}
                        />
                        <HolidayTypeProvider
                            for={holidayTypeColumns}
                        />

                        <SortingState
                            defaultSorting={[{columnName: 'requestDate', direction: 'asc'}]}
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        <FilteringState
                        onFiltersChange={(filters) => {this.props.changeFilters(filters)}}
                        />
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />
                        <Table
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => { return <CustomEmptyTable loading={loading}/>}}
                            columnExtensions={tableColumnExtensions}/>
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}
                    </Grid>

                    <Dialog
                        open={this.state.isProposalDialogOpen}
                        onClose={() => { this.setState({isProposalDialogOpen: false}) }}
                    >
                        <AppBar position="static" color="primary" elevation={0}>
                            <Toolbar>
                                <Typography variant="h6" color="inherit">
                                    {_newProposal}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent style={{marginTop: 30}}>
                            <Typography variant="subtitle1" color="inherit">
                                {this.state.newRequestProposal != null && (
                                    `${_newProposal} : from ${this.state.newRequestProposal.startD} to ${this.state.newRequestProposal.returnD}`
                                )}
                            </Typography>
                            <Typography variant="subtitle1" color="inherit">
                                {_acceptMsg}
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button color="primary" onClick={() => {
                                this.setState({isProposalDialogOpen: false})
                            }}>
                                {_cancel}
                            </Button>
                            <Button color="primary" onClick={() => {
                                this.props.processNewRequestProposal(this.state.newRequestProposal.id, false);
                                this.setState({isProposalDialogOpen: false})
                            }}>
                                {_reject}
                            </Button>
                            <Button color="primary" onClick={() => {
                                this.props.processNewRequestProposal(this.state.newRequestProposal.id, true);
                                this.setState({isProposalDialogOpen: false})
                            }} >
                                {_approve}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Fragment>
        );
    }
}

const mapStateTopProps = state => {
};

let mapActionsToProps = {processNewRequestProposal};

export default connect(mapStateTopProps, mapActionsToProps)(GlobalGrid);
