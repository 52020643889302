import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';
import {store} from "../../../../../store/ConfigureStore";
import {flagExported} from "../../../../../actions/ExportHrActions";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        background: theme.palette.background.paper,
    },
    dialog: {
        width: '80%',
        maxHeight: 300
    },
});


class ExportRequest extends React.Component {
    state = {
        comment: "",
    };


    handleChange = (event, name) => {
        this.setState({[name]: event.target.value})
    };

    handleSubmit = () => {
        store.dispatch(flagExported(String(this.props.requestId), this.state.comment))
    };

    render() {
        const {classes} = this.props;
        const {comment} = this.state;
        return (
            <div>
                <Dialog
                    classes={{
                        paper: classes.dialog,
                    }}
                    open={true}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Export de la demande</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="comment"
                            multiline
                            rows="4"
                            placeholder="saisir un commentaire"
                            value={comment}
                            onChange={(event) => {
                                this.handleChange(event, "comment")
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.props.handleDialogOpen(null)
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.handleDialogOpen(null);
                            this.handleSubmit();
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ExportRequest);
