import React, {Component} from 'react';
import CustomTableToolBar from '../../../../utilities/CustomTableToolBar'
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import Grid from "./Grid";
import {getAbsenceByDay} from "../../../../actions/AbsenceActions";
import {Subject, timer} from "rxjs";
import {debounce, skip} from "rxjs/operators";
import Assignment from "@material-ui/icons/Assignment";
import Button from "@material-ui/core/Button";
import ExportDialog from "./ExportDialog";

const styles = theme => ({});


class PerDayAbsence extends Component {
    searchSubject = new Subject();
    state = {
        filters: [],
        sort: "",
        exportIsOpen: false
    };

    changeFilters = (filters) => {

        this.setState({
            filters: filters.reduce(function (obj, item) {
                obj[item.columnName] = item.value;
                return obj;
            }, {})
        }, () => {
            const {filters, sort} = this.state;
            const {
                justified,
                matricule,
                $start
            } = filters;
            let {startDate, endDate} = $start;
            endDate = endDate.replace("Invalid date", "")
            endDate = endDate.replace("undefined", "")
            this.searchSubject.next({page: 0, size: 25, justified, matricule, startDate, endDate})
        })
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getAbsenceByDay(page - 1, size)
    };

    componentDidMount() {
        this.props.getAbsenceByDay(0, 25);
        this.searchSubject.pipe(
            skip(1),
            debounce(() => timer(1000)),
        ).subscribe((searchEvent) => {
            const {page, size, justified, matricule, startDate, endDate} = searchEvent;
            this.props.getAbsenceByDay(page, size, justified, matricule, startDate, endDate)
        })
    }

    componentWillUnmount() {
        this.searchSubject.unsubscribe();
    }

    toggleExport () {
        this.setState({exportIsOpen: !this.state.exportIsOpen})
    }

    render() {
        const {exportIsOpen} = this.state;
        const {absence, auth} = this.props;
        const {dayAbsence} = absence
        const {loading, data, totalElements} = dayAbsence;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={(page, size) => {
                        this.changePaging(page, size)
                    }}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                    {exportIsOpen && <ExportDialog exportIsOpen={exportIsOpen} rows={data} toggleExport={() => {this.toggleExport()}}/>}

                    <Button id='fab-1'
                            title={"Rapport des absences non justifiées"}
                            onClick={() => {
                                this.props.getAbsenceByDay(0, totalElements)
                                this.toggleExport();
                            }}
                            color={"primary"}
                            variant={"fab"}>
                        <Assignment/>
                    </Button>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {auth: state.auth, absence: state.absence}
};

let mapActionsToProps = {
    getAbsenceByDay
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(PerDayAbsence));

