import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {store} from "../../../../store/ConfigureStore";
import DialogActions from "@material-ui/core/DialogActions";
import {showTimeEntryDetails} from "../../../../actions/AbsenceActions";
import pink from "@material-ui/core/colors/pink";
import {getTimeEntryType} from "../../../../utilities/MappingFunctions";
import {strings} from "../../../../i18n/Strings";


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function statusFormatter (value) {
    if (value === "CANCELED") {
        return <span style={{fontWeight: "bold", color: "Red"}}>Annulé</span>
    }
    if (value === "PUSHED") {
        return <span style={{fontWeight: "bold", color: "Green"}}>Saisi</span>
    }
}

function formateDate (value){
    if (value){
        return value.replace("T", " ");
    }
    return "";
}
const styles = theme => {
    return {
        dialogPaper: {
            height: 'auto',
            maxWidth: 900,
            minWidth: 900,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

class ConfirmationDialog extends React.Component {

    render() {
        const { _absenceEndHour, _absenceStartHour, _requestStatus, _Date_concernee, _MotifEntryTime } = strings;
        const {showAbsenceDetails} = this.props.absences;
        const {classes} = this.props;
        const {open, status, data} = showAbsenceDetails;
        const { date, start, end, type, registrationNumber,firstName, lastName,
            motif,createdAt,createdBy, canceled,deletedAt,deletedBy,exported  } = data;
        return (
            <div style={{position: "relative"}}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="#"
                    aria-describedby="#"
                    classes={{paper: classes.dialogPaper}}
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                Récapitulatif de la demande
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid container direction={"row"} spacing={32}>
                            <Grid item sm={12}>
                                <Grid
                                    container
                                    style={{ paddingTop: 20, paddingLeft: 40 }}
                                    direction={"column"}
                                    justify={"center"}
                                ><Grid item>
                                        <Typography variant="body2" gutterBottom>
                                                  <span style={{ fontWeight: "bold" }}>
                                                    {"Matricule / nom et prénom du collaborateur : "}
                                                  </span>
                                            {registrationNumber + " (" + firstName + " " + lastName + " )"}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" gutterBottom>
                                            <span style={{ fontWeight: "bold" }}>
                                                {"Date de la saisie de la demande : "}
                                            </span>
                                            {formateDate(createdAt)}
                                            <span style={{ fontWeight: "bold" }}>
                                                {" Par : "}
                                            </span>
                                            {createdBy != null  ? createdBy.registrationNumber + " (" + createdBy.firstName + " " + createdBy.lastName + " )" : ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" gutterBottom>
                                            <span style={{ fontWeight: "bold" }}>{_MotifEntryTime} : </span>
                                            {getTimeEntryType(type)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" gutterBottom>
                                                  <span style={{ fontWeight: "bold" }}>
                                                    {_Date_concernee} :{" "}
                                                  </span>
                                            {date}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" gutterBottom>
                                            <span style={{ fontWeight: "bold" }}>
                                                        {_absenceStartHour} :{" "}
                                            </span>
                                            {start + " "}
                                            <span style={{ fontWeight: "bold" }}>
                                                        {_absenceEndHour} : {" "}
                                            </span>
                                            {end}
                                        </Typography>
                                    </Grid>
                                    <div hidden={canceled}>
                                        <Grid item>
                                            <Typography variant="body2" gutterBottom>
                                                <span style={{ fontWeight: "bold" }}>
                                                    {_requestStatus} : {statusFormatter("PUSHED")}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </div>
                                    <div hidden={!canceled}>
                                        <Grid item>
                                            <Typography variant="body2" gutterBottom>
                                                <span style={{ fontWeight: "bold" }}>
                                                    {"Date d'annulation : "}
                                                </span>
                                                    {formateDate(deletedAt)}
                                                    <span style={{ fontWeight: "bold" }}>
                                                    {" Par : "}
                                                </span>
                                                {deletedBy != null  ? deletedBy.registrationNumber + " (" + deletedBy.firstName + " " + deletedBy.lastName + " )" : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" gutterBottom>
                                                        <span style={{ fontWeight: "bold" }}>
                                                            {_requestStatus} :
                                                        </span>
                                                {statusFormatter("CANCELED")}
                                            </Typography>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            store.dispatch(showTimeEntryDetails({type: "CANCEL", data: data, open: false}))
                        }} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>

                </Dialog>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        absences: state.absence
    }
};


export default withStyles(styles)(connect(mapStateToProps, null)(ConfirmationDialog));
