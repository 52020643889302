export const qoutasConge = [

]
export const qoutas = [
    {
        code: 'Quota',
        values: [],
        class: 'Quota color1'
    },
    {
        code: 'Max',
        values: [

        ],
        class: 'Quota color2'
    },
    {
        code: 'Total',
        values: [

        ],
        class: 'success total color3'
    },      {
        code: 'CP',
        values: [

        ],
        class: 'success byTotal'
    },      {
        code: 'CSS',
        values: [

        ],
        class: 'success byTotal'
    },      {
        code: 'CE',
        values: [

        ],
        class: 'success byTotal'
    },{
        code: 'Reste',
        values: [

        ],
        class: 'worng color4'
    },
];
export const teams = [
]
