export const HALF_DAY = 'H';
export const FULL_DAY = 'D';
export const LAST_DATE = 'L';
export const RETURN_DATE = 'R';
export const MIN_FR_REG_LENGTH = 8;
export const ACTIVE_COUNTRIES = [
    { id: 1, label: "Maroc" },
    { id: 2, label: "Senegal" },
    { id: 3, label: "Cameroun" },
    { id: 5, label: "France" },
    { id: 8, label: "Cote d'Ivoire" },
    { id: 140, label: "Madagascar" },
    { id: 148, label: "Maurice" },
    { id: 18, label: "Portugal"},
    { id: 121, label: "Jamaïque"}
];
export const LAST_DAY = [
    { id: 'L', label: "Last Day" },
    { id: 'R', label: "Return Day" },
];
export const UNIT_DAY = [
    { id: 'D', label: "Day" },
    { id: 'H', label: "Half day" },
];
export const VALIDATORS = ['TL', 'OPS', 'BUM', 'PPV'];
export const VALIDATORS_HORS_COMP_EQUIPE = ['TL', 'OPS', 'BUM', 'PPV'];

export const settingsMenuAllowedUsernames = ['047726', '047713', '047728'];