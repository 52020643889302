import React, { Component } from "react";
import ReactExport from "react-data-export";
import Button from "@material-ui/core/Button";
import { getHolidayType, states } from "../../../utilities/MappingFunctions";
import { getAxios } from "../../../actions/AuthActions";
import { API,ROLE_ADMIN_COUNTRY,COUNTRY_SUB_REQUESTS } from "../../../api/Endpoints";
import SubRequests from "@material-ui/icons/ListAltOutlined";
import moment from "moment";
import {hasAuthority, hasPermission} from "../../../actions/AuthActions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default class ExportChildrenSubRequests extends Component {
  state = {
    dataToExport: [{ columns: [""], data: [{ value: "" }] }]
  };
apiToCall(){
  let apiUri;
  if (hasAuthority("ROLE_ADMIN_COUNTRY")){
    apiUri = `${COUNTRY_SUB_REQUESTS}`;
  }
  return apiUri;
}
  componentDidMount() {
    this.handleClick();
  }

  handleClick = () => {
    const {
        addedForEmp,
        startDate,
        returnDate,
        status,
        typeConge
    } = this.props.filters;
    getAxios().post(`${API}/`+this.apiToCall(), {
    //getAxios().post(`http://localhost:8081/conges/request/filter/countrySubRequests`, {
        "registrationNumber": addedForEmp,
        "startDate": startDate,
        "endDate": returnDate,
        "status": status,
        "typeConge": typeConge,
        "sort": null,
        "order": false,
        "page": 0,
        "size": 100000
      })
      .then(res => {
        let dataToExport = res.data.payload
          //.filter(h => h.typeRequest === "REQUEST")
          .map(h => {
            const {
                requester,
                startDate,
                endDate,
                reason,
                exported,
                errorMessage
            } = h;
            return {
              matricule: h.requester.registrationNumber,
              Prénom: h.requester.firstName,
              Nom: h.requester.lastName,
              //"Type Congé": getHolidayType(typeConge),
              "Date départ": startDate,
              "Date retour": endDate,
              //status: states[h.status],
              //Durée: duration,
              //"Durée totale": totalDuration,
              //"Date de dépôt": updateDate,
              "Export HRA": exported,
              "Type" : reason,
              "Message d'erreur" : errorMessage,

            };
          });
        this.setState({ dataToExport });
      }); 
  }
  render() {
    const { dataToExport } = this.state;
    return (
      <div>
        <ExcelFile
          filename={`Extraction ${moment().format("DD/MM/YYYY hh:mm:ss")}`}
          element={
            <Button
              title={"Extraire les sous congés"}
              id={"fab_cong"}
              color={"primary"}
              variant={"fab"}
              onClick={() => this.handleClick()}

            >
              <SubRequests />
            </Button>
          }
        >
          <ExcelSheet
            dataSet={[
              {
                columns: Object.keys(dataToExport[0] || {}),
                data: (dataToExport ? dataToExport : []).map(o => {
                  return Object.values(o).map(e => {
                    return { value: e };
                  });
                })
              }
            ]}
            name={`username~`}
          />
        </ExcelFile>
      </div>


    );
  }
}
