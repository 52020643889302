import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Selects from "./Selects";
import Indicator from "../../holidays/addHolidayRequest/Indicator";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from "react-redux";
import {addHolidayRequest} from "../../../../actions/HolidaysActions";
import {setShowAddRequest} from "../../../../actions/RequestsActions";
import {getDaysOffSuccess} from "../../../../actions/ReferencesActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {strings} from "../../../../i18n/Strings";

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    dialogPaper: {
        height: "auto",
        // maxHeight: '70vh',
        maxWidth: '95vw',
    },
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AddRequestDialog extends React.Component {
    state = {
        request: {}
    };

    handleRequestChange = request => {
        this.setState({request})
    };

    render() {
        const {classes,addedClient, addHolidayRequest, requests, auth} = this.props;
        const {showAddRequest} = requests;
        const {open, canSubmit} = showAddRequest;
        const {request} = this.state;
        const {loading} = auth;
        const {_newHolidayRequest, _confirm, _cancel} = strings;
        return (
            <>
                <Dialog
                    classes={{paper: classes.dialogPaper}}
                    open={open}
                    onClose={() => {
                    }}
                    TransitionComponent={Transition}
                >
                    <AppBar
                        elevation={0}
                        className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {_newHolidayRequest}
                            </Typography>
                            <IconButton color="inherit" onClick={() => {
                                this.props.setShowAddRequest({open: false});
                                this.props.getDaysOffSuccess([]);
                            }}>
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {loading && <LinearProgress/>}
                    <DialogContent>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <div style={{marginTop: 20}}>
                                <Grid container>
                                    <Grid item sm={5}>
                                        <Selects handleRequestChange={this.handleRequestChange}
                                                 addedClient={addedClient}/>
                                    </Grid>
                                    <Grid item sm={7}>
                                        <Indicator addedClient={addedClient}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.props.setShowAddRequest({open: false, canSubmit: false});
                            this.props.getDaysOffSuccess([]);
                        }} color="primary">
                            {_cancel}
                        </Button>
                        <Button disabled={!canSubmit} onClick={() => {
                            this.props.getDaysOffSuccess([]);
                            addHolidayRequest(request, addedClient);
                        }} color="primary">
                            {_confirm}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}


const mapStateTopProps = (state) => {
    return {requests: state.requests, auth: state.auth}
};

const mapActionsToprops = {
    addHolidayRequest,
    setShowAddRequest,
    getDaysOffSuccess,
};

export default connect(mapStateTopProps, mapActionsToprops)(withStyles(styles)(AddRequestDialog));
