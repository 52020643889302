import {
    DOWNLOAD_QUOTA_FILE,
    DOWNLOAD_QUOTA_FILE_FAIL,
    DOWNLOAD_QUOTA_FILE_SUCCESS,
    GET_QUOTA_HISTORY,
    GET_QUOTA_HISTORY_FAIL,
    GET_QUOTA_HISTORY_SUCCESS,
    STOP_QUOTA_IMPORT,
    STOP_QUOTA_IMPORT_FAIL,
    STOP_QUOTA_IMPORT_SUCCESS
} from "../types/QuotaTypes";

export default function quotaReducer(state = {
    holidays: [],
    quotas: {
        data: [],
        totalElements: 0,
        loading: false
    },
    loading: false,
    totalElements: 0,
    sites: [],
    quotaLoading: false,
    siteLabel: ""
}, {type, payload}) {
    switch (type) {
        case DOWNLOAD_QUOTA_FILE:
            return {
                ...state,
                loading: true
            };
        case DOWNLOAD_QUOTA_FILE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case DOWNLOAD_QUOTA_FILE_FAIL:
            return {
                ...state,
                loading: false
            };
        case STOP_QUOTA_IMPORT:
            return {
                ...state,
                loading: true
            };
        case STOP_QUOTA_IMPORT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case STOP_QUOTA_IMPORT_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_QUOTA_HISTORY:
            return {
                ...state,
                quotas: payload,
                loading: true
            };
        case GET_QUOTA_HISTORY_SUCCESS:
            return {
                ...state,
                quotas: payload.results,
                totalElements: payload.totalElements,
                loading: false
            };
        case GET_QUOTA_HISTORY_FAIL:
            return {
                ...state,
                quotas: [],
                totalElements: 20,
                loading: false
            };
        case "GET_QUOTA_HOLIDAYS":
            return {...state, holidays: payload};
        case "GET_SITES":
            return {...state, sites: payload};
        case "QUOTA_LOADING":
            return {...state, quotaLoading: payload};
        case "SET_SITE_LABEL":
            return {...state, siteLabel: payload};
        default:
            return state
    }
}
