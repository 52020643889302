import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {DataTypeProvider, IntegratedFiltering, IntegratedSorting, SortingState,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import MGrid from '@material-ui/core/Grid';
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete'
import {setDeleteMappingDialog} from "../../../actions/ReferencesActions";
import {store} from "../../../store/ConfigureStore";
import {CountryFormatter, FilterCell, IdEditor} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {TableFilterRow} from "@devexpress/dx-react-grid-material-ui/dist/dx-react-grid-material-ui.cjs";
import {FilteringState} from "@devexpress/dx-react-grid/dist/dx-react-grid.umd";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";


const IdFormatter = ({value}) => (
    <MGrid container spacing={0}>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(setDeleteMappingDialog({open: true, mapping: value}))
            }
            } color="default" aria-label="add">
                <DeleteIcon fontSize="small"/>
            </IconButton>
        </MGrid>
    </MGrid>
);


const CountryColumnsProvider = props => (
    <DataTypeProvider
        formatterComponent={CountryFormatter}
        {...props}
    />
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);

const mapValues = rows => {
    return rows.map(row => {
        const {mappingTO, label, country} = row;
        return {
            id: JSON.stringify(row),
            labelTeleopti: mappingTO.label,
            $label: label,
            country: {label: country.label, value: country.id},
            abbreviation: mappingTO.abbreviation
        }
    })
}

export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableColumnExtensions: [
                {columnName: 'id', width: 80, wordWrapEnabled: true},
                {columnName: 'abbreviation', width: 100, wordWrapEnabled: true},
                {columnName: 'labelTeleopti', wordWrapEnabled: true},
                {columnName: '$label', wordWrapEnabled: true},
            ],
            countryColumns: ['country'],
            idColumns: ['id']
        };
    }

    render() {
        const {countryColumns, idColumns, tableColumnExtensions} = this.state;
        const rows = this.props.data;
        const {loading, searchActive} = this.props;
        const {_abbreviation, _teleoptiHolidayType, _exceptionalHolidayType, _country} = strings;
        const columns = [
            {name: 'abbreviation', title: _abbreviation},
            {name: 'labelTeleopti', title: _teleoptiHolidayType},
            {name: '$label', title: _exceptionalHolidayType},
            {name: 'country', title: _country},
            {name: 'id', title: ' '},
            ]
        return (
            <Paper style={tableSize}>
                <Grid
                    rows={mapValues(rows)}
                    columns={columns}
                    style={tableSize}
                >

                    <CountryColumnsProvider
                        for={countryColumns}
                    />
                    <IdProvider
                        for={idColumns}
                    />
                    <SortingState
                        onSortingChange={(sorting) => {
                            this.props.changeSorting(sorting)
                        }}
                    />
                    <FilteringState
                    onFiltersChange={(filters) => {this.props.changeFilters(filters)}}
                    />
                    <IntegratedSorting/>
                    <IntegratedFiltering/>
                    <Table
                        rowComponent={CustomTableRow}
                        containerComponent={CustomTableContainer}
                        noDataCellComponent={() => {
                            return <CustomEmptyTable loading={loading}/>
                        }}
                        columnExtensions={tableColumnExtensions}
                    />
                    <TableHeaderRow
                        contentComponent={TableHeaderContent}
                        showSortingControls/>
                    {searchActive && <TableFilterRow
                        messages={{filterPlaceholder: " "}}
                        cellComponent={FilterCell}
                    />}
                </Grid>
            </Paper>
        );
    }
}
