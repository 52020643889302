import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedSorting,
    SortingState
} from '@devexpress/dx-react-grid';
import {Grid, Table, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import IconButton from '@material-ui/core/IconButton'
import ConsultIcon from '@material-ui/icons/Visibility'
import {store} from "../../../store/ConfigureStore";
import {
    getToCancelRequestsFollow,
    getToCancelRequestsHistory,
    showRequestDetails,
    showRequestCancel
} from "../../../actions/RequestsActions";
import MGrid from '@material-ui/core/Grid'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import CancelIcon from '@material-ui/icons/Cancel'
import {
    IdEditor,
    RequestStatusProvider,
    EmployeeProvider,
    FilterCell, HolidaySubTypeProvider,
    compareDates,
    HolidayTypeProvider, endDatePredicate, startDatePredicate, noPredicate, compareDateTimes
} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";

const IdFormatter = ({value}) => (
    <MGrid container spacing={0}>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(getToCancelRequestsHistory(value.id));
                store.dispatch(getToCancelRequestsFollow(value.id));
                store.dispatch(showRequestDetails({type: "ADD", details: value, open: true}));
            }
            } color="default" aria-label="add">
                <ConsultIcon fontSize="small"/>
            </IconButton>
        </MGrid>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(showRequestCancel({type: "DELETE", details: value, open: true}));
            }} aria-label="Cancel">
                <CancelIcon fontSize="small"/>
            </IconButton>
        </MGrid>
    </MGrid>)


const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


function mapValues(rows) {
    return rows.map(row => {
        return {requestDetails : {typeConge: row.typeConge, exceptionalType: row.exceptionalType},value: row, ...row};
    });
}


export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableColumnExtensions: [
                {columnName: 'value', align: "right", width: 120},
                {columnName: 'totalDuration', width: 80, wordWrapEnabled: true},
                {columnName: 'status', wordWrapEnabled: true},
                {columnName: 'updateDate', wordWrapEnabled: true},
                {columnName: 'typeConge', wordWrapEnabled: true},
                {columnName: 'requestDetails',width: 80, wordWrapEnabled: true},
                {columnName: 'startDate', wordWrapEnabled: true},
                {columnName: 'returnDate', wordWrapEnabled: true},
                {columnName: 'returnDate', wordWrapEnabled: true},
                {columnName: 'addedForEmp', width: 200, wordWrapEnabled: true},
            ],
            filteringStateColumnExtensions: [
                { columnName: 'updateDate', filteringEnabled: false },
            ],
            integratedSortingColumnExtensions: [
                { columnName: 'startDate', compare: compareDates },
                { columnName: 'returnDate', compare: compareDates },
                { columnName: 'updateDate', compare: compareDateTimes }
            ],
            integratedFilteringColumnExtensions: [
            { columnName: 'startDate', predicate: startDatePredicate },
            { columnName: 'returnDate', predicate: endDatePredicate },
            { columnName: 'addedForEmp', predicate: noPredicate },
        ],
            employeeColumns: ['addedForEmp'],
            requestColumns: ['status'],
            idColumns: ['value'],
            holidayTypeColumns: ['typeConge'],
            descHolidayTypeColumns: ['requestDetails']
        };
    }

    render() {
        const {integratedSortingColumnExtensions, requestColumns, employeeColumns, idColumns, holidayTypeColumns,integratedFilteringColumnExtensions, tableColumnExtensions, filteringStateColumnExtensions, descHolidayTypeColumns} = this.state;
        const rows = this.props.data;
        const {loading, searchActive} = this.props;
        const {_partner,_updateDate, _holidayType, _startDate, _returnDate, _requestStatus, _duration, _totalDuration } = strings;
        const columns = [
            {name: 'addedForEmp', title: _partner},
            {name: 'updateDate', title: _updateDate},
            {name: 'typeConge', title: _holidayType},
            {name: 'requestDetails', title: ' '},
            {name: 'startDate', title: _startDate},
            {name: 'returnDate', title: _returnDate},
            {name: 'totalDuration', title: _totalDuration},
            {name: 'status', title: _requestStatus},
            {name: 'value', title: ' '}
            ]
        return (
            <Fragment>

                <Paper style={tableSize}>
                    <Grid
                        rows={mapValues(rows)}
                        columns={columns}
                        style={tableSize}
                    >
                        <IdProvider
                            for={idColumns}
                        />

                        <RequestStatusProvider
                            for={requestColumns}
                        />
                        <HolidayTypeProvider
                            for={holidayTypeColumns}
                        />
                        <HolidaySubTypeProvider
                            for={descHolidayTypeColumns}
                        />
                        <EmployeeProvider
                            for={employeeColumns}
                        />
                        <SortingState
                            defaultSorting={[{columnName: 'requestDate', direction: 'asc'}]}
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        <FilteringState
                            onFiltersChange={(filters) => {
                                this.props.changeFilters(filters)
                            }}
                            columnExtensions={filteringStateColumnExtensions}
                        />
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />
                        <Table
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => {
                                return <CustomEmptyTable loading={loading}/>
                            }}
                            columnExtensions={tableColumnExtensions}
                        />
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}
                    </Grid>
                </Paper>
            </Fragment>
        );
    }
}
