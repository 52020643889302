import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getAllPendingRequests} from "../../../actions/RequestsActions";
import _ from 'lodash'

const styles = theme => ({});

class AllPendingRequests extends Component {

    state = {
        filters: [],
        sort: ""
    };
    onChangeDebounceFilter = _.debounce(filters => this.changeFilters(filters), 500);

    changeFilters = (filters) => {
        this.setState({filters})
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getAllPendingRequests(page - 1, size)
    };

    componentDidMount() {
        this.props.getAllPendingRequests(0,25)
    }

    render() {
        const {allPendingRequests} = this.props.requests;
        const {loading, data, totalElements} = allPendingRequests;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={(page, size) => {
                        this.changePaging(page, size)
                    }}
                    totalPages={totalElements}
                />
                <Grid
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={e => this.onChangeDebounceFilter(e)}/>
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests}
};

let mapActionsToProps = {getAllPendingRequests};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(AllPendingRequests));

