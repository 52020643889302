import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import Balance from "./Balance";
import TimeLine from "./TimeLine";
import EmployeeBalance from "./EmployeeBalance";
import {hasPermission} from "../../../actions/AuthActions";
import {strings, language, hasLanguage} from "../../../i18n/Strings";


const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 14,
        paddingBottom: 5,
    }),
    title: {
        color: '#000000',
        display: 'inline',
        paddingTop: 20
    }
});

function Home(props) {
    const {classes} = props;
    const {_lastUpdate} = strings;

    return (
        <Paper elevation={0}>
                    <Grid container direction={"row"}>

                        <Grid item sm={2} style={{ textAlign: 'center', fontSize: 13, paddingTop: 15}}>
                            <Typography  className={classes.title} component="h3">
                                {_lastUpdate}
                            </Typography>
                            <Typography  className={classes.title} style={{paddingLeft: 5}}>
                                {moment().locale('fr').format(hasLanguage())}
                            </Typography>
                            <Balance/>
                        </Grid>
                        <Grid item sm={10}>
                            <TimeLine/>
                            {hasPermission("SOLDE_EMPLOYEES") && <EmployeeBalance/>}
                        </Grid>
                    </Grid>
        </Paper>

    );
}

export default withStyles(styles)(Home);
