import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import AddRequestDialog from '../holidays/addHolidayRequest/AddRequestDialog';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import RequestDetails from "../../utilities/RequestDetails";
import {getMyRequests} from "../../../actions/RequestsActions";
import ConfirmationDialog from "./ConfirmationDialog";
import {getBackup} from "../../../actions/ReferencesActions";

const styles = theme => ({});

class Requests extends Component {

    state = {
        filters: [],
        sort: ""
    };

    changeFilters = (filters) => {

        this.setState({filters: filters.reduce(function(obj,item){
                obj[item.columnName] = item.value;
                return obj;
            }, {})}, () => {
            const {filters,sort} = this.state;
            const {
                typeConge,
                typeRequest,
                returnDate,
                startDate,
                status
            } = filters;
            this.props.getMyRequests(0,25,typeConge,typeRequest,returnDate,startDate,status)
        })
    };
    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getMyRequests(page-1,size)
    };
    componentDidMount() {
        this.props.getMyRequests(0,25)
        this.props.getBackup();
    }

    render() {
        const {requests, auth} = this.props;
        const {myRequests, myRequestsHistory, myRequestsFollow, dialog, requestDetails} = requests;
        const {loading, data, totalElements} = myRequests;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    data={data}
                    loading={loading}
                    changePaging={(page,size) => {this.changePaging(page, size)}}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}
                    isMyRequests={true}/>
                <AddRequestDialog/>
                <RequestDetails
                    myRequestsHistory={myRequestsHistory}
                    myRequestsFollow={myRequestsFollow}
                    details={requestDetails}
                />
                <ConfirmationDialog
                    dialog={dialog}
                    myRequests={myRequests}
                />

            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests,  auth: state.auth}
};

let mapActionsToProps = {getMyRequests, getBackup};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(Requests));

