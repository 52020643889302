import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
    lightTooltip: {
        background: theme.palette.common.grey,
        color: theme.palette.text.primary,
        fontSize: 11,
        height: 100,
        width: 250
    },
    button: {
        margin: theme.spacing.unit,
    },
});

const Title = props => {
    const {title} = props;
    return (<Grid container>
        <Grid item>

        </Grid>
        <Grid item>

        </Grid>
    </Grid>)
};

class EventToolTip extends React.Component {
    render() {
        const {classes, title} = this.props;
        return (
            <Tooltip placement="right-end" title={<Title title={title}/>} classes={{tooltip: classes.lightTooltip}}>
                {this.props.children}
            </Tooltip>
        );
    }
}

export default withStyles(styles)(EventToolTip);