import React from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core/styles";
import {dialogStyles} from "../../styles/CommonStyles";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";

const Transition = (props) => {
    return <Slide direction="up" {...props} />;
}

const ConfirmDialog = ({open, handleClose, handleAction, classes}) => {

    return (
        <div>
            <Dialog
                open={open}
                //TransitionComponent={Transition}
                //keepMounted
                onClose={handleClose}
                classes={{paper: classes.dialogPaper}}
            >
                <DialogTitle
                    style={{backgroundColor: 'rgb(255 10 105)'}}
                    classes={{root: classes.root}}>
                    ATTENTION
                </DialogTitle>

                <DialogContent style={{marginTop: 30}}>
                    <Grid container direction={"row"}>
                        <Grid item sm={12}>
                            <p style={{fontSize: 17}}>Êtes vous sûr de vouloir arrêter l'import quotas ? </p>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleAction}>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withStyles(dialogStyles)(ConfirmDialog);
