import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ActiveCountries from './ActiveCountries'
import {withStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import connect from "react-redux/es/connect/connect";
import {addRejectionMotif, setShowAddRejectionMotif} from "../../../actions/ReferencesActions";
import {rolesToAdmin} from "../../../actions/AuthActions";

const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 550,
            maxHeight: 550,
            maxWidth: 800,
            minWidth: 800,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AddRejectionMotif extends React.Component {


    etats = [{value: "ENABLED", label: "Activé"}, {value: "DISABLED", label: "Désactivé"}].map((etat) => <MenuItem
        key={etat.toString()} value={etat.value}>{etat.label}</MenuItem>)



    adminRole =  rolesToAdmin();

    roles = [this.adminRole].map((etat) => <MenuItem key={etat.toString()}
                                                              value={etat.value}>{etat.label}</MenuItem>)

    criticites = [{value: "MAJOR", label: "Majeur"}, {value: "MINOR", label: "Mineur"}, {
        value: "NORMAL",
        label: "Normale"
    }].map((criticite) => <MenuItem key={criticite.toString()}
                                    value={criticite.value}>{criticite.label}</MenuItem>)


    state = {
        id: null,
        activeCountries: [],
        state: "",
        criticality: "",
        assignedToRole: this.adminRole.value,
        label: "",
        countries: [],
        allCountries: false
    };


    handleChange(e, value) {
        this.setState({[value]: e})
    }

    handleAllCountriesToggle() {
        this.setState({allCountries: !this.state.allCountries, countries: this.props.references.activeCountries})
    }

    componentDidMount() {
        const {assignedToRole, label, countries, state, criticality, comment, allCountries, id} = this.props.references.showAddRejectionMotif.details;
        this.setState({assignedToRole, label, countries, state, criticality, comment, allCountries, id})
    }

    render() {
        const {classes} = this.props;
        const {allCountries, state, criticality, assignedToRole, label, comment, countries} = this.state;
        const {activeCountries, references} = this.props;
        return (
            <Dialog
                classes={{paper: classes.dialogPaper}}
                open={true}
                TransitionComponent={Transition}
                onClose={() => {
                    this.props.setShowAddRejectionMotif({open: false, details: {}, type: "ADD"})
                }}
                aria-labelledby="form-dialog-title"
            >
                <AppBar position="static" color="primary" elevation={0}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit">
                            {`${(this.props.type === "EDIT") ? "Editer" : "Ajouter"} un motif de refus`}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item sm={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="Label"
                                label="Label"
                                fullWidth
                                value={label}
                                onChange={(e) => {
                                    this.handleChange(e.target.value, "label")
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Grid container spacing={16}>
                                <Grid item sm={8}>
                                    {
                                        !allCountries && <ActiveCountries
                                            multi={true}
                                            type={this.props.type}
                                            allCountries={allCountries}
                                            activeCountries={activeCountries}
                                            countries={countries}
                                            handleChangeLable={(countries) => {
                                                this.handleChange(countries.map( c => {return { label: c.label, id: c.value} }), "countries")
                                            }}/>
                                    }
                                </Grid>
                                <Grid item sm={4}>
                                    <div style={{marginTop: 12}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={allCountries}
                                                    onChange={() => {
                                                        this.handleAllCountriesToggle()
                                                    }}
                                                    value="checkedA"
                                                    color={"primary"}

                                                />
                                            }
                                            label="Tous les pays"
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                id="etat"
                                select
                                value={state}
                                label="Etat"
                                onChange={(e) => {
                                    this.handleChange(e.target.value, "state")
                                }}
                                margin="dense"
                                fullWidth
                            >
                                {this.etats}
                            </TextField>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                id="criticite"
                                select
                                label="Criticité"
                                value={criticality}
                                onChange={(e) => {
                                    this.handleChange(e.target.value, "criticality")
                                }}
                                margin="dense"
                                fullWidth
                            >
                                {this.criticites}
                            </TextField>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                id="etat"
                                select
                                value={assignedToRole}
                                label="Role à assigner"
                                onChange={(e) => {
                                    this.handleChange(e.target.value, "assignedToRole")
                                }}
                                margin="dense"
                                fullWidth
                            >
                                {this.roles}
                            </TextField>
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                value={comment}
                                onChange={(e) => {
                                    this.handleChange(e.target.value, "comment")
                                }}
                                id="multiline-static"
                                label="Commentaire"
                                multiline
                                rows="4"
                                style={{width: '100%'}}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.setShowAddRejectionMotif({open: false, details: {}, type: "ADD"})
                        this.props.addRejectionMotif(this.state, this.props.type);
                    }} color="primary">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


let mapStateToProps = (state) => {
    return {references: state.references}
};

let mapActionsToProps = {setShowAddRejectionMotif, addRejectionMotif};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(AddRejectionMotif));

