const apiMapping = {
    "prod": "https://ms-myconges.intelcia.com",
    "preprod": "http://dev.myconges.intelcia.com",
    // "localDev": "http://172.19.44.15:8081",
    "localDev": "http://localhost:8081",

};
 export const API = apiMapping["prod"];

//export const API = apiMapping["localDev"];


export const CORE_API = "https://mscore.intelcia.com";
export const DEAFULT_AVATAR_CORE_SERVER = "conges/pictures/default";
export const GATEWAY = "conges/gateway/watchdog";
export const MEDIUM_AVATAR_CORE_SERVER = "conges/pictures/medium";
export const EXCEPT_TYPE_RH = "conges/exceptionalType/exceptTypeHr";
export const EXCEPT_TYPE_RH_USER = "conges/exceptionalType/exceptTypeHrUser";
export const BACKUP = "conges/employee/backup";
export const DAYS_OF = "conges/myCongeDate/daysOff";
export const HR = "conges/solde/hr";
export const ESTIMATED = "conges/solde/estimated";
export const PERCENTAGE = "conges/solde/percentage";
export const TIMELINE = "conges/timeLine";
export const MY_UO = "conges/org/uo";
export const MY_REQUESTS = "conges/request/filter";
export const MY_QUOTAS = "conges/quotaFile/filter";
export const STOP_QUOTAS_IMPORT = "conges/quotaFile/stop";
export const DOWNLOAD_IMPORTED_QUOTAS_FILE = "conges/quotaFile/download";
export const MY_REQUESTS_HISTORY = "conges/requests/details";
export const CONFIG_USER = "conges/config";
export const MY_REQUESTS_FOLLOW = "conges/requests";
export const DELETE_REQUEST = "conges/request";
export const SOLDE_DES_EMPLOYES = "conges/solde/soldeDesEmployees";
export const PROCESSED_REQUESTS = "conges/requests/processed";
export const PENDING_REQUESTS = "conges/requests/pending";
export const IS_PROD = "conges/employee/isProd";
export const IS_TL = "conges/employee/isTl";
export const CHILDREN_REQUESTS = "conges/request/filter/childrenRequests";
export const ROLE_ADMIN_GROUP = "conges/request/filter/groupRequests";
export const ROLE_ADMIN_COUNTRY = "conges/request/filter/countryRequests";
export const COUNTRY_SUB_REQUESTS = "conges/request/filter/countrySubRequests";
export const ALL_PENDING_REQUESTS = "conges/task";
export const TO_CANCEL_REQUESTS = "conges/cancelHoliday/requestsToCancel";
export const TO_CANCEL_REQUESTS_FR = "conges/cancelHoliday/requestsToCancelFr";
export const TO_CANCEL_REQUESTS_ROLE_MAN = "conges/cancelHoliday/requestsManToCancel";
export const CANCEL_HOLIDAY = "conges/cancelHoliday";
export const HR_REQUESTS = "conges/request/hrRequests";
export const HR_ERROR_REQUESTS = "conges/request/erRequests";
export const MAPPING_TELEOPTI = "conges/exceptionalType/filterTeleopti";
export const REJECTION_MOTIFS = "conges/rejectionReason/filter";
export const EXCEPT_HOLIDAY = "/conges/exceptionalType/filter";
export const RECUPS = "conges/recups/filter";
export const BY_DAY_ABSENCE = "conges/abscence/dayAbsence";
export const BY_DAY_ABSENCE_FR = "conges/abscence/dayAbsenceFrance";
export const BY_HOUR_ABSENCE = "conges/abscence/hourAbsence";
export const BY_HOUR_ABSENCE_FR = "conges/abscence/hourAbsenceFrance";
export const ADDED_REQUESTS = "conges/requests/added";
export const PUBLIC_HOIDAYS = "conges/publicHolidays/filter";
export const EMPLOYEE_CHILDREN = "conges/employee/children";
export const EMPLOYEE_CHILDREN_COUNTRY = "conges/employee/children/country";
export const EMPLOYEE_QUERY = "conges/employee/q";
export const JUSTIFY_HOUR = "conges/abscence/justifyHour";
export const SPLIT_HOUR_ABSENCE = "conges/abscence/splitHourAbsence";
export const ADD_EXCEP_CONGE_REQUEST = "conges/excepCongeRequest";
export const ADD_PAYED_CONGE_REQUEST = "conges/requestProcess";
export const GET_CONFIG_REQUEST = "conges/requestProcess";
export const COUNT = "conges/requests/pending/count";
export const CANCEL_HOLIDAY_RH = "conges/cancelHoliday/cancelHolidaysRH";
export const CONGE_ERROR_DATE = "conges/myCongeDate/info";
export const EXCEPTIONAL_TYPES = "conges/exceptionalType/exceptTypeHr?user=";
export const EXCEPTIONAL_TYPES_FR = "conges/exceptionalType/exceptTypeHrByCountryCode/FR";
export const VALIDATED = "conges/requests/validated";
export const FLAGS_CDN = "https://www.countryflags.io";
export const OAUTH_LOGOUT_DEV = "http://oauth.intelcia.com/uaa/logout";
export const OAUTH_LOGOUT_PROD = "https://oauth.intelcia.com/uaa/logout";
export const GET_TIME_TRAJET = "conges/trajet";
export const GET_TIME_DELEGATION = "conges/delegation";
export const GET_TIME_ENTRY = "conges/timeEntry";
export const GET_TIME_ENTRY_FILTRED = "conges/timeEntry/filtre";
export const GET_JUSTIFICATION_TYPES = "conges/timeEntry/types";
export const SAVE_NEW_REQUESTS_PROPOSAL = "conges/new-requests-proposals";
export const ACCEPT_NEW_REQUESTS_PROPOSAL = "conges/new-requests-proposals/accept";
export const REFUSE_NEW_REQUESTS_PROPOSAL = "conges/new-requests-proposals/refuse";
