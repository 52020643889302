import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {DataTypeProvider, IntegratedFiltering, IntegratedSorting, SortingState,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import {store} from "../../../store/ConfigureStore";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {setShowAddHoliday} from "../../../actions/HolidaysActions";
import MGrid from '@material-ui/core/Grid';
import {hasAuthority} from "../../../actions/AuthActions";
import {CountryFormatter, FilterCell, IdEditor, noPredicate, formatDate, compareDates} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {FilteringState} from "@devexpress/dx-react-grid";
import {TableFilterRow} from "@devexpress/dx-react-grid-material-ui";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";
import moment from 'moment';

const IdFormatter = ({value}) => (
    <MGrid container spacing={0}>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(setShowAddHoliday({type: "EDIT", open: true, details: value}))
            }
            } color="default" aria-label="add">
                <EditIcon fontSize="small"/>
            </IconButton>
        </MGrid>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(setShowAddHoliday({type: "DELETE", open: true, details: value}))
            }} aria-label="Delete">
                <DeleteIcon fontSize="small"/>
            </IconButton>
        </MGrid>
    </MGrid>
);


const CountryColumnsProvider = props => (
    <DataTypeProvider
        formatterComponent={CountryFormatter}
        {...props}
    />
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableColumnExtensions: [
                {columnName: 'duration', width: 100},
                {columnName: 'country', width: 250},
                {columnName: 'value', width: 150},
            ],
            countryColumns: ['country'],
            idColumns: ['value'],
            integratedSortingColumnExtensions: [
                { columnName: '$startDate', compare: compareDates },
                { columnName: '$endDate', compare: compareDates }
            ],
            integratedFilteringColumnExtensions: [
                { columnName: 'country', predicate: noPredicate },
                { columnName: 'label', predicate: noPredicate },
                { columnName: '$startDate', predicate: noPredicate },
                { columnName: '$endDate', predicate: noPredicate },
            ],
        };
    }

    render() {
        const {countryColumns, idColumns, tableColumnExtensions, filteringStateColumnExtensions, integratedSortingColumnExtensions} = this.state;
        const rows = this.props.data;
        const {_country, _startDate, _endDate, _duration,_totalDuration, _publicHoliday} = strings;

        const columns = [
            {name: 'label', title: _publicHoliday},
            {name: '$startDate', title: _startDate},
            {name: '$endDate', title: _endDate},
            {name: 'duration', title: _totalDuration},
            {name: 'country', title: _country},
            ];
        const {loading, searchActive} = this.props;
        if (hasAuthority(["ROLE_ADMIN_RH_CONGE"])) {
            columns.push({name: 'value', title: ' '});
        }
        return (
            <Paper style={tableSize}>
                <Grid
                    rows={rows.map(row => {
                        return {value: row, ...row, $startDate: row.startDate, $endDate: row.endDate}
                    })}
                    columns={columns}
                    style={tableSize}
                >
                    <CountryColumnsProvider
                        for={countryColumns}
                    />
                    <IdProvider
                        for={idColumns}
                    />
                    <SortingState
                        defaultSorting={[{columnName: '$startDate', direction: 'asc'}]}
                        onSortingChange={(sorting) => {
                            this.props.changeSorting(sorting)
                        }}
                    />
                    <FilteringState
                        columnExtensions={filteringStateColumnExtensions}
                        onFiltersChange={(filters) => {this.props.changeFilters(filters)}}
                    />
                    <IntegratedSorting 
                        columnExtensions={integratedSortingColumnExtensions}/>
                    <IntegratedFiltering/>
                    <Table
                        rowComponent={CustomTableRow}
                        containerComponent={CustomTableContainer}
                        noDataCellComponent={() => {
                            return <CustomEmptyTable loading={loading}/>
                        }}
                        columnExtensions={tableColumnExtensions}
                    />
                    <TableHeaderRow
                        contentComponent={TableHeaderContent}
                        showSortingControls/>
                    {searchActive && <TableFilterRow
                        messages={{filterPlaceholder: " "}}
                        cellComponent={FilterCell}
                    />}
                </Grid>
            </Paper>
        );
    }
}
