import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedSorting,
    SortingState
} from '@devexpress/dx-react-grid';
import AddRequestDialog from "./AddRequestDialog";
import {Grid, Table, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {tableSizeTabs} from '../../../../styles/CommonStyles'
import IconButton from '@material-ui/core/IconButton'
import PaperIcon from "@material-ui/icons/Receipt";
import MGrid from '@material-ui/core/Grid'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../../utilities/CustomTableElements";
import {API, MEDIUM_AVATAR_CORE_SERVER} from "../../../../api/Endpoints";
import Avatar from "@material-ui/core/Avatar";
// import ConfirmationDialog from "./ConfirmationDialog";
import Typography from "@material-ui/core/Typography";
import {FilterCell, IdEditor, noPredicate, ToJustifyTypeProvider, compareDates} from "../../../../utilities/MappingFunctions";
import {strings} from "../../../../i18n/Strings";
import {CustomTableContainer} from "../../../../utilities/CustomTableContainer";
import {setShowAddRequest} from "../../../../actions/RequestsActions";
import { store } from "../../../../store/ConfigureStore";
import {getEmployeeChildren} from "../../../../actions/ReferencesActions";

const IdFormatter = ({value}) => {
    const countryCode = store.getState().auth.profile.countryCode;
    if(countryCode != "PT" && countryCode != "FR") {
        return (<></>)
    }
    return (
    <>
    <IconButton
      onClick={() => {
          store.dispatch(getEmployeeChildren(value.matricule));
          store.dispatch(setShowAddRequest({open: true, canSubmit: true, value}));
      }}
      color="default"
      aria-label="add"
    >
      <PaperIcon fontSize="small"/>
    </IconButton>
  </>
)};


const EmployeeFormatter = ({value}) => {
    const addedByEmp = value;
    if (addedByEmp) {
        const {employeeName, employeeLastName, regestionNumberEmployee} = addedByEmp;
        return <MGrid container>
            <MGrid item sm={4}>
                <Avatar src={`${API}/${MEDIUM_AVATAR_CORE_SERVER}/${regestionNumberEmployee}`}/>
            </MGrid>
            <MGrid item sm={8} style={{paddingTop: 5}}>
                <MGrid container direction={"column"}>
                    <MGrid item>
                        <Typography gutterBottom noWrap>
                            {`${employeeName} ${employeeLastName}`}
                        </Typography>
                    </MGrid>
                    <MGrid item>
                        {regestionNumberEmployee}
                    </MGrid>
                </MGrid>
            </MGrid>
        </MGrid>
    } else {
        return <div>{value.toString()}</div>
    }

};


const EmployeeProvider = props => (
    <DataTypeProvider
        formatterComponent={EmployeeFormatter}
        {...props}
    />
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);

export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            confirmationInMassDialogOpen: false,
            tableColumnExtensions: [
                {columnName: 'justified', align: "right", width: 100},
                { columnName: "id", align: "right", width: 100 }
            ],
            integratedSortingColumnExtensions: [
                { columnName: '$start', compare: compareDates },
            ],
            integratedFilteringColumnExtensions: [{ columnName: '$start', predicate: noPredicate }],
            idColumns: ['id'],
            justifyColumns: ['justified'],
            selection: [],
        };
        this.handleToggleConfirmation = () => {
            this.setState({confirmationInMassDialogOpen: !this.state.confirmationInMassDialogOpen})
        }

    }


    render() {
        const {integratedSortingColumnExtensions, idColumns, justifyColumns, tableColumnExtensions, integratedFilteringColumnExtensions} = this.state;
        const rows = this.props.data;
        const {loading, searchActive} = this.props;
        const {_justified, _registrationNumber, _firstName, _lastName, _absenceDay} = strings;
        const columns = [
            {name: 'matricule', title: _registrationNumber},
            {name: 'prenom', title: _firstName},
            {name: 'nom', title: _lastName},
            {name: '$start', title: _absenceDay},
            {name: 'justified', title: _justified},
            { name: "id", title: " " }
        ]
        return (
            <Fragment>
                <Paper style={tableSizeTabs}>
                    <Grid
                        rows={rows.map( row => {
                            const {matricule, prenom, nom, start, justified} = row;
                            return {matricule, prenom, nom, $start: start.split(" ")[0], justified, id:row}
                        })}
                        columns={columns}
                        style={tableSizeTabs}
                    >
                        <IdProvider for={idColumns}/>

                        <ToJustifyTypeProvider
                            for={justifyColumns}
                        />

                        <SortingState
                            // defaultSorting={[{columnName: '$start', direction: 'asc'}]}
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        <FilteringState
                            onFiltersChange={(filters) => {
                                this.props.changeFilters(filters)
                            }}
                        />
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />

                        <Table
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => {
                                return <CustomEmptyTable loading={loading}/>
                            }}
                            columnExtensions={tableColumnExtensions}/>
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}

                    </Grid>
                </Paper>
                <AddRequestDialog addedClient={true} />
                {/*<ConfirmationDialogInMass*/}
                {/*handleToggleConfirmation={this.handleToggleConfirmation}*/}
                {/*open={confirmationInMassDialogOpen}/>*/}

            </Fragment>
        );
    }
}

