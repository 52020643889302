import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {DataTypeProvider, IntegratedFiltering, IntegratedSorting, SortingState,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import IconButton from '@material-ui/core/IconButton'
import ConsultIcon from '@material-ui/icons/Done'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import {
    EmployeeProvider,
    HolidayTypeProvider, IdEditor,
    RequestTypeProvider,
    compareDates
} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";


const IdFormatter = ({value}) => (
    <IconButton onClick={() => {
    }
    } color="default" aria-label="add">
        <ConsultIcon fontSize="small"/>
    </IconButton>)

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


function mapValues(rows) {
    return rows.map(row => {
        const {task, request} = row;
        return {...task, ...request};
    })

}


export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableColumnExtensions: [
                {columnName: 'id', align: "right"},
                {columnName: 'duration', width: 80, wordWrapEnabled: true},
                {columnName: 'status', wordWrapEnabled: true},
                {columnName: 'typeRequest', wordWrapEnabled: true},
                {columnName: 'typeConge', wordWrapEnabled: true},
                {columnName: 'startDate', wordWrapEnabled: true},
                {columnName: 'returnDate', wordWrapEnabled: true},
                {columnName: 'returnDate', wordWrapEnabled: true},
                {columnName: 'reasonOfRejection', wordWrapEnabled: true},
                {columnName: 'addedForEmp', width: 200, wordWrapEnabled: true},
            ],
            integratedSortingColumnExtensions: [
                { columnName: 'startDate', compare: compareDates },
                { columnName: 'returnDate', compare: compareDates }
            ],
            employeeColumns: ['addedForEmp', "assignee", "candidates"],
            requestTypeColumns: ['typeRequest'],
            idColumns: ['id'],
            holidayTypeColumns: ['typeConge'],
        };
    }

    render() {
        const {integratedSortingColumnExtensions, requestTypeColumns, employeeColumns, idColumns, tableColumnExtensions, holidayTypeColumns} = this.state;
        const rows = this.props.data;
        const {loading} = this.props;
        const {_partner, _validator, _backup, _requestType, _holidayType, _startDate, _returnDate, _duration, _totalDuration} = strings;
        const columns = [
            {name: 'addedForEmp', title: _partner},
            {name: 'assignee', title: _validator},
            {name: 'candidates', title: _backup},
            {name: 'typeConge', title: _holidayType},
            {name: 'typeRequest', title: _requestType},
            {name: 'startDate', title: _startDate},
            {name: 'returnDate', title: _returnDate},
            {name: 'totalDuration', title: _totalDuration},
            // {name: 'duration', title: _duration},
            {name: 'id', title: ' '},
        ]
        return (
            <Fragment>
                <Paper style={tableSize}>
                    <Grid
                        rows={mapValues(rows)}
                        columns={columns}
                        style={tableSize}
                    >
                        <IdProvider
                            for={idColumns}
                        />

                        <RequestTypeProvider
                            for={requestTypeColumns}
                        />
                        <HolidayTypeProvider
                            for={holidayTypeColumns}
                        />

                        <EmployeeProvider
                            for={employeeColumns}
                        />
                        <SortingState
                            defaultSorting={[{columnName: 'requestDate', direction: 'asc'}]}
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        {/*<FilteringState*/}
                        {/*onFiltersChange={(filters) => {this.props.changeFilters(filters)}}*/}
                        {/*/>*/}
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <IntegratedFiltering/>
                        <Table
                            containerComponent={CustomTableContainer}
                            rowComponent={CustomTableRow}
                            noDataCellComponent={() => {
                                return <CustomEmptyTable loading={loading}/>
                            }}
                            columnExtensions={tableColumnExtensions}/>
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {/*<TableFilterRow/>*/}
                    </Grid>
                </Paper>
            </Fragment>
        );
    }
}
