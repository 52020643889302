import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import { IntegratedFiltering, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import {
    FilterCell
} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {FilteringState} from "@devexpress/dx-react-grid";
import {TableFilterRow} from "@devexpress/dx-react-grid-material-ui";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";
import {withStyles} from "@material-ui/core/styles";
import connect from "react-redux/lib/connect/connect";

class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tableColumnExtensions: [
                {columnName: 'registrationNumber', width: 150 , wordWrapEnabled: true},
                {columnName: 'firstName',  width: 200, wordWrapEnabled: true },
                {columnName: 'lastName', width: 200, wordWrapEnabled: true },
                {columnName: 'solde',  width: 150, wordWrapEnabled: true },
                {columnName: 'soldeCvl',  width: 150, wordWrapEnabled: true },
                {columnName: 'uo', width: 300,  wordWrapEnabled: true }
            ],
            integratedFilteringColumnExtensions: [
            ]
        };
    }

    render() {
        const {tableColumnExtensions,integratedFilteringColumnExtensions} = this.state;
        const rows = this.props.data;
        const {loading, searchActive,profile} = this.props;
        const {_firstName, _lastName, _registrationNumber, _annualBalance, _casualBalance} = strings;
        const columns = profile.countryCode == 'EG' ? [
            {name: 'registrationNumber', title: _registrationNumber},
            {name: 'firstName', title: _firstName},
            {name: 'lastName', title: _lastName},
            {name: 'solde', title: _annualBalance},
            {name: 'soldeClv', title: _casualBalance},
            {name: 'uo', title: 'UO'}
        ] : [
            {name: 'registrationNumber', title: _registrationNumber},
            {name: 'firstName', title: _firstName},
            {name: 'lastName', title: _lastName},
            {name: 'solde', title: _annualBalance},
            {name: 'uo', title: 'UO'}
        ];
        return (
            <Fragment>
                <Paper style={tableSize}>
                        <Grid
                            rows={rows.map(row => {
                                return {...row, id : row};
                            })}
                            columns={columns}
                            style={tableSize}
                        >
                        
                        <SortingState
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        <FilteringState
                        onFiltersChange={(filters) => {this.props.changeFilters(filters)}}
                        />
                        <IntegratedSorting/>
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />
                        <Table
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => { return <CustomEmptyTable loading={loading}/>}}
                            columnExtensions={tableColumnExtensions}/>
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}
                    </Grid>
                </Paper>
            </Fragment>
        );
    }
}

let mapStateToProps = (state) => {
    return {profile: state.auth.profile}
};

export default withStyles(() => {})(connect(mapStateToProps)(GlobalGrid));
