import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        background: theme.palette.background.paper,
    },
    dialog: {
        width: '80%',
        maxHeight: 400
    },
});


class ChangeReturnDate extends React.Component {
    state = {
        comment: "",
        changeReason: "",
        returnDate: ""
    };


    handleChange = (event, name) => {
        this.setState({[name]: event.target.value})
    };

    handleSubmit = () => {
        this.setState({
            comment: "",
            changeReason: "",
            returnDate: ""
        })
    };

    render() {
        const {classes} = this.props;
        const {comment, changeReason, returnDate} = this.state;
        return (
            <div>
                <Dialog
                    classes={{
                        paper: classes.dialog,
                    }}
                    open={true}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Modification date de retour</DialogTitle>
                    <DialogContent>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Grid container direction={"row"} style={{marginTop: 15}}>
                                    <Grid item sm={6} style={{marginTop: 5}}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Nouvelle date de retour
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <TextField
                                            type={"date"}
                                            fullWidth
                                            margin="dense"
                                            id="returnDate"
                                            value={returnDate}
                                            onChange={(event) => {
                                                this.handleChange(event, "returnDate")
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction={"row"}>
                                    <Grid item sm={6} style={{marginTop: 5}}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Motif de modification
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <TextField
                                            select
                                            fullWidth
                                            value={changeReason}
                                            onChange={(event) => {
                                                this.handleChange(event, "changeReason")
                                            }}
                                            id="holidayTypes"
                                        >
                                            {["Date de sorite"].map((reason, index) => <MenuItem key={index} value={reason}> {reason}</MenuItem>)}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="comment"
                                    multiline
                                    rows="4"
                                    placeholder="saisir un commentaire"
                                    value={comment}
                                    onChange={(event) => {
                                        this.handleChange(event, "comment")
                                    }}
                                />
                            </Grid>
                        </Grid>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {this.props.handleDialogOpen(null)}} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.handleDialogOpen(null);
                            this.handleSubmit();
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ChangeReturnDate);