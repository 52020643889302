import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {store} from "../../../store/ConfigureStore";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core/styles";
import {setShowAddExceptionalHoliday} from "../../../actions/HolidaysActions";
import {deleteExceptionalHoliday} from "../../../actions/ReferencesActions";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class DeletePublicHolidayConfirmationDialog extends React.Component {

    render() {
        const {showAddExceptionalHoliday} = this.props.holidays;
        const {label, id} = showAddExceptionalHoliday.details;

        return (
            <div>
                <Dialog
                    open={true}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="#"
                    aria-describedby="#"
                >
                    <DialogTitle id="#">
                        Suppression du type de congé exceptionnel
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="#">
                            Confirmez-vous la suppression du type de congé exceptionnel  <br/> {label} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            store.dispatch(setShowAddExceptionalHoliday({type: "ADD", open: false, details: {}}))
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            store.dispatch(setShowAddExceptionalHoliday({type: "ADD", open: false, details: {}}));
                            store.dispatch(deleteExceptionalHoliday(id))
                        }} color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {holidays: state.holidays}
};

let mapActionsToProps = {};


export default withStyles(() => {
})(connect(mapStateToProps, mapActionsToProps)(DeletePublicHolidayConfirmationDialog));
