import {applyMiddleware, combineReducers, createStore, } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import authReducer from "../reducers/AuthReducer";
import referencesReducer from "../reducers/ReferencesReducer";
import soldeReducer from "../reducers/SoldeReducer";
import holidaysReducer from "../reducers/HolidaysReducer";
import requestsReducer from "../reducers/RequestsReducer";
import i18nReducer from "../reducers/i18nReducer";
import absenceReducer from "../reducers/AbsenceReducer";
import exportHrReducer from "../reducers/ExportHrReducer";
import quotaReducer from "../reducers/QuotaReducer";

export const store = createStore(combineReducers({
    auth: authReducer,
    references: referencesReducer,
    solde: soldeReducer,
    holidays: holidaysReducer,
    requests: requestsReducer,
    i18n: i18nReducer,
    absence: absenceReducer,
    hrExports: exportHrReducer,
    quota: quotaReducer
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()),
    applyMiddleware(thunk, logger));
