import * as React from "react";
import { Fragment } from "react";
import { Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { NotFound } from "../assets/notFound";
import { strings } from "../i18n/Strings";

const styles = {
  customRow: {
    "&:hover": {
      backgroundColor: "#f1f1f1"
    }
  },
  button: {
    margin: `0 10px`
  }
};

const CustomTableRowBase = ({ classes, ...restProps }) => (
  <Table.Row className={classes.customRow} {...restProps} />
);

export const CustomTableRow = withStyles(styles, { name: "CustomTableRow" })(
  CustomTableRowBase
);

const TableHeaderContentBase = ({
  column,
  children,
  classes,
  ...restProps
}) => (
  <TableHeaderRow.Content column={column} {...restProps}>
    <Typography style={{ fontWeight: "bold" }} variant="subtitle2" gutterBottom>
      {children}
    </Typography>
  </TableHeaderRow.Content>
);
export const TableHeaderContent = withStyles(styles, {
  name: "TableHeaderContent"
})(TableHeaderContentBase);

export const CustomEmptyTable = ({ loading }) => {
  const { _noResultsFound, _loading } = strings;
  return (
  <Grid
    container
    direction={"column"}
    justify={"center"}
    alignItems={"center"}
    style={{ width: "calc( 100vw - 240px)", height: "60vh" }}
  >
    <Fragment>
      {!loading ? (
        <Fragment>
          <Grid item>
            <Typography variant="h4" component="h5">
              {_noResultsFound}...
            </Typography>
          </Grid>
          <Grid item>
            <NotFound />
          </Grid>
        </Fragment>
      ) : (
        <Grid item>
          <Typography variant="h4" component="h5">
            {_loading}...
          </Typography>
        </Grid>
      )}
    </Fragment>
  </Grid>
)};
