import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedSorting,
    SortingState,
} from '@devexpress/dx-react-grid';
import {Grid, Table, TableFilterRow, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import IconButton from '@material-ui/core/IconButton'
import ConsultIcon from '@material-ui/icons/Visibility'
import {store} from "../../../store/ConfigureStore";
import {
    getChildrenRequestsFollow,
    getChildrenRequestsHistory,
    showRequestDetails
} from "../../../actions/RequestsActions";
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import {
    EmployeeFormatter, endDatePredicate,
    FilterCell, HolidayTypeProvider,
    HolidaySubTypeProvider, IdEditor, noPredicate,
    RequestStatusFormatter,
    RequestTypeProvider, startDatePredicate,
    compareDates,compareDateTimes
} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";


const IdFormatter = ({value}) => (
    <IconButton onClick={() => {
        store.dispatch(getChildrenRequestsHistory(value));
        store.dispatch(getChildrenRequestsFollow(value));
        store.dispatch(showRequestDetails({open: true}));
    }
    } color="default" aria-label="add">
        <ConsultIcon fontSize="small"/>
    </IconButton>
);

const RequestStatusProvider = props => (
    <DataTypeProvider
        formatterComponent={RequestStatusFormatter}
        {...props}
    />
);

const EmployeeProvider = props => (
    <DataTypeProvider
        formatterComponent={EmployeeFormatter}
        {...props}
    />
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


function mapValues(rows) {
    return rows.map(row => {
        row.comment = {typeConge: row.typeConge, exceptionalType: row.exceptionalType};
        return row;
    });
}


export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableColumnExtensions: [
                {columnName: 'id',width: 60,align: "right"},
                {columnName: 'duration', width: 60, wordWrapEnabled: true},
                {columnName: 'status',width: 80, wordWrapEnabled: true},
                {columnName: 'typeRequest',width: 120, wordWrapEnabled: true},
                {columnName: 'updateDate',width: 120, wordWrapEnabled: true},
                {columnName: 'typeConge',width: 120, wordWrapEnabled: true},
                {columnName: 'comment',width: 80, wordWrapEnabled: true},
                {columnName: 'startDate',width: 120, wordWrapEnabled: true},
                {columnName: 'returnDate',width: 120, wordWrapEnabled: true},
                {columnName: 'reasonOfRejection', wordWrapEnabled: true},
                {columnName: 'addedForEmp', width: 190, wordWrapEnabled: true}
            ],
            integratedFilteringColumnExtensions: [
                { columnName: 'startDate', predicate: startDatePredicate },
                { columnName: 'returnDate', predicate: endDatePredicate },
                { columnName: 'addedForEmp', predicate: noPredicate }
            ],
            integratedSortingColumnExtensions: [
                { columnName: 'startDate', compare: compareDates },
                { columnName: 'returnDate', compare: compareDates },
                { columnName: 'updateDate', compare: compareDateTimes }
            ],
            employeeColumns: ['addedForEmp'],
            requestColumns: ['status'],
            idColumns: ['id'],
            requestTypeColumns: ['typeRequest'],
            holidayTypeColumns: ['typeConge'],
            descHolidayTypeColumns: ['comment']
        };
    }

    render() {
        const {integratedSortingColumnExtensions,requestColumns, employeeColumns, idColumns, tableColumnExtensions, holidayTypeColumns, requestTypeColumns, integratedFilteringColumnExtensions, descHolidayTypeColumns} = this.state;
        const rows = this.props.data;
        const {loading, searchActive} = this.props;
        console.log('children Requests', rows);
        const {_updateDate, _requestType, _addedForEmp, _holidayType, _startDate, _returnDate, _requestStatus, _duration, _totalDuration} = strings;
        const columns = rows[0] && rows[0].totalDuration ? [
            {name: 'updateDate', title: _updateDate},
            {name: 'addedForEmp', title: _addedForEmp},
            {name: 'typeConge', title: _holidayType},
            {name: 'comment', title: ' '},
            {name: 'typeRequest', title: _requestType},
            {name: 'startDate', title: _startDate},
            {name: 'returnDate', title: _returnDate},
            {name: 'totalDuration', title: _totalDuration},
            {name: 'status', title: _requestStatus},
            {name: 'id', title: ' '}
        ] : [
            {name: 'updateDate', title: _updateDate},
            {name: 'addedForEmp', title: _addedForEmp},
            {name: 'typeConge', title: _holidayType},
            {name: 'comment', title: ' '},
            {name: 'typeRequest', title: _requestType},
            {name: 'startDate', title: _startDate},
            {name: 'returnDate', title: _returnDate},
            {name: 'duration', title: _duration},
            {name: 'status', title: _requestStatus},
            {name: 'id', title: ' '}
        ];

        return (
            <Fragment>
                <Paper style={tableSize}>
                    <Grid
                        rows={mapValues(rows)}
                        columns={columns}
                        style={tableSize}
                    >
                        <IdProvider
                            for={idColumns}
                        />

                        <RequestStatusProvider
                            for={requestColumns}
                        />

                        <EmployeeProvider
                            for={employeeColumns}
                        />

                        <RequestTypeProvider
                            for={requestTypeColumns}
                        />

                        <HolidayTypeProvider
                            for={holidayTypeColumns}
                        />

                        <HolidaySubTypeProvider
                            for={descHolidayTypeColumns}
                        />
                        <SortingState
                            defaultSorting={[{columnName: 'requestDate', direction: 'asc'}]}
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        <FilteringState
                            onFiltersChange={(filters) => {
                                this.props.changeFilters(filters)
                            }}
                        />
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />
                        <Table
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => {
                                return <CustomEmptyTable loading={loading}/>
                            }}
                            columnExtensions={tableColumnExtensions}/>
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}
                    </Grid>
                </Paper>
            </Fragment>
        );
    }
}
