import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {DataTypeProvider, IntegratedFiltering, IntegratedSorting, SortingState,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import {FLAGS_CDN} from "../../../api/Endpoints";
import RefData from '../../../utilities/RefData'
import Avatar from "@material-ui/core/Avatar/Avatar";
import MGrid from '@material-ui/core/Grid';
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import Tooltip from "@material-ui/core/Tooltip";
import {store} from "../../../store/ConfigureStore";
import IconButton from "@material-ui/core/IconButton";
import {setShowAddRejectionMotif} from "../../../actions/ReferencesActions";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {CriticalityFormatter, FilterCell, IdEditor, StateFormatter} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {FilteringState} from "@devexpress/dx-react-grid";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";

const CountryFormatter = ({value}) => {
    return <Fragment>
        {value.map((country, index) => {
            const {label} = country
            const src = `${FLAGS_CDN}/${RefData.COUNTRY_CODES[label]}/flat/64.png`;
            return <div style={{position: "relative"}}>
                <Tooltip title={label}>
                    <div style={{position: "absolute", top: -7, left: 0 + 25 * index}}><Avatar alt="#" style={{
                        height: 20,
                        width: 20
                    }} src={src}/></div>
                </Tooltip>
                {/*<div style={{position: "absolute", top: -5, left: 25 + 25*index}}>{label} </div>*/}
            </div>
        })}
    </Fragment>

};

const IdFormatter = ({value}) => (
    <MGrid container spacing={0}>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(setShowAddRejectionMotif({type: "EDIT", open: true, details: value}))
            }
            } color="default" aria-label="add">
                <EditIcon fontSize="small"/>
            </IconButton>
        </MGrid>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(setShowAddRejectionMotif({type: "DELETE", open: true, details: value}))
            }} aria-label="Delete">
                <DeleteIcon fontSize="small"/>
            </IconButton>
        </MGrid>
    </MGrid>
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


const CountryColumnsProvider = props => (
    <DataTypeProvider
        formatterComponent={CountryFormatter}
        {...props}
    />
);


const StateColumnsProvider = props => (
    <DataTypeProvider
        formatterComponent={StateFormatter}
        {...props}
    />
);
const CriticalityColumnsProvider = props => (
    <DataTypeProvider
        formatterComponent={CriticalityFormatter}
        {...props}
    />
);

export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tableColumnExtensions: [
                {columnName: 'countries', width: 300, wordWrapEnabled: true},
                {columnName: 'label', wordWrapEnabled: true},
                {columnName: 'comment', wordWrapEnabled: true},
                {columnName: 'value', width: 150},
            ],
            countryColumns: ['countries'],
            stateColumns: ['state'],
            criticalityColumns: ['criticality'],
            idColumns: ['value'],
            filteringStateColumnExtensions: [
                { columnName: 'countries', filteringEnabled: false },
            ]
        };
    }

    render() {
        const {countryColumns, tableColumnExtensions, criticalityColumns, idColumns, stateColumns, filteringStateColumnExtensions} = this.state;
        const rows = this.props.data;
        const {loading, searchActive} = this.props;
        const {_label, _status, _criticality, _comment, _country} = strings;
        const columns = [
            {name: 'label', title: _label},
            {name: 'state', title: _status},
            {name: 'criticality', title: _criticality},
            {name: 'comment', title: _comment},
            {name: 'countries', title: _country},
            {name: 'value', title: ' '},
        ]
        return (
            <Paper style={tableSize}>
                <Grid
                    rows={rows.map(row => {
                        return {value: row, ...row}
                    })}
                    columns={columns}
                    style={tableSize}
                >
                    <IdProvider
                        for={idColumns}
                    />
                    <CountryColumnsProvider
                        for={countryColumns}
                    />
                    <CriticalityColumnsProvider
                        for={criticalityColumns}
                    />
                    <StateColumnsProvider
                        for={stateColumns}
                    />
                    <SortingState
                        onSortingChange={(sorting) => {
                            this.props.changeSorting(sorting)
                        }}
                    />
                    <FilteringState
                        onFiltersChange={(filters) => {
                            this.props.changeFilters(filters)
                        }}
                    />
                    <FilteringState columnExtensions={filteringStateColumnExtensions} />
                    <IntegratedSorting/>
                    <IntegratedFiltering/>
                    <Table
                        rowComponent={CustomTableRow}
                        containerComponent={CustomTableContainer}
                        noDataCellComponent={() => {
                            return <CustomEmptyTable loading={loading}/>
                        }}
                        columnExtensions={tableColumnExtensions}
                    />
                    <TableHeaderRow
                        contentComponent={TableHeaderContent}
                        showSortingControls/>
                    {searchActive && <TableFilterRow
                        messages={{filterPlaceholder: " "}}
                        cellComponent={FilterCell}
                    />}
                </Grid>
            </Paper>
        );
    }
}
