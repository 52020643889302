import React, {Fragment} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import SunIcon from '@material-ui/icons/WbSunny';
import TabIcon from '@material-ui/icons/Tab';
import CancelIcon from '@material-ui/icons/Cancel';
import PeopleIcon from '@material-ui/icons/People';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LinkIcon from '@material-ui/icons/Link';
import RadioButtonCheckedIcon from '@material-ui/icons/Check';
import ListIcon from '@material-ui/icons/List';
import WarningIcon from '@material-ui/icons/Error';
import HistoryIcon from '@material-ui/icons/History';
import TrendingUp from '@material-ui/icons/TrendingUp';
import BookIcon from '@material-ui/icons/Book';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import List from '@material-ui/core/List';
import {hasAuthority, hasPermission, getFromSession} from "../../actions/AuthActions";
import {Link} from 'react-router-dom';
import ListItemIcon from "@material-ui/core/es/ListItemIcon/ListItemIcon";
import {strings} from "../../i18n/Strings";
import Badge from "@material-ui/core/Badge";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { store } from '../../store/ConfigureStore';
import { settingsMenuAllowedUsernames } from '../../utilities/GlobalConstants';

const noUnderLine = {textDecoration: 'none'};

function isSelected(selected, path) {
    return selected === path ? {backgroundColor: "#e5e5e5"} : {};
}

export const NavItem = props => {
    const {path, icon, title, selected, count} = props;
    const styleListItem = Object.assign(isSelected(selected, path), {
        borderBottom: '1px solid #e4e5e7',
        paddingLeft: 15
    });
    return <Fragment>
        {
            !(path === "DemandesEnAttente") ? <Link to={path} style={noUnderLine}>
                    <ListItem className="routerLink" style={styleListItem} button onClick={() => {
                        props.onSelect(path)
                    }}>
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                        <ListItemText style={{padding: '0 5px'}}
                                      primary={title}
                        />
                    </ListItem>
                </Link> :
                <Link to={path} style={noUnderLine}>
                    <ListItem style={styleListItem} button onClick={() => {
                        props.onSelect(path)
                    }}>
                        {
                            (count != 0) ? <Badge badgeContent={count} color="primary">
                                    <ListItemIcon>
                                        {icon}
                                    </ListItemIcon>
                                </Badge> :
                                <ListItemIcon>
                                    {icon}
                                </ListItemIcon>
                        }
                        <ListItemText style={{padding: '0 5px'}}
                                      primary={title}
                        />
                    </ListItem>
                </Link>
        }
    </Fragment>
};


export default class ListItems extends React.Component {
    state = {selected: ""};
    onSelect = selected => {
        this.setState({selected});
    };

    render() {
        const {_home, _recuperation, _teleoptiMapping, _exceptionalHolidays, _publicHolidays, _rejectionMotifs,
            _addedRequests,_addedRequestsFr, _processedRequests, _pendingRequests, _childrenRequests, _allPendingRequests,
            _payedCollaboratorsHolidays,_payedErrorHolidays, _holidayCancelation, _effectifsHolidays, _exportsManagement,
            _justificationOfAbsences,_justificationOfCurrentAbsences, _myRequests, _justificationOfAbsencesFr} = strings;
        const {countryCode} = this.props;
        const {auth: {profile : {profile}}} = store.getState();
        const { staffType , job, userName} = store.getState().auth.profile;
        const isLeader = profile == 'LEADER';
        const isBumOpsFR = (countryCode == 'FR' && (profile == 'COORDINATOR' || profile == 'MANAGER') && staffType == 'P');
        const jobs = ["123","151","IN01002"]
        const isDirecteurSite = (jobs.includes(job)) && staffType == 'H' && countryCode == 'FR';
        const configUser = JSON.parse(getFromSession('configUser'));

        let pages = [
            {title: _home, icon: <HomeIcon/>, path: "Accueil", permission: "#", authorities: '#'},
            {
                title: _teleoptiMapping,
                icon: <LinkIcon/>,
                path: "MappingTeleopti",
                permission: "MAPPING_TELEOPTI",
                authorities: []
            },
            {
                title: _exceptionalHolidays,
                icon: <ListIcon/>,
                path: "CongeExceptionnel",
                permission: "",
                authorities: ["ROLE_ADMIN_RH_CONGE"]
            },
            {
                title: _publicHolidays,
                icon: <SunIcon/>,
                path: "JoursFeries",
                permission: "PUBLIC_HOLIDAYS",
                authorities: ["ROLE_USER_NOPROD_CONGE"]
            },
            {
                title: _rejectionMotifs,
                icon: <CancelIcon/>,
                path: "MotifsDeRefus",
                permission: "REJECTION_REASONS",
                authorities: []
            },
            {
                title: _addedRequests,
                icon: <AssignmentReturnedIcon/>,
                path: "DemandesAjoutees",
                permission: "",
                authorities: ["ROLE_RH_CONGE", "ROLE_MAN"]
            },
            {
                title: _addedRequestsFr,
                icon: <AssignmentReturnedIcon/>,
                path: "DemandesAjouteesFr",
                permission: "",
                authorities: ["ROLE_PAIE_CONGE"]
            },
            {title: _myRequests, icon: <TabIcon/>, path: "MesDemandes", permission: "#", authorities: "#"},
            {
                title: _processedRequests,
                icon: <RadioButtonCheckedIcon/>,
                path: "DemandesTraitees",
                permission: "",
                authorities: ["ROLE_USER_NOPROD_CONGE", "ROLE_MANAGER_CONGE", "ROLE_RH_CONGE"]
            },
            {
                title: _pendingRequests,
                icon: <HourglassEmptyIcon/>,
                path: "DemandesEnAttente",
                permission: "",
                authorities: ["ROLE_USER_NOPROD_CONGE", "ROLE_MANAGER_CONGE", "ROLE_RH_CONGE"]
            },
            {
                title: _childrenRequests,
                icon: <PeopleIcon/>,
                path: "CongesDeMesCollaborateurs",
                permission: "CHILDREN_REQUESTS",
                authorities: "#",
                showVacationsToTl: configUser && configUser.showVacationsToTl,
                showVacationsToDirSite: isDirecteurSite
            },
            // {
            //     title: _allPendingRequests,
            //     icon: <HourglassEmptyIcon/>,
            //     path: "ToutesDemandesEnAttente",
            //     permission: "",
            //     authorities: ["ROLE_ADMIN_RH_CONGE"]
            // },
            {
                title: _payedCollaboratorsHolidays,
                icon: <HistoryIcon/>,
                path: "CongesPayesDeMesCollaborateurs",
                permission: "",
                authorities: ["ROLE_RH_CONGE","ROLE_RH_CONGE_TEMPO"]
            }, {
                title: _holidayCancelation,
                icon: <WarningIcon/>,
                path: "AnnnulationConges",
                permission: "",
                authorities: ["ROLE_RH_CONGE", "ROLE_MAN","ROLE_PAIE_CONGE"],
                showCancelToOpsBum : isBumOpsFR
            },
            // {
            //     title: _effectifsHolidays,
            //     icon: <AssignmentReturnedIcon/>,
            //     path: "CongesDesEffectifs",
            //     permission: "",
            //     authorities: ["ROLE_PPV_CONGE"]
            // },
            {
                title: 'Flag Exported',
                icon: <TrendingUp/>,
                path: "FlagExported",
                permission: "",
                authorities: ["ROLE_EXCEPTION_USER_FLAG"]
            },
            {
                title: _justificationOfAbsences,
                icon: <PeopleIcon/>,
                path: "JustificationAbsences",
                permission: "",
                authorities: "ROLE_RH_CONGE"
            },
             {
                 title: "Quotas",
                 icon: <TrendingUp />,
                 path: "quotas",
                 permission: "",
                 authorities: ["ROLE_QUOTA_ADMIN", "ROLE_QUOTA_READ", "ROLE_QUOTA_READ_WFM"]
             },
             {
                title: _payedErrorHolidays,
                icon: <HistoryIcon/>,
                path: "RapportExportError",
                permission: "",
                authorities: ["ROLE_HRA_CONGE_EXPORT"]
            },
            // {
            //     title: "Toutes demandes en attente",
            //     icon: <AssignmentLateTwoToneIcon/>,
            //     path: "ToutesDemandesEnAttente",
            //     permission: "PENDING_REQUESTS",
            //      authorities: []
            // },
            // {
            //     title: _exportsManagement,
            //     icon: <ImportExportIcon/>,
            //     path: "GestionDesExports",
            //     permission: "",
            //     authorities: ["ROLE_RH_CONGE", "ROLE_ADMIN_RH_CONGE"]
            // },
            // {
            //     title: "Gestion des referentiel export",
            //     icon: <BookIcon/>,
            //     path: "GestionDesReferentielExport",
            //     permission: "",
            //     authorities: ["ROLE_RH_CONGE", "ROLE_ADMIN_RH_CONGE"]
            // },
        ];
        const {selected} = this.state;
        const {count} = this.props;
        pages = countryCode != 'FR'  ?
            (countryCode != 'TN' ? [...pages,
                {
                    title: _recuperation,
                    icon: <CalendarIcon/>,
                    path: "Recuperation",
                    permission: "RECUPERATION",
                    authorities: []
                },
                {
                    title: _justificationOfAbsencesFr,
                    icon: <PeopleIcon/>,
                    path: "JustificationAbsencesFr",
                    permission: "",
                    authorities: ["ROLE_PAIE_CONGE"]
                }] :[...pages]) :
            [...pages
        //     ,{
        //     title: _justificationOfCurrentAbsences,
        //     icon: <PeopleIcon/>,
        //     path: "JustificationCurrentAbsences",
        //     permission: "",
        //     authorities: ["ROLE_RH_CONGE"]
        // }
        ];
        return <List dense style={{maxHeight: 'calc(100vh - 140px)', paddingTop: 0}}>
            {pages.map(  (page, index) => {
                const {title, icon, path, permission, authorities, showVacationsToTl,showCancelToOpsBum,showVacationsToDirSite} = page;
                const configUser = JSON.parse(getFromSession('configUser'));
                console.log("configUser  ==> " ,  configUser.temporaryHrbp );
         /*       if(typeof authorities ==="object" &&  authorities.includes("ROLE_RH_CONGE")    ){
                      authorities.push("ROLE_RH_CONGE_TEMPO") ;
                }
           */


                if(typeof authorities ==="object" &&  authorities.includes("ROLE_RH_CONGE") && title === "Congés de mes collaborateurs" && configUser.temporaryHrbp ){
                    authorities.push("ROLE_RH_CONGE_TEMPO") ;
                }
                console.log("authorities after   ==> " ,  authorities)

                if (permission === "#" || hasPermission(permission) || hasAuthority(authorities) || authorities === "#"
                    || (showVacationsToTl && isLeader) || showCancelToOpsBum || showVacationsToDirSite ||
                    ((["004791", "004860", "E21061", "034544", "036462", "037296", "037925", "040002", "064073", "070067", "M01045", "P54853", "P54896", "P51886", "P00302", "P00605", "P50185", "P53215", "P53251", "P02618", "P58599", "P61737", "M01019", "007609", "008390", "D00020", "D00829", "C00027", "C00094", "C01028", "A01924", "A02913", "T00088", "TO0235", "T03649", "T05164", "004791", "038485", "063898", "069412"])
                        .includes(userName) && path == "FlagExported")) {
                    return <NavItem
                        key={index}
                        selected={selected}
                        onSelect={this.onSelect}
                        title={title}
                        icon={icon}
                        count={count}
                        path={path}/>
                }
            })}
        </List>
    }
}
