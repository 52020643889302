import React, {Component} from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import BalanceGauge from "./BalanceGauge";
import {strings} from "../../../i18n/Strings";
import { store } from '../../../store/ConfigureStore';
import { getSolde } from '../../../actions/SoldeActions';

class Balance extends Component {

    state = {
    }

    async componentWillMount(){
        store.dispatch(getSolde());
    }
    render() {
        const titleTop = {paddingTop: 20, textAlign: 'center'};
        const indicatorCenter = {textAlign: 'center', marginTop: 13};
        const {hr, estimated, recuperation, rtt, pendingRtt, anciennete, pendingAnciennete, casualBalance} = this.props.solde;
        const {references} = this.props;
        const {configUser} = references;
        const {_annualBalance, _pendingBalance, _rttBalance, _ancienneteBalance, _pendingRtt, _pendingAnciennete, _casualBalance} = strings;
        const ancienneteBalance$ = (
            configUser && configUser.ancienneteBalance ?
                    <>
                    <Grid item sm={12}>
                        <Typography variant="body1" style={titleTop} gutterBottom>
                            {_ancienneteBalance}
                        </Typography>
                        <Typography variant="h5" style={indicatorCenter} gutterBottom>
                            {(anciennete >= 0) ? anciennete : 0}
                        </Typography>
                    </Grid>
                    </> : ''
                    );
        const pendingAnciennete$ = (
            configUser && configUser.ancienneteBalance ?
                    <>
                    <Grid item sm={12}>
                        <Typography variant="body1" style={titleTop} gutterBottom>
                            {_pendingAnciennete}
                        </Typography>
                        <Typography variant="h5" style={indicatorCenter} gutterBottom>
                            {pendingAnciennete}
                        </Typography>
                    </Grid>
                    </> : ''
                    );
        const rttBalance$ = (
            configUser && configUser.rttBalance ?
                    <>
                    <Grid item sm={12}>
                        <Typography variant="body1" style={titleTop} gutterBottom>
                            {_rttBalance}
                        </Typography>
                        <Typography variant="h5" style={indicatorCenter} gutterBottom>
                            {(rtt >= 0) ? rtt : 0}
                        </Typography>
                    </Grid>
                    </>
                    : ''
                );
        const pendingRtt$ = (
            configUser && configUser.rttBalance ?
                    <>
                    <Grid item sm={12}>
                        <Typography variant="body1" style={titleTop} gutterBottom>
                            {_pendingRtt}
                        </Typography>
                        <Typography variant="h5" style={indicatorCenter} gutterBottom>
                            {pendingRtt}
                        </Typography>
                    </Grid>
                    </>
                    : ''
                );
        const casualBalance$ = (
            configUser && configUser.clvBalance ?
            <>
                <Grid item sm={12}>
                    <Typography variant="body1" style={titleTop} gutterBottom>
                        {_casualBalance}
                    </Typography>
                    <Typography variant="h5" style={indicatorCenter} gutterBottom>
                        {casualBalance}
                    </Typography>
                </Grid>
            </>
            : ''
        );
        return (
            <div>
                <Grid container direction={"column"}>

                   {
                        recuperation !== 0 && <Grid item sm={12}>
                            <Typography variant="body1" style={titleTop} gutterBottom>
                                Solde de récupération
                            </Typography>
                            <Typography variant="h3" style={indicatorCenter} gutterBottom>
                                {recuperation}
                            </Typography>
                        </Grid>
                    }
                    <Grid item sm={12}>
                        <Typography variant="body1" style={titleTop} gutterBottom>
                            {_annualBalance}
                        </Typography>
                        <Typography variant="h3" style={indicatorCenter} gutterBottom>
                            {(hr >= 0) ? hr : 0}
                        </Typography>

                    </Grid>
                    {ancienneteBalance$}
                    {rttBalance$}
                    {casualBalance$}
                    <Grid item sm={12}>
                        <Typography variant="body1" style={titleTop} gutterBottom>
                            {_pendingBalance}
                        </Typography>
                        <Typography variant="h5" style={indicatorCenter} gutterBottom>
                            {estimated}
                        </Typography>
                    </Grid>
                    {pendingRtt$}
                    {pendingAnciennete$}


                    {/*<Grid item sm={4}>*/}
                        {/*<Typography variant="h5" style={titleTop} gutterBottom>*/}
                            {/*Pourcentage restant*/}
                        {/*</Typography>*/}
                        {/*<BalanceGauge percentage={percentage}/>*/}
                        {/*/!*<SoldeGauge radius={30}/>*!/*/}
                    {/*</Grid>*/}

                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        solde: state.solde,
        references: state.references
    };
}

export default connect(
    mapStateToProps,
)(Balance);
