import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getPendingRequests} from "../../../actions/RequestsActions";
import ConfirmationDialog from "./ConfirmationDialog";
import _ from 'lodash'
import ConfirmationDialogDomRep from './ConfirmationDialogDomRep';

const styles = theme => ({});


class Requests extends Component {

    state = {
        filters: [],
        sort: "",
        selection: [],
    };
    onChangeDebounceFilter = _.debounce(filters => this.changeFilters(filters), 500);

    changeSelection = selection => this.setState({selection});

    changeFilters = (filters) => {
        this.setState({filters: filters.reduce(function(obj,item){
                obj[item.columnName] = item.value;
                return obj;
            }, {})}, () => {
            const {filters,sort} = this.state;
            const {
                addedByEmp,
                typeConge,
                returnDate,
                startDate,
                status
            } = filters;
            this.props.getPendingRequests(0,25,addedByEmp,typeConge,returnDate,startDate,status)
        })
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getPendingRequests(page - 1, size)
    };
    componentDidMount() {
        this.props.getPendingRequests(0,25)
    }

    render() {
        const {requests, auth} = this.props;
        const {pendingRequests, validationDetails} = requests;
        const { open } = validationDetails;
        const {loading, data, totalElements} = pendingRequests;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={(page, size) => {
                        this.changePaging(page, size)
                    }}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={e => this.onChangeDebounceFilter(e)}/>

                {open ? (validationDetails.data.employeeCountryCode !== 'DO' ? <ConfirmationDialog/> : <ConfirmationDialogDomRep/>) : <></>}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests, auth: state.auth}
};

let mapActionsToProps = {getPendingRequests};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(Requests));

