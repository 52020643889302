import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import {withStyles} from "@material-ui/core";

import {AppBar, Toolbar, IconButton, Typography, Button} from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';



export default class SubRequestToolBar extends React.Component {

    
    render() {
        return(
        
            <AppBar position="static" color={"default"}>
        <Toolbar>
                        <Typography variant="dense" color="inherit" noWrap>
                            SubRequest
                        </Typography>
                        </Toolbar>
      </AppBar>
       
   
        )
      }

}