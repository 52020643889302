
export const primaryPink = "#ff1a75";

export const dialogStyles = theme => {
    return {
        dialogPaper: {
            minWidth: 600,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            '& h6': {
                color: 'white'
            }
        },
    }
};

export const profile = {
    row: {
        display: 'flex',
        justifyContent: 'center',

    },
    padd_top_10: {
        marginTop: 10,
        marginLeft: 10
    },
    padd_bottom_10: {
        paddingTop: 25,
        marginTop: 25,
        marginLeft: 10,
        marginBottom: 10
    },
    bigAvatar: {
        width: 90,
        height: 90,
        borderStyle: "solid",
        borderColor: primaryPink,
        backgroundColor: "white"
    },
    leftIcon: {
        marginRight: 20,
    }
};

export const tableSize = {height: "calc(100vh - 128px)", position: "relative", width: "100%", overflow: 'visible'};
export const tableSizeTabs = {height: "calc(100vh - 113px)", position: "relative", width: "100%", overflow: 'visible'};

export const labelStyles = {};
export const $labelStyles = {marginLeft: 9, fontSize: 11};
export const _labelStyles = { fontSize: 11};
