import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PerDayAbsence from "./perDay/PerDayAbsence";
import TimeTrajet from "./timeTrajet/TimeTrajet";
import PerHourAbsence from "./perHour/PerHourAbsence";
import {strings} from "../../../i18n/Strings";
import connect from "react-redux/lib/connect/connect";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

class AbsenceTabs extends React.Component {
    state = {
        value: 0,
    };

    componentWillMount() {
        window.localStorage.setItem("CURRENT_TAB", "ABSENCE_DAY")
    }

    handleChange = (event, value) => {
        this.setState({value});
        window.localStorage.setItem("CURRENT_TAB", value ? "ABSENCE_HOUR" : "ABSENCE_DAY")
    };

    render() {
        const {classes, auth} = this.props;
        const {value} = this.state;
        const {_dayAbsence, _hourAbsence ,_timeDelegation} = strings;
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange}>
                        <Tab label={_hourAbsence}/>
                        {auth.profile.countryCode != 'TN' && <Tab label={_dayAbsence}/>}
                        {auth.profile.countryCode == 'FR' && <Tab label={_timeDelegation}/>}
                    </Tabs>
                </AppBar>
                {value === 0 && <PerHourAbsence/>}
                {value === 1 && <PerDayAbsence/>}
                {value === 2 && <TimeTrajet/>}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {auth: state.auth, absence: state.absence}
};

export default withStyles(styles)(connect(mapStateToProps)(AbsenceTabs));


