import React, {Component} from 'react';
import CustomTableToolBar from '../../../../utilities/CustomTableToolBar'
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import Grid from "./Grid";
import {getAbsenceByHour} from "../../../../actions/AbsenceActions";
import {Subject, timer} from "rxjs";
import {debounce, skip} from "rxjs/operators";
import AddJustificationDialog from "./AddJustificationDialog";
import SpliterDialog from "./SpliterDialog";
import SearchIcon from "@material-ui/icons/ScheduleOutlined";
import Button from "@material-ui/core/Button";
import LessThanHourSearchAbsence from "./LessThanHourSearchDialog"
import {setSearchActive} from "../../../../actions/AuthActions";
import ExportDialog from "../perDay/ExportDialog";
import Assignment from "@material-ui/icons/Assignment";

const styles = theme => ({});


class PerHourAbsence extends Component {
    searchSubject = new Subject();
    state = {
        filters: [],
        sort: "",
        lessThanHourAbsenceIsOpen: false,
        exportIsOpen: false

    };

    changeFilters = (filters) => {
        this.setState({
            filters: filters.reduce(function (obj, item) {
                obj[item.columnName] = item.value;
                return obj;
            }, {})
        }, () => {
            const {filters, sort} = this.state;
            const {
                justified,
                matricule,
                absenceDay,
                active
            } = filters;
            let {startDate, endDate} = absenceDay;
            endDate = endDate.replace("Invalid date", "")
            endDate = endDate.replace("undefined", "")
            this.searchSubject.next({page: 0, size: 25, justified, matricule, startDate, endDate, active})
        })
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getAbsenceByHour(page - 1, size)
    };

    componentDidMount() {
        this.props.getAbsenceByHour(0, 25);
        this.searchSubject.pipe(
            skip(1),
            debounce(() => timer(1000)),
        ).subscribe((searchEvent) => {
            const {page, size, justified, matricule, startDate, endDate, active} = searchEvent;
            this.props.getAbsenceByHour(page, size, justified, matricule, startDate, endDate, active);
        })

    }

    componentWillUnmount() {
        this.searchSubject.unsubscribe();
    }

    toggleAbsence () {
        this.setState({lessThanHourAbsenceIsOpen: !this.state.lessThanHourAbsenceIsOpen})
    }

    toggleExport () {
        this.setState({exportIsOpen: !this.state.exportIsOpen})
    }

    render() {
        const {exportIsOpen} = this.state;
        const {lessThanHourAbsenceIsOpen} = this.state;
        const {absence, auth} = this.props;
        const {hourAbsence, showAddJustification, showSpliter} = absence;
        const {show,spliterDetails} = showSpliter
        const {open, details} = showAddJustification;
        const {loading, data, totalElements} = hourAbsence;
        const {searchActive} = auth;

        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={(page, size) => {
                        this.changePaging(page, size)
                    }}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                {open && <AddJustificationDialog open={open} details={details}/>}
                {show && <SpliterDialog show={show} spliterDetails={spliterDetails}/>}
                { lessThanHourAbsenceIsOpen && <LessThanHourSearchAbsence toggleAbsence={() => {this.toggleAbsence()}}/>}

                <Button id={"fab"}
                        title={"Recherche avancée ( absences inférieur à une heure )"}
                        onClick={() => {
                            this.toggleAbsence();
                            this.props.setSearchActive(false)
                        }}
                        color={"primary"}
                        variant={"fab"}>
                    <SearchIcon/>
                </Button>
                {exportIsOpen && <ExportDialog exportIsOpen={exportIsOpen} rows={data} toggleExport={() => {this.toggleExport()}}/>}

                <Button id='fab-1'
                        title={"Rapport des absences non justifiées"}
                        onClick={() => {
                            this.props.getAbsenceByHour(0, totalElements)
                            this.toggleExport();
                        }}
                        color={"primary"}
                        variant={"fab"}>
                    <Assignment/>
                </Button>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {auth: state.auth, absence: state.absence}
};

let mapActionsToProps = {
    getAbsenceByHour,
    setSearchActive
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(PerHourAbsence));

