import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getPublicHolidays, setShowAddHoliday} from "../../../actions/HolidaysActions";
import AddPublicHolidayDialog from './AddPublicHolidayDialog'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeletePublicHolidayConfirmationDialog from './DeletePublicHolidayConfirmationDialog'
import {hasAuthority} from "../../../actions/AuthActions";
import RefData from "../../../utilities/RefData";

const styles = theme => ({});

class PublicHolidays extends Component {

    state = {
        filters: [],
        sort: "",
        edit: false
    };

    changeFilters = (filters) => {
        this.setState({
            filters: filters.reduce(function (obj, item) {
                obj[item.columnName] = item.value;
                return obj;
            }, {})
        }, () => {
            const {filters, sort} = this.state;
            const {
                label,
                country,
                $endDate
            } = filters;
            const year = $endDate;
            this.props.getPublicHolidays(0, 25, country? country.label: null,country ? country.id: null, label, year)
        })
    };

    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getPublicHolidays(page - 1, size)
    };

    componentDidMount() {
        this.props.getPublicHolidays(0, 25)
    }

    render() {
        const {auth, holidays} = this.props;
        const {publicHolidays, showAddHoliday} = holidays;
        const {open, type} = showAddHoliday;
        const {totalElements, data, loading} = publicHolidays;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={this.changePaging}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                {
                    hasAuthority(["ROLE_ADMIN_RH_CONGE"]) && <Button id={"fab"}
                                                                     onClick={() => {
                                                                         this.props.setShowAddHoliday({
                                                                             type: "ADD",
                                                                             open: true,
                                                                             details: {}
                                                                         })
                                                                     }}
                                                                     color={"primary"}
                                                                     variant={"fab"}>
                        <AddIcon/>
                    </Button>
                }
                {open && (type === "EDIT" || type == "ADD") && <AddPublicHolidayDialog type={type}/>}
                {open && (type === "DELETE") && <DeletePublicHolidayConfirmationDialog/>}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {holidays: state.holidays, auth: state.auth}
};

let mapActionsToProps = {getPublicHolidays, setShowAddHoliday};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(PublicHolidays));

