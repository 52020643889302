import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {API, GATEWAY} from "../../api/Endpoints";
import {getAxios} from "../../actions/AuthActions";

class AppContainer extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        const axios = getAxios();
        this.unlisten = this.props.history.listen((location) => {
            const payload =
                {
                    url: window.location.href,
                    application: "myVacation",
                    matricule: this.props.username,
                    page: this.props.currentRouteComponent(location.pathname)
                }

            axios.post(`${API}/${GATEWAY}/api/urls`, payload);
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        return (
            <div>{this.props.children}</div>
        );
    }
}

export default withRouter(AppContainer);
