import React, {Component} from 'react';
import CustomTableToolBar from '../../../../utilities/CustomTableToolBar'
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import Grid from "./Grid";
import {getJustificationTypes, getTime, getTimeFiltred} from "../../../../actions/AbsenceActions";
import {Subject, timer} from "rxjs";
import {debounce, skip} from "rxjs/operators";
import SearchIcon from "@material-ui/icons/ScheduleOutlined";
import Button from "@material-ui/core/Button";
import {setSearchActive} from "../../../../actions/AuthActions";
import Assignment from "@material-ui/icons/Assignment";
import {strings} from "../../../../i18n/Strings";
import InputDialog from './InputDialog'
import ConfirmationDialog from './ConfirmationDialog'
import DeletionDialog from "./DeletionDialog";

const styles = theme => ({});


class TimeTrajet extends Component {
    searchSubject = new Subject();
    state = {
        filters: [],
        sort: "",
        lessThanHourAbsenceIsOpen: false,
        dialogIsOpen: false

    };

    changeFilters = (filters) => {
        this.setState({
            filters: filters.reduce(function (obj, item) {
                console.log(item.columnName + " " + item.value);
                obj[item.columnName] = item.value;
                return obj;
            }, {})
        }, () => {
            const {filters, sort} = this.state;
            let {
                matricule,
                date,
                type,
                canceled
            } = filters;
            if (canceled == 'Annulée')
                canceled = true;
            else if (canceled == 'Saisie')
                canceled = false;
            else canceled = null;
            console.log(canceled);
            //this.searchSubject.next({page: 0, size: 25, matricule, date, type, canceled})
            this.props.getTimeFiltred({canceled})
        })
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getTime()
    };

    componentDidMount() {
        this.props.getJustificationTypes();
        this.props.getTime();
        this.searchSubject.pipe(
            skip(1),
            debounce(() => timer(1000)),
        ).subscribe((searchEvent) => {
            this.props.getTime();
        })

    }

    componentWillUnmount() {
        this.searchSubject.unsubscribe();
    }

    toggleAbsence () {
        this.setState({lessThanHourAbsenceIsOpen: !this.state.lessThanHourAbsenceIsOpen})
    }

    toggleShow () {
        this.setState({dialogIsOpen: !this.state.dialogIsOpen})
    }

    render() {
        const {dialogIsOpen} = this.state;
        const {_timeTrajetIn , _timeDelegation} = strings;
        const {lessThanHourAbsenceIsOpen} = this.state;
        const {absence, auth} = this.props;
        const {timeTrajet, showAddJustification} = absence;
        const {open, details} = showAddJustification;
        const {loading, data, totalElements} = timeTrajet;
        const {searchActive} = auth;
        return (
            auth.profile.countryCode == 'FR' &&
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={(page, size) => {
                        this.changePaging(page, size)
                    }}
                    totalPages={totalElements}

                    addOnButton={<Button
                        onClick={() => {
                            this.toggleShow();
                        }}
                   
                    >
                {_timeDelegation}
            </Button>}
                    
                />
                {dialogIsOpen && <InputDialog dialogIsOpen={dialogIsOpen} rows={data} toggleShow={() => {this.toggleShow()}}/>}

                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={this.changeFilters}/>
                <ConfirmationDialog/>
                <DeletionDialog/>

            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {auth: state.auth, absence: state.absence}
};

let mapActionsToProps = {
    getTimeFiltred,
    getTime,
    setSearchActive,
    getJustificationTypes,
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(TimeTrajet));

