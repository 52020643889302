import React from "react";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import "../../../styles/react-big-calendar.css";
import "moment/locale/fr";
import "moment/locale/en-au";
import CustomToolbar from "./TimeLineToolBar";
import Divider from "@material-ui/core/Divider";
import { getTimeLine } from "../../../actions/HolidaysActions";
import { connect } from "react-redux";
import EventToolTip from "./ToolTip";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { API, DEAFULT_AVATAR_CORE_SERVER } from "../../../api/Endpoints";
import { strings } from "../../../i18n/Strings";

const localizer = BigCalendar.momentLocalizer(moment);
const { _lang } = strings;

const Event = ({ event }) => {
  const eventDetails = event.title.split("#");
  const fullName = eventDetails[0];
  const registrationNumber = eventDetails[1];
  const typeConge = eventDetails[2];
  return (
    <div style={{ pointerEvents: "none", height: 30 }}>
      <Grid container justify={"flex-start"} spacing={16}>
        <Grid item>
          <EventToolTip title={event}>
            <Avatar
              style={{ height: 25, width: 25 }}
              alt="user"
              src={`${API}/${DEAFULT_AVATAR_CORE_SERVER}/${registrationNumber}`}
            />
          </EventToolTip>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            style={{ color: "white" }}
            gutterBottom
          >
            {fullName}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

class TimeLine extends React.Component {
  state = {
    drawerOpen: true,
    selectedUOId: "",
    selectedUOName: ""
  };

  setDate = () => {
    this.setState({ timeLineStartDate: new Date(2016, 3, 10) });
  };

  handleClick = () => {
    this.setState({ drawerOpen: true });
  };

  getSelectedUO = name => {
    let UOList = this.props.timeLine.UOList;
    for (let i in UOList) {
      if (UOList[i].name === name) {
        this.setState({
          selectedUOId: UOList[i].id,
          selectedUOName: UOList[i].name
        });
        return String(UOList[i].id);
      }
    }
  };

  handleChange = event => {
    let uoId = this.getSelectedUO(event.target.value);
    this.props.getTimeLine("01/06/2018", uoId);
  };

  createEvents = timeLine => {
    let events = [];
    for (let i in timeLine) {
      const element = timeLine[i];
      const { fullName, registrationNumber, holidays } = element;
      const holiday = holidays[0];
      if (holidays[0] !== undefined) {
        let { type, start, end } = holiday;
        start = start.split("T")[0];
        let s = start.split("T")[0].split("-");
        end = end.split("T")[0];
        let e = end.split("T")[0].split("-");
        events.push({
          id: Number(i),
          title: `${fullName}#${registrationNumber}#${type}#${start}#${end}`,
          start: new Date(Date.UTC(parseInt(s[0]),parseInt(s[1]) -1, parseInt(s[2]) ,0 ,0 ,0)),
          end: new Date(Date.UTC(parseInt(e[0]),parseInt(e[1]) -1, parseInt(e[2]) ,0 ,0 ,0))
        });
      }
    }
    return events;
  };

  render() {
    let { timeLine, timeLineStartDate } = this.props.holidays;
    const {profile }=this.props.auth;

    console.log("timeline", timeLine)
    let events = profile.role == 'CI' || profile.role == 'CC' ? this.createEvents(timeLine.filter(tl => tl.registrationNumber == profile.userName)): this.createEvents(timeLine);
    return (
      <div className={"font"}>
        <div style={{ height: "calc(100vh - 70px)", width: "100%" }}>
          <Divider />
          <BigCalendar
            onNavigate={() => {}}
            locale={_lang}
            localizer={localizer}
            events={events}
            views={["month", "agenda"]}
            defaultView={"month"}
            step={15}
            components={{
              toolbar: CustomToolbar,
              month: { event: Event }
            }}
            showMultiDayTimes
            defaultDate={moment(timeLineStartDate).toDate()}
            date={moment(timeLineStartDate).toDate()}
            toolbar={true}
            messages={{ showMore: total => `+${total} plus` }}
            tooltipAccessor={event => {
              const eventDetails = event.title.split("#");
              const fullName = eventDetails[0];
              const registrationNumber = eventDetails[1];
              const typeConge = eventDetails[2];
              const startDate = moment(eventDetails[3]);
              const endDate = moment(eventDetails[4]);
              const diff = endDate.diff(startDate, "days");
              const duration = startDate === endDate ? diff + 1 : diff;
              debugger;
              return `
                         Détails congé :
                         Collaborateur : ${fullName}
                         Type congé : ${typeConge}
                         Date départ : ${startDate.format("DD/MM/YYYY")}
                         Date retour : ${endDate.format("DD/MM/YYYY")}
                         Durée : ${duration === 0 ? 1 : duration} Jour(s)

                        `;
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateTopProps = state => {
  return { 
    holidays: state.holidays,
    auth: state.auth
   };
};

const mapActionsToprops = { getTimeLine };

export default connect(
  mapStateTopProps,
  mapActionsToprops
)(TimeLine);
