import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {withStyles} from '@material-ui/core/styles';
import {setShowAddMapping} from "../../../actions/ReferencesActions";
import AddIcon from '@material-ui/icons/Add';
import SelectAutoComplete from "./SelectAutoComplete";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";

const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 350,
            maxHeight: 350,
            maxWidth: 800,
            minWidth: 800,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AddMappingTeleoptiDialog extends React.Component {
    state = {
        value: 0,
    };

    handleChange = (event, value) => {
        this.setState({value});
    };

    render() {
        const {classes, references} = this.props;
        const {value} = this.state;
        const {showAddMapping, countries, mappingTeleoptiLabels} = references;
        return (
            <div style={{position: "relative"}}>
                <Button id={"fab"}
                        onClick={() => {
                             this.props.setShowAddMapping(true)
                        }}
                        color={"primary"}
                        variant={"fab"}>
                    <AddIcon/>
                </Button>
                <Dialog
                    open={showAddMapping}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    classes={{paper: classes.dialogPaper}}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                {"Mapping des types de congés exceptionnels"}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={{margin: 30}}>
                            <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={32}>
                                <Grid item><SelectAutoComplete data={countries} label={"Pays"}/></Grid>
                                <Grid item><SelectAutoComplete data={mappingTeleoptiLabels} label={"Mapping Teleopti"}/></Grid>
                            </Grid>
                    </div>

                    <DialogActions>
                        <Button onClick={() => {
                            this.props.setShowAddMapping(false)
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.setShowAddMapping(false)
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {references: state.references}
};

let mapActionsToProps = {
    setShowAddMapping
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(AddMappingTeleoptiDialog));
