import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import {getAxios} from "../../../../../actions/AuthActions";
import {API} from "../../../../../api/Endpoints";


const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        background: theme.palette.background.paper,
    },
    dialog: {
        width: '80%',
        maxHeight: 400
    },
});


class FlagToExported extends React.Component {
    state = {
        comment: "",
        motif: "",
        motifs: []
    };


    handleChange = (event, name) => {
        this.setState({[name]: event.target.value})
    };

    handleSubmit = () => {
        const {comment, motif } = this.state;
       alert(JSON.stringify({requestId: this.props.requestId, comment, motif}))
    };

    componentWillMount() {
        const axios = getAxios();
        axios.get(`${API}/conges/request/motifs`)
             .then(
                motifs => {
                    this.setState({motifs: motifs.data})
                }
        );

    }

    render() {
        const {classes} = this.props;
        const {comment, motif} = this.state;
        return (
            <div>
                <Dialog
                    classes={{
                        paper: classes.dialog,
                    }}
                    open={true}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Modification du statut de la demande</DialogTitle>
                    <DialogContent>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Grid container direction={"row"}>
                                    <Grid item sm={6} style={{marginTop: 5}}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Libellé motif de modification
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <TextField
                                            select
                                            fullWidth
                                            value={motif}
                                            onChange={(event) => {
                                                this.handleChange(event, "motif")
                                            }}
                                            id="holidayTypes"
                                        >
                                            {this.state.motifs.map((motif, index) => <MenuItem key={index} value={motif.id}> {motif.label}</MenuItem>)}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="comment"
                                    multiline
                                    rows="4"
                                    placeholder="saisir un commentaire"
                                    value={comment}
                                    onChange={(event) => {
                                        this.handleChange(event, "comment")
                                    }}
                                />
                            </Grid>
                        </Grid>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {this.props.handleDialogOpen(null)}} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.handleDialogOpen(null);
                            this.handleSubmit();
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(FlagToExported);
