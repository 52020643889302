import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {profile} from "../../styles/CommonStyles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {logout} from "../../actions/AuthActions";
import {strings} from "../../i18n/Strings";


class SignOutMenu extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {anchorEl} = this.state;
        const {fullName} = this.props;
        const {_disconnect} = strings;
        return (
            <div>
                <Button
                    fullWidth
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    <span className={"ellipsis"}>{fullName}</span>
                    <ExpandMoreIcon styles={profile.leftIcon}/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={() => {logout()}}>{_disconnect}</MenuItem>
                    <MenuItem onClick={this.handleClose}>Activation 2FA</MenuItem>
                </Menu>
            </div>
        );
    }
}

export default SignOutMenu;
