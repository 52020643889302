import  React, {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {FilteringState, IntegratedFiltering, IntegratedSorting, SortingState,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableFilterRow, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import {tableSize, tableSizeTabs} from '../../../../styles/CommonStyles';
import SearchToolBar from './SearchToolBar';
import AdvancedSearchDialog from './AdvancedSearchDialog';
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../../utilities/CustomTableElements";
import {CustomTableContainer} from "../../../../utilities/CustomTableContainer";
import {
    EmployeeProviderExport,
    endDatePredicate,
    FilterCell, HolidayTypeProvider,
    startDatePredicate,
    compareDates, compareDateTimes
} from "../../../../utilities/MappingFunctions";

export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
            columns: [
                {name: 'addedForEmp', title: 'Collaborateur'},
                {name: 'typeConge', title: 'Type de congés'},
                {name: 'updateDate', title: 'Date de la demande'},
                {name: 'startDate', title: 'Date de départ'},
                {name: 'returnDate', title: 'Date de retour'},
                {name: 'totalDuration', title: 'Durée globale'},
                {name: 'duration', title: 'Durée à exporter'}
            ],
            integratedFilteringColumnExtensions: [
                { columnName: 'startDate', predicate: startDatePredicate },
                { columnName: 'returnDate', predicate: endDatePredicate }
            ],
            integratedSortingColumnExtensions: [
                { columnName: 'startDate', compare: compareDates },
                { columnName: 'returnDate', compare: compareDates },
                { columnName: 'updateDate', compare: compareDateTimes }
            ],
            employeeColumns: ['addedForEmp'],
            holidayTypeColumns: ['typeConge'],
            rows: [],
        };
    }

    toggleSearch = () => {
        const {searchOpen} = this.state;
        this.setState({searchOpen: !searchOpen});
    }

    render() {
        const {integratedSortingColumnExtensions, rows, columns, searchOpen, integratedFilteringColumnExtensions, employeeColumns, holidayTypeColumns} = this.state;
        const {loading, searchActive, data} = this.props;
        return (
            <Fragment>
                <Paper style={tableSizeTabs}>
                    <Grid
                        style={tableSizeTabs}
                        rows={data.map( row => {
                            return row;
                        })}
                        columns={columns}
                    >
                        <EmployeeProviderExport
                            for={employeeColumns}
                        />
                        <HolidayTypeProvider
                            for={holidayTypeColumns}
                        />
                        <SortingState
                            defaultSorting={[{columnName: 'requestDate', direction: 'asc'}]}
                        />
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <FilteringState
                            onFiltersChange={(filters) => {this.props.changeFilters(filters)}}
                        />
                        <Table
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => {
                                return <CustomEmptyTable loading={loading}/>
                            }}
                            columnExtensions={[]}
                        />
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}
                    </Grid>
                </Paper>

            </Fragment>

        );
    }
}
