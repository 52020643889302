import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import {fade} from '@material-ui/core/styles/colorManipulator';
import {withStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {Tooltip} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import TranslateIcon from '@material-ui/icons/Translate';
import {getMyRequests} from "../../actions/RequestsActions";
import connect from "react-redux/es/connect/connect";
import {changeLang} from "../../actions/i18nActions";
import PDF_CC from '../../download/MyCongés_FRANCE-UserGuide_CC.pdf';
import PDF_MANAGER from '../../download/MyCongés_FRANCE-UserGuide_Manager.pdf';
import PDF_HRBP from '../../download/MyCongés_FRANCE-UserGuide_HRBP.pdf';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {getAuthoritiesConge, getLanguage} from '../../actions/AuthActions';
import FRENCH from "../../images/fr.png";
import PORTUGUESE from "../../images/pt.png";
import ENGLISH from "../../images/en.jpg";
import {setLanguage, strings, stringsLangs} from "../../i18n/Strings";
import { withRouter } from 'react-router-dom';


const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    pdf_download : {
        marginLeft: 30,
        float: 'right',
        marginTop: 6,
        textDecoration: 'none',
        color: 'white',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
});

const defaultLang = sessionStorage.getItem('language') != null ? sessionStorage.getItem('language') : 'fr';
const _fr_name =   stringsLangs[defaultLang]._fr_name;
const _en_name = stringsLangs[defaultLang]._en_name;
const _pt_name = stringsLangs[defaultLang]._pt_name;

class PrimarySearchAppBar extends React.Component {
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
        languages: [
            {code: "fr", label :_fr_name, srcPicture: FRENCH},
            {code: "en", label :_en_name, srcPicture: ENGLISH},
            {code: "pt", label :_pt_name, srcPicture: PORTUGUESE},
        ],
    };
    handleProfileMenuOpen = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleMenuClose = () => {
        this.setState({anchorEl: null});
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({mobileMoreAnchorEl: event.currentTarget});
    };

    handleMobileMenuClose = () => {
        this.setState({mobileMoreAnchorEl: null});
    };
    pdf_downloader = () => {
    const {authoritiesConge} = this.props.auth;
    const countryCode = this.props.auth && this.props.auth.profile && this.props.auth.profile.countryCode;
    const {classes} = this.props;
    if(countryCode == 'FR' || countryCode == '5') {
        if(authoritiesConge.some(auth => auth.authority == 'ROLE_RH_CONGE')){
            return (
               <div>
                   <a  title='Guide utilisateurs'  className={classes.pdf_download}  href = {PDF_HRBP} target = "_blank"><PictureAsPdfIcon /></a>
               </div>
               )
           }
        else if(authoritiesConge.some(auth => auth.authority == 'ROLE_MANAGER_CONGE')){
        return (
            <div>
                <a  title='Guide utilisateurs'  className={classes.pdf_download}  href = {PDF_MANAGER} target = "_blank"><PictureAsPdfIcon /></a>
            </div>
            )
        }
        else if(authoritiesConge.some(auth => auth.authority == 'ROLE_USER_PROD_CONGE')){
        return (
            <div>
                <a  title='Guide utilisateurs'  className={classes.pdf_download}  href = {PDF_CC} target = "_blank"><PictureAsPdfIcon /></a>
            </div>
            )
        }
    return null;

    }
    return null;
    }

    render() {
        const {anchorEl, mobileMoreAnchorEl, languages, codeLang} = this.state;
        const {classes} = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
        const result = this.pdf_downloader();
        const {_fr_name , _en_name, _pt_name } = strings

        const handleClose = () => {
            this.setState({
                anchorEl: null
            })
        };
        const handleChange = (codeLang) => {
            this.setState({
                anchorEl: null
            });
            sessionStorage.setItem('language', codeLang )
            this.props.getLanguage(setLanguage(codeLang));
            this.props.history.push("/");
            window.location.reload(false);
        };

        const handleClick = (event) => {
            this.setState({
                anchorEl: event.currentTarget
            })
        };

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem>

                    <IconButton color="inherit">
                        <TranslateIcon/>
                    </IconButton>
                    <p>Francais</p>
                </MenuItem>
                <MenuItem>
                    <IconButton color="inherit">
                        <Badge className={classes.margin} badgeContent={11} color="secondary">
                            <NotificationsIcon/>
                        </Badge>
                    </IconButton>
                    <p>Notifications</p>
                </MenuItem>
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        <AccountCircle/>
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            this.props.handleDrawerToggle()
                        }}
                        className={classes.menuButton} color="inherit" aria-label="Open drawer">
                        <MenuIcon/>
                    </IconButton>
                    <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                        MyVacation
                    </Typography>
                    <div className={classes.grow}/>
                    <div style={{marginRight: "-25px", marginTop: "-11px",position: "absolute", right: "80px"}} className={classes.sectionDesktop}>
                        <Tooltip title="Traduire" aria-label="add">
                            <TranslateIcon onClick={handleClick}
                                           style={{cursor: 'pointer', marginTop: 25, marginLeft: 11}}/>
                        </Tooltip>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                            transformOrigin={{vertical: "top", horizontal: "right"}}
                            PaperProps={{
                                style: {
                                    width: 150,
                                }
                            }}
                            disableAutoFocus
                            disableAutoFocusItem
                        >
                            {languages && languages.map(language =>
                                <MenuItem onClick={() => handleChange(language.code)}>
                                    <Grid container spacing={1}>
                                        <Grid style={{paddingLeft: '3px', paddingTop: "8px"}} item xs={6}>
                                                    <span style={{
                                                        paddingLeft: '5px',
                                                        fontFamily: 'Varela Round',
                                                        fontSize: '13px'
                                                    }}>{language.label}</span>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            )}
                        </Menu>
                    </div>
                    <div>
                    {result}
                    </div>
                    {/*<div className={classes.search}>*/}
                        {/*<div className={classes.searchIcon}>*/}
                            {/*<SearchIcon/>*/}
                        {/*</div>*/}
                        {/*<InputBase*/}
                            {/*placeholder="Recherche..."*/}
                            {/*classes={{*/}
                                {/*root: classes.inputRoot,*/}
                                {/*input: classes.inputInput,*/}
                            {/*}}*/}
                        {/*/>*/}
                    {/*</div>*/}
                    <div className={classes.grow}/>
                    {/*<div className={classes.sectionDesktop}>*/}
                        {/*<IconButton color="inherit">*/}
                            {/*<TranslateIcon/>*/}
                        {/*</IconButton>*/}
                        {/*<IconButton color="inherit">*/}
                            {/*<Badge className={classes.margin} badgeContent={17} color="secondary">*/}
                                {/*<NotificationsIcon/>*/}
                            {/*</Badge>*/}
                        {/*</IconButton>*/}
                        {/*<IconButton*/}
                            {/*aria-owns={isMenuOpen ? 'material-appbar' : null}*/}
                            {/*aria-haspopup="true"*/}
                            {/*onClick={this.handleProfileMenuOpen}*/}
                            {/*color="inherit"*/}
                        {/*>*/}
                            {/*<AccountCircle/>*/}
                        {/*</IconButton>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sectionMobile}>*/}
                        {/*<IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">*/}
                            {/*<MoreIcon/>*/}
                        {/*</IconButton>*/}
                    {/*</div>*/}
                </Toolbar>
                {renderMobileMenu}
            </div>
        );
    }
}




let mapStateToProps = (state) => {
    return {i18n: state.i18n, auth: state.auth}
};

let mapActionsToProps = {getLanguage};


export default withRouter(withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(PrimarySearchAppBar)));
