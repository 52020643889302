import Moment from 'moment'
import {extendMoment} from 'moment-range';
import {getHolidayType} from "../../../utilities/MappingFunctions";
import {
    API,
    MEDIUM_AVATAR_CORE_SERVER,
    MY_REQUESTS_FOLLOW,
    MY_REQUESTS_HISTORY
} from "../../../api/Endpoints";
import {getAxios} from "../../../actions/AuthActions";
import {lang, strings} from "../../../i18n/Strings";


const moment = extendMoment(Moment);

moment.locale(lang);
function isNegative(date, temp) {
    for( let d of temp ) {
        if(d.date == date && d.value < 0) {
            return "resteMax"
        }
    }
    return ""
}
const {_QUOTA, _MAX, _MIN, _TOTAL, _PL, _UL, _EL, _REST, _TEAM_LABEL, _LABEL_WEEK} = strings;

export const QuoatsBuild = {
    cellWidth : 40,
    data: {
        "teamLabel": _TEAM_LABEL,
        "project": "",
        "site": "",
        "zone": "",
        "start":"",
        "end": "",
        "quotas": [],
        "teams": [],
        "conges": [],
        "legends": [
            {label: 'Congé payé validé ', class: "CONGE_VALIDE_OK"},
            {label: 'Congé payé validé', class: "CONGE_VALIDE_KO"},
            {label: 'Congé payé refusé', class: "CONGE_REFUS_OK"},
            {label: 'Congé payé refusé', class: "CONGE_REFUS_KO"},
            {label: 'Demande en attente', class: "CONGE_PENDING"},

            {label: 'Congé TL', class: "CONGE_TL"},

            {label: 'Congé social validé ', class: "CONGE_VALIDE_OK_EXCEPTIONALHOLIDAY"},
            {label: 'Congé social refusé', class: "CONGE_REFUS_KO_EXCEPTIONALHOLIDAY"},
            {label: 'Demande social en attente', class: "CONGE_PENDING_EXCEPTIONALHOLIDAY"},

            {label: 'Congé sans solde validé ', class: "CONGE_VALIDE_OK_UNPAIDHOLIDAY"},
            {label: 'Congé sans solde validé', class: "CONGE_VALIDE_KO_UNPAIDHOLIDAY"},
            {label: 'Congé sans solde refusé', class: "CONGE_REFUS_OK_UNPAIDHOLIDAY"},
            {label: 'Congé sans solde refusé', class: "CONGE_REFUS_KO_UNPAIDHOLIDAY"},
            {label: 'Demande en attente', class: "CONGE_PENDING_UNPAIDHOLIDAY"},
        ]
    },
    typeDays: {jour: 'DD', name: 'dddd'},
    //monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNames: Array.apply(0, Array(12)).map(function(_,i){return moment().month(i).format('MMMM')}),
    teams: [],
    items: [],
    payload: [],
    parent: null,
    newTr: () => document.createElement("tr"),
    headRowDate: () => document.createElement("tr"),
    buildAll: (parent, element, startDate, endDate, holidays) => {
        holidays = (typeof holidays == 'undefined' ? [] : holidays);
        QuoatsBuild.parent = parent;
        const topTable = QuoatsBuild.buildTable("quotas",{projectName: QuoatsBuild.data.project, labelDate: 'Date ', typeDays: 'jour',
            className: 'quotasTable'}, startDate, endDate, (table, days) => {
            table.appendChild(QuoatsBuild.body(days));
            return table;
        });

        const middleTable = QuoatsBuild.buildTable( "holidays",{projectName: _TEAM_LABEL, labelDate: '', typeDays: 'jour', className: ''},
            startDate, endDate, (table, days) => {
                return table;
            })
    
        const bottomTable = QuoatsBuild.buildTable( "holidays",{projectName: _TEAM_LABEL, labelDate: '', typeDays: 'jour', className: ''},
            startDate, endDate, (table, days) => {
                table.appendChild(QuoatsBuild.bodyBar(days, holidays));
                return table;
            })

        element.innerText = '';
        let divTop = document.createElement('div');
            divTop.className = 'top_content';
        let divMiddle = document.createElement('div');
            divMiddle.className = 'middle_content';
        let divBottom = document.createElement('div');
            divBottom.className = 'bottom_content';

        divTop.appendChild(topTable)
        divMiddle.appendChild(middleTable)
        divBottom.appendChild(bottomTable)

        element.appendChild(divTop)
        element.appendChild(divMiddle)
        element.appendChild(divBottom)

        element.appendChild(QuoatsBuild.createLegends(QuoatsBuild.data.legends))

        setTimeout(() => {}, 100000);
        QuoatsBuild.accordion()
        QuoatsBuild.initEvent();
    },
    buildTeams: (startDate, endDate) => {

    },
    buildTable: (type, config, startDate, endDate, c) => {
        var headRowMonth = QuoatsBuild.newTr();
        var headRowWeek = QuoatsBuild.newTr();
        var headRowDate = QuoatsBuild.newTr();
        var table = document.createElement("table");
        table.className = config.className;
        var thead = document.createElement("thead");
        var tbody = document.createElement("tbody");

        QuoatsBuild.addTrHeader(headRowWeek, QuoatsBuild.addTh(config.projectName));
        QuoatsBuild.addTrHeader(headRowDate, QuoatsBuild.addTh(config.labelDate));
        var days = QuoatsBuild.enumerateDaysBetweenDates(moment(startDate, 'YYYY/MM/DD'), moment(endDate, 'YYYY/MM/DD'),true);
        var months = []

        days.forEach((day, i) => {
            const dayM = moment(day, 'YYYY/MM/DD');
            const isWeekend = dayM.day()%6==0; // Monday ... Sunday
            if (months.indexOf(dayM.format('MM')) == -1) {
                months.push(dayM.format('MMMM'))
            }
            let temp;
            QuoatsBuild.data.quotas.forEach( e => {
                if(e.code == "Reste") {
                    temp = e.values.map( d => {return { date: d.date, value: parseInt(d.value[0]) }});
                }
            });
            let rClass = "";
            if (type === "quotas") {
                rClass = isNegative(dayM._i, temp);
            }
            QuoatsBuild.addTrHeader(headRowDate, QuoatsBuild.addTh(dayM.format(QuoatsBuild.typeDays[config.typeDays]), null,
                {className: 'fixedSizeHead ' + (isWeekend ? 'isWeekend ': '') + rClass }));

            if ( dayM % 7 == 0) {
                QuoatsBuild.addTrHeader(headRowWeek, QuoatsBuild.addTh(dayM.lang("fr").format('MMMM')+ ' | ' + _LABEL_WEEK + ' ' + dayM.week(), 7));
            }
        })

        thead.appendChild(headRowMonth);
        thead.appendChild(headRowWeek);
        thead.appendChild(headRowDate);
        table.appendChild(thead);
        QuoatsBuild.WheelScroll(40);
        return c(table, days);
    },
    bodyBar: (days, holidays) => {
        var tbody = document.createElement("tbody");

        QuoatsBuild.data.teams.forEach(function(team, index) {
            var tr = document.createElement("tr");
            tr.className = "accordion"
            const nbrMemberbyTeam = QuoatsBuild.data.conges.filter( conge => {
                return String(conge.idTeam) == team.id;
            }).length;
            const firsttd = QuoatsBuild.addTd(`${team.label} ( ${nbrMemberbyTeam} )`, {className: ''});
            firsttd.classList.remove("fixedSize");
            setTimeout(() => {
                firsttd.style.width = 'calc('+ document.querySelector('.Quota .fixedSize').offsetWidth +'px )'; 
                firsttd.style.maxWidth = 'calc('+ document.querySelector('.Quota .fixedSize').offsetWidth +'px )'; 
            }, 100);
            tr.appendChild(firsttd);
            days.forEach((d, i) => {
                const max = QuoatsBuild.maxQuotas(team.id ,d, team.type, holidays);
                let dd   = moment(d).add(1, 'days').format('DD/MM/YYYY');
                let maxQ = team.maxQuota[dd]
                    maxQ = (typeof maxQ === "undefined" ? 0 : maxQ);
                tr.appendChild(QuoatsBuild.addTd([max, ' / ' + maxQ, ].join(''),
                    {className: (max > maxQ ? ' maxQuota ': '') + ' maxQuotaGetEl'}));
                    // {className: ' maxQuota '}));

                /*                tr.appendChild(QuoatsBuild.addTd(( max == 0 ? '' : [max, ' / ', el.maxQuota].join('')),
                    {className: (max > el.maxQuota ? ' maxQuota ': '')}));*/
            })
            var trP = document.createElement("tr");
            // + (index == 0 ? ' active': '')
            trP.className = "panel" ;
            trP.appendChild(QuoatsBuild.addTd(QuoatsBuild.payloadBody(days, team.id), {className: ' team_' + team.id}, days.length + 1));

            tbody.appendChild(tr);
            tbody.appendChild(trP);
        })


        return tbody;
    },
    body: (days) => {
        var tbody = document.createElement("tbody");

        QuoatsBuild.data.quotas.forEach(function(el) {
            var tr = document.createElement("tr");
            var colNames = {
                Quota: 'Quota en %',
                Max: 'Quota en nombre',
                Total: 'Total validé & en attente de validation Management (Hors Maternité)',
                Reste: 'Reste',
            }
            tr.className = el.class
            // tr.appendChild(QuoatsBuild.addTd(colNames[el.code]? colNames[el.code] : el.code));
            const maping = {
                "Quota": _QUOTA,
                "Max": _MAX,
                "Min": _MIN,
                "Total": _TOTAL,
                "CP": _PL,
                "CSS": _UL,
                "CE": _EL,
                "Reste": _REST
            }
            tr.appendChild(QuoatsBuild.addTd(maping[el.code] || el.code));
            days.forEach((day, i) => {
                const dayM = moment(day, 'YYYY/MM/DD');
                const isWeekend = dayM.day()%6==0; // Monday ... Sunday
                var v = '';
                var text = ''
                var className = (isWeekend ? ' isWeekend ' : '')
                for (var o in el.values) {
                    if ( moment(day, 'YYYY/MM/DD').isSame(moment(el.values[o].date, 'YYYY/MM/DD')) ) {
                        const values = el.values[o].value;
                        v = values.join('/')
                        if (values.length > 1) {
                            v = document.createElement('div');
                            var hr = document.createElement('HR');
                            hr.style.transform = el.values.length > 15 ? 'rotate(-45deg)':(el.values.length > 10 ?'rotate(-25deg)': 'rotate(-12deg)');
                            var s1 = document.createElement('span');
                            s1.innerText = values[0];
                            s1.style.position = 'relative';
                            s1.style.top = '5px';
                            s1.style.left = '-8px';

                            var s2 = document.createElement('span');
                            s2.innerText = values[1];
                            s2.style.position = 'relative';
                            s2.style.right = '-8px';

                            v.appendChild(s1);
                            v.appendChild(hr);
                            v.appendChild(s2);
                            text = `(matinée: ${values[0]} / aprés midi: ${values[1]} )`

                            // className += ' diagonale';
                        }
                        //diagonale
                    }
                }
                tr.appendChild(QuoatsBuild.addTd(v, {className, title: text}, null,el.code))
            })
            tbody.appendChild(tr);
        });
        return tbody;
    },
    payloadBody: (days, id) => {
        var tbody = document.createElement("tbody");

        QuoatsBuild.data.conges.forEach(function(conge) {
            // console.log('QuoatsBuild.data.conges.forEach ===> ', conge);
            if (conge.idTeam == id) {
                var user = document.createElement("span");
                var divparent = document.createElement("div");
                var divimg = document.createElement("div");
                    divimg.className = 'avatar-quota';
                    divimg.setAttribute('style', `background-image: url(${API}/${MEDIUM_AVATAR_CORE_SERVER}/${conge.user.userName})`)

                if(conge.user.tl) {
                    var tlBadge = document.createElement("div");
                    tlBadge.className = "tl";
                    tlBadge.innerText = "TL";
                    divimg.appendChild(tlBadge);
                }
                var tr = document.createElement("tr");
                tr.className = conge.class
                user.innerText = [conge.user.lastName, conge.user.firstName, '[' + conge.user.userName + ']'].join(' ');
                // user.style.paddingLeft = "8px";
                divparent.appendChild(divimg);
                divparent.appendChild(user);
                const firsttdt = QuoatsBuild.addTd(divparent);
                setTimeout(() => {
                    firsttdt.style.width = 'calc('+ (document.querySelector('.Quota .fixedSize').offsetWidth - 3) +'px )'; 
                    firsttdt.style.maxWidth = 'calc('+ (document.querySelector('.Quota .fixedSize').offsetWidth - 3) +'px )'; 
                }, 100);
                tr.appendChild(firsttdt);
                const first_date = moment(days[0], 'YYYY/MM/DD')
                const end_date = moment(days[days.length-1], 'YYYY/MM/DD')
                const lenghtPeriod = moment(end_date, 'YYYY/MM/DD').diff(moment(first_date, 'YYYY/MM/DD'), 'days');
                setTimeout(()=>{
                days.forEach((day, i) => {
                    const dayM = moment(day, 'YYYY/MM/DD');
                    const isWeekend = dayM.day()%6==0; ; // Monday ... Sunday
                    var v = '';
                    var className = (isWeekend ? ' isWeekend ' : '')
                    conge.writed = false
                    const distinctConge = [
                        ...new Map(conge.valeurs
                        .filter(d => d.typeRequest != "CANCELLATION" && d.type.indexOf("REFUS")  == -1)
                        .map((item) => [item["start"], item])).values(),
                    ];
                    distinctConge.filter(d => d.typeRequest != "CANCELLATION").forEach((d, i) => {
                        const lengthDay = moment(d.end, 'YYYY/MM/DD').add(1, 'days').diff(moment(day, 'YYYY/MM/DD'), 'days');
                        const htmlCollectionMaxQuota = document.getElementsByClassName('maxQuotaGetEl');
                            const width = htmlCollectionMaxQuota[0] ? htmlCollectionMaxQuota[0].offsetWidth : 0;
                            if (
                                   (moment(day, 'YYYY/MM/DD').isSame(moment(d.start, 'YYYY/MM/DD')))
                               // || (moment(day, 'YYYY/MM/DD').isSameOrAfter(moment(d.start, 'YYYY/MM/DD')) && moment(day, 'YYYY/MM/DD').isBefore(moment(d.end, 'YYYY/MM/DD')))
                                ||  moment(day, 'YYYY/MM/DD').isSameOrBefore(moment(d.end, 'YYYY/MM/DD')) && moment(moment(first_date, 'YYYY/MM/DD'), 'YYYY/MM/DD').isAfter(d.start) && moment(moment(first_date, 'YYYY/MM/DD'), 'YYYY/MM/DD').isSame(moment(day, 'YYYY/MM/DD'))
                            ) {
                                // console.log('request ====================> ', d);
                                var bar = document.createElement("div");
                                var lab = getHolidayType(d.label);
                                bar.className = 'bar ' + d.type + (d.label == 'PAIDHOLIDAY' ? '' : ('_' + d.label));
                                bar.title = lab;
                                bar.style.width = 'calc('+width+'px * ' + ( lengthDay ) + ')';
                                bar.innerText = lab;
                                bar.onclick = async function(){
                                    QuoatsBuild.parent.setState({ infoQuota: { user: conge.user, data : d, isShow: true } })
                                    const axios = getAxios();
                                    const myRequestsFollow = await axios.get(`${API}/${MY_REQUESTS_HISTORY}/${d.id}`);
                                    QuoatsBuild.parent.setState({follow: myRequestsFollow.data});
                                }
                                v = bar
                            }
                    })
                    tr.appendChild(QuoatsBuild.addTd(v, {className}))
                })
                tbody.appendChild(tr);
                },50);
            }
        });
        return tbody;
    },
    addTrHeader: (el, t) => {
        el.appendChild(t);
    },
    addTd: (text, opts, colspan, type) => {
        var td = document.createElement("td");
        if (typeof text == 'object') {
            td.appendChild(text)
        } else {
            td.appendChild(document.createTextNode(text))
        }
        td.className = 'fixedSize'
        if (opts) {
            td.className += opts.className;
            if(Object.keys(opts).indexOf('title') > -1) {
                td.title = opts.title
            }
        }
        if (typeof colspan !== 'undefined') {
            td.colSpan = colspan
        }
        if( text.innerHTML && type === "Reste" ) {
            var l = text.innerHTML.split('<span style="position: relative; right: -8px;">');
            if( typeof l != "undefined" && l.length) {
                var str = l[1].replace("</span>", "");
            }
            if( parseInt(str) < 0) {
                td.style.backgroundColor = "#fff";
                td.style.color = "#f44336";
                //td.style.textShadow = "0px 2px 0px black";
            }
        }
        return td;
    },
    addTh: (text, colspan, opts) => {
        var th = document.createElement("th");
        var span = document.createElement('span'); // is a node
        if (opts) {
            th.className += opts.className
        }
        if (colspan) {
            th.colSpan = colspan;
        }
        if (typeof(text) == 'object') {
            span.innerHTML = ``;
            th.appendChild(span);
        }else {
            span.innerHTML = text;
            th.appendChild(span);
        }
        return th
    },
    addSelect: (data, selected) => {
        var selectList = document.createElement("select");
        selectList.id = "month";

        //Create and append the options
        data.forEach((d, i) => {
            var option = document.createElement("option");
            option.value = d;
            option.text = d;
            if (i + 1  == selected + 1) {
                option.selected = 'selected';
            }
            selectList.appendChild(option);
        })
        return selectList;
    },
    daysInMonth: (start, end) => {
        let diffDays = 31;
        if (moment(start).isBefore(moment(end)) ) {
            diffDays = moment(end).diff(moment(start), 'days')
        }
        return diffDays
    },
    isDate: (s1, s2) => {
        var d1 = Date.parse(s1);
        var d2 = Date.parse(s2);
        return (d1 === d2)
    },
    enumerateDaysBetweenDates: (startDate, endDate, activeMinMax) => {
        let length = endDate.diff(startDate, 'days')
        var now = startDate, dates = [];
        if ( typeof activeMinMax == "undefined") {
            if ( length < 30) {
                endDate.add(Math.abs(length - 30), 'days');
            }
        } else {
            //now.add(1, 'days');
            endDate.add(1, 'days');
        }
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    },
    /* Function Events */
    on: (el, ev,  c) => {if (el) el.addEventListener(ev, c)},
    accordion: () => {
        var acc = document.getElementsByClassName("accordion");
        var legends = document.querySelector(".footer_legends");
        var i;
        var IndexOpened = 0;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.display === "block") {
                    panel.style.display = "none";
                    ++IndexOpened;
                } else {
                    panel.style.display = "block";
                    --IndexOpened;
                }

                if(IndexOpened == 0) {
                    legends.classList.remove("active");
                }else{
                    legends.classList.add("active");
                }
            });
        }
    },
    createTimeLine: () => {
        var table = document.createElement('TABLE');
        table.border = '1';

        var tableBody = document.createElement('TBODY');
        table.appendChild(tableBody);

        for (var i = 0; i < 3; i++) {
            var tr = document.createElement('TR');
            tableBody.appendChild(tr);

            for (var j = 0; j < 4; j++) {
                var td = document.createElement('TD');
                td.width = '75';
                td.appendChild(document.createTextNode("Cell " + i + "," + j));
                tr.appendChild(td);
            }
        }
        return table;
    },
    createLegends: (legends) => {
        const div = document.createElement('DIV');
        div.className = "footer_legends";
        if ( Array.isArray(legends ) ) {
            legends.forEach((d, i) => {
                var legend =  document.createElement('DIV');
                legend.className = d.class;
                legend.innerText = d.label;
                legend.title = d.title
                div.appendChild(legend);
            })
        }
        return div;
    },
    WheelScroll: (wheelto) => {
        let e = document.querySelector('#content')
        let mouseWheelEvt = function (event) {
            if (e.doScroll)
                e.doScroll(event.wheelDelta>0?"left":"right");
            else if ((event.wheelDelta || event.detail) > 0)
                e.scrollLeft -= wheelto;
            else
                e.scrollLeft += wheelto;

            return false;
        }
        // e.addEventListener("mousewheel", mouseWheelEvt);
    },
    initEvent: () => {
        // set width
        let quotasTable = document.querySelector('.quotasTable')
        let bottomContent = document.querySelector('.bottom_content')
        bottomContent.style.width = quotasTable.getBoundingClientRect().width + 'px'

        let close = document.querySelector('.info-quota .close');
        let total = document.querySelector('.total');
        total.onclick = function(ev) {
            const childbyTotal = document.querySelectorAll('.byTotal')
            childbyTotal.forEach((d, i) => {
                if (d.classList.contains('active')) {
                    d.classList.remove('active')
                } else {
                    d.classList.add('active')
                }
            })
                QuoatsBuild.scrollTableBottom(document.querySelector('#content .bottom_content'))
        }
        close.onclick = function () {
            const { infoQuota } = QuoatsBuild.parent.state;
            infoQuota.isShow = false
            QuoatsBuild.parent.setState({ infoQuota })
        }
        // fixed first t , tr table
        document.querySelector('#content').onscroll = function(ev) {
            var e = document.querySelectorAll('td:first-child:not([class*="team"]), th:first-child:not([class*="team"])')
            var thead = document.querySelector('.bottom_content thead')
            var leftThead = (this.scrollLeft == 0 ? 0 : this.scrollLeft)
            thead.style.left = '-' + leftThead + 'px'
            e.forEach(r => r.style.left = (this.scrollLeft - 1) + 'px')
        }
        document.querySelector('#content .bottom_content').onscroll = function() {
            QuoatsBuild.scrollTableBottom(this)
        }
        window.onresize = function() {
            const _top = document.querySelector('.top_content .quotasTable').getBoundingClientRect().width;
            if (_top) {
                const _bottom = document.querySelector('.bottom_content');
                _bottom.style.width = _top + 'px';
                document.querySelectorAll('.fixedSize[class*="team_"] tbody tr').forEach((el) => {
                    var  _top = document.querySelector('.top_content .quotasTable').getBoundingClientRect().width;
                    el.style.width = _top + 'px'
                })
            }
        }
    },
    maxQuotas: (id, date, type, holidays) => {
        type = (typeof type == 'undefined' ? '' : type);
        let maxQuota = {};
        QuoatsBuild.data.conges.forEach((d, i) => {
            if (!Array.isArray(maxQuota[d.idTeam])) { maxQuota[d.idTeam] = [] }
            maxQuota[d.idTeam].push( d.valeurs )
        });
        let dates = maxQuota[id];
            dates = (typeof  dates === "undefined" ? [] : dates)
            dates = [].concat(...dates).map(e => {
                e['dates'] = QuoatsBuild.enumerateDaysBetweenDates(moment(e.start), moment(e.end).subtract(1, "days"),
                    true)
                return e;
            })
        let count = 0;
        let unique = [];
        dates.forEach((d, i) => {
                d.dates.forEach((t, u) => {
                    if ( t === date && d.type.indexOf("REFUS")  == -1 && d.type != "CONGE_TL" && !unique.includes(d.id) && d.typeRequest != "CANCELLATION") {
                        // console.log(" d.dates.forEach OK : " , d);
                        unique.push(d.id);
                        count++;
                    }
                })
            });
            return count
    },
    scrollTableBottom: function($this) {
        var e = document.querySelector('.bottom_content thead')
        var em = document.querySelector('.middle_content')
        var el = e.getBoundingClientRect()
        var t = document.querySelector('.byTotal.active')
        var top = 0
        top = ($this.getBoundingClientRect().top - 64)

        if( $this.scrollTop  !== 0) {
            // e.style.position = 'absolute';
            // e.style.top = top + 'px'
        }else {
            // e.style.position = 'inherit';
            // e.style.top =  '0px'
        }
    }
}
