import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import {labelStyles} from "../../../styles/CommonStyles";
import InputLabel from "@material-ui/core/InputLabel";
import moment from 'moment';
import {getAxios} from "../../../actions/AuthActions";
import MenuItem from "@material-ui/core/MenuItem";
import {API} from "../../../api/Endpoints";
import {store} from "../../../store/ConfigureStore";
import {getQuotaHolidays} from "../../../actions/QuotaActions";
import {formatBackDate, formatDate} from "../../../utilities/MappingFunctions";
import {setTeams} from "../../../actions/ReferencesActions";
import { strings } from "../../../i18n/Strings";
import Select from 'react-select';


const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 500,
        background: theme.palette.background.paper,
        padding: 100,
        marginTop: 150
    },
    dialog: {
        width: '80%',
        maxHeight: 400
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    }
});

class SearchQuotaDialog extends React.Component {
    state = {
        zone: "",
        team: "",
        project: "",
        zones: [],
        teams: [],
        projects: [],
        start: "",
        end: "",
    };

    componentWillMount() {
        this.getAllZones();
    }

    componentWillUnmount() {
        this.setState({zone: "", team: "", project: ""})
    }

    handleChange = (event, name) => {
        this.setState({[name]: event.target.value})
    };

    handleTeamChange = (event) => {

        this.setState({ team: event.target.value})
    };

    getAllZones = async () => {
        const axios = getAxios();
        const zones = await axios.get(`${API}/conges/uo/quarters`);
        this.setState({zones: zones.data})
    }

    getProjectsByZone = async id => {
        const axios = getAxios();
        const projects = await axios.get(`${API}/conges/uo/byzone/${id}`)
        this.setState({projects: projects.data});
    }

    getTeamsByProject = async (idProject) => {
        const axios = getAxios();
        const teams = await axios.get(`${API}/conges/uo/teams/${this.state.zone}/${idProject}`)
        this.setState({teams: teams.data})
        store.dispatch(setTeams(teams.data));
    }



    handleSubmit = () => {
        const {zone, team, project, start, end} = this.state;
        // alert(JSON.stringify({idTeam: parseInt(team), start, end}))
        // store.dispatch(getQuotaHolidays({idTeam: parseInt(team), start: moment(start).format("DD/MM/YYYY"), end: moment(end).format("DD/MM/YYYY")}))

    };

    /* handle select: zone*/
    handleZone = function(event) {
        this.handleChange({
            target: event.value
        }, "project");
        this.getTeamsByProject(event.value);
        this.setState({team: ""})
    }
    compare(a, b) {
        return a.shortName > b.shortName ? 1 : b.shortName > a.shortName ? -1 : 0;
    }
    compareProject(a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    }
    render() {
        const {classes} = this.props;
        const {zone, team, project, zones, projects, teams, start, end} = this.state;
        const {_area , _project,_starting,_end,_team,_cancel,_confirm,_searchQuota}=strings;
        return (<Dialog
                    classes={{
                        paper: classes.dialog,
                    }}
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                {_searchQuota}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid container direction={"column"} spacing={32} style={{paddingTop: 10}}>
                            <Grid item>
                                <Grid container spacing={32} rowSpacing={0}>
                                    <Grid item xs={6} spacing={1}>
                                        <InputLabel
                                            style={labelStyles}
                                        >
                                            {_area}
                                        </InputLabel>
                                        <br/>
                                        {/*<TextField
                                            value={zone}
                                            onChange={async (event) => {
                                                this.handleChange(event, "zone");
                                                this.getProjectsByZone(event.target.value);
                                                this.setState({ team: "", project: ""})
                                            }}
                                            style={{width: 250}}
                                            id="zone"
                                        >
                                            {zones.map((zone, index) => <MenuItem key={index} value={zone.id}> {zone.shortName}</MenuItem>)}
                                        </TextField>*/}
                                        <Select
                                            options={(zones || []).sort(this.compare).map((zone, index) => ({value: zone.id, label: zone.shortName}))}
                                            width={'100%'}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    minHeight: '31px',
                                                    height: '31px',
                                                }),
                                                indicatorsContainer: (provided, state) => ({
                                                    ...provided,
                                                    height: '31px',
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    width: "max-content",
                                                    minWidth: "100%"
                                                })
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className={"rs-autocomplete"}
                                            onChange={(event) => {
                                                this.setState({zone: event.value})
                                                this.getProjectsByZone(event.value);
                                                this.setState({ team: "", project: ""})
                                                console.log(event)
                                            }}

                                            placeholder=" "
                                        />
                                        </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel
                                            style={labelStyles}
                                        >
                                            {_project}
                                        </InputLabel>
                                        <br/>
                                        {/*<TextField
                                            select
                                            value={project}
                                            onChange={(event) => {
                                                this.handleChange(event, "project");
                                                this.getTeamsByProject(event.target.value);
                                                this.setState({team: ""})
                                            }}
                                            style={{width: 250}}
                                            id=""
                                        >
                                            {projects.map( project => { return { id : project.projectCode, name: project.name}}).map((project, index) => <MenuItem key={index} value={project.id}> {project.name}</MenuItem>)}
                                        </TextField>*/}
                                        <Select
                                            options={projects.sort(this.compareProject).map((project, index) => ({value: project.projectCode, label: project.name}))}
                                            width={'100%'}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    minHeight: '31px',
                                                    height: '31px',
                                                }),
                                                indicatorsContainer: (provided, state) => ({
                                                    ...provided,
                                                    height: '31px',
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    width: "max-content",
                                                    minWidth: "100%"
                                                })
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className={"rs-autocomplete"}
                                            onChange={(event) => {
                                                this.setState({project: event.value})
                                                this.getTeamsByProject(event.value);
                                                this.setState({team: ""})
                                            }}

                                            placeholder=" "
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction={"row"}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={<InputLabel
                                                style={{fontSize: 25}}
                                            >
                                                {_starting}
                                            </InputLabel>}
                                            style={{width: 250, marginLeft: -2}}
                                            type="date"
                                            // defaultValue={moment().format("YYYY-MM-DD")}
                                            onChange={(event) => {
                                                this.handleChange(event,"start")
                                            }}
                                            value={moment(start).format("YYYY-MM-DD")}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            style={{width: 250, marginLeft: 22}}
                                            label={<InputLabel
                                                style={{fontSize: 25}}
                                            >
                                                {_end}
                                            </InputLabel>}
                                            type="date"
                                            defaultValue={moment().add('1','months').format("YYYY-MM-DD")}
                                            onChange={(event) => {
                                               this.handleChange(event,"end")
                                            }}
                                            value={moment(end).format("YYYY-MM-DD")}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{paddingTop: 20}} xs={12}>
                                        <InputLabel
                                            style={labelStyles}
                                        >
                                            {_team}
                                        </InputLabel>
                                        <br/>
                                        <TextField
                                            select
                                            value={team}
                                            onChange={(event) => {
                                                this.handleTeamChange(event)
                                            }}
                                            style={{width: 250}}
                                            id=""
                                        >
                                            {teams.map((team, index) => <MenuItem
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: 250,
                                                        width: 200,
                                                    },
                                                }}
                                                key={index} value={team}> {team.shortName}</MenuItem>)}
                                        </TextField>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => {
                            this.props.handleDialog();
                            this.setState({zone: "", team: "", project: "", teams: [], projects: []})
                        }} color="primary">
                            {_cancel}
                        </Button>
                        <Button disabled={( start === "") || (end === "") || project === "" || zone === ""} onClick={() => {
                            this.props.handleDialog();
                            this.handleSubmit();
                            this.props.generateData( team === "" ? teams : [team],"", parseInt(project), parseInt(zone), formatBackDate(start), formatBackDate(end));
                            this.setState({zone: "", team: "", project: "", teams: [], projects: []})
                        }} color="primary">
                            {_confirm}
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    }
}

export default withStyles(styles)(SearchQuotaDialog);
