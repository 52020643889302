import React from "react";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {API, MEDIUM_AVATAR_CORE_SERVER} from "../../../api/Endpoints";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import FilterIcon from "@material-ui/icons/Sort";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { strings } from "../../../i18n/Strings";
import Tooltip from "@material-ui/core/Tooltip";
import { store } from "../../../store/ConfigureStore";
import { getEmployeeSolde, getEmployeeSoldeByPage } from "../../../actions/SoldeActions";
import CustomTableToolBar from './CustomTableToolBar'
import GridData from './Grid';
import connect from "react-redux/es/connect/connect";

const styles = theme => ({
  root: {
    width: 350,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "hidden",
    padding: 0
  },
  rootLarge: {
    width: 'calc(100vw - 220px)',
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "hidden",
    padding: 0
  },
  listSection: {
    backgroundColor: "inherit",
    padding: 0
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  title: {
    marginTop: 7
  }
});

function Container(props) {
  return (
    <Grid container justify={"center"}>
      <div style={{ marginTop: 20 }}>{props.children}</div>
    </Grid>
  );
}

class UsersList extends React.Component {
  state = {
    page: 0,
    size: 50,
    filters: [],
    sort: "solde",
    order: false,
    small: true
  };
  changeFilters = (filters) => {
    console.log('filter',filters);
    this.setState({filters: filters.reduce(function(obj,item){
      obj[item.columnName] = item.value;
      return obj;
    }, {})}, () => {
    const {page, size, sort, order, filters} = this.state;
    const {registrationNumber, uo, firstName, lastName, solde } = filters;
    this.props.getEmployeeSoldeByPage(page, size, sort, order, registrationNumber, uo, firstName, lastName, solde);
})
  };

  changeSorting = (sorting) => {
    const {columnName, direction} = sorting[0];
    this.setState({sort: columnName, order: (direction === "asc")});
    const {page, size, filters} = this.state;
    const {registrationNumber, uo, firstName, lastName, solde } = filters;
    this.props.getEmployeeSoldeByPage(page, size, columnName, (direction === "asc"), registrationNumber, uo, firstName, lastName, solde);
   };
  changePaging = ({page, size}) => {
      const {sort, order, filters} = this.state;
      const {registrationNumber, uo, firstName, lastName, solde } = filters;
      this.props.getEmployeeSoldeByPage(page-1, size, sort, order, registrationNumber, uo, firstName, lastName, solde);
   };
  render() {
    const { small } = this.state; 
    const { classes, soldeEmployee, loadingEmployeeSolde, auth, solde } = this.props;
    const { _employeesBalance, _noResultsFound } = strings;
    const {totalElements} = solde;
    const limitIsReached = soldeEmployee => {
        return soldeEmployee.length == totalElements
    };
    const loading = false;
    const data = soldeEmployee;
    // const totalElements = soldeEmployee.length;
    const {searchActive} = auth;
    const {sort, order, filters} = this.state;
    
    return (
      <List className={small ? classes.root : classes.rootLarge}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Grid container>
              <Grid item sm={2}>
                <IconButton aria-label="ChevronLeft" onClick={() => {
                    this.setState({small: !small});
                    const {registrationNumber, uo, firstName, lastName, solde } = filters;
                    !small ? this.props.getEmployeeSolde(0, 50, "solde", false, null, null, null, null, null) : 
                      this.props.getEmployeeSoldeByPage(0, 25, "solde", false, null, null, null, null, null);
                    }}>
                  {small ? 
                  <ArrowBackIosIcon style={{ color: "white" }} fontSize="small" />
                  :<ArrowForwardIosIcon style={{ color: "white" }} fontSize="small" />}
                </IconButton>
              </Grid>
              <Grid item sm={10}>
                <Typography
                  className={classes.title}
                  variant="subheading"
                  color="inherit"
                >
                  {_employeesBalance}
                </Typography>
              </Grid>
              {/* <Grid item sm={2}>
                <IconButton aria-label="Delete">
                  <FilterIcon style={{ color: "white" }} fontSize="small" />
                </IconButton>
              </Grid> */}
            </Grid>
          </Toolbar>
        </AppBar>
        { small ? (loadingEmployeeSolde && soldeEmployee.length === 0 ? (
          <Container>
            <CircularProgress color="primary" />
          </Container>
        ) : (
          <div style={{ height: "calc(100vh - 64px)", overflowY: "scroll" }}>
            {soldeEmployee.length === 0 ? (
              <Container>{_noResultsFound}</Container>
            ) : [
                ...soldeEmployee.map((UOMember, index) => {
                  const {
                    registrationNumber,
                    firstName,
                    lastName,
                    uo,
                    solde
                  } = UOMember;
                  return (
                    <ListItem key={index} button>
                      <Tooltip
                        title={`${registrationNumber}`}
                        placement="right-end"
                      >
                        <Avatar
                          src={`${API}/${MEDIUM_AVATAR_CORE_SERVER}/${registrationNumber}`}
                        />
                      </Tooltip>
                      <ListItemText
                        primary={`${firstName} ${lastName}`}
                        secondary={uo}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Comments"
                          style={{ color: solde > 48 ? "red" : "grey" }}
                        >
                          {solde}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }),
                <ListItem
                  onClick={() => {
                    if (!(limitIsReached(soldeEmployee))) {
                      let currentPage = this.state.page + 1;
                      this.setState({ page: currentPage });
                      this.props.getEmployeeSolde(currentPage, this.state.size);
                    }
                  }}
                  button={!limitIsReached(soldeEmployee)}
                >
                  <ListItemText
                    primary={`${
                      limitIsReached(soldeEmployee) ? "" : "Plus de résultats"
                    }`}
                  />
                  <ListItemSecondaryAction>
                    {loadingEmployeeSolde ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <></>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ]
            }
          </div>
        )) : (
          <div>
            <CustomTableToolBar
              filters={filters}
              sort={sort}
              order={order}
              data={data}
              loading={loading}
              changePaging={(page,size) => {this.changePaging(page, size)}}
              totalPages={totalElements}
            />
            <GridData
              searchActive={searchActive}
              loading={loading}
              data={data}
              changeSorting={this.changeSorting}
              changeFilters={this.changeFilters}/>
          </div>
        ) }
      </List>
    );
  }
}

let mapStateToProps = (state) => {
  return {auth: state.auth, solde: state.solde}
};

let mapActionsToProps = {
  getEmployeeSoldeByPage,
  getEmployeeSolde
};

export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(UsersList));
