import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {getAddedRequests, showConfirmationDialog} from "../../../actions/RequestsActions";
import {store} from "../../../store/ConfigureStore";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core/styles";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function getSelectedRow(id, addedRequests) {
    for (let request of addedRequests.data) {
        if (request.assignedToConge.id === id) {
            return request
        }
    }
    return {}
}

const getHolidayType = type => {
    if (type === "PAIDHOLIDAY") {
        return "Congé payé";
    }
    if (type === "RTTHOLIDAY") {
        return "Congé RTT";
    }
    if (type === "SENIORITYHOLIDAY") {
        return "Congé Ancienneté";
    }
    if (type === "UNPAIDHOLIDAY") {
        return "Congé sans solde";
    }
    if (type === "EXCEPTIONALHOLIDAY") {
        return "Congé exceptionnel";
    }
    if (type === "ENTRYRECUP") {
        return "Récupération";
    }
    if (type === "CASUALLEAVE") {
        return "Congé occasionnel";
    }
    else {
        return type;
    }
};

class ConfirmationDialog extends React.Component {

    render() {
        const { addedRequests} = this.props;
        const {dialog} = this.props.requests;
        const {id, open} = dialog ;
        const request = getSelectedRow(id,addedRequests).assignedToConge || {};
        const {startDate, returnDate, typeConge} = request;
        return (
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="#"
                    aria-describedby="#"
                >
                    <DialogTitle id="#">
                       Suppression du congé
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="#">
                            Confirmez-vous la suppression du congé suivant : <br/> Type de congé : {getHolidayType(typeConge)} <br/> du : {startDate} au {returnDate} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            store.dispatch(showConfirmationDialog({type: "CANCEL", id: null, open: false}))
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {

                            store.dispatch(showConfirmationDialog({type: "CANCEL", id: null, open: false}))
                        }} color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests}
};

let mapActionsToProps = {getAddedRequests};


export default withStyles(() => {})(connect(mapStateToProps, mapActionsToProps)(ConfirmationDialog));
