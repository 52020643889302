import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UnExportedHolidays from './unExportedHolidays/UnExportedHolidays';
import OnExportErrors from './onErrorExports/OnExportErrors';


const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        // height: "100vh",
    },
});

class ExportManagementTabs extends React.Component {
    state = {
        value: '0',
    };

    handleChange = (event, value) => {
        this.setState({value});
        window.localStorage.setItem("CURRENT_TAB", value ? "UNEXPORTED_HOLIDAYS" : "ERROR_EXPORTS")
    };

    componentWillMount() {
        window.localStorage.setItem("CURRENT_TAB", "UNEXPORTED_HOLIDAYS")
    }

    render() {
        const {classes} = this.props;
        const {value} = this.state;

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange}>
                        <Tab value="0" label="Congés non exportés"/>
                        <Tab value="1" label="Exports en erreur"/>
                    </Tabs>
                </AppBar>
                {value === '0' && <UnExportedHolidays/>}
                {value === '1' && <OnExportErrors/>}
            </div>
        );
    }
}


export default withStyles(styles)(ExportManagementTabs);
