import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {store} from "../../../store/ConfigureStore";
import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core/styles";
import {setDeleteMappingDialog} from "../../../actions/ReferencesActions";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class DeleteMappingConfimationDialog extends React.Component {

    render() {
        const {deleteMappingDialog} = this.props.references;
        const {mapping, open} = deleteMappingDialog;
        // const selectedRow = JSON.parse(mapping);
        return (
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="#"
                    aria-describedby="#"
                >
                    <DialogTitle id="#">
                        Suppression
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="#">
                            Confirmez-vous la suppression du mapping Téléopti ? <br/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            store.dispatch(setDeleteMappingDialog({open: false, mapping: "{}"}))
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            store.dispatch(setDeleteMappingDialog({open: false, mapping: "{}"}))
                        }} color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {references: state.references}
};

let mapActionsToProps = {};


export default withStyles(() => {
})(connect(mapStateToProps, mapActionsToProps)(DeleteMappingConfimationDialog));
