import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {withStyles} from '@material-ui/core/styles';
import {setShowAddExceptionalHoliday} from "../../../actions/HolidaysActions";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/es/TextField/TextField";
import '../../../styles/DatePicker.css';
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import {_labelStyles} from "../../../styles/CommonStyles";
import SelectAutoComplete from "../mappingTeleopti/SelectAutoComplete";
import ProvedByDocumentsGrid from "./ProvedByDocumentsGrid";
import {addExceptionalHoliday, editExceptionalHoliday, getBackup} from "../../../actions/ReferencesActions";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import Checkbox from "@material-ui/core/Checkbox";
import {getAxios} from "../../../actions/AuthActions";
import {API} from "../../../api/Endpoints";


const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 600,
            maxHeight: 600,
            maxWidth: 650,
            minWidth: 650,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        textField: {
            height: 50,
            width: 250
        },
        button: {
            width: '80vw',
            maxWidth: 480
        },
        leftIcon: {
            marginRight: 5,
        },
        rightIcon: {
            marginLeft: 5,
        },
        iconSmall: {
            fontSize: 20,
        },
    }
};



function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class AddExceptionalHolidayDialog extends React.Component {
    state = {
        value: 0,
        label: null,
        duration: null,
        country: null,
        mappingHR: null,
        mappingTO: null,
        focusedInputRange: null,
        provedByDocuments: [{ label: " ", id: null}],
        forJustify: false,
        mappingsHR: []
    };

    handleChange = (value, name) => {
        this.setState({[name]: value});
    };

    handleDocumentChange = (label, id) => {
        let {provedByDocuments} = this.state;
        provedByDocuments = provedByDocuments.map(e => {
            return (e.id === id) ? { id, label, delete: false}: e
        });

        this.setState({provedByDocuments})
    }


    componentWillMount() {
        if (this.props.type === "EDIT" ) {
            const {label, duration, country, mappingHR, provedByDocuments, forJustify, mappingTO, id} = this.props.holidays.showAddExceptionalHoliday.details;
            this.setState({id, label, duration, country, mappingHR, provedByDocuments, forJustify, mappingTO})
            this.getMappingHR(country.id);
        }

    }

    getMappingHR(id) {
        const axios = getAxios();
        axios.get(`${API}/conges/exceptionalType/mapping/${id}`).then(res => {
            this.setState({mappingsHR : res.data})
        })
    }

    handleMappingChange = (mapping) => {

        this.setState({mappingHR: this.state.mappingsHR.filter( $mapping => $mapping.id === mapping.value)[0]})
    }

    handleCountryChange = (country) => {

        const {value, label} = country;
        this.setState({country: {id: value, label}})
        this.getMappingHR(value)
    }

    deleteDocument = (id) => {
        let {provedByDocuments} = this.state;
        if( provedByDocuments.length === 1) {
            this.setState({justifyDocuments: [{ label: "", id: null, delete: false}]})
        }
        else {
            provedByDocuments = provedByDocuments.map(e => {
                return (e.id === id) ? {...e, delete: true }: e
            });
            this.setState({provedByDocuments: provedByDocuments})
        }

    }

    addDocument = () => {
        let {provedByDocuments} = this.state;
        provedByDocuments.push({ id: Date.now(), label: "", delete: false});
        this.setState({provedByDocuments: provedByDocuments})
    }


    componentWillUnmount() {
        this.setState({label: null, startDate: null, returnDate: null, country: null})
    }

    render() {
        const {classes, references, holidays, type} = this.props;
        const {activeCountries} = references;
        let {label, duration, country, mappingHR, provedByDocuments, forJustify, mappingsHR} = this.state;
        return (
            <div style={{position: "relative"}}>
                <Dialog
                    open={true}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    classes={{paper: classes.dialogPaper}}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                {`${(type === "EDIT") ? 'Editer' : 'Ajouter'} un type de congé exceptionnel`}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{marginTop: 16}}>
                        <Grid container direction={"row"} spacing={32}>
                            <Grid item sm={6}>
                                <InputLabel style={_labelStyles} htmlFor="publicHoliday">Label</InputLabel>
                                <TextField
                                    id={"publicHoliday"}
                                    onChange={(e) => {
                                        this.handleChange(e.target.value, "label")
                                    }}
                                    autoFocus
                                    value={label}
                                    margin="dense"
                                    id="publicHoliday"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item sm={6} style={{marginTop: 3}}>
                                <InputLabel style={_labelStyles} htmlFor="duration">Durée légale en jours</InputLabel>
                                <TextField
                                    id={"duration"}
                                    onChange={(e) => {
                                        this.handleChange(e.target.value, "duration")
                                    }}
                                    fullWidth
                                    value={duration}
                                    type="number" inputProps={{min: "0", max: "999", step: "1"}}/>
                            </Grid>
                            <Grid item sm={6}>
                                <SelectAutoComplete
                                    type={"country"}
                                    handleCountryChange={this.handleCountryChange}
                                    defaultValue={country}
                                    data={activeCountries} label={"Pays"}/>
                            </Grid>
                            <Grid item sm={6}>
                                <SelectAutoComplete
                                    type={"mapping"}
                                    defaultValue={mappingHR}
                                    handleMappingChange={this.handleMappingChange}
                                    data={mappingsHR} label={"Mapping HR Acccess"}/>
                            </Grid>
                            <Grid item sm={12}>
                                <Grid container spacing={4}>
                                    <Grid item>

                                        <Checkbox
                                            checked={forJustify}
                                            onChange={() => {
                                                    this.setState({forJustify: !forJustify})
                                            }}
                                            value="checkedA"
                                        />

                                    </Grid>
                                    <Grid item>
                                        <Typography style={{paddingTop: 15, color: "#878787"}}  gutterBottom noWrap>
                                            A justifié
                                        </Typography>


                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>

                                <Grid container justify={"center"} alignItems={"center"} direction={"column"} style={{padding: 24, border: "1px solid #878787",borderRadius: 5}}>
                                    {
                                        provedByDocuments.filter( e => e.delete == false).map((e, id) => <Grid item>
                                            <div key={id} style={{position: "relative"}}>
                                                <div style={{position: "absolute", marginLeft: 235, top: -8}}>
                                                    <IconButton onClick={() => {
                                                        this.deleteDocument(e.id)
                                                    }}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </div>
                                                <TextField
                                                    classes={{
                                                        root: classes.textField
                                                    }}
                                                    placeholder=" "
                                                    value={e.label}
                                                    onChange={(event) => {

                                                        this.handleDocumentChange(event.target.value, e.id)
                                                    }}/>
                                                <br/>
                                            </div>
                                            </Grid>

                                        )
                                    }
                                    <Button onClick={this.addDocument} size="small" className={classes.button}>
                                        <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
                                        Piece justificative
                                    </Button>
                                </Grid>



                                {/*<ProvedByDocumentsGrid provedByDocuments={provedByDocuments}/>*/}
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => {
                            this.props.setShowAddExceptionalHoliday({type: "ADD", open: false, details: {}})
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.setShowAddExceptionalHoliday({type: "ADD", open: false, details: {}})
                            const {provedByDocuments, forJustify, label, duration, country, mappingTO, mappingHR, id} = this.state;
                            if(type === "EDIT") {
                                this.props.editExceptionalHoliday({provedByDocuments, forJustify, label, duration, country, mappingTO, mappingHR, id});
                            }
                            else {
                                this.props.addExceptionalHoliday({provedByDocuments, forJustify, label, duration, country, mappingHR});
                            }
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {references: state.references, holidays: state.holidays}
};

let mapActionsToProps = {
    setShowAddExceptionalHoliday,
    editExceptionalHoliday,
    addExceptionalHoliday
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(AddExceptionalHolidayDialog));
