import * as React from 'react';
import {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {DataTypeProvider, IntegratedFiltering, IntegratedSorting, SortingState,} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import {tableSize} from '../../../styles/CommonStyles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MGrid from '@material-ui/core/Grid'
import {CustomEmptyTable, CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import {setShowAddRecup} from "../../../actions/HolidaysActions";
import {store} from "../../../store/ConfigureStore";
import {
    RequestStatusProvider,
    HolidayTypeProvider,
    FilterCell, IdEditor, noPredicate, compareDateTimes
} from "../../../utilities/MappingFunctions";
import {strings} from "../../../i18n/Strings";
import {FilteringState} from "@devexpress/dx-react-grid";
import {TableFilterRow} from "@devexpress/dx-react-grid-material-ui";
import {CustomTableContainer} from "../../../utilities/CustomTableContainer";
import {startDatePredicate, endDatePredicate, compareDates} from "../../../utilities/MappingFunctions";
import {
    getMyRequestsFollow,
    getMyRequestsHistory,
    showRequestDetails,
    showRequestHistory
} from "../../../actions/RequestsActions";
import HistoryIcon from '@material-ui/icons/History'

const RecupStatusFormatter = ({value}) => {
    if (value === "CONSUMED") {
        return <div style={{fontWeight: "bold", color: "black"}}>Consommée</div>
    }
    if (value === "PENDING") {
        return <div style={{fontWeight: "bold", color: "black"}}>En attente</div>
    }
    if (value === "ACTIVE") {
        return <div style={{fontWeight: "bold", color: "green"}}>Active</div>
    }
    if (value === "EXPIRED") {
        return <div style={{fontWeight: "bold", color: "red"}}>Exiprée</div>
    }
    if (value === "INACTIVE") {
        return <div style={{fontWeight: "bold", color: "red"}}>Inactive</div>
    }
    else {
        return <div style={{fontWeight: "bold", color: "red"}}>{value}</div>
    }
};


const IdFormatter = ({value}) => (
    <MGrid container spacing={0}>
        <MGrid item>
            <IconButton onClick={() => {
                const {id} = value;
                store.dispatch(getMyRequestsHistory(id));
                store.dispatch(showRequestHistory(true));
            }
            } color="default" aria-label="add">
                <HistoryIcon fontSize="small"/>
            </IconButton>
        </MGrid>
        <MGrid item>
            <IconButton disabled={!(value.recuperationStatus === "ACTIVE")} onClick={() => {
                store.dispatch(setShowAddRecup({open: true, details: value, type: "EDIT"}))
            }} aria-label="Cancel">
                <EditIcon fontSize="small"/>
            </IconButton>
        </MGrid>
        <MGrid item>
            <IconButton onClick={() => {
                store.dispatch(setShowAddRecup({open: true, details: value, type: "DELETE"}))
            }} aria-label="Delete">
                <DeleteIcon fontSize="small"/>
            </IconButton>
        </MGrid>
    </MGrid>
);



const RecupStatusProvider = props => (
    <DataTypeProvider
        formatterComponent={RecupStatusFormatter}
        {...props}
    />
);

const IdProvider = props => (
    <DataTypeProvider
        formatterComponent={IdFormatter}
        editorComponent={IdEditor}
        {...props}
    />
);


export default class GlobalGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableColumnExtensions: [
                {columnName: 'value', width: 220, align: "right"},
                {columnName: 'duration', width: 80, wordWrapEnabled: true},
                {columnName: 'startDate', wordWrapEnabled: true},
                {columnName: 'returnDate', wordWrapEnabled: true},
                {columnName: 'totalDuration', width: 80, wordWrapEnabled: true},
                {columnName: 'status', wordWrapEnabled: true},
                {columnName: 'typeRequest', wordWrapEnabled: true},
                {columnName: 'updateDate', wordWrapEnabled: true},
                {columnName: 'recuperationStatus', wordWrapEnabled: true},
            ],
            integratedFilteringColumnExtensions: [
                { columnName: 'startDate', predicate: startDatePredicate },
                { columnName: 'returnDate', predicate: endDatePredicate },
                { columnName: 'status', predicate: noPredicate },
                { columnName: 'recuperationStatus', predicate: noPredicate },
                { columnName: 'duration', predicate: noPredicate }
                ],
            integratedSortingColumnExtensions: [
                { columnName: 'startDate', compare: compareDates },
                { columnName: 'returnDate', compare: compareDates },
                { columnName: 'updateDate', compare: compareDateTimes }
            ],
            exportColumns: ['status'],
            idColumns: ['value'],
            recupsColumns: ['recuperationStatus'],
            holidayTypeColumns: ['typeConge']
        };
    }

    render() {
        const {integratedSortingColumnExtensions, exportColumns, recupsColumns, idColumns,integratedFilteringColumnExtensions, tableColumnExtensions, holidayTypeColumns} = this.state;
        const rows = this.props.data;
        const {loading, searchActive} = this.props;
        const {_startDate, _endDate, _updateDate, _status, _totalDuration, _recupStatusConsumed, _rejected} = strings;
        const columns = [
            {name: 'updateDate', title: _updateDate},
            {name: 'startDate', title: _startDate},
            {name: 'returnDate', title: _endDate},
            {name: 'status', title: _status},
            {name: 'totalDuration', title: _totalDuration},
            {name: 'recuperationStatus', title: _recupStatusConsumed},
            {name: 'value', title: ' '}
        ]
        return (
            <Fragment>

                <Paper style={tableSize}>
                    <Grid
                        rows={rows.map(row => {
                            return {value: row, ...row};
                        })}
                        columns={columns}
                        style={tableSize}
                    >

                        <RequestStatusProvider
                            for={exportColumns}
                        />
                        <HolidayTypeProvider
                            for={holidayTypeColumns}
                        />
                        <RecupStatusProvider
                            for={recupsColumns}
                        />
                        <IdProvider
                            for={idColumns}
                        />
                        <SortingState
                            defaultSorting={[{columnName: 'requestDate', direction: 'asc'}]}
                            onSortingChange={(sorting) => {
                                this.props.changeSorting(sorting)
                            }}
                        />
                        <FilteringState
                        onFiltersChange={(filters) => {this.props.changeFilters(filters)}}
                        />
                        <IntegratedSorting
                            columnExtensions={integratedSortingColumnExtensions}/>
                        <IntegratedFiltering
                            columnExtensions={integratedFilteringColumnExtensions}
                        />
                        <Table
                            rowComponent={CustomTableRow}
                            containerComponent={CustomTableContainer}
                            noDataCellComponent={() => {
                                return <CustomEmptyTable loading={loading}/>
                            }}
                            columnExtensions={tableColumnExtensions}/>
                        <TableHeaderRow
                            contentComponent={TableHeaderContent}
                            showSortingControls/>
                        {searchActive && <TableFilterRow
                            messages={{filterPlaceholder: " "}}
                            cellComponent={FilterCell}
                        />}
                    </Grid>
                </Paper>
            </Fragment>
        );
    }
}
