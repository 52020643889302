import moment from 'moment-timezone';

export const getDateElementsFromString = (date, separator) => date.split(separator);
export const getDay = dateArray => dateArray[0];
export const getMonth = dateArray => dateArray[1];
export const getYear = dateArray => dateArray[2];

export const sortDateComparator = (a, b) => {
    const aElements = getDateElementsFromString(a, '/');
    const bElements = getDateElementsFromString(b, '/');
    const A = new Date(getYear(aElements), getMonth(aElements) - 1, getDay(aElements));
    const B = new Date(getYear(bElements), getMonth(bElements) - 1, getDay(bElements));
    return moment(A).diff(B, 'days');
}
export const countDaysOffAfterFinishDate = (daysOff, endDate) => {
    return Object.keys(daysOff).filter(key => {
        const keyDateElements = getDateElementsFromString(key, '/');
        const keyDate = new Date(getYear(keyDateElements), getMonth(keyDateElements) - 1, getDay(keyDateElements));
        return daysOff[key] && daysOff[key].type != "LEAVE_DAY" && moment(keyDate).isAfter(endDate);
    })
    .length
};

export const leaveRequestNotAllowed = (daysOff) => {
    return Object.values(daysOff).every(value => {
        return value && value.type != "LEAVE_DAY";
    })
};