import * as React from 'react';
import {Grid, Table, TableHeaderRow, TableSelection} from '@devexpress/dx-react-grid-material-ui';
import {CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {DataTypeProvider, IntegratedSelection, SelectionState} from '@devexpress/dx-react-grid';
import MenuItem from "@material-ui/core/MenuItem";
import MGrid from '@material-ui/core/Grid';
import {getAxios} from "../../../actions/AuthActions";
import {API} from "../../../api/Endpoints";
import {store} from "../../../store/ConfigureStore";
import {getSitesSuccess, setQuota} from "../../../actions/QuotaActions";
import {setShowAddJustification} from "../../../actions/AbsenceActions";
import {connect} from "react-redux";
import { strings } from "../../../i18n/Strings";

const styles = theme => ({
    dialogPaper: {
        height: 550
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    }
});



const QuotaFormatter = ({value}) => {

    return (
            <TextField
                disabled={!(typeof value.quota !== 'undefined') }
                onChange={(e) => {
                    let sites = store.getState().quota.sites;
                    for (var i in sites) {
                        if (sites[i].id == value.id) {
                            sites[i].quota = parseInt(e.target.value) ||  0;
                            break;
                        }
                    }
                    console.log(sites);
                    store.dispatch(getSitesSuccess(sites));

                }}
                id="quotaFormatter"
                value={value.quota}
                margin="normal"
            />
    )
};


const QuotaProvider = props => (
    <DataTypeProvider
        formatterComponent={QuotaFormatter}
        {...props}
    />
);


class SitesSearchQuotaDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            zones: [],
            sites: [],
            projects: [],
            project: "",
            zone: "",
            site: "",
            columns: [
                {name: 'shortName', title: 'Site'},
                {name: 'quota', title: 'Quota'},
            ],
            tableColumnExtensions: [
                {columnName: 'site', wordWrapEnabled: true},
                {columnName: 'quota', width: 200, wordWrapEnabled: true},

            ],
            selection: []
            ,
            quotaColumns: ['quota']
        };
        this.changeSelection = selection => this.setState({selection});

    };

    getAllZones = async () => {
        const axios = getAxios();
        const zones = await axios.get(`${API}/conges/uo/quarters`);
        this.setState({zones: zones.data})
    };

    getProjectsByZone = async id => {
        this.setState({sites: [], project: []});
        const axios = getAxios();
        const projects = await axios.get(`${API}/conges/uo/byzone/${id}`)
        this.setState({projects: projects.data});
    }

    getSitesByProjectAndZone = async (projectId, zoneId) => {
        this.setState({sites: []});
        const axios = getAxios();
        const sites = await axios.get(`${API}/conges/uo/sites/${zoneId}/${projectId}`)
        this.setState({sites: sites.data});
        store.dispatch(getSitesSuccess(sites.data))
    }

    componentWillMount() {
        this.getAllZones();
    }

    handleChange = (event, name) => {
        this.setState({[name]: event.target.value});
    };

    render() {
        const {columns, tableColumnExtensions, selection, quotaColumns, zones, projects, zone, project, site} = this.state;
        const {sites} = this.props.quota;
        const rows = sites.map( site => { return {...site, quota: site}});
        const {classes} = this.props;
        const {_marageQuota,_geographicalArea,_project,_cancel,_confirm} = strings;
        return (
            <Dialog
                classes={{paper: classes.dialogPaper}}
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >

                <AppBar position="static" color="primary" elevation={0}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit">
                            {_marageQuota}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{height: 450, widht: 450, overflow: "scroll"}}>
                    <Grid
                        style={{overflow: "hidden"}}
                        rows={rows.map(row => row)}
                        columns={columns}
                    >
                        <MGrid container justify={"center"} alignContent={"space-between"} style={{paddingRight: 75}}>
                            <TextField
                                style={{marginLeft: 50, marginTop: 15, width: 200}}
                                label={_geographicalArea}
                                select
                                value={zone}
                                onChange={event => {
                                    this.handleChange(event, 'zone');
                                    this.getProjectsByZone(event.target.value)
                                    this.setState({project: ""})
                                }}
                                >
                                {zones.map((zone, index) => <MenuItem key={index} value={zone.id}> {zone.shortName}</MenuItem>)}
                            </TextField>
                            <br/>
                            <MGrid>
                                <TextField
                                    label={_project}
                                    style={{marginLeft: 50, marginTop: 15, width: 200}}
                                    select
                                    value={project}
                                    onChange={(event) => {
                                        this.handleChange(event, 'project');
                                        this.getSitesByProjectAndZone(event.target.value, zone)
                                    }}
                                >
                                    {projects.map((project, index) => <MenuItem key={index} value={project.projectCode}> {project.name}</MenuItem>)}
                                </TextField>
                            </MGrid>
                            {/*<MGrid>*/}
                                {/*<TextField*/}
                                    {/*style={{marginLeft: 50}}*/}
                                    {/*label="Recherche par nom de site"*/}
                                    {/*value={this.state.site}*/}
                                    {/*onChange={ event => { this.handleChange(event, 'site')}}*/}
                                {/*/>*/}
                            {/*</MGrid>*/}
                        </MGrid>
                        <QuotaProvider
                            for={quotaColumns}
                        />
                        <SelectionState
                            selection={selection}
                            onSelectionChange={this.changeSelection}
                        />
                        <IntegratedSelection/>
                        <Table
                            rowComponent={CustomTableRow}
                            columnExtensions={tableColumnExtensions}
                            noDataCellComponent={() => <></>}

                        />
                        <TableHeaderRow contentComponent={TableHeaderContent}/>

                        <TableSelection showSelectAll/>
                    </Grid>
                </div>

                <DialogActions>
                    <Button onClick={() => {
                        this.props.handleDialog();

                        this.setState({zone: "", sites: [], project: ""})
                    }} color="primary">
                        {_cancel}
                    </Button>
                    <Button  onClick={() => {
                        // this.handleSubmit();
                        store.dispatch(setQuota(sites))
                        this.setState({zone: "", sites: [], project: ""})
                        this.props.handleDialog();
                    }} color="primary">
                        {_confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {quota: state.quota};
}

export default connect(mapStateToProps, {})(withStyles(styles)(SitesSearchQuotaDialog));
