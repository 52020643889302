import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/IconButton'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import SearchIcon from '@material-ui/icons/Search'
import ResetIcon from '@material-ui/icons/ClearAllTwoTone'
import ConsultIcon from '@material-ui/icons/Assignment'
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from '@material-ui/core/LinearProgress';
import {strings} from "../../../i18n/Strings";
import connect from "react-redux/es/connect/connect";
import {setSearchActive}  from "../../../actions/AuthActions";
import {
    getAddedRequests,
    getChildrenRequests,
    getHRRequests,
    getMyRequests,
    getPendingRequests,
    getProcessedRequests, getToCancelRequests
} from "../../../actions/RequestsActions";
import {store} from "../../../store/ConfigureStore";
import {getExceptionalHolidays, getPublicHolidays, getRecups} from "../../../actions/HolidaysActions";
import {getRejectionMotifs} from "../../../actions/ReferencesActions";
import {getAbsenceByDay, getAbsenceByHour} from "../../../actions/AbsenceActions";
import { getEmployeeSoldeByPage } from "../../../actions/SoldeActions";

const flatten = require('flat');

const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        paddingTop: 10,
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    }
});

const mapValues = data => {
    return data.map(element => {
        return (flatten(element, {delimiter: "#"}))
    })
}

const requestsMapping = {
    "DemandesTraitees" : getProcessedRequests(0,25),
    "Recuperation" : getRecups(0,25),
    "JoursFeries": getPublicHolidays(0,25),
    "MesDemandes": getMyRequests(0,25),
    "DemandesEnAttente": getPendingRequests(0,25),
    "CongesDeMesCollaborateurs": getChildrenRequests(0,25),
    "CongesPayesDeMesCollaborateurs": getHRRequests(0,25),
    "MotifsDeRefus": getRejectionMotifs(0,25),
    "CongeExceptionnel": getExceptionalHolidays(0,25),
    "AnnnulationConges": getToCancelRequests(0,25),
    "DemandesAjoutees": getAddedRequests(0,25),
    "ABSENCE_DAY": getAbsenceByDay(0,25),
    "ABSENCE_HOUR": getAbsenceByHour(0,25),
    "ERROR_EXPORTS": () => {},
    "UNEXPORTED_HOLIDAYS": () => {},
    "": () => {},
}

class CustomTableToolBar extends React.Component {
    state = {page: 1, size: 25, totalPages: 100, searchActive: false};

    getRequestRefresh() {
        const currentPage = window.location.href.split("/")[3];
        if ( currentPage === "JustificationAbsences") {
            const currentTab = window.localStorage.getItem("CURRENT_TAB")
            if ( currentTab === "ABSENCE_DAY") {
                return requestsMapping["ABSENCE_DAY"]
            }
            else {
                return requestsMapping["ABSENCE_HOUR"]
            }
        }
        if ( currentPage === "GestionDesExports") {
            const currentTab = window.localStorage.getItem("CURRENT_TAB")
            if ( currentTab === "UNEXPORTED_HOLIDAYS") {
                return requestsMapping["UNEXPORTED_HOLIDAYS"]
            }
            else {
                return requestsMapping["ERROR_EXPORTS"]
            }
        }
        return requestsMapping[currentPage]
    }

    incrementPage = () => {
        let {size, page} = this.state;
        const $page = page + 1;
        const $size = size;
        this.setState({page: $page, size: $size});
        this.props.changePaging({page: $page, size: $size})
    };
    decrementPage = () => {
        const {size, page} = this.state;
        this.setState({page: page - 1});
        this.props.changePaging({page: this.state.page, size})
    };
    handlePageSizeChange = event => {
        const $page = 1;
        const $size = event.target.value;
        this.setState({size: $size, page: $page});
        this.props.changePaging({page: $page, size: $size})
    };

    handleExportToExcel(filters, sort, order) {
        const {registrationNumber, uo, firstName, lastName, solde, soldeClv } = filters;
        this.props.getEmployeeSoldeByPage(undefined, undefined, sort, order, registrationNumber, uo, firstName, lastName, solde,soldeClv);
    }

    render() {
        const {classes, totalPages, data, loading, filters, sort, order, auth} = this.props;
        const {page, size} = this.state;
        const {_resulstsFound,_of} = strings;
        const {searchActive} = auth;
        return (
            <div className={classes.root}>
                <AppBar elevation={0} position="static" color={"default"}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" noWrap>
                            {_resulstsFound} {totalPages}
                        </Typography>
                        <Tooltip title="Export EXCEL">
                            <IconButton style={{marginLeft: 50}} 
                                onClick={() => this.handleExportToExcel(filters, sort, order)} color="default" aria-label="add">
                                <ConsultIcon style={{color: "black"}} fontSize="small"/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Recherche avancée">
                            <IconButton style={{marginLeft: 50}} onClick={() => {
                                this.props.setSearchActive(!searchActive)
                            }} color="default" aria-label="search">
                                <SearchIcon style={{color: "black"}} fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                        {
                            searchActive && <Tooltip title="Réinitialiser la recherche">
                                <IconButton style={{marginLeft: 50}} onClick={() => {
                                    this.props.setSearchActive(false)
                                    setTimeout(() => {
                                        store.dispatch(this.getRequestRefresh())
                                        this.props.setSearchActive(true)
                                    }, 200)
                                }} color="default" aria-label="search">
                                    <ResetIcon style={{color: "black"}} fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }
                        <div className={classes.grow}/>
                        <div>
                            <Grid container direction={"row"} spacing={32}>
                                <Grid item>
                                    <TextField
                                        select
                                        style={{width: 70, paddingTop: 5}}
                                        value={size}
                                        onChange={(event) => {
                                            this.handlePageSizeChange(event)
                                        }}
                                        id="paging"
                                    >
                                        {[25, 50, 100].map((p, index) => <MenuItem key={index}
                                                                                   value={p}> {p}</MenuItem>)}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                                        {page} {_of} {parseInt(totalPages / size) + 1}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={16}>
                                        <Grid item>
                                            <Button disabled={page <= 1} onClick={() => this.decrementPage()}
                                                    color="default" aria-label="add">
                                                <ChevronLeftIcon/>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button disabled={page >= parseInt(totalPages / size) + 1}
                                                    onClick={() => this.incrementPage()}
                                                    color="default" aria-label="add">
                                                <ChevronRightIcon/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Toolbar>
                </AppBar>
                {loading && <LinearProgress />}
            </div>
        );
    }
}



let mapStateToProps = (state) => {
    return {auth: state.auth}
};

let mapActionsToProps = {
    setSearchActive,
    getEmployeeSoldeByPage
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(CustomTableToolBar));

