import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import React, {Component} from 'react';
import {$rolesToValidationUrl, getAxios, rolesToValidation, rolesToValidationUrl, setLoading} from "../../../actions/AuthActions";




const Main = ({title, onAddNewInformationValue, infoList, onRemoveInformationValue}) => (
    <main>
      
      <AddInformationGroup onAddNewInformationValue={onAddNewInformationValue} />
      <InformationList infoList={infoList} onRemoveInformationValue={onRemoveInformationValue} />
    </main>
  );
export default class GlobalGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            result: {}
        }
    }
    render (){
        return(
            <div style={{ width: '80%',padding: '20px' , display:'flex',height:'100%', position:'fixed',overflow:'auto' }}>

            <div style={{width:'30%', display:'flex'}} >
                <div className='input'>
                    <Appe onResponse={(data) => {
                        console.log(this.state)
                        this.setState({
                            result: data
                        })
                        console.log("data on repsonse:"  , data)
                    }}/>
                </div>
                

            </div>

            <div style={{
                
                height: '80%',
                maxWidth: 'calc(100vw - 600px)',
                background: 'white',
                borderRadius: '12px',
                minWidth: 'calc(100vw - 600px)',
                wordBreak: 'break-word',
                overflow:'auto',
            }}>
                <div style={{background: '#eee', width: '100%', padding: '10px 15px',}}>
                    Résultat
                </div>
                <div >
                    
                <pre>
                  {JSON.stringify(this.state.result, null, 4)}
                </pre>
               
                    
                </div>

            </div>

                
   
    </div>
        )
    }


}
 class InformationChild extends React.Component {
    render() {
      return (
        <div>
        <table>
        <tbody>
            <tr> 

        <li>
          <td> {this.props.value} </td> 
          <td>
        <button
         onClick={e => this.props.onRemoveInformationValue(this.props.index)}
         style={{borderRadius: '7px',
            fontSize: '21px',
            border: 'none',
            cursor: 'pointer',
            width: '24px',
            top: '2px',
            position: 'relative',
            background: 'rgb(238, 238, 238)'}}>-</button></td>
            
        </li>
        </tr>
        </tbody>
        </table>
        </div>
      );
    }
  }
  
  
  class InformationList extends React.Component {
    constructor(props) {
      super(props);
      
      this.renderList = this.renderList.bind(this);
    }
    
    renderList() {
      if (this.props.infoList.length > 0) {
        return (
          <ul className='info-list'>
            {this.props.infoList.map((infoValue, index) => {
              return <InformationChild key={`${index}-${infoValue}`} index={index} value={infoValue} onRemoveInformationValue={this.props.onRemoveInformationValue} />
            })}
          </ul>
        );
      } else {
        return ;
      }
    }
    
    render() {
      return (
        <div>
            <h4>
            Id(s) SubRequest: : {this.props.infoList.length}
            </h4>
        <div  style={{background: 'white',
            height: '257px',
            maxWidth:'240px',
            marginTop: '23px',
            borderRadius: '11px',
            //paddingTop: '15px',
            overflow:'auto'}}>

          
          {this.renderList()}  
        </div>
        </div>
      );
    }
  }

  class AddInformationGroup extends React.Component {
    constructor(props) {
      super(props);
      
      this.onFormSubmit = this.onFormSubmit.bind(this);
    }
    
    onFormSubmit(e) {
      e.preventDefault();
      console.log(this.refs)
      const infoFieldValue = this.refs.addInfoField.value;
      
      if (infoFieldValue.trim() === '') { return; }
      if (infoFieldValue.split(',').length > 1) {
        let data = infoFieldValue.split(',');
        console.log(data.length);
  
        this.props.onAddNewInformationValue(data);
      } else {
        this.props.onAddNewInformationValue(infoFieldValue);
      }
      
      
      e.target.reset();
    }
    
    render() {    
      return (
        <div>
        <form className='add-info-form' onSubmit={this.onFormSubmit}  > 
        
          <input ref="addInfoField" style={{width: '80%',
                                            padding: '12px 20px',
                                            margin: '8px 0',
                                            display: 'inline-block',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            boxSizing: 'border-box',}} />
          <button style={{borderRadius: '7px',
                          fontSize: '22px',
                          border: 'none',
                          color: 'white',
                          cursor: 'pointer',
                          top: '2px',
                          position: 'relative',
                          left: '12px',
                          background: '#f80061'}}>+</button>
        </form>
        </div>
      );
    }
  };
  class Appe extends React.Component {
    constructor(props) {
      super(props);
      
      this.onAddNewInformationValue = this.onAddNewInformationValue.bind(this);
      this.onRemoveInformationValue = this.onRemoveInformationValue.bind(this);
      this.OnSubRequestClick=this.OnSubRequestClick.bind(this)
   
      
      this.state = {
        infoList: [],
        };
    }
    
    
    
    onAddNewInformationValue(value) {
      console.log('onAddNewInformationValue');
      let infoList;
      if(typeof value=='object'){
        infoList = [...this.state.infoList, ...value];
      }
      else {
        infoList = [...this.state.infoList, value];
      }
      
      this.setState({ infoList });
    }
    
    onRemoveInformationValue(index) {
      console.log('onRemoveInformationValue', index);
    
      let newInfoList = [...this.state.infoList];
      
    
      newInfoList.splice(index, 1);
      
   
      this.setState({
        infoList: newInfoList
      });
    }
    
    async OnSubRequestClick() {

        const axios = getAxios();
        axios.post('https://ms-myconges.intelcia.com/conges/updateSubRequestToExported', this.state.infoList)
        .then((response) => {
            this.props.onResponse({'Succes':true})

        })
        .catch((err) => {
            
            this.props.onResponse(err)
        })
    }
   
    
    render() {
      console.log(this.state.infoList);
      
      return (
        <div className="app">
         
          <Main
            onAddNewInformationValue={this.onAddNewInformationValue}
            onRemoveInformationValue={this.onRemoveInformationValue} 
            infoList={this.state.infoList} 
            />
          <button onClick={this.OnSubRequestClick} style={{border: 'none',borderRadius:'10px',color: '#e91e63', width: '100%', background: '#e91e632e', cursor: 'pointer', height: '40px',marginTop:'30px'}}> Send</button>

        </div>
      );
    }
  };
 
  