import React, {Component} from 'react';
import ReactExport from 'react-data-export';
import Button from "@material-ui/core/Button";
import {getHolidayType, states} from "../../../utilities/MappingFunctions";
import {getAxios} from "../../../actions/AuthActions";
import {API, HR_ERROR_REQUESTS} from "../../../api/Endpoints";
import AddIcon from '@material-ui/icons/Assignment';
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default class ExportHRRequests extends Component {

    state = {
        dataToExport: [{columns: [""], data: [{value: ""}]}]
    };

    componentWillMount() {
        const axios = getAxios();
        axios.post(`${API}/${HR_ERROR_REQUESTS}`, {
            "sort": null,
            "order": false,
            "page": 0,
            "size": 10000
        }).then(res => {

            let dataToExport = res.data.payload
                .map(h => {
                    console.log("data => " , h);

                    const {duration, startDate, firstName,lastName,exported,typeConge, returnDate, totalDuration, updateDate} = h;
                    return {
                        matricule: h.addedForEmp.registrationNumber,
                        Prénom: firstName,
                        Nom: lastName,
                     "Type Congé": typeConge,
                        "Date retour": returnDate,
                        "Date départ": startDate,
                        status: exported,
                        "Durée": totalDuration,
                        "Durée totale": totalDuration,
                     //   "Date de dépôt": updateDate
                    }
                });
            this.setState({dataToExport})
        });
    }

    render() {
        const {dataToExport} = this.state;

        return (
            <div>
                <ExcelFile filename={`Extraction ${moment().format('DD/MM/YYYY hh:mm:ss')}`}  element={<Button title={"Extraire les congés"} id={"fab"} color={"primary"} variant={"fab"}>
                    <AddIcon/>
                </Button>}>
                    <ExcelSheet dataSet={
                        [{
                            columns: Object.keys(dataToExport[0] || {}),
                            data: (dataToExport || []).map(o => {
                                return Object.values(o).map(e => {
                                    return {"value": e}
                                })
                            })
                        }
                        ]} name={`username~`}/>
                </ExcelFile>
            </div>
        );
    }
}
