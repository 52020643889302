import {getAxios} from "./AuthActions";
import {store} from "../store/ConfigureStore";
import {
    API,
    BY_DAY_ABSENCE,
    BY_DAY_ABSENCE_FR,
    BY_HOUR_ABSENCE,
    BY_HOUR_ABSENCE_FR,
    JUSTIFY_HOUR,
    GET_TIME_TRAJET,
    GET_TIME_DELEGATION,
    SPLIT_HOUR_ABSENCE, GET_TIME_ENTRY, GET_TIME_ENTRY_FILTRED, GET_JUSTIFICATION_TYPES
} from "../api/Endpoints";
import {showTimedToaster} from "./ReferencesActions";
import moment from "moment";
import { strings } from "../i18n/Strings";

export function setShowAddJustification(payload) {

    return {
        type: "SET_SHOW_ADD_JUSTIFICATION",
        payload
    }

}

export function setShowSpliterDialog(payload) {

    return {
        type: "SET_SHOW_SPLITER",
        payload
    }

}

export function getAbsenceByDaySuccess(payload) {

    return {
        type: "GET_ABSENCE_BY_DAY",
        payload
    }

}

export function getAbsenceByHourSuccess(payload) {

    return {
        type: "GET_ABSENCE_BY_HOUR",
        payload
    }

}

export function getTimeTrajetSuccess(payload) {

    return {
        type: "GET_TIME_TRAJET",
        payload
    }

}

export const getAbsenceByDay = (page, size, justified, registrationNumber, startDate, endDate,currentAbsence) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.dayAbsence;
        dispatch(getAbsenceByDaySuccess({loading: true, data, totalElements}));
        let absences = await axios.post(`${API}/${BY_DAY_ABSENCE}`, {
            "matricule": registrationNumber,
            "nom": null,
            "prenom": null,
            "startDate": startDate,
            "endDate": endDate,
            "justified": justified,
            "currentAbsence": currentAbsence,
            "currentUser": store.getState().auth.profile.userName,
          "authorities": [],
          "order": true,
          "sort": "registrationNumber",
          "page": page,
            "size": size,
        });
        dispatch(getAbsenceByDaySuccess({
            loading: false,
            data: absences.data.payload,
            totalElements: absences.data.totalElements
        }));

    }
};
export const getAbsenceByDayFr = (page, size, justified, registrationNumber, startDate, endDate,currentAbsence) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.dayAbsence;
        dispatch(getAbsenceByDaySuccess({loading: true, data, totalElements}));
        let absences = await axios.post(`${API}/${BY_DAY_ABSENCE_FR}`, {
            "matricule": registrationNumber,
            "nom": null,
            "prenom": null,
            "startDate": startDate,
            "endDate": endDate,
            "justified": justified,
            "currentAbsence": currentAbsence,
            "currentUser": store.getState().auth.profile.userName,
          "authorities": [],
          "order": true,
          "sort": "registrationNumber",
          "page": page,
            "size": size,
        });
        dispatch(getAbsenceByDaySuccess({
            loading: false,
            data: absences.data.payload,
            totalElements: absences.data.totalElements
        }));

    }
};


export const getAbsenceByHour = (page, size, justified, registrationNumber, startDate, endDate, active,currentAbsence) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.hourAbsence;
        const $startDate = (startDate === "") ? null : startDate;
        const $endDate = (endDate === "") ? null : endDate;
        dispatch(getAbsenceByHourSuccess({loading: true, data, totalElements}));
        let absences = await axios.post(`${API}/${BY_HOUR_ABSENCE}`, {
                "matricule": registrationNumber,
                "active": !!active || active === undefined,
                "nom": null,
                "prenom": null,
                "dateAbsence": null,
                "startDate": $startDate,
                "endDate": $endDate,
                "startHour": null,
                "endHour": null,
                "justified": justified,
                "currentAbsence": currentAbsence,
                "order": true,
                "sort": "registrationNumber",
                "currentUser": store.getState().auth.profile.userName,
                "authorities": ["ROLE_RH_CONGE"],
                "page": page,
                "size": size
            }
        );
        dispatch(getAbsenceByHourSuccess({
            loading: false,
            data: absences.data.payload,
            totalElements: absences.data.totalElements
        }));
    }
};
export const getAbsenceByHourFr = (page, size, justified, registrationNumber, startDate, endDate, active,currentAbsence) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.hourAbsence;
        const $startDate = (startDate === "") ? null : startDate;
        const $endDate = (endDate === "") ? null : endDate;
        dispatch(getAbsenceByHourSuccess({loading: true, data, totalElements}));
        let absences = await axios.post(`${API}/${BY_HOUR_ABSENCE_FR}`, {
                "matricule": registrationNumber,
                "active": !!active || active === undefined,
                "nom": null,
                "prenom": null,
                "dateAbsence": null,
                "startDate": $startDate,
                "endDate": $endDate,
                "startHour": null,
                "endHour": null,
                "justified": justified,
                "currentAbsence": currentAbsence,
                "order": true,
                "sort": "registrationNumber",
                "currentUser": store.getState().auth.profile.userName,
                "authorities": ["ROLE_RH_CONGE"],
                "page": page,
                "size": size
            }
        );
        dispatch(getAbsenceByHourSuccess({
            loading: false,
            data: absences.data.payload,
            totalElements: absences.data.totalElements
        }));
    }
};

function reverseDate(date) {
    date = date.replace(/-/g,"/");
    let temp = date.split("T");
    return `${temp[0].split("/").reverse().join("/")} ${temp[1]}`
}

export const getAbsenceLessThanHour = (startDate, endDate, matricule) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.hourAbsence;
        const $startDate = (startDate === "") ? null : startDate;
        const $endDate = (endDate === "") ? null : endDate;
        dispatch(getAbsenceByHourSuccess({loading: true, data, totalElements}));
        let absences = await axios.post(`${API}/conges/abscence/minuteAbsence`, {startDate: moment($startDate).format("DD/MM/YYYY"),endDate: moment($endDate).format("DD/MM/YYYY"), matricule});
        dispatch(getAbsenceByHourSuccess({
            loading: false,
            data: absences.data.payload.map( abs => {
                let {daily,end, idMyshift,  justified, matricule, nom, prenom, start} = abs;
                return {daily,end: reverseDate(end), idMyshift,  justified, matricule, nom, prenom, start: reverseDate(start), updateDate: reverseDate(start).split(" ")[1]};
            }),
            totalElements: absences.data.totalElements
        }));
    }
};



export const justifyAbsence = (id, idMyshift, matricule, nom, prenom, start, end, justifType, justified, daily) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respJustifyAbsence} = strings; 

        await axios.post(`${API}/${JUSTIFY_HOUR}`, [{
                "id": id,
                "idMyshift": idMyshift,
                "matricule": matricule,
                "nom": nom,
                "prenom": prenom,
                "start": start,
                "end": end,
                "justifType": justifType,
                "justified": justified,
                "daily": daily
            }]
        );
        dispatch(getAbsenceByHour(2,25));
        dispatch(showTimedToaster({open: true, message: _respJustifyAbsence, type: "success"}));

    }
};

export const splitAbsence = (id, idMyshift, matricule, start, selectedEnd, selectedStart, end, timeZone) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respSplitAbsence,_errorSaveSplitAbsence} = strings;
        selectedStart = start.split(" ")[0] + " " + selectedStart;
        selectedEnd = end.split(" ")[0] + " " + selectedEnd;
        console.log("selectedStart tzone :" + moment(selectedStart, "DD/MM/YYYY HH:mm").subtract(timeZone, 'h').format("DD/MM/YYYY HH:mm"))
        console.log("selectedEnd tzone :" + moment(selectedEnd,"DD/MM/YYYY HH:mm").subtract(timeZone, 'h').format("DD/MM/YYYY HH:mm"))
        if (start !== moment(selectedStart, "DD/MM/YYYY HH:mm").subtract(timeZone, 'h').format("DD/MM/YYYY HH:mm")){
            await axios.post(`${API}/${SPLIT_HOUR_ABSENCE}`, [{
                    "id": id,
                    "idMyshift": idMyshift,
                    "matricule": matricule,
                    "start": start,
                    "end": moment(selectedEnd, "DD/MM/YYYY HH:mm").subtract(timeZone, 'h').format("DD/MM/YYYY HH:mm")
                },{
                    "id": null,
                    "idMyshift": null,
                    "matricule": matricule,
                    "start":  moment(selectedStart, "DD/MM/YYYY HH:mm").subtract(timeZone, 'h').format("DD/MM/YYYY HH:mm"),
                    "end": end,
                }]
            ).then( res => {
                dispatch(showTimedToaster({open: true, message: _respSplitAbsence, type: "success"}));
                dispatch(getAbsenceByHour(2,25));
            }).catch( e => {
                dispatch(showTimedToaster({open: true, message: _errorSaveSplitAbsence, type: "warning"}));
                dispatch(getAbsenceByHour(2,25));
            });
        }
    }
};

export const getTimeTrajet = () => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.timeTrajet;
        dispatch(getTimeTrajetSuccess({loading: true, data, totalElements}));
        axios.get(`${API}/${GET_TIME_TRAJET}`).then(r=>{
            let d = r.data.payload.flatMap(e =>  {
                return e.trajets.map(x=> {
                    x.registrationNumber = e.registrationNumber;
                    x.date = e.date;
                    x.firstName = e.firstName;
                    x.lastName = e.lastName;
                    return x;
                })
            } );
    
            dispatch(getTimeTrajetSuccess({
                loading: false,
                data: d,
                totalElements: d.length
            }));
        }).catch(e=>{
            dispatch(getTimeTrajetSuccess({
                loading: false,
                data: [],
                totalElements: 0
            }));
        });

    }
};


export const saveTimeTrajet = (trajet) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respSaveTrajet} = strings; 

        await axios.post(`${API}/${GET_TIME_TRAJET}`, trajet);
        dispatch(getTimeTrajet());
        dispatch(showTimedToaster({open: true, message: _respSaveTrajet, type: "success"}));

    }
};

export const deleteTimeTrajet = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respSaveTrajet} = strings; 

        await axios.delete(`${API}/${GET_TIME_TRAJET}/${id}`);
        dispatch(getTimeTrajet());
        dispatch(showTimedToaster({open: true, message: _respSaveTrajet, type: "success"}));

    }
};

export function getTimeDelegationSuccess(payload) {

    return {
        type: "GET_TIME_DELEGATION",
        payload
    }

}

export const getTimeDelegation = () => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.timeTrajet;
        dispatch(getTimeDelegationSuccess({loading: true, data, totalElements}));
        axios.get(`${API}/${GET_TIME_DELEGATION}`)
        .then(r=> {
            let d = r.data.payload.flatMap(e =>  {
                return e.delegations.map(x=> {
                    x.registrationNumber = e.registrationNumber;
                    x.date = e.date;
                    x.firstName = e.firstName;
                    x.lastName = e.lastName;
                    x.motif = e.motif;
                    return x;
                })
            } );
            dispatch(getTimeDelegationSuccess({
                loading: false,
                data: d,
                totalElements: d.length
            }));
        }).catch(e=>{
            dispatch(getTimeDelegationSuccess({
                loading: false,
                data: [],
                totalElements: 0
            }));
        })
    }
};

export const saveTimeDelegation = (delegation) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respSaveTrajet} = strings; 

        await axios.post(`${API}/${GET_TIME_DELEGATION}`, delegation);
        dispatch(getTimeDelegation());
        dispatch(showTimedToaster({open: true, message: _respSaveTrajet, type: "success"}));

    }
};

export const deleteTimeDelegation = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respSaveTrajet} = strings; 

        await axios.delete(`${API}/${GET_TIME_DELEGATION}/${id}`);
        dispatch(getTimeDelegation());
        dispatch(showTimedToaster({open: true, message: _respSaveTrajet, type: "success"}));

    }
};


export function getTimeSuccess(payload, type) {
    switch (type)  {
        case 'TRAJET' :
            return {
                type: "GET_TIME_TRAJET",
                payload
            };
        case 'DELEGATION' :
            return {
                type: "GET_TIME_DELEGATION",
                payload
            } ;  
    }
}

export const getTime = () => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.timeTrajet;
        dispatch(getTimeTrajetSuccess({loading: true, data, totalElements}));
        axios.get(`${API}/${GET_TIME_ENTRY}`)
        .then(r=> {
            let d = r.data.payload.flatMap(e =>  {
                return e.timeEntrys.map(x=> {
                    x.registrationNumber = e.registrationNumber;
                    x.date = e.date;
                    x.firstName = e.firstName;
                    x.lastName = e.lastName;
                    x.motif = e.motif;
                    x.type = e.type;
                    return x;
                })
            } );
            dispatch(getTimeTrajetSuccess({
                loading: false,
                data: d,
                totalElements: d.length
            }));


        }).catch(e=>{
            dispatch(getTimeTrajetSuccess({
                loading: false,
                data: [],
                totalElements: 0
            }));
        })
    }
};

export const getTimeFiltred = (canceled) => {
    return async dispatch => {
        const axios = getAxios();
        const {data, totalElements} = store.getState().absence.timeTrajet;
        dispatch(getTimeTrajetSuccess({loading: true, data, totalElements}));
        await axios.post(`${API}/${GET_TIME_ENTRY_FILTRED}`, canceled
        ).then(r=> {
            let d = r.data.payload.flatMap(e =>  {
                return e.timeEntrys.map(x=> {
                    x.registrationNumber = e.registrationNumber;
                    x.date = e.date;
                    x.firstName = e.firstName;
                    x.lastName = e.lastName;
                    x.motif = e.motif;
                    x.type = e.type;
                    return x;
                })
            } );
            dispatch(getTimeTrajetSuccess({
                loading: false,
                data: d,
                totalElements: d.length
            }));
        }).catch(e=>{
            dispatch(getTimeTrajetSuccess({
                loading: false,
                data: [],
                totalElements: 0
            }));
        });
    }
};

export const saveTime = (timeEntry, type) => {
    return async dispatch => {
        const axios = getAxios();
        await axios.post(`${API}/${GET_TIME_ENTRY}`, timeEntry);
        dispatch(getTime());
        dispatch(showTimedToaster({open: true, message: "La saisie est enregistrée avec success", type: "success"}));
    }
};

export function showTimeEntryDetails(payload) {
    console.log("payload-----",payload)
    return {
        type: "SHOW_TIME_ENTRY_DETAILS",
        payload
    }
}

export function showDeletionDialog(payload) {
    console.log("showDeletionDialog ==== ", payload)
    return {
        type: "SHOW_DELETION_DIALOG",
        payload
    }
}

export const deleteTimeEntry = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respSaveTrajet} = strings;
        console.log("delete id :", id);
        await axios.delete(`${API}/${GET_TIME_ENTRY}/${id}`);
        dispatch(getTime());
        dispatch(showDeletionDialog({type: "CANCEL", data: {}, open: false}));
        dispatch(showTimedToaster({open: true, message: "La saisie est bien supprimée", type: "success"}));

    }
};
export const deleteTime = (id, type) => {
    return async dispatch => {
        switch (type)  {
            case 'TRAJET' :
                dispatch(deleteTimeTrajet(id))
            case 'DELEGATION' :
                dispatch(deleteTimeDelegation(id))
        }
    }
};


export const getJustificationTypes = () => {
    return async dispatch => {
        const axios = getAxios();
        dispatch(getJustificationTypesSuccess({loading: true, data: null}));
        axios.get(`${API}/${GET_JUSTIFICATION_TYPES}`)
            .then(resp => {
                dispatch(getJustificationTypesSuccess({loading: false, data: resp.data}));
            }).catch(e=>{
                const {_INTERNAL_SERVER_ERROR} = strings;
                dispatch(showTimedToaster({open: true, message: _INTERNAL_SERVER_ERROR, type: "warning"}));
                dispatch(getJustificationTypesSuccess({loading: false, data: null}));
            });
    }
};

export function getJustificationTypesSuccess(payload) {
    return {
        type: "GET_JUSTIFICATION_TYPES",
        payload
    }
}
