import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { connect } from "react-redux";
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/fr';
import Tooltip from "@material-ui/core/Tooltip";
import { getIsProd, getIsTL } from "../../../../actions/AuthActions";
import { strings } from "../../../../i18n/Strings";
import { store } from '../../../../store/ConfigureStore';
import * as moment from 'moment';
import { getMonth, getDay, getDateElementsFromString, getYear, sortDateComparator } from '../../../utilities/DateUtils';
let ChipIndicator = props => {
    return <div style={{ marginTop: 5 }}>
        <Chip
            avatar={<Avatar style={{ backgroundColor: props.chip.color }}>{props.chip.value}</Avatar>}
            label={props.chip.name}
        />
    </div>
};

let ChipValueIndicator = props => {
    const countryCode = store.getState().auth.profile.countryCode;
    const { chip } = props;
    const { name, value } = chip;
    const { _annualBalance, _cumulativeBalance } = strings;
    return <span style={{ padding: 5 }}>

        {
            (name === _annualBalance) &&  <Tooltip title={value}>
                <Chip
                    avatar={<Avatar style={{ color: "darkgrey" }}><span
                        style={{ color: "white", margin: 15 }}>
                         {(countryCode === "MA") &&
                                    (value)
                         }

                        {(countryCode !== "MA" ) &&
                                parseInt(value)
                         }
                            </span></Avatar>}
                    label={name}
                />
            </Tooltip>
        }

        {
            (name === _cumulativeBalance) && <Tooltip title={"solde acquis à la date de début du congé payé"}>
                <Chip
                    avatar={<Avatar style={{ color: "darkgrey" }}><span
                        style={{ color: "white", margin: 15 }}>{parseInt(value)}</span></Avatar>}
                    label={name}
                />
            </Tooltip>
        }

        {
            (name !== _annualBalance && name !== _cumulativeBalance) && <Chip
                avatar={<Avatar style={{ color: "darkgrey" }}><span
                    style={{ color: "white", margin: 15 }}>{parseInt(value)} </span></Avatar>}
                label={name}
            />
        }

    </span>
};


/*
*  get Modifiers and getModifiersStyles are used to adapt the list of days to calendar library
* */
class Indicator extends React.Component {

    state = {
        days: this.props.days,
        modifers: {},
        modifersStyes: {},
        isProd: false,
        isTL: false,

    };

    componentWillMount() {
        const { userName } = this.props.auth.profile;
        getIsTL(userName).then((res) => {
            this.setState({ isTL: res.data })
        });
        getIsProd(userName).then((res) => {
            this.setState({ isProd: res.data })
        });
    }

    
    getModifiersStyles = days => {
        let styles = {};
        const {references} = this.props;
        const {daysOff} = references;
        let am = this.props.references.ampm && this.props.references.ampm.checkedAM;
        let pm = this.props.references.ampm && this.props.references.ampm.checkedPM;
        if (am == undefined) am = false;
        if (pm == undefined) pm = false;
        let keys = Object.keys(daysOff).filter(key => daysOff[key] && daysOff[key].type == "LEAVE_DAY");
        keys = keys.sort(sortDateComparator);
        const firstDay = keys[0];
        const lastDay = keys[keys.length - 1];
        for (let i in days) {
            let sday = i;
            let vday = days[sday].type;
            let quota = days[sday].quota;
            const halfNoAM = (sday === lastDay && am);
            const halfNoPM = (sday === firstDay && pm);
            if (vday === "HOLYDAY" && (quota === "QUOTA_TL" || quota === "QUOTA_NO_PROD"))
                // styles[sday] = this.getStyle('#1A93F0', "transparent");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#1A93F0', 'am', 'transparent') : this.getHalfDayStyle('#1A93F0', 'pm', 'transparent')) : this.getStyle('#1A93F0', "transparent");
            if (vday === "HOLYDAY" && (quota === "QUOTA_AVAILABLE" || quota === "QUOTA_NOT_ALLOCATED" ))
                // styles[sday] = this.getStyle('#1A93F0', "#228b22");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#1A93F0', 'am', '#228b22') : this.getHalfDayStyle('#1A93F0', 'pm', '#228b22')) : this.getStyle('#1A93F0', "#228b22");
            if (vday === "HOLYDAY" && quota === "QUOTA_TIGHT")
                // styles[sday] = this.getStyle('#1A93F0', '#FF971D');
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#1A93F0', 'am', '#FF971D') : this.getHalfDayStyle('#1A93F0', 'pm', '#FF971D')) : this.getStyle('#1A93F0', "#FF971D");
            if (vday === "HOLYDAY" && quota === "QUOTA_AFFECTED")
                // styles[sday] = this.getStyle('#1A93F0', "#ff0000");
            if ((vday === "DAY_OFF_PART_TIME" || vday== "DAY_LEAVE_PART_TIME") && (quota === "QUOTA_TL" || quota === "QUOTA_NO_PROD"))
                // styles[sday] = this.getStyle('#1A93F0', "transparent");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#294f9a', 'am', 'transparent') : this.getHalfDayStyle('#294f9a', 'pm', 'transparent')) : this.getStyle('#294f9a', "transparent");
            if ((vday === "DAY_OFF_PART_TIME" || vday== "DAY_LEAVE_PART_TIME")  && quota === "QUOTA_AVAILABLE")
                // styles[sday] = this.getStyle('#294f9a', "#228b22");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#294f9a', 'am', '#228b22') : this.getHalfDayStyle('#294f9a', 'pm', '#228b22')) : this.getStyle('#294f9a', "#228b22");
            if ((vday === "DAY_OFF_PART_TIME" || vday== "DAY_LEAVE_PART_TIME")  && quota === "QUOTA_TIGHT")
                // styles[sday] = this.getStyle('#294f9a', '#FF971D');
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#294f9a', 'am', '#FF971D') : this.getHalfDayStyle('#294f9a', 'pm', '#FF971D')) : this.getStyle('#294f9a', "#FF971D");
            if ((vday === "DAY_OFF_PART_TIME" || vday== "DAY_LEAVE_PART_TIME")  && quota === "QUOTA_AFFECTED")
                // styles[sday] = this.getStyle('#294f9a', "#ff0000");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#294f9a', 'am', '#ff0000') : this.getHalfDayStyle('#294f9a', 'pm', '#ff0000')) : this.getStyle('#294f9a', "#ff0000");
            if ((vday === "DAY_OFF_PART_TIME" || vday== "DAY_LEAVE_PART_TIME")  && quota === "QUOTA_NOT_ALLOCATED")
                // styles[sday] = this.getStyle('#294f9a', "#A8A8A8");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#294f9a', 'am', '#A8A8A8') : this.getHalfDayStyle('#294f9a', 'pm', '#A8A8A8')) : this.getStyle('#294f9a', "#A8A8A8");
            if (vday === "DAY_OFF" && (quota === "QUOTA_TL" || quota === "QUOTA_NO_PROD"))
                // styles[sday] = this.getStyle('#EA2161', "transparent");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#EA2161', 'am', 'transparent') : this.getHalfDayStyle('#EA2161', 'pm', 'transparent')) : this.getStyle('#EA2161', "transparent");
            if (vday === "DAY_OFF" && quota === "QUOTA_AVAILABLE")
                // styles[sday] = this.getStyle('#EA2161', "#228b22");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#EA2161', 'am', '#228b22') : this.getHalfDayStyle('#EA2161', 'pm', '#228b22')) : this.getStyle('#EA2161', "#228b22");
            if (vday === "DAY_OFF" && quota === "QUOTA_TIGHT")
                // styles[sday] = this.getStyle('#EA2161', "#FF971D");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#EA2161', 'am', '#FF971D') : this.getHalfDayStyle('#EA2161', 'pm', '#FF971D')) : this.getStyle('#EA2161', "#FF971D");
            if (vday === "DAY_OFF" && quota === "QUOTA_AFFECTED")
                // styles[sday] = this.getStyle('#EA2161', "#ff0000");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#EA2161', 'am', '#ff0000') : this.getHalfDayStyle('#EA2161', 'pm', '#ff0000')) : this.getStyle('#EA2161', "#ff0000");
            if (vday === "DAY_OFF" && quota === "QUOTA_NOT_ALLOCATED")
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#EA2161', 'am', '#A8A8A8') : this.getHalfDayStyle('#EA2161', 'pm', '#A8A8A8')) : this.getStyle('#EA2161', "#A8A8A8");
            // styles[sday] = this.getStyle('#EA2161', "#A8A8A8");
            if (vday === "LEAVE_DAY" && (quota === "QUOTA_TL" || quota === "QUOTA_NO_PROD"))
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#8B008B', 'am', 'transparent') : this.getHalfDayStyle('#8B008B', 'pm', 'transparent')) : this.getStyle('#8B008B', "transparent");
            if (vday === "LEAVE_DAY" && quota === "QUOTA_AVAILABLE")
                // styles[sday] = this.getStyle('#8B008B', "#228b22");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#8B008B', 'am', '#228b22') : this.getHalfDayStyle('#8B008B', 'pm', '#228b22')) : this.getStyle('#8B008B', "#228b22");
            if (vday === "LEAVE_DAY" && quota === "QUOTA_TIGHT")
                // styles[sday] = this.getStyle('#8B008B', "#FF971D");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#8B008B', 'am', '#FF971D') : this.getHalfDayStyle('#8B008B', 'pm', '#FF971D')) : this.getStyle('#8B008B', "#FF971D");
            if (vday === "LEAVE_DAY" && quota === "QUOTA_AFFECTED")
                // styles[sday] = this.getStyle('#8B008B', "#ff0000");
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#8B008B', 'am', '#ff0000') : this.getHalfDayStyle('#8B008B', 'pm', '#ff0000')) : this.getStyle('#8B008B', "#ff0000");
            if (vday === "LEAVE_DAY" && quota === "QUOTA_NOT_ALLOCATED")
                // styles[sday] = this.getStyle('#8B008B', "#A8A8A8")
                styles[sday] = (halfNoAM || halfNoPM) ? (halfNoAM ? this.getHalfDayStyle('#8B008B', 'am', '#A8A8A8') : this.getHalfDayStyle('#8B008B', 'pm', '#A8A8A8')) : this.getStyle('#8B008B', "#A8A8A8");
        }
        // #8B008B
        // #FF971D
        return styles
    };

    getModifiers = days => {
        let obj = {};
        for (let i in days) {
            let nday = i.split('/');
            obj[i] = new Date(nday[2], nday[1] - 1, nday[0])
        }
        return obj
    };

    getContrastYIQ(hexcolor) {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    getHalfDayStyle = (bg, ampm, underline) => {
        // const color = this.getContrastYIQ(bg);
        return ampm == 'pm' ? {
            color: 'black',
            fontWeight: 'bold',
            background: `linear-gradient(-45deg, ${bg} 55%, white 50%)`,
            textDecoration: "none",
            borderRadius: '0%',
            boxShadow: ` 0 -6px ${underline} inset`,
            // borderBottom: `6px solid ${underline}`
        } : {
                color: 'black',
                background: `linear-gradient(135deg, ${bg} 56%, white 50%)`,
                textDecoration: "none",
                fontWeight: 'bold',
                borderRadius: '0%',
                boxShadow: ` 0 6px ${underline} inset`,
                // borderTop: `6px solid ${underline}`
            }
    }
    getStyle = (bg, underline) => {

        return {
            color: 'white',
            backgroundColor: bg,
            textDecoration: "none",
            borderRadius: '0%',
            boxShadow: ` 0 -6px ${underline} inset`
            // borderBottom: `6px solid ${underline}`
        }
    };


    render = () => {
        const { _annualBalance, _pendingBalance, _cumulativeBalance, _numbersOfDaysOff, _lang, _publicHolidays, _leaveDay, _rttBalance, _ancienneteBalance } = strings;
        const { solde, references, addedClient } = this.props;
        // const {hr, estimated, totalDaysOff} = solde;
        const { hr, estimated, totalDaysOff, rtt, pendingRtt, anciennete, pendingAnciennete } = solde;
        const { daysOff, monthIndicator } = references;
        const { isProd, isTL } = this.state;
        const isPartialTime = Object.values(daysOff).some(d => d.type == "DAY_LEAVE_PART_TIME" || d.type == "DAY_OFF_PART_TIME");
        let modifiers = this.getModifiers(daysOff);
        let modifersStyes = this.getModifiersStyles(daysOff);
        const soldeList = rtt != undefined ? [
            { name: _annualBalance, value: hr },
            { name: _pendingBalance, value: estimated },
            { name: _cumulativeBalance, value: 0 },
            { name: _numbersOfDaysOff, value: totalDaysOff },
            { name: _rttBalance, value: rtt },
            { name: _ancienneteBalance, value: anciennete }
        ].map((value, index) => <Grid item> <ChipValueIndicator key={index}
                chip={value}  /> </Grid>)
            :
            [
                { name: _annualBalance, value: hr },
                { name: _pendingBalance, value: estimated },
                { name: _cumulativeBalance, value: 0 },
                { name: _numbersOfDaysOff, value: totalDaysOff },
            ]
                .map((value, index) => <Grid item> <ChipValueIndicator key={index}
                    chip={value} /> </Grid>)
        return (
            <div style={{ marginLeft: 130 }}>
                <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <div>
                                    <DayPicker
                                        modifiers={modifiers}
                                        modifiersStyles={modifersStyes}
                                        showOutsideDays
                                        localeUtils={MomentLocaleUtils}
                                        locale={_lang}
                                        month={monthIndicator}
                                    />
                                </div>
                            </Grid>
                            <div style={{ paddingTop: 70 }} className='my-legend'>
                                <div className='legend-scale'>
                                    <ul className='legend-labels'>
                                        <li><span style={{ background: "rgb(26, 147, 240)" }}></span>{_publicHolidays}</li>
                                        <li><span style={{ background: "rgb(234, 33, 97)" }}></span>Day-Off</li>
                                        <li><span style={{ background: "rgb(139, 0, 139)" }}></span>{_leaveDay}</li>
                                        {
                                            isProd && !isTL && <>
                                                <li><span style={{ background: "rgb(34, 139, 34)" }}></span>Quota disponible</li>
                                                <li><span style={{ background: "red" }}></span>Quota atteint</li>
                                                <li><span style={{ background: "rgb(157, 157, 157)" }}></span>Quota non paramétré</li>
                                                <li><span style={{ background: "rgb(255, 151, 29)" }}></span>Quota presque atteint</li>
                                            </>
                                        }
                                        {
                                            isPartialTime && <>
                                                <li><span style={{ background: "#294f9a" }}></span>Temps partiel</li>
                                            </>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Grid>

                    </Grid>
                    <>
                        {
                            !addedClient && <Grid style={{ paddingTop: 25 }} container direction={"row"} spacing={8}>

                                <Grid container direction={"row"} spacing={16}>
                                    {soldeList}
                                </Grid>
                            </Grid>
                        }
                        {/*<Grid container spacing={8}>*/}
                        {/*{*/}
                        {/*[{name: "Jour férié", color: "#1A93F0"},*/}
                        {/*{name: "Day-Off", color: "#EA2161"},*/}
                        {/*{name: "Jour travaillé", color: "#8B008B"},*/}
                        {/*]*/}
                        {/*.map((value, index) => <Grid item><ChipIndicator key={index}*/}
                        {/*chip={value}/></Grid>)*/}
                        {/*}*/}

                        {/*</Grid>*/}


                        {/*{*/}
                        {/*!addedClient && <Grid item>*/}
                        {/*<div style={{marginLeft: 30}}>*/}
                        {/*{*/}
                        {/*isProd && <>*/}

                        {/*<Grid container spacing={8}>*/}
                        {/*{*/}
                        {/*[{name: "Quota disponible", color: "#228b22"},*/}
                        {/*{name: "Quota atteint", color: "red"},*/}
                        {/*{name: "Quota non paramétré", color: "rgb(157,157,157)"},*/}
                        {/*]*/}
                        {/*.map((value, index) => <Grid item><ChipIndicator key={index}*/}
                        {/*chip={value}/></Grid>)*/}
                        {/*}*/}

                        {/*</Grid>*/}
                        {/*<Grid container spacing={8}>*/}
                        {/*<Grid item><ChipIndicator*/}
                        {/*chip={{name: "Quota presque atteint", color: "#FF971D"}}/></Grid>*/}
                        {/*</Grid>*/}
                        {/*</>*/}
                        {/*}*/}


                        {/*</div>*/}
                        {/*</Grid>*/}
                        {/*}*/}
                    </>

                </Grid>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        references: state.references,
        solde: state.solde,
        auth: state.auth
    }
};

let mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(Indicator)
