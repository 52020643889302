import React from 'react';
import {EditingState, IntegratedSorting, SortingState,} from '@devexpress/dx-react-grid';
import {
    DragDropProvider,
    Grid,
    Table,
    TableColumnReordering,
    TableEditColumn,
    TableEditRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {Getter} from '@devexpress/dx-react-core';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import {CustomTableRow, TableHeaderContent} from "../../../utilities/CustomTableElements";
import Typography from "@material-ui/core/Typography";
import MGrid from '@material-ui/core/Grid';
import {strings} from "../../../i18n/Strings";

const styles = theme => ({
    lookupEditCell: {
        paddingTop: theme.spacing.unit * 0.875,
        paddingRight: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
    },
    dialog: {
        width: 'calc(100% - 16px)',
    },
    inputRoot: {
        width: '100%',
    },
});

const AddButton = ({onExecute}) => (
    <div style={{marginRight: 30, padding: 20}}>
        <Button
            color="primary"
            variant={"raised"}
            onClick={onExecute}
        >  Ajouter <AddIcon/>
        </Button>

    </div>

    );

const EditButton = ({onExecute}) => (
    <IconButton onClick={onExecute} title="Editer">
        <EditIcon/>
    </IconButton>
);

const DeleteButton = ({onExecute}) => (
    <IconButton onClick={onExecute} title="Supprimer">
        <DeleteIcon/>
    </IconButton>
);

const CommitButton = ({onExecute}) => (
    <IconButton onClick={onExecute} title="Sauvegarder">
        <SaveIcon/>
    </IconButton>
);

const CancelButton = ({onExecute}) => (
    <IconButton onClick={onExecute} title="Annuler">
        <CancelIcon/>
    </IconButton>
);

const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton,
};

const Command = ({id, onExecute}) => {
    const CommandButton = commandComponents[id];
    return (
        <CommandButton
            onExecute={onExecute}
        />
    );
};


const Cell = (props) => {
    return <Table.Cell {...props} />;
};

const EditCell = (props) => {
    return <TableEditRow.Cell {...props} />;
};

const getLabel = rows => {
    if(rows.length > 0) {
        return rows[0].label
    }
    else { return ""}
}

const getRowId = row => row.id;

class ProvedByDocumentsGrid extends React.PureComponent {
    constructor(props) {
        super(props);
        const rows = [];
        this.state = {
            tableColumnExtensions: [{columnName: 'label', wordWrapEnabled: true}],
            rows,
            sorting: [],
            editingRowIds: [],
            addedRows: [],
            rowChanges: {},
            currentPage: 0,
            deletingRows: []
        };
        const getStateDeletingRows = () => {
            const {deletingRows} = this.state;
            return deletingRows;
        };
        const getStateRows = () => {
            const {rows} = this.state;
            return rows;
        };


        this.changeSorting = sorting => this.setState({sorting});
        this.changeEditingRowIds = editingRowIds => this.setState({editingRowIds});
        this.changeAddedRows = addedRows => this.setState({
            addedRows: addedRows.map(row => (Object.keys(row).length ? row : {
                id: String(Date.now()),
            })),
        });
        this.changeRowChanges = rowChanges => this.setState({rowChanges});
        this.commitChanges = ({added, changed, deleted}) => {
            let {rows} = this.state;
            if (added) {
                rows = [
                    ...rows,
                    ...added.map((row, index) => ({
                        ...row
                    })),
                ];
            }
            if (changed) {
                rows = rows.map(row => (changed[row.id] ? {...row, ...changed[row.id]} : row));
            }
            this.setState({rows, deletingRows: deleted || getStateDeletingRows()});
            this.setRows(rows);
        };
        this.setRows = rows => {
            // const {clientDetails} = store.getState().clientSearchReducer;
            // clientDetails["infoFiscaleSociales"] = rows.map( row => {
            //     const {document, id} = row;
            //     return {document, id}
            // });
            // store.dispatch(setClientDetails(clientDetails));
        };
        this.cancelDelete = () => this.setState({deletingRows: []});
        this.deleteRows = () => {
            const rows = getStateRows().slice();
            getStateDeletingRows().forEach((rowId) => {
                const index = rows.findIndex(row => row.id === rowId);
                if (index > -1) {
                    rows.splice(index, 1);
                }
            });
            this.setState({rows, deletingRows: []});
            this.setRows(rows);

        };
        this.changeColumnOrder = (order) => {
            this.setState({columnOrder: order});
        };
    }

    componentWillMount() {
        const docs = this.props.provedByDocuments;
        this.setState({rows: docs ? docs : []});
    }

    render() {
        const {classes} = this.props;
        const {_provedByDocuments} = strings;
        const columns = [
            {name: 'label', title: _provedByDocuments},
        ];
        const {
            rows,
            tableColumnExtensions,
            sorting,
            editingRowIds,
            addedRows,
            rowChanges,
            deletingRows,
            columnOrder,
        } = this.state;
        const containerStyle = {
            height: 200,
            position: "relative"
        };
        return (
            <Paper>
                <Grid
                    style={containerStyle}
                    rows={rows}
                    columns={columns}
                    getRowId={getRowId}
                >
                    <SortingState
                        sorting={sorting}
                        onSortingChange={this.changeSorting}
                    />
                    <EditingState

                        editingRowIds={editingRowIds}
                        onEditingRowIdsChange={this.changeEditingRowIds}
                        rowChanges={rowChanges}
                        onRowChangesChange={this.changeRowChanges}
                        addedRows={addedRows}
                        onAddedRowsChange={this.changeAddedRows}
                        onCommitChanges={this.commitChanges}
                    />

                    <IntegratedSorting/>

                    <DragDropProvider/>

                    <Table
                        columnExtensions={tableColumnExtensions}
                        rowComponent={CustomTableRow}
                        cellComponent={Cell}
                        noDataCellComponent={() => {
                            return <MGrid container direction={"column"} justify={"center"} alignItems={"center"}
                                          style={{width: 750, paddingTop: 30}}>
                                <MGrid item>
                                    <Typography variant="body2">
                                        Aucun Document
                                    </Typography>
                                </MGrid>
                            </MGrid>
                        }}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={this.changeColumnOrder}
                    />
                    <TableHeaderRow
                        contentComponent={TableHeaderContent}
                        showSortingControls/>
                    <TableEditRow
                        cellComponent={EditCell}
                    />
                    <TableEditColumn
                        width={120}
                        showAddCommand={!addedRows.length}
                        showEditCommand
                        showDeleteCommand
                        commandComponent={Command}
                    />
                    <Getter
                        name="tableColumns"
                        computed={({tableColumns}) => [
                            ...tableColumns.filter(c => c.type !== 'editCommand'),
                            {key: 'editCommand', type: 'editCommand', width: 140}]
                        }
                    />

                </Grid>

                <Dialog
                    open={!!deletingRows.length}
                    onClose={this.cancelDelete}
                    classes={{paper: classes.dialog}}
                >
                    <DialogTitle>
                        Confirmation de suppression pièce justificatif
                    </DialogTitle>
                    <DialogContent>
                        <br/>
                        <Typography variant="subtitle2" gutterBottom>
                            {getLabel((rows.filter(row => deletingRows.indexOf(row.id) > -1)))}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.cancelDelete} color="primary">
                            ANNULER
                        </Button>
                        <Button onClick={this.deleteRows} color="primary">
                            CONFIRMER
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles, {name: 'ProvedByDocumentsGrid'})(ProvedByDocumentsGrid));
