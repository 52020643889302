import React, {Component} from 'react';
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import {profile} from "../../styles/CommonStyles";
import SignOutMenu from './SignOutMenu'
import {connect} from "react-redux";
import {API, DEAFULT_AVATAR_CORE_SERVER} from "../../api/Endpoints";

class ProfileCard extends Component {
    render() {
        const {userName,firstName,lastName} = this.props.auth.profile;
        const {bigAvatar, padd_top_10} = profile;
        return (
            <Grid container direction={"row"} className={"profile-card"}>
                <Grid item sm={12}>
                    <Avatar
                        style={Object.assign({}, bigAvatar, padd_top_10)}
                    >
                        <img style={{height: 100, marginTop: 10}} src={`${API}/${DEAFULT_AVATAR_CORE_SERVER}/${userName}`} alt=""/>
                    </Avatar>
                </Grid>
                <Grid item sm={12}>
                    <SignOutMenu fullName={`${firstName} ${lastName}`}/>
                </Grid>
            </Grid>
        );
    }
}

let mapStateToProps = (state) => {
    return {auth: state.auth}
};

let mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(ProfileCard);
