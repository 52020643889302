import React, {Component} from 'react';
import CustomTableToolBar from '../../../utilities/CustomTableToolBar'
import Grid from './Grid';
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {getToCancelRequests} from "../../../actions/RequestsActions";
import RequestDetails from "../../utilities/RequestDetails";
import CancelRequestConfirmationDialog from "./CancelRequestConfirmationDialog";
import _ from 'lodash'
const styles = theme => ({});


class Requests extends Component {

    state = {
        filters: [],
        sort: ""
    };

    onChangeDebounceFilter = _.debounce(filters => this.changeFilters(filters), 500);

    changeFilters = (filters) => {
        this.setState({filters: filters.reduce(function(obj,item){
                obj[item.columnName] = item.value;
                return obj;
            }, {})}, () => {
            const {filters,sort} = this.state;
            const {
                addedForEmp,
                typeConge,
                returnDate,
                startDate,
                status
            } = filters;
            this.props.getToCancelRequests(0,25,addedForEmp,typeConge,returnDate,startDate,status)
        })
    };

    /** use direction attribute to sort selected column
     * @param sorting
     */
    changeSorting = (sorting) => {
        const {columnName, direction} = sorting[0];
        this.setState({sort: columnName, order: (direction === "asc")});
    };
    changePaging = ({page, size}) => {
        this.setState({page, size});
        this.props.getToCancelRequests(page - 1, size)
    };

    componentWillMount() {
        this.props.getToCancelRequests(0, 25)
    }

    render() {
        const {auth, requests} = this.props;
        const {toCancelRequests,  myRequestsHistory, myRequestsFollow, requestDetails, showRequestCancel} = requests;
        const {loading, data, totalElements} = toCancelRequests;
        const {type, details, open} = showRequestCancel;
        const {searchActive} = auth;
        return (
            <div>
                <CustomTableToolBar
                    loading={loading}
                    data={data}
                    changePaging={(page, size) => {
                        this.changePaging(page, size)
                    }}
                    totalPages={totalElements}
                />
                <Grid
                    searchActive={searchActive}
                    loading={loading}
                    data={data}
                    changeSorting={this.changeSorting}
                    changeFilters={e => this.onChangeDebounceFilter(e)}/>
                <RequestDetails
                myRequestsHistory={myRequestsHistory}
                myRequestsFollow={myRequestsFollow}
                details={requestDetails}
                />
                { type === "DELETE" && open && <CancelRequestConfirmationDialog details={details}/>}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {requests: state.requests, auth: state.auth}
};

let mapActionsToProps = {getToCancelRequests};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(Requests));

