import {getAxios, getIsProd, rolesToAdmin, setLoading, saveInSession} from "./AuthActions";
import {
    API,
    BACKUP,
    DAYS_OF,
    EMPLOYEE_CHILDREN,
    EXCEPT_TYPE_RH,
    MAPPING_TELEOPTI, MY_REQUESTS_FOLLOW,
    MY_UO,
    REJECTION_MOTIFS,
    EXCEPT_TYPE_RH_USER,
    CONFIG_USER,
    EXCEPTIONAL_TYPES_FR
} from "../api/Endpoints";
import {store} from "../store/ConfigureStore";
import {getTotalDaysOff} from "./SoldeActions";
import {setShowAddRequest} from "./RequestsActions";
import {getExceptionalHolidays, getPublicHolidays} from "./HolidaysActions";
import {getToasterMessage} from "../utilities/MappingFunctions";
import { strings } from "../i18n/Strings";
import moment from "moment";

export function getExceptTypeHrSuccess(payload) {
    return {
        type: "GET_EXCEPT_TYPE_RH",
        payload
    }
}
export function getExceptTypeHrFrSuccess(payload) {
    return {
        type: "GET_EXCEPT_TYPE_RH_FR",
        payload
    }
}
export function getExceptTypeHrUserSuccess(payload) {
    return {
        type: "GET_EXCEPT_TYPE_RH_USER",
        payload
    }
}

export function getBackupSuccess(payload) {
    return {
        type: "GET_BACKUP",
        payload
    }
}


export function setTeams(payload) {
    return {
        type: "SET_TEAMS",
        payload
    }
}

export function getDaysOffSuccess(payload, isLoading = false) {
    return {
        type: "GET_DAYS_OFF",
        payload,
        isLoading,
    }
}

export function getMyuoSuccess(payload) {
    return {
        type: "GET_UO",
        payload
    }
}

export function getEmployeeChildrenDone(payload) {
    return {
        type: "GET_EMPLOYEE_CHILDREN",
        payload
    }
}

export function setShowAddMapping(payload) {
    return {
        type: "SET_SHOW_ADD_MAPPING",
        payload
    }
}

export function setShowExcepHolidayDetails(payload) {
    return {
        type: "SET_SHOW_EXCEPTIONAL_HOLIDAY_DETAILS",
        payload
    }
}


export function toggleDrawer(payload) {
    return {
        type: "TOGGLE_DRAWER",
        payload
    }
}

export function getTeleoptiMappingDone(payload) {
    return {
        type: "GET_MAPPING_TELEOPTI",
        payload
    }
}

export function setDeleteMappingDialog(payload) {
    return {
        type: "SET_DELETE_MAPPING",
        payload
    }
}

export function getRejectionMotifsDone(payload) {
    return {
        type: "GET_REJECTION_MOTIFS",
        payload
    }
}

export function getRejectionMotifsLabelsDone(payload) {
    return {
        type: "GET_REJECTION_LABELS_MOTIFS",
        payload
    }
}

export function showToaster(payload) {
    return {
        type: "SHOW_TOASTER",
        payload
    }
}

export function setMonthIndicator(payload) {
    return {
        type: "SET_MONTH_INDICATOR",
        payload
    }
}

export function handleChangeCheckboxes(payload) {
    return {
        type: "SET_AM_PM_DATES",
        payload
    }
}

export function addRejectionMotifDone(payload) {
    return {
        type: "SET_MONTH_INDICATOR",
        payload
    }
}

export const addRejectionMotif = (motif, type) => {
    return async dispatch => {
        const axios = getAxios();
        const {_reasonOfRejection, _edit, _create, _withSuccess} = strings;
        if( type === "EDIT") {
            await axios.put(`${API}/conges/rejectionReason`, motif);
        }
        else {
            delete motif.id;
            await axios.post(`${API}/conges/rejectionReason`, motif);
        }

        dispatch(showTimedToaster({open: true, message: _reasonOfRejection+` ${ (type === "EDIT") ? _edit: _create} ` + _withSuccess, type: "success"}));
        dispatch(getRejectionMotifs(0,25));
    }
};

export const deleteRejectionReason = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respDeleteRejectionReason} = strings;
        await axios.delete(`${API}/conges/rejectionReason/${id}`);
        dispatch(showTimedToaster({open: true, message: _respDeleteRejectionReason, type: "success"}));
        dispatch(getRejectionMotifs(0,25));
    }
}


export const showTimedToaster = (payload) => {
    return async dispatch => {
        dispatch(showToaster(payload));
        setTimeout(() => {
            dispatch(showToaster({open: false, message: "               ", type: payload.type}));
        }, 6000)
    }
};




export const getRejectionMotifsLabels = (registrationNumber, requestId, countryCode) => {
    return async dispatch => {
        const axios = getAxios();
        const myRequestsFollow = await axios.get(`${API}/${MY_REQUESTS_FOLLOW}/${requestId}`)
        const follow = myRequestsFollow.data.filter( step => step.state === "PENDING");
        const {role} = follow[0];
        const mappings = {
            "RH_VALIDATION": "ADMIN_RH",
            "MANAGER_VALIDATION": "ADMIN_MANAGER",
            "PPV_VALIDATION": "ADMIN_PPV",
        };
        let rejectionReasons = [];
        if (countryCode === 'DO') {
            rejectionReasons = await axios.get(`${API}/conges/rejectionReason/role/${registrationNumber}?role=${mappings[role]}&includeDefault=false`);
        } else {
            rejectionReasons = await axios.get(`${API}/conges/rejectionReason/role/${registrationNumber}?role=${mappings[role]}`);
        }
        dispatch(getRejectionMotifsLabelsDone(rejectionReasons.data))
    }
};

export function setShowAddRejectionMotif(payload) {
    return {
        type: "SET_SHOW_ADD_REJECTION_MOTIF",
        payload
    }
}

export function setConfigUserSuccess(payload) {
    console.log('payload', JSON.stringify(payload));
    return {
        type: "GET_CONFIG_USER",
        payload
    }
}

export const getReferences = () => {
    return async dispatch => {
        const {profile} = store.getState().auth;
        const axios = getAxios();
        const exceptTypeHr = await axios.get(`${API}/${EXCEPT_TYPE_RH}?user=${profile.userName}`);
        dispatch(getExceptTypeHrSuccess(exceptTypeHr.data))
    }
};
export const getReferencesFrance = () => {
    return async dispatch => {
        const axios = getAxios();
        const exceptTypeHrFr = await axios.get(`${API}/${EXCEPTIONAL_TYPES_FR}`);
        dispatch(getExceptTypeHrFrSuccess(exceptTypeHrFr.data))
    }
};

export const getConfigUser = () => {
    return async dispatch => {
        const axios = getAxios();
        const configUserResp = await axios.get(`${API}/${CONFIG_USER}`).catch(err =>{ throw err});
        saveInSession("configUser", JSON.stringify(configUserResp.data));
        dispatch(setConfigUserSuccess(configUserResp.data))
    }
};

export const getReferencesUser = () => {
    return async dispatch => {
        const {profile} = store.getState().auth;
        const axios = getAxios();
        const exceptTypeHr = await axios.get(`${API}/${EXCEPT_TYPE_RH_USER}?user=${profile.userName}`);
        console.log(exceptTypeHr);
        dispatch(getExceptTypeHrUserSuccess(exceptTypeHr.data))
    }
};

export const getBackup = () => {
    return async dispatch => {
        dispatch(setLoading(true));
        const {profile} = store.getState().auth;
        const res = await getIsProd(profile.userName);
        const isProd = res.data;
        const axios = getAxios();
        axios.get(`${API}/${BACKUP}?user=${profile.userName}&isProd=${isProd}`)
            .then( backup => {
                dispatch(getBackupSuccess(backup.data));
                dispatch(setLoading(false))
            })
            .catch( e => {
                // dispatch(showTimedToaster({open: true, message: e.response.data.message, type: "warning"}));
                dispatch(setLoading(false));
            })
        ;

    }
};

export const getMyuo = () => {
    return async dispatch => {
        const axios = getAxios();
        const uo = await axios.get(`${API}/${MY_UO}`).catch(err => err);
        dispatch(getMyuoSuccess(uo.data));
    }
};

export const getDaysOff = (startDate, returnDate, user = null) => {
    return async dispatch => {
        const registrationNumber = user == null ? store.getState().auth.profile.userName : user.registrationNumber;
        const res = await getIsProd(registrationNumber);
        const isProd = res.data;
        const axios = getAxios();
        const request = {
            "@type": "MyCongeVO",
            addedForEmp: {
                registrationNumber,
                role: [],
                prod: isProd
            },
            duration: 0,
            hasBackup: false,
            returnDate: returnDate,
            startDate: startDate,
            typeRequest: "REQUEST"
        };
        dispatch(setLoading(true));
        axios.post(`${API}/${DAYS_OF}`, request)
            .then(daysOff => {
                dispatch(getDaysOffSuccess(daysOff.data));
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(showTimedToaster({
                    open: true,
                    message: "Planning non disponible",
                    type: "warning"}));
                // dispatch(showTimedToaster({open: true, message: getToasterMessage(e.response.data.message), type: "warning"}));
                dispatch(setLoading(false));
            })

        ;

    }
};

export const getDaysOffFr = (startDate, returnDate, registrationNumber) => {
    return async dispatch => {
        const {profile} = store.getState().auth;
        const res = await getIsProd(profile.userName);
        const isProd = res.data;
        const axios = getAxios();
        const request = {
            "@type": "MyCongeVO",
            addedForEmp: {
                registrationNumber: registrationNumber,
                role: [],
                prod: isProd
            },
            duration: 0,
            hasBackup: false,
            returnDate: returnDate,
            startDate: startDate,
            typeRequest: "REQUEST"
        };
        dispatch(setLoading(true));
        axios.post(`${API}/${DAYS_OF}`, request)
            .then(daysOff => {
                dispatch(getDaysOffSuccess(daysOff.data));
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(showTimedToaster({
                    open: true,
                    message: "Planning non disponible",
                    type: "warning"}));
                // dispatch(showTimedToaster({open: true, message: getToasterMessage(e.response.data.message), type: "warning"}));
                dispatch(setLoading(false));
            })

        ;

    }
};

export const getDaysOffExcep = (startDate, exceptionalType, typeConge = "EXCEPTIONALHOLIDAY", user = null, callBack) => {
    return async dispatch => {
        const registrationNumber = user == null ? store.getState().auth.profile.userName : user.registrationNumber;
        const res = await getIsProd(registrationNumber);
        const isProd = res.data;
        const axios = getAxios();
        const request = {
            "@type": typeConge === "EXCEPTIONALHOLIDAY" ? "ExceptionalCongeVO" : "AbscenceVO",
            "addedForEmp": {
                registrationNumber,
                role: [],
                prod: isProd
            },
            "startDate": startDate,
            "duration": 0,
            "totalDuration": 0,
            "typeConge": typeConge,
            "typeRequest": "REQUEST",
            "hasBackup": false
        };
        if(typeConge === "EXCEPTIONALHOLIDAY") {
            request["exceptionalType"] = exceptionalType;
        }
        dispatch(getDaysOffSuccess([], true));
        const daysOff = await axios.post(`${API}/${DAYS_OF}`, request);
        dispatch(getDaysOffSuccess(daysOff.data, false));
        dispatch(getTotalDaysOff(Object.values(daysOff.data).filter(e => e === "DAY_OFF").length))

        if(callBack) {
            let moments = [...Object.keys(daysOff.data)].map(d => moment(d, "DD/MM/YYYY"));
            let endDate = moment.max(moments);
            callBack(endDate);
        }
    }
};


export const getEmployeeChildren = (registrationNumber) => {
    return async dispatch => {
        const axios = getAxios();
        const {profile} = store.getState().auth;
        dispatch(setLoading(true));
        const employees = await axios.get(`${API}/${EMPLOYEE_CHILDREN}/${profile.userName}?q=${registrationNumber}`);
        dispatch(getEmployeeChildrenDone(employees.data));
        dispatch(setLoading(false));

    }
};


export const getTeleoptiMapping = (page, size, abbreviation, country) => {
    return async dispatch => {
        const axios = getAxios();
        const {references} = store.getState();
        const {data, totalElements} = references.teleoptiMapping;
        dispatch(getTeleoptiMappingDone({loading: true, data, totalElements}));
        let teleoptiMapping = await axios.post(`${API}/${MAPPING_TELEOPTI}`, {
            "label": null,
            "abbreviation": abbreviation,
            "country": country,
            "sort": null,
            "order": null,
            "page": page,
            "size": size
        });
        dispatch(getTeleoptiMappingDone({
            loading: false,
            data: teleoptiMapping.data.payload,
            totalElements: teleoptiMapping.data.totalElements
        }));
    }
};


export const getRejectionMotifs = (page, size, country, label, criticality, state) => {
    return async dispatch => {
        const axios = getAxios();
        const {references} = store.getState();
        const {activeCountries} = references;
        const {profile} = store.getState().auth;
        const role = profile.authorities
            .filter(e => e.clientId === "conges")
            .map(e => e.authority.replace("ROLE_", "").replace("_CONGE", ""))
            .filter(e => ["ADMIN_PPV", "ADMIN_RH", "ADMIN_MANAGER"].includes(e))[0];
        const {data, totalElements} = references.rejectionMotifs;
        dispatch(getRejectionMotifsDone({loading: true, data, totalElements}));
        let rejectionMotifs = await axios.post(`${API}/${REJECTION_MOTIFS}`, {
            "label": label,
            "state": state,
            "criticality": criticality,
            "sort": null,
            "order": null,
            "roleEnum": [role],
            "country": country,
            "page": page,
            "size": size
        });
        let $rejectionMotifs = rejectionMotifs.data.payload || [];
        let _rejectionMotifs = [];
        $rejectionMotifs.map(r => {
            if (r.allCountries) {
                let _r = r;
                _r.countries = activeCountries;
                _rejectionMotifs.push(_r)
            } else {
                _rejectionMotifs.push(r)
            }
        });
        dispatch(getRejectionMotifsDone({
            loading: false,
            data: _rejectionMotifs,
            totalElements: rejectionMotifs.data.totalElements
        }));
    }
};


export const editExceptionalHoliday = (exceptionalHoliday) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respEditExceptionalHoliday} = strings;
        await axios.put(`${API}/conges/exceptionalType`, {...exceptionalHoliday,  "roleEnum": "RH_VALIDATION", "requiresEndDate": false});
        dispatch(showTimedToaster({open: true, message: _respEditExceptionalHoliday, type: "success"}))
        dispatch(getExceptionalHolidays(0,25));
    }
};


export const addExceptionalHoliday = (exceptionalHoliday) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respAddExceptionalHoliday} = strings;
        await axios.post(`${API}/conges/exceptionalType`, {...exceptionalHoliday,  "roleEnum": "RH_VALIDATION"});
        dispatch(showTimedToaster({open: true, message: _respAddExceptionalHoliday, type: "success"}))
        dispatch(getExceptionalHolidays(0,25));
    }
};


export const deleteExceptionalHoliday = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respDeleteExceptionalHoliday} = strings;
        await axios.delete(`${API}/conges/exceptionalType/${id}`);
        dispatch(showTimedToaster({open: true, message: _respDeleteExceptionalHoliday, type: "success"}))
        dispatch(getExceptionalHolidays(0,25));
    }
};


export const addPublicHoliday = (publicHoliday) => {
    return async dispatch => {
        const axios = getAxios();
        const {userName} = store.getState().auth.profile;
        const {_respAddPublicHoliday} = strings;
        axios.post(`${API}/conges/publicHolidays`, {...publicHoliday,  addedByEmp: {registrationNumber: userName, roles: [], prod: false}})
            .then( res => {

                dispatch(showTimedToaster({open: true, message: _respAddPublicHoliday, type: "success"}));
                dispatch(getPublicHolidays(0,25));
            })
            .catch( e => {

                dispatch(showTimedToaster({open: true, message: e.response.data.message, type: "warning"}));
            })
    }
};


export const editPublicHoliday = (publicHoliday) => {
    return async dispatch => {
        const axios = getAxios();
        const {userName} = store.getState().auth.profile;
        const {_respEditPublicHoliday} = strings;
        axios.put(`${API}/conges/publicHolidays`, {...publicHoliday,  addedByEmp: {registrationNumber: userName, roles: [], prod: false}})
            .then( res => {

                dispatch(showTimedToaster({open: true, message: _respEditPublicHoliday, type: "success"}));
                dispatch(getPublicHolidays(0,25));
            })
            .catch( e => {

                dispatch(showTimedToaster({open: true, message: e.response.data.message, type: "warning"}));
            })

    }
};


export const deletePublicHoliday = (id) => {
    return async dispatch => {
        const axios = getAxios();
        const {_respDeletePublicHoliday} = strings;
        await axios.delete(`${API}/conges/publicHolidays/${id}`);
        dispatch(showTimedToaster({open: true, message: _respDeletePublicHoliday, type: "success"}))
        dispatch(getPublicHolidays(0,25));
    }
};
