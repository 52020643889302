
export const environmentPreprod = {
    production: false,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'http://dev.oauth.intelcia.com/uaa/oauth/token',
        authorize: 'http://dev.oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '79c4aV31X8Eg52c',
        client_id: 'intranet-liferay',
        response_type: 'code',
        redirectTo: 'http://dev.myconges-react.intelcia.com',
    }
};




export const environmentProd = {
    production: true,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '79c4aV31X8Eg52c',
        client_id: 'intranet-liferay',
        response_type: 'code',
        redirectTo: 'https://myvacation.intelcia.com',
    }
};


export const environmentLocalDev = {
    production: true,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '79c4aV31X8Eg52c',
        client_id: 'intranet-liferay',
        response_type: 'code',
        redirectTo: 'http://localhost:3000',
    }
};

