import React, {Component} from 'react';
import ReactExport from 'react-data-export';
import Button from "@material-ui/core/Button";
import {getHolidayType, getStatus, states} from "../../../utilities/MappingFunctions";
import {getAxios} from "../../../actions/AuthActions";
import {API, HR_REQUESTS} from "../../../api/Endpoints";
import AddIcon from '@material-ui/icons/Assignment';
import moment from "moment";
import {strings} from "../../../i18n/Strings";
import {withStyles} from "@material-ui/core";
import connect from "react-redux/lib/connect/connect";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function mapHolidayType (row){
    const {
        _exceptionalHoliday
    } = strings;
    if(row.typeConge === "EXCEPTIONALHOLIDAY" && row.exceptionalType) {
        return row.exceptionalType.label;
    }
    return "";
}

class ExportHRRequests extends Component {

    state = {
        dataToExport: [{columns: [""], data: [{value: ""}]}]
    };

    componentWillMount() {
    }

    exportExcel () {
        const axios = getAxios();
        axios.post(`${API}/${HR_REQUESTS}`, {
            "sort": null,
            "order": false,
            "page": 0,
            "size": 100000
        }).then(res => {
            let dataToExport = res.data.payload
                .filter(h => h.typeRequest === "REQUEST")
                .map(h => {

                    const {duration, returnDate, startDate, totalDuration, updateDate, typeConge} = h;
                    const {_registrationNumber, _firstName, _lastName, _holidayType, _startDate, _returnDate, _status,
                        _duration, _totalDuration, _deposit_date, _typeOfsocialLeave} = strings;
                    const statesItem = getStatus();
                    console.log( "h :", h);

                    return {
                        [_registrationNumber]: h.addedForEmp.registrationNumber,
                        [_firstName]: h.addedForEmp.firstName,
                        [_lastName]: h.addedForEmp.lastName,
                        [_holidayType]: getHolidayType(h.typeConge, h.addedForEmp.country.code === 'FR'),
                        [_typeOfsocialLeave]: mapHolidayType(h),
                        [_returnDate] : returnDate,
                        [_startDate] : startDate,
                        [_status]: statesItem[h.status],
                        [_duration]: duration,
                        [_totalDuration]: totalDuration,
                        [_deposit_date]: updateDate
                    }
                });
            this.setState({dataToExport})
        });

    }

    render() {
        const {dataToExport} = this.state;


        console.log("this.props.dataToExport  ", dataToExport);
        return (
            <div>
                <ExcelFile filename={`Extraction ${moment().format('DD/MM/YYYY hh:mm:ss')}`}  element={<Button title={"Extraire les congés"} id={"fab"} color={"primary"} variant={"fab"}
                                                                                                               onClick={()=> {
                                                                                                                   this.exportExcel()
                                                                                                               }}>
                    <AddIcon/>
                </Button>}>
                    <ExcelSheet dataSet={
                        [{
                            columns: Object.keys(dataToExport[0] || {}),
                            data: (dataToExport || []).map(o => {
                                return Object.values(o).map(e => {
                                    return {"value": e}
                                })
                            })
                        }
                        ]} name={`username~`}/>
                </ExcelFile>
            </div>
        );
    }
}
let mapStateToProps = (state) => {
    return {data: state}
};
export default connect(mapStateToProps)(ExportHRRequests);
